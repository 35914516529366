<h6 class=" font-18 font-weight-400  mt-4 mb-3 text-left product-heading title">
    Digital Voucher {{isEdit ? 'Update':'Creation'}}</h6>
<div class="card">
    <div class="card-body">
        <form #voucherRequestForm="ngForm" name="form" (ngSubmit)="requestVoucher(requestCouponVoucher)">
            <div class="row flex-wrap">

    
                <div class="col-4">
                    <div class="md-form form-sm my-3 my-input-class">
                        <label for="webshopName" class="mb-2">Client<span class="text-danger">*</span></label>

                        <ng-autocomplete [data]="companyData" [searchKeyword]="companyKeyword"
                            placeholder="Select Client" [itemTemplate]="itemTemplate"
                            [ngClass]="{'disabled': role === 'Company'}" (keydown)="restrictEnter($event)"
                            [(ngModel)]="requestCouponVoucher.company_name" name="company_name" mdbInput
                            autocomplete="on" (inputCleared)='productClear($event)'
                            (inputChanged)='onChangeCompany($event)' [disabled]="true"
                            (selected)='selectCompanyEvent($event)' #company_name="ngModel" required
                            [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.CompanyName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                        <span class="text-danger"
                            *ngIf="(company_name.touched || voucherRequestForm.submitted) && company_name.errors?.['required'] ">
                            client is required
                        </span>

                    </div>
                </div>
                <div class="col-4">
                    <div class="md-form form-sm my-3 my-input-class">
                        <label *ngIf="brandlabelshow" for="webshopName" class="mb-2">Product
                            <span class="text-danger">*</span>
                        </label>

                        <ng-autocomplete [data]="brandData" [searchKeyword]="brandKeyword" placeholder="Select Product"
                            [itemTemplate]="itemTemplatebrand" [disabled]="brandDisabled && role == 'brand'"
                            [ngClass]="{'disabled': role === 'brand'}" [(ngModel)]="requestCouponVoucher.brand_name"
                            name="brand_name" #brand_name="ngModel" required mdbInput autocomplete="on"
                            (inputChanged)='onChangeBrand($event)' (inputCleared)='validityClear($event)'
                            (selected)='selectBrandEvent($event)' (keydown)="restrictEnter($event)"
                             required [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
                        <ng-template #itemTemplatebrand let-item>
                            <a [innerHTML]="item.BrandName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                        <span class="text-danger" *ngIf="
                            (brand_name.touched || voucherRequestForm.submitted) &&
                            brand_name.errors?.['required']
                          ">
                            product is required
                        </span>
                    </div>
                </div>


                


                


                <div class="col-4 no-icon" *ngIf="usagehide">
                    <div class="md-form form-sm my-3">
                        <label class="mb-2"> Usage mode</label>

                        <input class="form-control" [(ngModel)]="requestCouponVoucher.usagemode" name="usagemode"
                            #usagemode="ngModel" disabled>

                    </div>
                </div>


                <!-- <div class="col-4 no-icon" *ngIf = "maxdiscountshow">
                    <div class="md-form form-sm my-3">
                        <label class="mb-2">Maximum Discount</label>        
                        
                        <input class="form-control"                        
                            [(ngModel)]="requestCouponVoucher.maxdiscount" name="maxdiscount" #maxdiscount="ngModel" disabled>                             
                     
                    </div>
                </div> -->




                <div class="col-4">
                    <div class="md-form form-sm my-3 position-relative">

                        <label for="issuance_date" class="mb-2">Issuance Date
                            <span class="text-danger">*</span>
                        </label>

                        <input (click)="di.toggleCalendar()" [(ngModel)]="getIssuanceDate" name="issuance"
                            placeholder="Select A Date" readonly class="form-control datepicker" ngx-mydatepicker
                            [options]="issuanceOptions" #di="ngx-mydatepicker" type="text" #issuance="ngModel"
                            (dateChanged)="onIssuanceDateChanged($event)" (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="di.toggleCalendar()" (click)="di.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger"
                            *ngIf="(voucherRequestForm.submitted) && issuance.errors?.['required']">
                            issuance date is required
                        </span>
                    </div>
                </div>

                <div class="col-4">
                    <div class="md-form form-sm my-3 position-relative">

                        <label for="start_date" class="mb-2">Start Date
                            <span class="text-danger">*</span>
                        </label>

                        <input (click)="dp.openCalendar()" [(ngModel)]="getstartDate" name="start"
                            placeholder="Select A Date" class="form-control datepicker" ngx-mydatepicker
                            [options]="startOptions" readonly #dp="ngx-mydatepicker" type="text" #start="ngModel"
                            (dateChanged)="onStartDateChanged($event)" (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="dp.openCalendar()" (click)="dp.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger" *ngIf="(voucherRequestForm.submitted) && start.errors?.['required']">
                            start date is required
                        </span>
                    </div>
                </div>


                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label for="countryList" class="mb-2">Validity
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" min="1" [attr.maxlength]="3" placeholder="60 Days"
                            [(ngModel)]="requestCouponVoucher.validity" name="validity" #validity="ngModel"
                            (keypress)="onKeyPresscomma($event);numberOnly($event);restrictAlphabets($event)"
                            (input)="restrictDecimals($event)" [disabled]="isDatepickerDisabled"
                            [ngClass]="{'disabled': requestCouponVoucher.status === 'G'}" mdbInput autocomplete="off"
                            required>
                        <span class="text-danger" *ngIf="
                        (validity.touched || voucherRequestForm.submitted) &&
                        validity.errors?.['required']
                      ">
                            validity is required
                        </span>
                    </div>
                </div>


                <div class="col-4">
                    <div class="md-form form-sm my-3">
                        <label for="selectType" class="mb-2">Model
                            <span class="text-danger">*</span>
                        </label>
                        <select id="selectType" class="form-select" [(ngModel)]="requestCouponVoucher.type" name="type"
                            #type="ngModel" required [disabled]="role === 'Company' || role === 'brand'"
                            (change)='selectModelEvent(requestCouponVoucher.type)'>
                            <option value="" selected disabled hidden>Select Model</option>
                            <option value="ID">Issuance & Distribution</option>
                            <option value="I">Issuance</option>
                        </select>
                        <span class="text-danger" *ngIf="
                    (type.touched || voucherRequestForm.submitted) &&
                    type.errors?.['required']
                  ">
                            model is required
                        </span>
                    </div>
                </div>


                <div class="col-4">
                    <div class="md-form form-sm my-3">
                        <label for="selectCategory" class="mb-2">Type
                            <span class="text-danger">*</span>
                        </label>
                        <select id="selectCategory" class="form-select" [(ngModel)]="requestCouponVoucher.category"
                            name="category" #category="ngModel" required>
                            <option value="" selected disabled hidden class="optionDisabled">Select Type</option>
                            <option value="Physical">Physical</option>
                            <option value="Digital">Digital</option>
                        </select>
                        <!-- <span class="text-danger"
                            *ngIf="(category.touched || voucherRequestForm.submitted) && category.errors?.['required']">
                            Category is required
                        </span> -->
                    </div>
                </div>


                <div class="col-4" *ngIf="discounttypehide">
                    <div class="md-form form-sm my-3">
                        <label for="kart_type" class="mb-2">Discount Type <span class="text-danger">*</span></label>
                        <select id="kart_type" class="form-select" [(ngModel)]="requestCouponVoucher.kart_type"
                             name="kart_type" #kart_type="ngModel"
                            (change)="selectdistypeEvent(requestCouponVoucher.kart_type,false)" required disabled>

                            <option value="" selected disabled hidden>Select Discount Type</option>
                            <option value="0">Percentage</option>
                            <option value="1">Value</option>

                        </select>
                        <span class="text-danger" *ngIf="
                            (kart_type.touched || voucherRequestForm.submitted) &&
                            kart_type.errors?.['required']
                          ">
                            discount type is required
                        </span>
                    </div>
                </div>



                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label for="countryList" class="mb-2">Quantity
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" placeholder="Enter Quantity"
                            (input)="restrictDecimals($event)" [(ngModel)]="requestCouponVoucher.quantity"
                            name="quantity" #quantity="ngModel" (ngModelChange)="calculate()"
                            (keypress)="numberOnly($event);restrictAlphabets($event);restrictspecialchars($event)"
                            (keydown)="keyDownHandler($event)"
                            [ngClass]="{'disabled': requestCouponVoucher.status === 'G'}" mdbInput autocomplete="off"
                            restrictChars required>
                        <span class="text-danger" *ngIf="
                            (quantity.touched || quantity.dirty || voucherRequestForm.submitted) &&
                            quantity.errors?.['required']
                          ">
                            quantity is required
                        </span>
                    </div>
                </div>


                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label for="countryList" class="mb-2"> Denomination - Face Value
                            <span class="text-danger">*</span>
                            <span style="color: blue; font-weight: 600;" *ngIf="minmaxshow && minprice && maxprice"
                                class="minmax" title="(Min {{minprice}} - Max {{maxprice}})">(Min {{minprice}} - Max
                                {{maxprice}})</span>
                        </label>
                        <input type="text" class="form-control" 
                            [disabled]="denomDisabled" 
                            (keypress)="allowOnlyNumbers($event)"
                            (keydown)="keyDownHandler($event)" [(ngModel)]="requestCouponVoucher.denomination"
                            placeholder="Enter Denomination" name="denomination" (ngModelChange)="calculate()"
                            #denomination="ngModel" mdbInput autocomplete="off" restrictChars required>
                        <span class="text-danger" *ngIf="
                            (denomination.touched || denomination.dirty || voucherRequestForm.submitted) &&
                            denomination.errors?.['required']
                          ">
                            denomination is required
                        </span>
                    </div>
                </div>


                <div class="col-4 no-icon" *ngIf="totalfacevalueshow">
                    <div class="md-form form-sm my-3">
                        <label class="mb-2"> Total Face Value</label>

                        <input class="form-control" [(ngModel)]="requestCouponVoucher.totalfacevalue"
                            name="totalfacevalue" #totalfacevalue="ngModel" disabled>

                    </div>
                </div>


                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label class="mb-2"> Discount
                            <span class="text-danger">*</span>
                            <span style="color: blue; font-weight: 700;" *ngIf="maxdiscountshow && discountvalue"
                                class="minmax">(Max Discount - {{discountvalue}}% )</span>

                        </label>
                        <input type="text" class="form-control" placeholder="Enter Discount"    
                           (keypress)="allowOnlyNumbers($event)"
                            (keydown)="keyDownHandler($event)" [(ngModel)]="requestCouponVoucher.discount" (ngModelChange)="calculate()"
                            name="discount" #discount="ngModel" mdbInput disabled autocomplete="off"
                            [attr.maxlength]="requestCouponVoucher.kart_type == 0 ? '2' : '8' " restrictChars required>
                        <span class="text-danger" *ngIf="
                            (discount.touched || discount.dirty || voucherRequestForm.submitted) &&
                            discount.errors?.['required']
                          ">
                            discount is required
                        </span>
                    </div>
                </div>

                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label for="selectdealvalue" class="mb-2"> Issuance Fee
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" placeholder="Enter Issuance Fee"
                            (keypress)="allowOnlyNumbers($event)" (ngModelChange)="calculate()"                         
                            (keydown)="keyDownHandler($event)" [(ngModel)]="requestCouponVoucher.issuance_fee"
                            name="issuance_fee" #issuance_fee="ngModel" mdbInput disabled
                            
                            autocomplete="off" restrictChars required>
                        <span class="text-danger" *ngIf="
                            (issuance_fee.touched || issuance_fee.dirty || voucherRequestForm.submitted) &&
                            issuance_fee.errors?.['required']
                          ">
                            issuance fee is required
                        </span>
                    </div>
                </div>


                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label *ngIf="!totalshow" class="mb-2"> Total</label>
                        <label *ngIf="totalshow" class="mb-2"> Total Payable Brand</label>

                        <input class="form-control" [(ngModel)]="requestCouponVoucher.total" name="total"
                            #total="ngModel" disabled>

                    </div>
                </div>



                <!-- <div class="col-4">
                    <div class="md-form form-sm my-3">
                        <label for="payment_status" class="mb-2">Payment
                            <span class="text-danger">*</span>
                        </label>
                        <select id="payment_status" class="form-select" [(ngModel)]="requestCouponVoucher.payment_status"  [disabled]="fetchDisabled"
                            name="payment_status">
                            <option value="" selected disabled hidden>Select Payment Status</option>
                            <option value="0">Advance</option>
                            <option value="1">Credit</option>
                        </select>
                        <span class="text-danger" *ngIf="
                        (payment_status.touched || voucherRequestForm.submitted) &&
                        payment_status.errors?.['required']
                      ">
                      payment status type is required
                    </span>
                    </div>
                </div> -->


                <div class="col-4">
                    <div class="md-form form-sm my-3">
                        <label for="payment_status" class="mb-2">Payment
                            <span class="text-danger">*</span>
                        </label>
                        <select id="payment_status" class="form-select"
                            [(ngModel)]="requestCouponVoucher.payment_status" [disabled]="fetchDisabled"
                            name="payment_status" #payment_status="ngModel" required>

                            <option value="" selected disabled hidden>Select Payment Status</option>
                            <option value="0">Advance</option>
                            <option value="1">Credit</option>

                        </select>
                        <span class="text-danger" *ngIf="
                        (payment_status.touched || voucherRequestForm.submitted) &&
                        payment_status.errors?.['required']
                      ">
                            payment is required
                        </span>

                    </div>
                </div>

                <div class="col-4" *ngIf="hideauthfield">
                    <div class="md-form form-sm my-3">
                        <label for="authentication_type" class="mb-2">Authentication Type
                            <span class="text-danger">*</span>
                        </label>
                        <select id="authentication_type" class="form-select"
                            [(ngModel)]="requestCouponVoucher.authentication_type" [disabled]="otpDisabled"
                            name="authentication_type" #authentication_type="ngModel" required>

                            <option value="" selected disabled hidden>Select Authentication Type</option>
                            <option value="OTP" *ngIf="otpshow">OTP</option>
                            <option value="PIN">Pin</option>

                        </select>
                        <span class="text-danger" *ngIf="
                        (authentication_type.touched || voucherRequestForm.submitted) &&
                        authentication_type.errors?.['required']
                      ">
                            authentication type is required
                        </span>

                    </div>
                </div>
                <div class="col-4" *ngIf="hideauthfield">
                    <div class="md-form form-sm my-3">
                        <label for="voucher_redemption_type" class="mb-2">Voucher Redemption Type
                            <span class="text-danger">*</span>
                        </label>
                        <select id="voucher_redemption_type" class="form-select"
                            [(ngModel)]="requestCouponVoucher.voucher_redemption_type" [disabled]="fetchDisabled"
                            name="voucher_redemption_type" #voucher_redemption_type="ngModel" required>

                            <option value="" selected disabled hidden>Choose Redemption Type</option>
                            <option value="Single">Single</option>
                            <option value="Multiple">Multiple</option>

                        </select>
                        <span class="text-danger" *ngIf="
                        (voucher_redemption_type.touched || voucherRequestForm.submitted) &&
                        voucher_redemption_type.errors?.['required']
                      ">
                            Voucher Redemption Type is required
                        </span>

                    </div>
                </div>
                <div class="col-4" *ngIf="hideauthfield">
                    <div class="md-form form-sm my-3">
                        <label for="voucher_redemption_url" class="mb-2">Redemption URL
                            <span class="text-danger">*</span>
                        </label>
                        <input id="voucher_redemption_url" class="form-control" (input)="validateUrl()" type="text"
                            [(ngModel)]="requestCouponVoucher.voucher_redemption_url" [disabled]="fetchDisabled"
                            name="voucher_redemption_url" #voucher_redemption_url="ngModel"
                            placeholder="Voucher Redemption URL" required>
                        <span class="text-danger" *ngIf="
                        (voucher_redemption_url.touched || voucherRequestForm.submitted) && 
                        voucher_redemption_url.errors?.['required']
                      ">
                            URL is required
                        </span>
                        <span class="text-danger" *ngIf="(!isValidUrl)">
                            Please enter valid data
                        </span>
                    </div>
                </div>

                <div class="col-4">
                    <div class="md-form form-sm my-3">
                        <label for="voucher_delivery_type" class="mb-2">Delivery Type
                            <span class="text-danger">*</span>
                        </label>
                        <select id="voucher_delivery_type" class="form-select" (change)="changeemail()"
                            [(ngModel)]="requestCouponVoucher.voucher_delivery_type" [disabled]="fetchDisabled"
                            name="voucher_delivery_type" #voucher_delivery_type="ngModel" required>

                            <option value="" selected disabled hidden>Select Delivery Type Status</option>
                            <option value="Single">Single Email Id</option>
                            <option value="Multiple">Multiple Email Id</option>

                        </select>
                        <span class="text-danger" *ngIf="
                                (voucher_delivery_type.touched || voucherRequestForm.submitted) &&
                                voucher_delivery_type.errors?.['required']
                            ">
                            Delivery type is required
                        </span>

                    </div>
                </div>



                <div class="col-4" *ngIf="requestCouponVoucher.voucher_delivery_type == 'Single'">
                    <div class="md-form form-sm my-3">
                      <label for="voucher_delivery_email" class="mb-2">
                        Recipient Email <span class="text-danger">*</span>
                      </label>
                      <input type="email" id="voucher_delivery_email" class="form-control" placeholder="abc@gmail.com" [(ngModel)]="requestCouponVoucher.voucher_delivery_email" maxlength="100"  mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="voucher_delivery_email"  mdbInput required email />
          
                      <span class="text-danger" *ngIf="(voucherRequestForm.controls['voucher_delivery_email']?.touched || voucherRequestForm.submitted) && voucherRequestForm.controls['voucher_delivery_email']?.errors?.['required']">
                        Email is required
                      </span>

                      <span class="text-danger" *ngIf="(voucherRequestForm.controls['voucher_delivery_email']?.touched || voucherRequestForm.submitted) && voucherRequestForm.controls['voucher_delivery_email']?.errors?.['email']">
                        Invalid email format
                      </span>
                    </div>
                  </div>

                <div class="col-4" *ngIf="requestCouponVoucher.voucher_delivery_type == 'Multiple'">
                    <div class="md-form form-sm my-3">
                        <label for="selectdealvalue" class="mb-2"> Upload CSV
                            <span class="text-danger">*</span>
                            <a style="color: green; padding-left: 5px;"
                                href="{{this.fileurls}}sample/sample_delivery_emails.csv">Sample file</a>
                        </label>
                        <input type="file" accept=".csv" id="inputFile" #inputFile (change)="uploadEmailFile($event)" />
                        
                        <span
                            *ngIf="requestCouponVoucher.voucher_delivery_email.length > 0 && requestCouponVoucher.voucher_request_id && requestCouponVoucher.voucher_delivery_type == 'Multiple'">
                            <a style="color: green; padding-left: 5px;"
                                href="{{this.fileurls}}/downlaod/delivery/emails/{{requestCouponVoucher.voucher_request_id}}"
                                class="mr-10 mt-2" type="button" target="_self" download="sample-file.csv">
                                <i class="fa fa-download pr-1"></i>
                                Download preview
                            </a>
                        </span>
                    </div>
                </div>


                <!-- <div class="col-4 position-relative">
                    <div class="md-form form-sm my-3">
                        <label for="payment_received" class="mb-2">Payment Received</label>
                        <select id="payment_received" class="form-select" [(ngModel)]="requestCouponVoucher.payment_received"
                            name="payment_received">
                            <option value="" selected disabled hidden>Select Payment Status</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div> -->


                <div class="col-4" *ngIf="isEdit">
                    <div class="md-form form-sm my-3">
                        <label for="webshopName" class="mb-2">Reference No</label>
                        <input type="text" disabled [(ngModel)]="requestCouponVoucher.voucher_request_id"
                            name="voucher_request_id" class="form-control" mdbInput autocomplete="off">
                    </div>
                </div>


                <!-- 
                <div class="col-4" *ngIf = "hidebtns">
                    <div class="md-form form-sm my-3 css">

                        
                <a 
                class="add_bt btn btn-success mr-10" type="button" target="_self" download="sample-file.csv"
                *ngIf="role === 'admin'">
                <i class="fa fa-download pr-1"></i> Sample file
            </a>


            <a 
            class="add_bt btn btn-success mr-10" type="button" target="_self" download="sample-file.csv"
            *ngIf="role === 'admin'">
            <i class="fa fa-upload pr-1"></i> Import User File
        </a>     
                      
                    </div>
                </div> -->



                <!-- <label class="rounded px-3 py-2 bg-success text-center border-0 labelText  mr-10"
                for="inputFile" *ngIf="role === 'admin'">
                <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                    >
                <i class="fa fa-upload pr-1"></i> Import</label> -->
            </div>

            <div class="d-flex align-items-end justify-content-end">
                <button (click)="cancelVoucherList()" class="btn btn-secondary mr-2" type="button">Cancel</button>
                <button class="btn btn-success mr-2" type="submit" [disabled]="totalval">
                    <!-- {{isEdit ? 'Update':'Save'}} -->
                    {{isEdit ? 'Update':'Save'}}
                </button>
                <div class="text-center"
                    *ngIf="(role === 'Company' || role === 'admin' || role === 'SuperAdmin') && isEdit == true && requestCouponVoucher.status == 'G'">
                    <label class="rounded px-4 py-2 bg-primary text-center border-0 mr-2" for="inputFile"><img
                            src="../../assets/images/icons/uploadicon.svg" class="me-2"> Import User</label>
                    <input type="file" accept=".xlsx, .xls, .csv" class="form-control d-none" id="inputFile" #inputFile
                        (change)="importUserData($event)">
                </div>
            </div>
        </form>

    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>