<div class="row mx-0">
    <div class="col-12 px-0 card shadow-sm border-0">
        <div class="card-body">
            <div class="mb-4 mt-2 row mx-0">
                <div class="col-3 text-start">
                    <h6 class="fw-bold">Rewardkart Clients</h6>
                </div>
                <div class="col-9 text-end">
                    <button type="button" class="btn btn-success" data-bs-toggle="modal"
                        data-bs-target="#exampleModal" (click)="add()">Add</button>
                </div>
                <div class="col-12">
                    <app-common-table [action]="'Edit'" [columns]="columns" [tableDatas]="clientData" [limit]="limit" [d]="d" [count]="count" (EditValue)="editEvent($event)"
                    (pagination)="page($event)" [skip]="skip" [lastvalue]="lastvalue" [primaryKeys]="primary" [index]="false" [cssClass]="'text-center'"></app-common-table>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{{action}} CLIENT</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="clientForm">
                    <div class="row">
                        <div class="col-4">
                            <div class="md-form form-sm my-3">
                                <label for="client" class="mb-2">Rewardkart Client<span class="text-danger">*</span></label>
                                <ng-autocomplete [data]="clientDatas" [searchKeyword]="companyKeyword"
                                    placeholder="Select Client" [itemTemplate]="itemTemplate"
                                    formControlName="client" name="client" mdbInput id="client"
                                    autocomplete="on" (inputCleared)="productClear($event)"
                                    (inputChanged)="onChangeCompany($event)"
                                    (selected)="selectCompanyEvent($event)" required
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.CompanyName"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="md-form form-sm my-3">
                                <label for="discount" class="mb-2 text-start w-100">Discount(%)</label>
                                <input type="text" (keypress)="allowOnlyNumbers($event)" name="discount"
                                    class="form-control" placeholder="Enter Discount" formControlName="discount"
                                    id="discount" mdbInput autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-secondary me-4" data-bs-dismiss="modal" (click)="close()">Close</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveDatas(isEdit ? true : false)">{{action}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-ui-loader></ngx-ui-loader>