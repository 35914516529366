import { Component, OnInit} from '@angular/core';
import { RequestCategory } from '../models/RequestCategory';
import { AppService } from '../restful-services/restful.service';
import { HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';





@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],

})
export class TemplatesComponent implements OnInit   {
  public name: string = "";
  public names: string = "";
  userDetails:any;
  roletype:any;
  private headers: any = {};
  values: any;
  limit: number = 25;
  skip: number = 0;
  datas: any;
  currentid: any;
  body: any;
  currentBody: any;
  apiLoading: boolean = false;
  updatedDatas: any;
  value: any;
  emailInput: any;
  updatedData: any;
  currentId: any;
 







  constructor(private _http:AppService, private loader: NgxUiLoaderService,  private router: Router, private toastr: ToastrService ){}
  ngOnInit(): void {

  this.getCatSub();
  // this.editedDatas()
  
  }


getCatSub(){
  this.loader.start();  

  let token = localStorage.getItem("token");
  this.headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  };

  this.apiLoading = true;

  this._http.getCategorySubject(this.name, this.limit, this.skip ,this.headers, "Voucherkart").subscribe((data:any) => {
    this.values = data.result;
    this.loader.stop();

    // console.log(this.values);
    
  },(error) => {
    this.toastr.error(error.error.Message);
    this.loader.stop();

  }
)}



edit(id:string){
  // this.currentid = id;
  // console.log(this.currentid);
 this.currentBody = this.values.find((p:any) => {
  return p.id == id;

});
  // console.log(this.currentBody.body);
  this.datas = this.currentBody.body;
  this.emailInput = this.currentBody.subject;
  this.currentId = this.currentBody.id;
  
  // console.log(this.datas);
}


//Edited datas

editedDatas(){

  // console.log(this.currentId);
  // console.log(this.datas);
  // console.log(this.emailInput);
  
  let user = localStorage.getItem("userDetails");
  if (typeof user === 'string') {
    const parse = JSON.parse(user)
    this.userDetails = parse.data;

    this.roletype = this.userDetails.UserID;
  }
let postParams = {
  id : this.currentId,
  body : this.datas,
  subject : this.emailInput,
  updated_by : this.roletype,
  platform:"Voucherkart"
}
this.apiLoading = true;


let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.updateTemplateBody(postParams ,this.headers).subscribe((data:any) =>{
      this.updatedData = data;
      this.getCatSub();

      if (data.status == 1){
        this.toastr.success(data.msg)
      }else if(data.status == 0){
        this.toastr.error("Data updated failure")
      }

      // data = this.updatedDatas;
      },
      
      (error) => {
        this.toastr.error(error.error.Message)
        // console.log("error",error);
        
      }
    )
}




//Editor
config: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '15rem',
  minHeight: '5rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [
    ['bold'],['toggleEditorMode']
    ],
    sanitize: false,
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ]
};
}

 


