<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Category Management List</label>
                </div>

                    
                <div class="text-end col-9">
                    <div class="dropdown">
                        <button class="btn btn-success mr-10 float-right" (click)= "addNewCategory()">Add New</button>
                    </div>
                    </div>
            </div>


            <div class="d-flex">                        
                                            

                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="name" name="name"
                        class="form-control border-0 bg-light" placeholder="Name"
                        style="width:210px;padding-top: 13px;" (keypress)="filterList();onKeyPresscomma($event)">
                        <label for="tnums">Name</label>  
                </div>


                <button class="btn btn-success mr-5" style="width: 100px;" (click)="filterList()">Search</button>
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>
           
            </div>




          

            <div class="mt-4 batch-data px-1">

                <div class="row mx-0 adcolor py-4 opacity-75 px-3">                

                    <div class="col labelText text-start">Name</div>
                    <div class="col labelText text-start"> Description</div>
                    <div class="col labelText text-start"> Parent Category </div>                
                    <div class="col labelText text-center"> Action </div> 

                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                *ngIf="categoryList && categoryList.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center">
                            No Record Found.
                        </div>
                    </div>
                </div>



              
                <div class=""
                *ngFor="let list of categoryList | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light">
                    <div class="card-body row mx-0 ps-0">

                       
                        <div class="col text-truncate text-start" title="{{list.name}}"> {{list.name}} </div>                      

                        <div class="col text-truncate text-start" title="{{list.description}}"> {{list.description}} </div>                       
                       
                        
                        <div class="col text-truncate text-start">
                            <span *ngIf="list.parent_category_name" title="{{list.parent_category_name}}"> {{list.parent_category_name}}</span>
                            <span *ngIf = "!list.parent_category_name">-</span>
                        </div>


                        <div class="col text-truncate text-center">                          


                            <a class="linkColor downloadIcon" #Edit (click)="editCategory(list)">
                                <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
                            </a>

                            <a class="linkColor" (click)="deleteModal(list.id)">
                                <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                            </a>
                            
                        

                          
                        </div>
                    
                      </div>
                </div>
            </div>

            <div>

                <div class="row" *ngIf="categoryList && categoryList.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                            {{count}} entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>



            </div>








            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>

<div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">    
                
                
                <p class="texts">{{Edit ? 'Update':'Add'}} Category</p>


                <form #addForm="ngForm" name="form" (ngSubmit)="AddCategory(AddrequestCategory)" class="mt-4">   
                    

                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Name
                            <span class="text-danger">*</span>
                        </label>
                         <input type="text" [(ngModel)]="AddrequestCategory.name" 
                            class="form-control" placeholder="Enter Name" mdbInput
                            autocomplete="off" name="name"
                            #nameval="ngModel"                                                   
                            (keypress)="onKeyPresscomma($event)"
                            (input)="restrictDecimals($event)"                           
                            
                            required >            
                                                        
                           
                        <span class="text-danger" 
                            *ngIf="(nameval.touched || addForm.submitted) && nameval.errors?.['required']">
                            name is required
                        </span>
                    </div>         
                    
                   
                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Description
                            <span class="text-danger">*</span>
                        </label>
                         <textarea type="text" [(ngModel)]="AddrequestCategory.description" 
                            class="form-control" placeholder="Enter Description" mdbInput
                            autocomplete="off" name="description"
                            #description="ngModel"  (keypress)="onKeyPresscomma($event)"                         
                            (input)="restrictDecimals($event)" 
                           
                            required > </textarea>
                            
                          

                        <span class="text-danger" 
                            *ngIf="(description.touched || addForm.submitted) && description.errors?.['required']">
                            description is required
                        </span>
                    </div>         
                    


                    <div class="md-form form-sm my-3 leftAlign">

                        <label for="category" class="mb-2">Parent Category 
                            <!-- <span class="text-danger">*</span> -->
                        </label>
                        <select id="category" class="form-select" [(ngModel)]="AddrequestCategory.category"  (change)='selectCategoryEvent(AddrequestCategory.category)'
                        name="category" #category="ngModel" required>
                         
                        <option value="" selected disabled hidden>Select Parent Category</option>
                        <option *ngFor="let option of categoryData" [value]="option.id">{{ option.name }}</option>                       
                                              
                    </select>

                        <!-- <span class="text-danger"
                            *ngIf="(category.touched || addForm.submitted) && category.errors?.['required']">
                            Parent category is required
                        </span> -->
                    </div>
                                                                  
                  
                    
                

                   

                 
                    <p class="mt-3">
                        <button class="btn btn-secondary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                            type="button"  (click)="backclear()">Back</button>
                            <button class="btn btn-success px-4 py-2" type="submit"> {{Edit ? 'Update':'Add'}}</button>                           
                    </p>


                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to delete the category?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closeBtn>Cancel</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deletecategory()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div>



