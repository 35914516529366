import { NgModule } from '@angular/core';
import {MainComponent} from './main.component';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
    imports: [CommonModule,ToastrModule.forRoot(),BrowserAnimationsModule],
    declarations: [MainComponent],
    exports: [MainComponent],
})
export class MainModule { }