<div class="dashboard-section">
    <div class="row mx-0">
        <div class="col-12 card border-0 shadow-sm px-0">
            <div class="card-body pb-4">
                <div class="row mb-4">
                    <div class="col-3">
                        <label class="texts">Product Voucher Request List</label>
                    </div>
                    <div class="text-end col-9">
                        <button class="btn btn-success mr-10" *ngIf="showRemoveImport" (click)="addNewCoupon()"><i
                                class="fa fa-plus mr-10"></i>Add New</button>
                     
                        <button class="btn btn-success mr-10" (click)="exportAllVoucher()" *ngIf="showRemoveImport"><i
                                class="fa fa-download mr-10"></i>Export</button>
                        <a href="{{this.fileurls}}/sample/brand_sample_request.csv"
                            class="add_bt btn btn-success mr-10" type="button" target="_self" download="sample-file.csv"
                            *ngIf="role === 'admin' || role === 'SuperAdmin' && showRemoveImport">
                            <i class="fa fa-download pr-1"></i> Sample file
                        </a>
                        <label class="rounded px-3 py-2 bg-success text-center border-0 labelText  mr-10"
                            for="inputFile" *ngIf="role === 'admin' || role === 'SuperAdmin' && showRemoveImport">
                            <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                                (change)="importVoucher($event)">
                            <i class="fa fa-upload pr-1"></i> Import</label>
                       
                        <button (click)="showSupplierModal('A')" class="btn btn-success mr-10" type="button"
                            data-bs-toggle="tooltip" data-bs-placement="left" data-bs-trigger="hover"
                            title="Approve Request List" *ngIf="((role === 'admin' || role === 'SuperAdmin')) && showApproveReject"
                            [ngClass]="{'disabled': approvalbrandList.length === 0}">Approve</button>

                        <button (click)="showModa('R')" class="btn btn-primary rejectBtn" type="button"
                            *ngIf="((role === 'admin' || role === 'SuperAdmin')) && showApproveReject" data-bs-trigger="hover"
                            data-bs-toggle="tooltip" data-bs-placement="left" title="Reject Request List"
                            [ngClass]="{'disabled': approvalbrandList.length === 0}">Reject</button>
                    </div>

                </div>

                

                <div class="d-flex">
                    
                    <div class="form-floating me-3"  *ngIf="role === 'admin' || role === 'SuperAdmin' || role === 'brand'">
                        <input type="text" class="form-control border-0 bg-light" [(ngModel)]="company" name="company" *ngIf="role === 'admin' || role === 'SuperAdmin' || role === 'brand'"
                            id="mname" placeholder="Clients" (keypress)="onKeyPresscomma($event);filterList()">
                        <label for="mname">Clients</label>
                    </div>


                    <div class="form-floating me-3" *ngIf="role === 'admin' || role === 'SuperAdmin' || role === 'company'">
                        <input type="text" class="form-control border-0 bg-light" [(ngModel)]="brand" name="brand" disabled
                            id="tnum" placeholder="Brand" (keypress)="onKeyPresscomma($event);filterList()" [disabled]="role == 'brand'">
                        <label for="tnum">Brand</label>
                    </div>
                    
                    <div class="form-floating me-3">
                        <select id="type" class="form-select border-0 bg-light" [(ngModel)]="type" name="type"
                            style="width:210px;padding-top: 13px;" (change)="filterList()" *ngIf="role === 'admin' || role === 'SuperAdmin'">
                            <option value="" selected disabled hidden>Model</option>
                            <option value="ID">Issuance & Distribution</option>
                            <option value="I">Issuance</option>
                        </select>
                    </div>
                    <div class="form-floating me-3">
                        <select id="type" class="form-select border-0 bg-light" [(ngModel)]="category" name="category"
                            style="width:150px;padding-top: 13px;" (change)="filterList()">
                            <option value="" selected disabled hidden>Category</option>
                            <option value="Physical">Physical</option>
                            <option value="Digital">Digital</option>
                        </select>
                    </div>

                    <div class="form-floating me-3">
                        <select id="status" class="form-select border-0 bg-light" [(ngModel)]="approved" name="approved"
                            style="width:150px;padding-top: 13px;" (change)="filterList()">
                            <option value="" selected disabled hidden>Status</option>
                            <option value="Y">Pending</option>
                            <option value="A">Approved</option>
                            <option value="R">Rejected</option>
                            <option value="G">Generated</option>                         
                        </select>
                        <!-- <label for="status">Status</label> -->
                    </div>
                    <button class="btn btn-secondary mr-5" (click)="clear()" style="width: 90px;">Clear</button>
                </div>

                <div class="mt-4 batch-data px-1">


                    <div class="row mx-0 adcolor py-4 opacity-75" *ngIf="role === 'admin' || role === 'SuperAdmin'">
                        <!-- <div class="col-1 text-center labelText" *ngIf="role === 'admin' || role === 'SuperAdmin' && brandList && approved !== 'G'">
                            All - <input type="checkbox" [checked]="selectAllChecked" class="filled-in form-check-input"
                                [(ngModel)]="selectAll" (change)="selectAllFn()">
                        </div> -->
                        <div class="col labelText test-class" *ngIf="role === 'admin' || role === 'SuperAdmin'" [ngClass]="approved === 'G' ? 'pe-2':'pe-0'"> Clients </div>
                        <div class="col labelText test-class" *ngIf="role === 'admin' || role === 'SuperAdmin'" [ngClass]="role !== 'admin' || role !== 'SuperAdmin' ? 'pe-2':'px-0'"> Source </div>
                        <div class="col labelText text-center test-class" [ngClass]="role !=='admin' || role !== 'SuperAdmin' ? 'pe-2':'px-0'"> Brand </div>
                        <div class="col labelText px-0 text-center test-class"> Model</div>
                        <!-- <div class="col px-0"> Type</div> -->
                        <div class="col labelText px-0 text-end" title="Denomination - Face Value">Denomination - Face Value</div>
                        <div class="col labelText px-0 text-end"> Quantity</div>
                        <div class="col labelText text-end"> Payment </div>
                        <div class="col labelText text-center"> Status</div>
                        <div class="col labelText text-center test-clas"> Created By</div>
                        <!-- <div class="col labelText px-0"> Order Receive From</div> -->
                        <div class="col labelText px-0 text-center test-cl"> View </div>
                    </div>


                    <div class="row mx-0 sides py-4 opacity-75" *ngIf="role === 'company'">
                        <!-- <div class="col-1 text-center" *ngIf="role === 'admin' || role === 'SuperAdmin' && brandList && approved !== 'G'">
                            All - <input type="checkbox" [checked]="selectAllChecked" class="filled-in form-check-input"
                                [(ngModel)]="selectAll" (change)="selectAllFn()">
                        </div> -->
                        <div class="col dd" *ngIf="role === 'admin' || role === 'SuperAdmin'" [ngClass]="approved === 'G' ? 'pe-2':'pe-0'"> Clients </div>
                        <div class="col dd" *ngIf="role === 'admin' || role === 'SuperAdmin'" [ngClass]="role !== 'admin' || role !== 'SuperAdmin' ? 'pe-2':'px-0'"> Source </div>
                        <div class="col dd" [ngClass]="role !=='admin' || role !== 'SuperAdmin' ? 'pe-2':'px-0'"> Brand </div>
                        <div class="col dd px-0"> Model</div>
                        <!-- <div class="col dd px-0"> Type</div> -->
                        <div class="col dd px-0 text-center text-truncate" title="Denomination - Face Value">Denomination - Face Value</div>
                        <div class="col dd px-0 text-center"> Quantity</div>
                        <div class="col dd px-0"> Payment </div>
                        <div class="col dd px-0"> Status</div>
                        <div class="col dd px-0"> Created By</div>
                        <div class="col dd px-0 text-center"> View </div>
                    </div>



                    <div class="row mx-0 side py-4 opacity-75" *ngIf="role === 'brand'">
                        <!-- <div class="col-1 text-center" *ngIf="role === 'admin' || role === 'SuperAdmin' && brandList && approved !== 'G'">
                            All - <input type="checkbox" [checked]="selectAllChecked" class="filled-in form-check-input"
                                [(ngModel)]="selectAll" (change)="selectAllFn()">
                        </div> -->
                        <div class="col dd test-class" *ngIf="role === 'admin' || role === 'SuperAdmin' || role === 'brand'" [ngClass]="approved === 'G' ? 'pe-2':'pe-0'"> Clients </div>
                        <div class="col dd test-class" *ngIf="role === 'admin' || role === 'SuperAdmin'" [ngClass]="role !== 'admin' || role !== 'SuperAdmin' ? 'pe-2':'px-0'"> Source </div>
                        <!-- <div class="col dd" [ngClass]="role!=='admin' ? 'pe-2':'px-0'"> Brand </div> -->
                        <div class="col dd px-0 test-class"> Model</div>
                        <!-- <div class="col dd px-0"> Type</div> -->
                        <div class="col dd px-0 text-center" title="Denomination - Face Value">Denomination - Face Value</div>
                        <div class="col dd px-0 text-center"> Quantity</div>
                        <div class="col dd px-0"> Payment </div>
                        <div class="col dd px-0"> Status</div>
                        <div class="col dd px-0 test-clas"> Created By</div>
                        <div class="col dd px-0 text-center"> View </div>
                    </div>


                    <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                        *ngIf="brandList && brandList.length === 0 && !apiLoading">
                        <div class="card-body row mx-0 ps-0">
                            <div class="text-center">
                                No Record Found.
                            </div>
                        </div>
                    </div>


                    <div>
                        <div 
                            *ngFor="let list of brandList | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count};let i = index">
                            <div class=" card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                                ng-reflect-ng-class="bg-light">
                                <div class="card-body row mx-0 px-0">


                                    <!-- <div class="col text-center px-0" *ngIf="role === 'admin' || role === 'SuperAdmin' && brandList && approved !== 'G'">
                                        <input type="checkbox" class="filled-in form-check-input"
                                            id="approvalCheckbox{{list.voucher_request_id}}" [checked]="list.isSelected"
                                            (change)="approvalVoucherId($event,list)" *ngIf="list.status == 'R' || list.status == 'Y'">
                                    </div> -->


                                    <div class="col-1 text-start px-2 test-class"
                                    *ngIf="role === 'admin'|| role === 'SuperAdmin' || role === 'brand'" [ngClass]="approved === 'G' || role !=='admin' || role !== 'SuperAdmin'? 'ps-2':'px-0'" title="{{list.company_name}}"> {{list.company_name}} </div>

                                    <div class="col text-start test-class px-2" *ngIf="role === 'admin' || role === 'SuperAdmin'"
                                    [ngClass]="role !== 'admin' || 'SuperAdmin' || 'company' || 'brand' ? 'pe-2':'px-1'" title="{{list.source}}"> {{list.source}} </div>


                                    <div class="col text-start px-1 test-brand" *ngIf="role === 'admin' || role === 'SuperAdmin'"
                                    [ngClass]="{'ps-2': role =='admin' || role === 'SuperAdmin'}" title="{{list.brand_name}}"> {{list.brand_name}} </div>


                                     <!-- <div class="col text-truncate text-start px-0"> {{list.modal == "ID" ? 
                                        "Issuance & Distribution"
                                        : list.modal == "I" ? "Issuance" : ""}} </div>  -->


                                        <!-- <div class="col text-start px-0" *ngIf = "list.modal == 'ID'" title="Issuance & Distribution">
                                            Issuance & Distribution
                                        </div>
    
    
                                        
                                        <div class="col text-start px-0" *ngIf = "list.modal == 'I'" title="Issuance">
                                            Issuance
                                        </div> -->

                                        
                                    <div class="col text-start" *ngIf="list.modal == 'ID'"
                                    title="Issuance & Distribution">

                                    <img src="../../../assets/images/icons/distribution_icon.svg" height="20">

                                </div>



                                <div class="col text-start" *ngIf="list.modal == 'I'" title="Issuance">

                                    <img src="../../../assets/images/icons/issuance_icon.svg" height="20">

                                </div>

    
                                  

                                    <div class="col px-0 text-end" title="{{list.denomination | currency : 'INR'}}"> {{list.denomination | currency : 'INR'}} </div>


                                    <div class="col px-0 text-end"> {{list.quantity}} </div>


                                    <!-- <div class="col text-truncate text-center px-0" style="margin-left: 2%;">
                                        {{list.payment_status == '0' ? 'Advance' :
                                        'Credit'}}
                                    </div> -->

                                    <div *ngIf="list.payment_status == '0'" class="col px-0 pe-2 text-end" title="Advance">
                                        <img src="../../../assets/images/icons/advance_icon.svg" height="20">
                                    </div>

                                    <div *ngIf="list.payment_status == '1'" class="col px-0 pe-2 text-end" title="Credit">
                                        <img src="../../../assets/images/icons/credit_icon.svg" height="20">
                                    </div>



                                    <!-- <div class="col text-truncate text-center test-cla px-0"> {{list.status == 'A' ? 'Approved' :
                                        (list.status == 'G') ? 'Generated' : (list.status == 'R') ? 'Rejected' :
                                        'Pending'}}</div> -->

                                        <div class="col px-0 pe-2 text-end">

                                            <span *ngIf="list.status == 'Y'" title="Pending"> <img
                                                src="../../../assets/images/icons/pending_icon.svg" height="20"></span>
    
                                            <span *ngIf="list.status == 'A'" title="Approved"> <img
                                                    src="../../../assets/images/icons/approved_icon.svg" height="20"></span>                                              
    
                                                        <span *ngIf="list.status == 'R'" title="Rejected"> <img
                                                            src="../../../assets/images/icons/rejected_icon.svg" height="20"></span>
    
                                                            <span *ngIf="list.status == 'G'" title="Generated"> <img
                                                                src="../../../assets/images/icons/generated_icon.svg" height="20"></span>
                                                          
    
                                        </div>
    


                                    <!-- <div class="col text-truncate text-center test-clas px-0"> {{list.created_by_name}} </div>

                                    <div class="col text-truncate text-center px-0">
                                        <span *ngIf="list.order_recevied_from" title="{{list.order_recevied_from}}"> {{list.order_recevied_from}}</span>
                                        <span *ngIf = "!list.order_recevied_from">-</span>
                                    </div>                                     -->

                                    <div class="col text-end test-create" title="{{list.order_recevied_from}} - {{list.created_by_name}}">{{list.order_recevied_from}} - {{list.created_by_name}}</div>


                                    <div class="col text-center test-view pe-0">

                                        <a class="linkColor" (click)="viewGenerationList(list)">
                                            <i class="fa fa-eye cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View"></i>
                                        </a>

                                        <a class="linkColor" (click)="editVoucher(list)" 
                                            *ngIf="list.status == 'Y' || list.status == ''"><i
                                                class="fa fa-edit cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i></a>


                                                
                                                <a class="linkColor" *ngIf="((list.status == 'Y' || list.status == 'R')) && ((role =='admin' || role === 'SuperAdmin'))" 
                                                [ngClass]="{'disabled': brandList.length === 0}"  (click)="approveshowModal('A',list)" #closeBtn>
                                                <i class="fa fa-check cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Approve"></i>
                                            </a>

                                            <a class="linkColor" *ngIf="(list.status == 'Y') && ((role =='admin' || role === 'SuperAdmin'))"
                                            [ngClass]="{'disabled': brandList.length === 0}" (click)="rejectshowModal('R',list)" #closeBtn>
                                            <i class="fa fa-times cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject"></i>
                                        </a>


                                        <a class="linkColor" *ngIf="list.status == 'G'"
                                            (click)="exportVoucher(list.voucher_request_id)">
                                            <i class="fa fa-download cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download"></i>
                                        </a>

                                        <a class="linkColor"
                                            *ngIf="((list.status == 'G' || list.status == 'A') && list.block_status === 0) && ((role =='admin' || role === 'SuperAdmin'))"
                                            (click)="blockVoucher(list,1)">
                                            <i class="fa fa-ban cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="UnBlock"></i>
                                        </a>

                                        <a class="linkColor"
                                            *ngIf="((list.status == 'G' || list.status == 'A') && list.block_status === 1) && ((role =='admin' || role === 'SuperAdmin'))"
                                            (click)="blockVoucher(list,0)">
                                            <i class="fa fa-check-circle cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Blocked"></i>
                                        </a>

                                        <a class="linkColor" *ngIf="list.log">
                                            <i class="fa fa-exclamation-circle cursor col" data-bs-toggle="tooltip"
                                                data-bs-placement="bottom" title="{{list.log}}"></i>
                                        </a>
                                    

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row" *ngIf="brandList && brandList.length > 0">
                            <div class="col-6 pt-3">
                                <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of
                                    {{count}} entries</p>
                            </div>
                            <div class="col-6" *ngIf = "role === 'admin' || role === 'SuperAdmin'">
                                <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                                </pagination-controls>
                            </div>

                            <div class="col-6" *ngIf = "role === 'company'">
                                <pagination-controls class="list-pagination companypage" (pageChange)="pageChanged($event)">
                                </pagination-controls>
                            </div>

                            <div class="col-6" *ngIf = "role === 'brand'">
                                <pagination-controls class="list-pagination brandpage" (pageChange)="pageChanged($event)">
                                </pagination-controls>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <ngx-ui-loader></ngx-ui-loader>
        
           
            <div class="modal fade" id="rejectModal" tabindex="-1" aria- labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <p>Are you sure to reject the voucher?</p>
                            <p class="mt-5">
                                <button class="btn btn-secondary mr-5" data-bs-dismiss="modal"  (click)="clear()" #closeBtn>Cancel</button>
                                <button class="btn btn-success" data-bs-dismiss="modal" (click)="Rejectbrand(requestCouponBrand)">Confirm
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="approveModals" tabindex="-1" aria- labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <div>
                            <label>Supplier Type : </label>
                            <span class="tex text-primary">External
                            </span>
                        </div>
                        <p class="mt-5">
                            <button class="btn btn-secondary mr-5" data-bs-dismiss="modal"  (click)="clear()" #closeBtn>Cancel</button>
                            <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveBrands(requestCouponBrand)">Okay
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="approveModalss" tabindex="-1" aria- labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div>
                        <label>Supplier Type : </label>
                        <span class="tex text-primary">Internal
                        </span>
                    </div>
                    <p class="mt-5">
                        <button class="btn btn-secondary mr-5" data-bs-dismiss="modal"  (click)="clear()" ##closeApproveeBtn>Cancel</button>
                        <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveBrand()">Okay
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>