import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { ToastrModule } from 'ngx-toastr';
import { CompanyComponent } from "./company.component";
import { CompanyAddEditComponent } from './company-add-edit/company-add-edit.component';
import { ClientProductMappingComponent } from './client-product-mapping/client-product-mapping.component';
import { WalletThresholdComponent } from './wallet-threshold/wallet-threshold.component';
import { ClientRewardkartComponent } from './client-rewardkart/client-rewardkart.component';
import { CommonTablesModule } from "../common-table/common-table.module";
@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),CommonTablesModule
    ],
    declarations: [
    CompanyComponent,
    CompanyAddEditComponent,
    ClientProductMappingComponent,
    WalletThresholdComponent,
    ClientRewardkartComponent
    ],
    exports: [],
    providers: []
})
export class CompanyModule {}