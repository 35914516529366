import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Params } from 'src/app/models/rewardkartClient';
import { AppService } from 'src/app/restful-services/restful.service';

@Component({
  selector: 'app-client-rewardkart',
  templateUrl: './client-rewardkart.component.html',
  styleUrls: ['./client-rewardkart.component.scss']
})
export class ClientRewardkartComponent {
  clientForm: FormGroup;
  clientData: any = [];
  clientDatas:any;
  companyKeyword: string = "CompanyName";
  headers:any;
  action: string = "ADD";
  d: number = 1;
  skip: number = 0;
  lastvalue: number = 0;
  count: number = 0;
  limit: number = 10;
  isEdit: boolean = false;
  editedClientName: string = "";
  editedDiscount!: number;
  editedID!: number;
  columns: Array<string> = [];
  primary: Array<string> = [];
  editModal: any;
  constructor(public formBuilder:FormBuilder,private _http: AppService, private toastr: ToastrService, private loader: NgxUiLoaderService){
    this.clientForm = this.formBuilder.group({
      client: ["", Validators.required],
      discount: ["", Validators.required]
    })
  }

  ngOnInit() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    
    this.columns = [
      "Client",
      "Discount(%)"
    ]
    this.primary = [
      "client_name",
      "discount_percentage"
    ]
    this.getClient();
  }

  ngAfterViewInit() {
    const element = document.getElementById('exampleModal') as HTMLElement;
    this.editModal = new Modal(element, { backdrop: 'static', keyboard: false });
  }

  getClient() {
    this.loader.start();
    this._http.getReClient(this.headers).subscribe({
      next: (data:any) => {
        if(data.status === 1){
          this.loader.stop();
          this.clientData = data.data;
          this.count = this.clientData.length;
          if (this.skip === 0) {
            this.lastvalue = this.skip + 10;
          }
        }else{
          this.loader.stop();
        }
      }, error : (e:any) => {
        this.loader.stop();
        console.log(e.error.Message);
      }
    })

  }


  selectcurrency() {}

  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = "0123456789."; // Define the allowed characters
    const inputChar = event.key;

    if (
      inputChar === " " ||
      (inputChar !== "." && allowedCharacters.indexOf(inputChar) === -1)
    ) {
      event.preventDefault(); // Prevent the input
    }
  } 

  productClear(event:any) {    
  }
  onChangeCompany(event:any) {
    this._http.getCompany(event,this.headers).subscribe({
      next: (data:any) => {
        if(data){
          this.clientDatas = data.data;
        }
      }
    })
  }

  selectCompanyEvent(event:any) {
   
  }

  saveDatas(action:boolean) {
    if(this.clientForm.invalid){
      this.toastr.error("Please Fill All the fields");
      return;
    }
    let params: Params = {
      "clientName": this.clientForm.value.client.CompanyName ? this.clientForm.value.client.CompanyName : this.editedClientName,
      "discount": this.clientForm.value.discount
    }
    let path = "";
    if(action){
      path = "client/discount/update";
      params.id = this.editedID;
    }else{
      path = "/client/discount/add"
    }
    this.loader.start();
    this._http.addRewardkartClient(path,params,this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        if(data && data.status === 1){
          this.toastr.success(data.msg);
          this.clientForm.reset();
          this.getClient();
        }else{
          this.toastr.error(data.msg);
        }
      },error :(e:any) => {
        this.loader.stop();
      }
    })
  }
  
  editEvent(event:any) {
    if(event.isEdit){
      this.editModal.show();
      this.action = "UPDATE";
      this.isEdit = true;
      this.clientForm.patchValue({
        client: event.data.client_name,
        discount: event.data.discount_percentage
      })
      this.editedClientName = event.data.client_name;
      this.editedDiscount = event.data.discount_percentage;
      this.editedID = event.data.id;
    }
  }

  page(event:boolean) {
    if(event) {
      this.getClient();
    }
  }

  add() {
    this.action = "ADD";
    this.isEdit = false;
    this.close();
  }

  close() {
    this.clientForm.reset();
  }
}
