export class MultiDenomination {
  id: any;
  client_id: any;
  client_name: any = '';
  issuance_date: any;
  no_of_brands: any = '';
  no_of_denomination: any = '';
  payment_status: string = '';
  quantity: any = '';
  status: string = '';
  voucher_approve_admin: any;
  total: any;
  source: string = '';
  voucher_group_id: any;
  voucher_request_id: any;
  LOG: any;
  voucher_list: any;
  total_amount: any = 0;

}
