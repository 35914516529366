import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../restful-services/restful.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WalletHistory } from '../models/WalletHistory';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-voucher-wallet-history',
  templateUrl: './voucher-wallet-history.component.html',
  styleUrls: ['./voucher-wallet-history.component.scss']
})
export class VoucherWalletHistoryComponent implements OnInit {

  private headers: any = {};
  voucherWalletHistory: WalletHistory[];
  public name: string = "";
  limit: number = 10;
  skip: number = 0;
  apiLoading: boolean = false;
  values: any;
  paramValue: any = '';
  currentBody: any;
  result : any;
  ids:any;
  value:any;
  datas:any;
  count:number = 0;
  p:number = 1;
  lastvalue: number = 0;
  voucher_id: any;
  // pagedItems: any;
  // pageSize: number = 5;
  // currentPage: number = 1;
  // totalItems: any;
  // paginationId : any;
  // itemsPerPage:number = 5;






constructor(private _http:AppService, private toastr: ToastrService, private route: ActivatedRoute, private router:Router,
  private loader:NgxUiLoaderService ){
  this.voucherWalletHistory = new Array<WalletHistory>();

}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params:any) => {
      this.paramValue = params['voucher_id'];
    });
    
    this.getWalletHistory(this.paramValue)
    
   
  }

  getWalletHistory(voucher_id:any) : void{
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.apiLoading = true;
    // voucher_id = this.paramValue;
    
    this._http.getVoucherWalletHistory(this.paramValue,this.name, this.limit, this.skip,this.headers ).subscribe((data:any) => {
      this.voucherWalletHistory = data.data;
      this.loader.stop();
      // this.totalItems = this.voucherWalletHistory.length;
      console.log(this.voucherWalletHistory);
      if (this.skip === 0) {
        this.lastvalue = this.skip + this.limit;
      }

      this.count = data.count;
      
      console.log(this.count)


    },(error) => {
      this.toastr.error(error.error.Message);
      this.loader.stop();
    });

  }
  

  back(){
    window.history.back()
    // this.router.navigate(['/digital-order-view'], {replaceUrl: false});
    // {queryParams :{voucher_id : data.voucher_id},replaceUrl: false}
    
  }


  pageChanged(pageNo: any) {
    
    this.p = pageNo;
    this.skip = (pageNo - 1) * 10;  //25
    this.lastvalue = this.skip + 10; //0
    console.log(this.paramValue);
    this.getWalletHistory(this.paramValue);
    localStorage.setItem('pageNo', pageNo);
  }


}
