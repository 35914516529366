<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-md-8 bg-rewardkart">
            <div class="d-flex justify-content-center h-75">
                <div class="align-self-center text-center">
                    <h1 class="text-white fw-normal">Welcome To</h1>
                    <img src="../../assets/images/vok logo inverse.png" height="105" />
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex flex-column justify-content-center h-100">

                <h3 class="adscolor text-center">Sign In</h3>
                <div class="w-75 mx-auto my-5">
                    <form #loginForm="ngForm" name="form" (ngSubmit)="loginForm.form.valid && onSubmit()" novalidate>
                        <div class="mb-4">
                            <input type="radio" value="a" name="role" id="admin" class="mx-3" checked
                                (change)="roleType('admin')">
                            <label for="admin">Admin</label>

                            

                            <input type="radio" value="b" name="role" id="brand" class="mx-3" 
                                (change)="roleType('brand')">
                            <label for="brand">Brand</label>
                            <input type="radio" value="c" name="role" id="company" class="mx-3"
                                (change)="roleType('company')">
                            <label for="company">Client</label>
                        </div>
                        <div class="form-group mb-4">
                            <label class="text-secondary">Username *</label>
                            <input type="text" [(ngModel)]="login.UserName" name="UserName" #UserName="ngModel"
                                placeholder="Username" class="form-control" required>
                            <span class="text-danger" *ngIf="
                              (UserName.touched || loginForm.submitted) &&
                              UserName.errors?.['required']
                            ">
                                Username is required
                            </span>
                        </div>
                        <div class="mb-4">
                            <label class="text-secondary">Password *</label>
                            <input type="password" [(ngModel)]="login.Password" name="Password" #Password="ngModel"
                                placeholder="Password" class="form-control" required>
                            <span class="text-danger" *ngIf="
                                (Password.touched || loginForm.submitted) &&
                                Password.errors?.['required']
                              ">
                                Password is required
                            </span>
                        </div>
                        <button type="submit" class="btn adcolor w-100 mt-4">Login</button>
                        <p class="text-center font-weight-normal mt-4">
                            <a href="javascript:void(0)" class="adscolor small">Forgot Password</a>
                        </p>
                    </form>
                </div>
                <h5 class="adscolor text-center">
                    Don't have an account? <a href="javascript:void(0)" class="adscolor">Signup</a>
                </h5>
            </div>
        </div>
    </div>
</div>