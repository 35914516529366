import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { INgxMyDpOptions, IMyDateModel } from "@mksasi/ngx-mydatepicker";
import { DatePipe } from "@angular/common";
import { AppService } from "../../restful-services/restful.service";
import { ChartData, ChartOptions, ChartType } from "chart.js";
import { RequestVoucher } from "../../models/RequestVoucher";
import { saveAs } from "file-saver";
import { HttpHeaders } from "@angular/common/http";
import * as XLSX from "xlsx";
import { Modal } from "bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { browserRefresh } from "../../app.component";
import { Supplier } from "../../models/supplierList";
import {
  environment,
  environmentfiles,
} from "../../../environments/environment";
declare var bootstrap: any;

@Component({
  selector: "vocuher-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class VKDashboardComponent implements OnInit {
  @ViewChild("pdflist", { static: true }) pdflist!: ElementRef;
  @ViewChild("closeBtn", { static: true }) closeBtn!: ElementRef;
  @ViewChild("closeApproveeBtn", { static: false })
  closeApproveeBtn!: ElementRef;
  @ViewChild("closeImportBtn", { static: true }) closeImportBtn!: ElementRef;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: ChartData<"doughnut"> = {
    datasets: [],
  };
  public doughnutChartType: ChartType = "doughnut";

  public startOptions: INgxMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
  };
  public endOptions: INgxMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
  };
  public trackData: any;
  public trackCount: any;
  public trackDatas: any = [];
  public getstartDate: any;
  public getendDate: any;
  public startDate: any;
  public endDate: any;
  public showChart: boolean = false;
  public role: any = "company";
  voucherList: RequestVoucher[];
  public company: string = "";
  public brand: string = "";
  public approved: string = "";
  public type: string = "";
  public modal: string = "";

  public category: string = "";
  limit: number = 25;

  skip: number = 0;
  p: number = 1;
  count: number = 0;
  user_type: any;
  userDetails: any;
  supplierType: any;
  approvalVoucherList: any = [];
  voucherStatus: string = "";
  selectAll: boolean = false;
  showApproveReject: boolean = false;
  approvalStatus: string = "";
  supplierImportType: string = "";
  selectAllChecked: boolean = false;
  lastvalue: number = 0;
  @ViewChild("inputFile") inputFile: any;
  browserRefresh: boolean = false;
  showRemoveImport: boolean = true;
  rejectedStatus: string = "";
  supplierList: any;
  supplierName: any;
  supplierKeyword = "supplier_name";
  supplierID: any;
  supplierDetail: Supplier;
  newSupplier: boolean = false;
  tooltipList: any;
  apiLoading: boolean = false;
  private headers: any = {};
  requestCouponVoucher: RequestVoucher;
  urlError: boolean = false;
  productURL: string = "";

  @ViewChild("supplierForm", { static: true }) supplierForm: any;
  fileurls: any;

  settingvalue: any;

  disticon: any;
  distsicon: any;

  constructor(
    public datePipe: DatePipe,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.voucherList = new Array<RequestVoucher>();
    this.supplierDetail = new Supplier();
    this.requestCouponVoucher = new RequestVoucher();
  }

  ngOnInit(): void {
    const d: Date = new Date();
    this.role = localStorage.getItem("role");
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }
    this.startOptions = {
      dateFormat: "dd/mm/yyyy",
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate() + 1,
      },
    };
    this.getstartDate = {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.endOptions = {
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: this.getstartDate.date.year,
        month: this.getstartDate.date.month,
        day: this.getstartDate.date.day,
      },
    };
    this.getendDate = {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.startDate =
      d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    this.endDate =
      d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

    this.category = "Digital";

    let page: any = localStorage.getItem("pagesNo");

    if (page !== null) {
      this.pageChanged(page);
    } else {
      this.getVoucherRequestList();
    }

    this.browserRefresh = browserRefresh;
    if (this.browserRefresh === true) {
      this.p = 1;
      this.pageChanged(this.p);
    }

    if (this.role === "company") {
      this.type = "ID";
    }
    if (this.role === "brand") {
      this.type = "I";
    }

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;

    this.disticon = "distribution_icon.svg";
  }

  addNewCoupon() {
    this.voucherList = [];
    localStorage.removeItem("voucherDetail");
    localStorage.removeItem("edit");

    localStorage.removeItem("authboolean");
    localStorage.removeItem("pdthide");
    // location.reload();
    if (this.role === "Company") {
      this.router.navigate(["/client-add-digital"], { replaceUrl: false });
    } else {
      this.router.navigate(["/add-digital"], { replaceUrl: false });
    }
  }

  filterList() {
    if (
      this.approved ||
      this.type ||
      this.category ||
      this.brand ||
      this.company
    ) {
      this.skip = 0;
      this.p = 1;
    }
    this.getVoucherRequestListfilter();
  }

  getVoucherRequestListfilter() {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }
    if (this.role === "company" || this.role === "Company") {
      this.type = "ID";
      this.company = this.userDetails.UserName;
    } else if (this.role === "brand") {
      this.type = "";
      this.brand = this.userDetails.UserName;
    }

    this.showRemoveImport = true;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.apiLoading = true;
    this._http
      .getVoucherList(
        this.limit,
        this.skip,
        this.company,
        this.brand,
        this.approved,
        this.type,
        this.category,
        this.user_type,
        this.userDetails.UserID,
        "voucher",
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.count = data.count;
            this.voucherList = data.data;
            this.apiLoading = false;
            this.toolTipInit();
            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  getVoucherRequestList() {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }
    if (this.role === "company" || this.role === "Company") {
      this.type = "ID";
      this.company = this.userDetails.entity_name;
    } else if (this.role === "brand") {
      this.type = "";
      this.brand = this.userDetails.UserName;
    }

    this.showRemoveImport = true;
    let action = "international"

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this.apiLoading = true;
    this._http
      .getVoucherList(
        this.limit,
        this.skip,
        this.company,
        this.brand,
        this.approved,
        this.type,
        this.category,
        this.user_type,
        this.userDetails.UserID,
        "voucher",
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data) {
            this.count = data.count;
            this.voucherList = data.data;
            this.apiLoading = false;
            this.toolTipInit();
            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  editVoucher(voucherList: RequestVoucher) {
    // let currentDate =new Date(voucherList.validity);
    // let dateSent =new Date(voucherList.start_date);

    //  let tday = currentDate.getDate();
    //  let tdays = dateSent.getDate();

    //  voucherList.validity = tday-tdays;

    let dat: any = voucherList.source;
    let validity = voucherList.validity;

    if (
      dat !== "QwikCilver" &&
      dat !== "Ticket Express" &&
      dat !== "Amazon" &&
      dat !== "FlipKart" &&
      dat !== "EZPIN" &&
      dat !== "Joyalukkas"
    ) {
      const start = new Date(voucherList.start_date);
      const end = new Date(voucherList.validity);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      let difference = Math.ceil(timeDiff / (1000 * 3600 * 24));
      voucherList.validity = difference;
    }

    this._http.voucherDetails(voucherList);
    const id = voucherList.voucher_request_id;
    this.router.navigate(
      [this.role === "Company" ? "/client-add-digital" : "/add-digital"],
      {
        state: { id, validity },
        replaceUrl: true,
      }
    );
  }

  pageChanged(pagesNo: any) {
    this.p = pagesNo;
    this.skip = (pagesNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem("pagesNo", pagesNo);
    this.getVoucherRequestList();

    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.showRemoveImport = true;
    this.approvalVoucherList = [];
  }

  clear() {
    this.company = "";
    if (this.role === "company") {
      this.type = "ID";
    } else if (this.role === "brand") {
      this.type = "I";
    } else {
      this.type = "";
    }
    this.brand = "";
    this.category = "Digital";
    this.approved = "";
    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.voucherList = [];
    this.approvalVoucherList = [];
    this.getVoucherRequestList();
    this.p = 1;
    let page = this.p;
    this.pageChanged(page);
  }

  viewGenerationList(voucherList: RequestVoucher) {
    this._http.voucherDetails(voucherList);
    const id = voucherList.voucher_request_id;
    localStorage.setItem("voucherDetail", JSON.stringify(voucherList));
    this.router.navigate(["/digital-order-view"], {
      queryParams: { Voucher_request_id: id },
      state: { id },
      replaceUrl: true,
    });

    localStorage.removeItem("logvalue");
  }

  exportVoucher(voucherRequestId: number) {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }

    this._http.exportVoucherssDatas(
      voucherRequestId,
      this.user_type,
      this.userDetails.UserID,
      "voucher"
    );
  }

  exportAllVoucher() {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "domestic";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }

    // this.loader.start();
    this._http.exportAllVouchers(
      this.user_type,
      this.userDetails.UserID,
      "voucher"
    );
  }

  // exportVoucher(voucherRequestId: number) {

  //   if (this.role === "admin") {
  //     this.user_type = "ALL"
  //   }
  //   else if (this.role === "company" || this.role === "brand") {
  //     this.user_type = "Restricted";
  //   }

  //   let token = localStorage.getItem("token");
  //   this.headers = {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'Authorization': 'Bearer ' + token
  //      })
  //    };

  //   this.loader.start();
  //   this._http.exportVoucherData(voucherRequestId,this.user_type,this.userDetails.UserID,'voucher',this.headers).subscribe({
  //     next: (data: any) => {
  //       this.loader.stop();
  //       saveAs(data, `voucher.csv`)
  //     },
  //     error: (_error) => {
  //       this.loader.stop();
  //     }
  //   })
  // }

  // exportAllVoucher() {
  //   if (this.role === "admin") {
  //     this.user_type = "ALL"
  //   }
  //   else if (this.role === "company" || this.role === "brand") {
  //     this.user_type = "Restricted";
  //   }

  //   let token = localStorage.getItem("token");
  //   this.headers = {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'Authorization': 'Bearer ' + token
  //      })
  //    };

  //   this.loader.start();
  //   this._http.exportAllVoucher(this.user_type, this.userDetails.UserID, 'voucher',this.headers).subscribe(
  //     (data: any) => {
  //       this.loader.stop();
  //       saveAs(data, `AllVouchers.csv`)
  //     }, _error => {
  //       this.loader.stop();
  //     })
  // }

  showSupplierImportModal() {
    this.supplierImportType = "Internal";
    const element = document.getElementById("importModal") as HTMLElement;
    const importModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    importModal.show();
  }

  importVoucher(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (getImg.toLowerCase() !== ".csv") {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierImportType == "" || this.supplierImportType == undefined || this.supplierImportType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    // this.closeImportBtn.nativeElement.click();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("filename", event.target.files[0]);
      // formData.append('supplier_type', this.supplierImportType);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);
      // const selectedFile = event.target.files[0];
      // const fileReader = new FileReader();
      // fileReader.readAsBinaryString(selectedFile);
      // fileReader.onload = (e: any) => {
      //   let binaryDara = e.target.result;
      //   let workbook = XLSX.read(binaryDara, { type: 'binary' });
      //   // workbook.SheetNames.forEach(sheet=>{
      //   //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      //   //   console.log(this.batchData)
      //   // })
      // }
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      // this.loader.start();

      this._http.importUserDataVoucher(formData, this.headers).subscribe({
        // this._http.importUserBulkApproval(formData,this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getVoucherRequestList();
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
          this.inputFile.nativeElement.value = "";
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = event.formatted;
    this.endOptions = {
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: event.date.year,
        month: event.date.month,
        day: event.date.day,
      },
      enableDates: [
        { year: event.date.year, month: event.date.month, day: event.date.day },
      ],
    };
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = event.formatted;
  }

  assignVoucher() {
    if (
      this.supplierType == "" ||
      this.supplierType == undefined ||
      this.supplierType == null ||
      this.supplierName == "" ||
      this.supplierName == undefined ||
      this.supplierName == null
    ) {
      this.toastr.error("Please select one aggregator");
      return;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    this.loader.start();

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
          this.supplierName = "";
          this.supplierList = "";
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  approveVoucher() {
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }

    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  approveVouchers(requestCouponVoucher: RequestVoucher) {
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  Rejectbrand(requestCouponVoucher: RequestVoucher) {
    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.rejectedStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == "R") {
              this.toastr.success("Voucher rejected successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            }
          } else {
            if (data.statu == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      },
    });
  }

  approvalVoucherId(event: any, voucher: RequestVoucher) {
    this.toolTipInit();
    this.selectAll = false;
    this.voucherStatus = voucher.status;
    if (event.target.checked == true) {
      this.approvalVoucherList.push(voucher.voucher_request_id);
    } else {
      if (this.approvalVoucherList.length > 0) {
        const index = this.approvalVoucherList.findIndex(
          (ch: any) => ch === voucher.voucher_request_id
        );
        this.approvalVoucherList.splice(index, 1);
        const listIndex = this.voucherList.findIndex(
          (ch: any) => ch.voucher_request_id === voucher.voucher_request_id
        );
        this.voucherList[listIndex].isSelected = false;
      }
    }
    if (this.approvalVoucherList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false;
    } else {
      this.showRemoveImport = true;
      this.showApproveReject = false;
    }
  }

  selectAllFn() {
    this.toolTipInit();
    this.selectAllChecked = true;
    let voucher: any = this.voucherList;
    if (this.selectAll) {
      this.voucherList.forEach((c: any) => (c.isSelected = true));
      // console.log("test5",this.voucherList.filter((v: any) =>v.status == 'R' || v.status == 'Y'));
      this.voucherList
        .filter((v: any) => v.status == "R" || v.status == "Y")
        .forEach((c: any) =>
          this.approvalVoucherList.push(c.voucher_request_id)
        );
      this.selectAllChecked = true;
    } else {
      this.approvalVoucherList = [];
      this.voucherList.forEach((c: any) => (c.isSelected = false));
      // this.selectAll = false;
      // this.selectAllChecked = false;
    }
    if (this.approvalVoucherList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false;
      this.toolTipInit();
    } else {
      this.showApproveReject = false;
      this.showRemoveImport = true;
    }
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  companySync() {
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.companySync(this.headers).subscribe((data: any) => {
      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Company sync successfully");
      }
    });
  }

  brandSync() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.brandSync(this.headers).subscribe((data: any) => {
      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Brand sync successfully");
      }
    });
  }

  blockVoucher(voucher: RequestVoucher, blockStatus: any) {
    this.loader.start();
    let requestParams = {
      voucher_request_id: voucher.voucher_request_id,
      status: blockStatus,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.blockVoucher(requestParams, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.status == 1 && blockStatus == 1) {
          this.toastr.success("Voucher blocked successfully");
          this.getVoucherRequestList();
        } else if (data.status == 1 && blockStatus == 0) {
          this.toastr.success("Voucher unblocked successfully");
          this.getVoucherRequestList();
        } else {
          this.toastr.error(data.msg);
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  showModa(rejectedStatus: string) {
    this.rejectedStatus = rejectedStatus;
    this.supplierType = "Internal";
    const element = document.getElementById("rejectModal") as HTMLElement;
    const rejectModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    rejectModal.show();
  }

  rejectshowModal(rejectedStatus: string, voucher: RequestVoucher) {
    this.approvalVoucherList.push(voucher.voucher_request_id);
    this.rejectedStatus = rejectedStatus;
    this.supplierType = "Internal";
    const element = document.getElementById("rejectModal") as HTMLElement;
    const rejectModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    rejectModal.show();
  }

  approveshowModal(approvalStatus: string, brand: RequestVoucher) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getsettingslist(this.headers).subscribe({
      next: (res: any) => {
        if (res) {
          let setvalue = res.data.order_limit_amount;

          localStorage.setItem("settingslist", setvalue);
        }
      },
    });

    this.settingvalue = localStorage.getItem("settingslist");

    this.requestCouponVoucher = brand;
    let sourcevalue = brand;

    let totalval = sourcevalue.total;

    let discountval = sourcevalue.voucher_approve_admin;

    let nullcount = 0;

    if (sourcevalue!.source == "" || sourcevalue!.source == undefined) {
      nullcount = nullcount + 1;
    }

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (discountval == "YES" && this.userDetails.role === "Admin") {
      this.toastr.error(
        "discount is more than 75% voucher approval by super admin"
      );
      return;
    } else if (
      totalval >= this.settingvalue &&
      this.userDetails.role === "Admin"
    ) {
      this.toastr.error(
        "voucher total amount is High. So, please get approval from super admin"
      );
      return;
    } else {
      if (
        sourcevalue.source === "QwikCilver" ||
        sourcevalue.source === "MEA" ||
        sourcevalue.source === "Ticket Express" ||
        sourcevalue.source === "Amazon" ||
        sourcevalue.source === "FlipKart" ||
        sourcevalue.source === "EZPIN" ||
        sourcevalue.source === "Joyalukkas"
      ) {
        this.approvalVoucherList = [brand.voucher_request_id];
        this.approvalStatus = approvalStatus;
        this.supplierType = "external";
        // this.approveVouchers(this.requestCouponVoucher);
        this.approvalVoucherList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;

        const element = document.getElementById("approveModals") as HTMLElement;
        const approveModals = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModals.show();
      } else {
        this.approvalVoucherList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "internal";
        const element = document.getElementById(
          "approveModalss"
        ) as HTMLElement;
        const approveModalss = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModalss.show();
      }
    }
  }

  showSupplierModal(approvalStatus: string) {
    let qccount = 0;
    let othercount = 0;
    let nullcount = 0;

    let disArray: any = [];

    this.approvalVoucherList.forEach((e: any) => {
      let selectrow = this.voucherList.find(
        (a: any) => a.voucher_request_id == e
      );

      let discountval: any = this.voucherList.find((a: any) => {
        // console.log(a, a.voucher_approve_admin, a.voucher_request_id)
        return a.voucher_approve_admin == "YES" && a.voucher_request_id == e;
      });

      if (discountval) {
        disArray.push(discountval);
      }

      if (selectrow!.source == "" || selectrow!.source == undefined) {
        nullcount = nullcount + 1;
      }

      if (
        selectrow!.source == "QwikCilver" ||
        selectrow!.source == "MEA" ||
        selectrow!.source == "Ticket Express" ||
        selectrow!.source == "Amazon" ||
        selectrow!.source == "FlipKart" ||
        selectrow!.source == "Joyalukkas"
      ) {
        qccount = qccount + 1;
      } else {
        othercount = othercount + 1;
      }
    });

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (qccount > 0 && othercount > 0) {
      this.toastr.error("please select unique source rows");
      return;
    }

    let discountvalue: any = disArray.find((a: any) => {
      return a.voucher_approve_admin;
    });

    let disvalue: string = "";
    if (discountvalue) {
      disvalue = discountvalue.voucher_approve_admin;
    }

    if (disvalue === "YES" && this.userDetails.role === "Admin") {
      this.toastr.error(
        "discount is more than 75% voucher approval by super admin"
      );
      return;
    } else {
      if (qccount > 0) {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "external";
        const element = document.getElementById("approveModals") as HTMLElement;
        const approveModals = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModals.show();
        // this.approveVouchers(this.requestCouponVoucher);
      } else {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "internal";
        const element = document.getElementById(
          "approveModalss"
        ) as HTMLElement;
        const approveModalss = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModalss.show();
      }
    }
  }

  onChangeSupplier(item: any) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getSupplierItems(item, this.headers).subscribe((data: any) => {
      this.supplierList = data.data;
    });
  }

  selectSupplierEvent(item: any) {
    this.supplierID = item.supplier_id;
    this.supplierName = item.supplier_name;
  }

  addNewSupplier() {
    this.newSupplier = true;
    this.supplierDetail = new Supplier();
  }

  addSupplier() {
    if (
      this.supplierDetail.supplier_name === "" ||
      this.supplierDetail.api_url === "" ||
      this.supplierDetail.api_key === "" ||
      this.supplierDetail.api_authentication_code === ""
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    const result = this.supplierDetail.api_url.match(urlRegex);
    if (result == null) {
      this.toastr.error("Please enter valid API URL");
      return;
    }
    this.loader.start();
    this.supplierDetail.action = "CREATE";
    this.supplierDetail.created_by_name = this.userDetails.UserName;
    this.supplierDetail.created_by_id = this.userDetails.UserID;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.addNewSupplier(this.supplierDetail, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.status == 1) {
          this.toastr.success("Supplier request send successfully");
          this.newSupplier = false;
        } else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  backToSupplier() {
    this.newSupplier = false;
  }

  changeSupplier() {
    if (this.supplierType == "Internal") {
      this.newSupplier = false;
    } else {
      this.newSupplier = false;
    }
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
    localStorage.removeItem("pagesNo");
  }

  // downloadPdf(list: RequestVoucher) {

  //   let doc = new jsPDF('p', 'pt', 'a4');

  //   let specialElementHandlers = {
  //     '#editor': function(element:any, renderer:any) {
  //       return true;
  //     }
  //   };

  //   let content = this.pdflist.nativeElement;

  //   doc.html(content.innerHTML, {
  //     'width': 190
  //   });

  //   doc.save('test.pdf');
  // }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  formatDate(responseDate:string){
    if(responseDate){
      const date = new Date(responseDate);
      const formattedDate = date.toLocaleString('en-GB',{month:'short', year:'numeric'}).replace(',', '');
      return `${formattedDate} - `;
    }
    else{
      return "";
    }
    
  }

  formatGift1to1(shop:string,domain:string){
    let regEx = /voucherkart/ig;
    if(shop && shop.toLowerCase() === "childshop" && domain && regEx.test(domain)){
      return domain.replace(/voucherkart/ig , "Gift1to1");
    } else {
      return domain;
    }
  }
}
