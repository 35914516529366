<!-- Modal -->
<div>
 <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header custom-model">
          <h5 class="modal-title pl-4" id="exampleModalLabel">Update Email Template</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="sub" class="my-1">
          <label for="emailSubject" class="lab1">Email Subject: </label>
          <input type="text" class="input1" [(ngModel)]="this.emailInput" >
        </div>
        <div>
          <label for="emailBody" style="padding: 10px ;  padding-left: 50px;font-weight: 500; ">Email Body: </label></div>
        <div class="modal-body" style="width: 800px; left: 150px; bottom: 44px;">
          <angular-editor [(ngModel)]="this.datas" [config]="config"></angular-editor>
          <!-- <h4>HTML Output</h4>
          <div class="html">
            {{this.datas}}
          </div> -->

        </div>
      
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="editedDatas()">Save changes</button>
        </div>
      </div>
    </div>
  </div> 
</div>
<div class="row mx-0">
  <div  class="col-12 card border-0 shadow-sm px-0">
      <div class="card-body pb-4">
<table class="table">
    <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
      <tr>
        <th scope="col" class="pt1">SI.No</th>
        <th scope="col" class="pt1">Category</th>
        <th scope="col" class="pt1">Subject</th>
        <th scope="col" class="pt1">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let value of values">
        <th scope="row">{{value.id}}</th>
        <td>{{value.category}}</td>
        <td>{{value.subject}}</td>
        <td>
          <a class="linkColor downloadIcon" #Edit (click)="edit(value.id)">
            <i class="fa fa-edit mr-10 icolor " data-bs-toggle="modal" data-bs-placement="bottom" data-bs-target="#exampleModal" title="Edit"></i>
        </a>
        </td>
        <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="edit(value.id)">
            <i class="fa fa-edit"></i>
          </button></td> -->
      </tr>
      
    </tbody>
  </table>
  </div>
  </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>

