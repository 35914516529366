<div class="dashboard-section">
    <div class="row mx-0">
        <div>
            <div class="d-flex" style="padding-top: 22px;">
                <div class="col-3" style="margin-left: 24px;">
                    <div class="md-form form-sm my-3 position-relative">
                        <label for="start_date" class="mb-2">Start Date
                        </label>
                        <input style="margin-top: 3px;" (click)="dp.openCalendar(); openCalendarAndDetectChanges(dp)"
                            [(ngModel)]="getstartDate" name="start" placeholder="Select Start Date"
                            class="form-control datepicker" (ngModelChange)="checkButtonStatus()" ngx-mydatepicker
                            [options]="startOptions" readonly #dp="ngx-mydatepicker" type="text" #start="ngModel"
                            (dateChanged)="onStartDateChanged($event)"
                            (calendarViewChanged)="onCalendarViewChanged($event)" (keypress)="$event.preventDefault()"
                            required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="dp.openCalendar()" (click)="dp.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger"
                            *ngIf="(start.dirty || start.touched) && start.errors?.['required'] && !dateRequire">
                            start date is required
                        </span>
                    </div>
                </div>
                <div class="col-3" style="margin-left: 45px;">

                    <div class="md-form form-sm my-3  leftAlign position-relative">
                        <label for="start_date" class="mb-2">End Date
                        </label>

                        <input style="margin-top: 3px;" (click)="edp.openCalendar(); openCalendarAndDetectChange(edp)"
                            [(ngModel)]="getendDate" name="end" placeholder="Select End Date"
                            class="form-control datepicker" ngx-mydatepicker [options]="endOptions" readonly
                            #edp="ngx-mydatepicker" type="text" (ngModelChange)="checkButtonStatus()" #end="ngModel"
                            (dateChanged)="onEndDateChanged($event)" (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="edp.openCalendar()" (click)="edp.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger" *ngIf="end.touched && end.errors?.['required'] && !dateRequire">
                            End date is required
                        </span>
                    </div>

                </div>
                <div class="col-2" style="padding-left: 58px; padding-top: 48px;">
                    <button type="button" [disabled]="isButtonDisabled" class="btn btn-success"
                        (click)="filterData()">Search</button>

                </div>
                <div class="col-3" style="padding-left: 18px; padding-top: 48px;">
                    <button class="btn btn-secondary " style="width: 90px;" (click)="clear()">Clear</button>
                </div>

            </div>
        </div>
        <div *ngIf="role === 'admin' || role === 'SuperAdmin' || role=== 'Company' || role=== 'brand' " class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">External Digital Voucher</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-8 mb-3">Approved Voucher</div>
                        <div class="col-4 mb-3">: {{digitalapprove || 0}}</div>
                        <div class="col-8 mb-3">Rejected Voucher</div>
                        <div class="col-4 mb-3">: {{digitalreject || 0}}</div>
                        <div class="col-8 mb-3">Generated Voucher</div>
                        <div class="col-4 mb-3">: {{digitalgenerate || 0}}</div>
                        <div class="col-8 mb-3">Pending Voucher</div>
                        <div class="col-4 mb-3">: {{digitalpend || 0}}</div>
                        <div class="col-8 mb-3">Redeemed Voucher</div>
                        <div class="col-4 mb-3">: 0</div>

                    </div>


                </div>
            </div>
        </div>

        <div *ngIf="role === 'admin' || role === 'SuperAdmin' || role=== 'Company'|| role=== 'brand'" class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">Internal Digital Voucher</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-8 mb-3">Approved Voucher</div>
                        <div class="col-4 mb-3">: {{internaldigitalapprove || 0}}</div>
                        <div class="col-8 mb-3">Rejected Voucher</div>
                        <div class="col-4 mb-3">: {{internaldigitalreject || 0}}</div>
                        <div class="col-8 mb-3">Generated Voucher</div>
                        <div class="col-4 mb-3">: {{internaldigitalgenerate || 0}}</div>
                        <div class="col-8 mb-3">Pending Voucher</div>
                        <div class="col-4 mb-3">: {{internaldigitalpend || 0}}</div>
                        <div class="col-8 mb-3">Redeemed Voucher</div>
                        <div class="col-4 mb-3">: 0</div>

                    </div>


                </div>
            </div>
        </div>

        <div class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">External Product Voucher</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-8 mb-3">Approved Voucher</div>
                        <div class="col-4 mb-3">: {{productapprove || 0}}</div>
                        <div class="col-8 mb-3">Rejected Voucher</div>
                        <div class="col-4 mb-3">: {{productreject || 0}}</div>
                        <div class="col-8 mb-3">Generated Voucher</div>
                        <div class="col-4 mb-3">: {{productgenerate || 0}}</div>
                        <div class="col-8 mb-3">Pending Voucher</div>
                        <div class="col-4 mb-3">: {{productpend || 0}}</div>
                        <div class="col-8 mb-3">Redeemed Voucher</div>
                        <div class="col-4 mb-3">: 0</div>

                    </div>

                </div>
            </div>
        </div>



        <div class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">Internal Product Voucher</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-8 mb-3">Approved Voucher</div>
                        <div class="col-4 mb-3">: {{internalproductapprove || 0}}</div>
                        <div class="col-8 mb-3">Rejected Voucher</div>
                        <div class="col-4 mb-3">: {{internalproductreject || 0}}</div>
                        <div class="col-8 mb-3">Generated Voucher</div>
                        <div class="col-4 mb-3">: {{internalproductgenerate || 0}}</div>
                        <div class="col-8 mb-3">Pending Voucher</div>
                        <div class="col-4 mb-3">: {{internalproductpend || 0}}</div>
                        <div class="col-8 mb-3">Redeemed Voucher</div>
                        <div class="col-4 mb-3">: 0</div>

                    </div>

                </div>
            </div>
        </div>

        <!-- Voucher internal Redemption -->


        <div class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">Internal Voucher Redemption</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-7 mb-3">No. of Issued Voucher</div>
                        <div class="col-5 mb-3">: {{issuedVoucher || 0}}</div>
                        <div class="col-7 mb-3">Issued Voucher Value</div>
                        <div class="col-5 mb-3">: {{(issuedVoucherValue | currency:"₹" )|| 0}}</div>
                        <div class="col-7 mb-3">Redeem Value</div>
                        <div class="col-5 mb-3">: {{(redeemValue | currency:"₹") || 0}}</div>
                        <div class="col-7 mb-3">Waiting For Redemption</div>
                        <div class="col-5 mb-3">: {{((issuedVoucherValue - redeemValue ) | currency:"₹") || 0}}</div>
                        <!-- <div class="col-8 mb-3">Redeemed Voucher</div> <div class="col-4 mb-3">: 0</div> -->

                    </div>

                </div>
            </div>
        </div>

        <!-- <div *ngIf="role === 'admin' ||role=== 'brand'" class="col-4">
            <div class="card border-0 shadow-sm px-0"> 
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">Deal Voucher</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-8 mb-3">Approved Voucher</div> <div class="col-4 mb-3">: {{dealapprove || 0}}</div>
                        <div class="col-8 mb-3">Rejected Voucher</div> <div class="col-4 mb-3">: {{dealreject || 0}}</div>
                        <div class="col-8 mb-3">Generated Voucherd</div> <div class="col-4 mb-3">: {{dealgenerate || 0}}</div>
                        <div class="col-8 mb-3">Pending Voucher</div> <div class="col-4 mb-3">: {{dealpend || 0}}</div>
                        <div class="col-8 mb-3">Redeemed Voucher</div> <div class="col-4 mb-3">: 0</div>
                    </div>
                    
                </div>
            </div>
        </div>
       -->

        <div *ngIf="role === 'SuperAdmin' || role === 'Company'" class="col-4">
            <div class="card border-0 shadow-sm px-0">
                <div class="card-header border-0 px-4 py-3">
                    <label class="texts">External Digital Voucher Issuane</label>
                </div>
                <div class="card-body px-4 py-3">
                    <div class="row small text-secondary">
                        <div class="col-5 mb-3"> No of Order </div>
                        <div class="col-6 mb-3">:{{dealorder || 0}}</div>
                        <div class="col-5 mb-3">Total Amount</div>
                        <div class="col-6 mb-3">:{{(dealamount | currency:'INR' )|| 0}}</div>
                        <div class="col-5 mb-3">Total Discount</div>
                        <div class="col-6 mb-3">:{{(dealdiscount) || 0}}%</div>
                        <div class="col-5 mb-3">Total Earning</div>
                        <div class="col-6 mb-3">:{{((dealEarn) | currency:'INR') || 0}}</div>
                        <div class="col-5 mb-3">Total Issuance</div>
                        <div class="col-6 mb-3">:{{(dealIssuance | currency:'INR') || 0}}</div>
                        <!-- <div class="col-8 mb-3">Redeemed Voucher</div> <div class="col-4 mb-3">: 0</div> -->

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="chart-section mt-3">
    <div class="row mx-0 my-3">
        <div class="col-md-12">
            <div class="d-flex">
            <div>
                <input type="radio" value="I" name="international" id="international" class="m-2" [(ngModel)]="chartTypes" (change)="chartTypeChange(chartTypes)">
                <label for="international">International</label>
            </div>
            <div>
                <input type="radio" value="D" name="domesitc" id="domestic" class="m-2" [(ngModel)]="chartTypes" (change)="chartTypeChange(chartTypes)">
                <label for="domestic">Domestic</label>
            </div>
            </div>
        </div>
    </div>
    <div class="row mx-0">
        <div class="col-4" *ngIf="(chartTypes === 'I' && barArray.length > 0) || (chartTypes !== 'I' && domesticBarArray.length > 0)">
            <div class="shadow-sm card  border-0">
                <div class="card-header  border-0">
                    <h6 class="my-2">{{chartTypes === 'I' ? 'International Overall' : 'Domestic Overall'}}</h6>
                </div>
                <div class="card-body">
                    <canvas *ngIf="chartTypes === 'I'" #chart baseChart [type]="chartType" [data]="interNationalOverall" [options]="options">
                    </canvas>
                    <canvas *ngIf="chartTypes !== 'I'" #chart baseChart [type]="chartType" [data]="domesticOverall" [options]="options">
                    </canvas>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf=" (chartTypes === 'I' && chartMeaVal.length > 0) || (chartTypes !== 'I' && chartamaVal.length > 0)">
            <div class="shadow-sm card border-0">
                <div class="card-header  border-0">
                    <h6>{{chartTypes === 'I' ? 'MEA' : 'Amazon'}}</h6>
                </div>
                <div class="card-body">
                    <canvas *ngIf="chartTypes === 'I'" #chart baseChart [type]="chartType" [data]="MEAdata" [options]="options">
                    </canvas>
                    <canvas *ngIf="chartTypes !== 'I'" #chart baseChart [type]="chartType" [data]="amazonData" [options]="options">
                    </canvas>
                </div>
            </div>
        </div>
        <div class="col-4 " *ngIf="(chartTypes === 'I' && chartEzVal.length > 0) || (chartTypes !== 'I' && chartfliVal.length > 0)">
            <div class="shadow-sm card border-0">
                <div class="card-header  border-0">
                    <h6 class="my-2">{{chartTypes === 'I' ? 'EZ PIN' : 'Flipkart'}}</h6>
                </div>
                <div class="card-body">
                    <canvas *ngIf="chartTypes === 'I'" #chart baseChart [type]="chartType" [data]="Ezdata" [options]="options">
                    </canvas>
                    <canvas *ngIf="chartTypes !== 'I'" #chart baseChart [type]="chartType" [data]="flipkartData" [options]="options">
                    </canvas>
                </div>
            </div>
        </div>
        <div class="col-4 mt-3" *ngIf="(chartTypes === 'I' && chartDtVal.length > 0) || (chartTypes !== 'I' && chartqwikVal.length > 0)">
            <div class="shadow-sm card border-0">
                <div class="card-header  border-0">
                    <h6 class="my-2">{{chartTypes === 'I' ? 'DT' : 'Qwikcilver'}}</h6>
                </div>
                <div class="card-body">
                    <canvas *ngIf="chartTypes === 'I'" #chart baseChart [type]="chartType" [data]="Dtdata" [options]="options">
                    </canvas>
                    <canvas *ngIf="chartTypes !== 'I'" #chart baseChart [type]="chartType" [data]="qwikdata" [options]="options">
                    </canvas>

                </div>
            </div>

        </div>
        <div class="col-4 mt-3" *ngIf="(chartTypes === 'I' && chartYggVal.length > 0) || (chartTypes !== 'I' && charttcktVal.length > 0)">
            <div class="shadow-sm card  border-0">
                <div class="card-header  border-0">
                    <h6 class="my-2">{{chartTypes === 'I' ? 'YGG' : 'TicketExpress'}}</h6>
                </div>
                <div class="card-body">
                    <canvas *ngIf="chartTypes === 'I'" #chart baseChart [type]="chartType" [data]="Yggdata" [options]="options">
                    </canvas>
                    <canvas *ngIf="chartTypes !== 'I'" #chart baseChart [type]="chartType" [data]="ticketVAL" [options]="options">
                    </canvas>
                </div>
            </div>
        </div>
        <div class="col-4 mt-3" *ngIf="(chartTypes !== 'I' && chartvoucherVal.length > 0)">
            <div class="shadow-sm card  border-0">
                <div class="card-header  border-0">
                    <h6 class="my-2">Voucherkart</h6>
                </div>
                <div class="card-body">
                    <canvas  #chart baseChart [type]="chartType" [data]="voucherValue" [options]="options">
                    </canvas>
                </div>
            </div>
        </div>
        
        
        <div class="col-12 mt-3" *ngIf="(chartTypes === 'I' && internationalBarData.length > 0 && this.monthLabel.length > 0) || (chartTypes !== 'I' && domesticBarData.length > 0) ">
            <div class="shadow-sm card  border-0">
                <div class="card-header border-0">
                    <h6 class="my-2">OVERALL AMOUNT</h6>
                </div>
                    <div class="card-body">
                        <canvas *ngIf="chartTypes === 'I'" #barchart baseChart [type]="'bar'" [data]="data" [options]="barOptions">
                        </canvas>
                        <canvas *ngIf="chartTypes !== 'I'" baseChart [type]="'bar'" [data]="domesticDatas" [options]="barOptions">
                        </canvas>
                </div>
            </div>
        </div>
    </div>

</div> -->