import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InternationaldashboardComponent } from './internationaldashboard/internationaldashboard.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { MainModule } from './main/main.module';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { VoucherKartModule } from './voucher-Kart/voucher-kart.module';
import { BrandKartModule } from './brand-kart/brand-kart.module';
import { DealKartModule } from './deal-kart/deal-kart.module';
import { CompanyModule } from './company/company.module';
import { BrandModule } from './brand/brand.module';
import { CompanyContractModule } from './company-contract/companycontract.module';
import { ProductModule } from './product/product.module';
import { CategoryModule } from './category/category.module';
import { BrandManagementModule } from './brand-management/brand-management.module';
import { ProductmgtModule } from './productmanagement/productmanagement.module';
import { ProductMappingModule } from './product-mapping/product-mapping.module';
import { SettingsmenuModule } from './settingsmenu/settingsmenu.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { QRCodeModule } from 'angularx-qrcode';
import { TemplatesModule } from './templates/templates.module';
import { NgxPaginationModule } from 'ngx-pagination';

// import { VoucherWalletHistoryComponent } from './voucher-wallet-history/voucher-wallet-history.component';

import { VoucherWalletHistoryModule } from './voucher-wallet-history/voucher-wallet-history.module';
// import { VoucherReportComponent } from './voucher-report/voucher-report.component';
import { VoucherReportModule } from './voucher-report/voucher-report.module';
import { DenominationDashboardComponent } from './denomination-dashboard/denomination-dashboard.component';
import { DenominationOrderViewlistComponent } from './denomination-order-viewlist/denomination-order-viewlist.component';
import { ClientDigitalVoucherComponent } from './voucher-Kart/dashboard/client-digital-voucher/client-digital-voucher.component';
import { TermsComponent } from './terms/terms.component';
import { RewardkartEmailTemplatesComponent } from './rewardkart-email-templates/rewardkart-email-templates.component';
import { DigitalVoucherReportComponent } from './digital-voucher-report/digital-voucher-report.component';
import { ExternalCategoryComponent } from './external-category/external-category.component';
import { ExternalBrandComponent } from './external-brand/external-brand.component';
import { B2cBrandComponent } from './b2c-brand/b2c-brand.component';
import { B2cProductComponent } from './b2c-product/b2c-product.component';
import { B2cCategoryComponent } from './b2c-category/b2c-category.component';
import { CategoryProductComponent } from './category-product/category-product.component';
import { ClientEmailLayoutComponent } from './client-email-layout/client-email-layout.component';
import { CurrencyMarkupComponent } from './currency-markup/currency-markup.component';
import { DomesticvoucherComponent } from './domesticvoucher/domesticvoucher.component';
import { InternationalorderviewComponent } from './internationalorderview/internationalorderview.component';
import { CommonTablesModule } from './common-table/common-table.module';




@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LeftSidebarComponent,
    LoginComponent,
    DenominationDashboardComponent,
    DenominationOrderViewlistComponent,
    TermsComponent,
    RewardkartEmailTemplatesComponent,
    DigitalVoucherReportComponent,
    ExternalCategoryComponent,
    ExternalBrandComponent,
    B2cBrandComponent,
    B2cProductComponent,
    B2cCategoryComponent,
    CategoryProductComponent,
    ClientEmailLayoutComponent,
    CurrencyMarkupComponent,
    DomesticvoucherComponent,
    InternationaldashboardComponent,
    InternationalorderviewComponent
    
    
  ],

  imports: [
    AppRoutingModule,
    BrandKartModule,
    BrowserModule,
    DealKartModule,CompanyModule,BrandModule,CompanyContractModule,ProductModule,CategoryModule,
    BrandManagementModule,ProductmgtModule,ProductMappingModule,SettingsmenuModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    MainModule,
    NgChartsModule,
    NgxMyDatePickerModule.forRoot(),
    NgxUiLoaderModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    VoucherKartModule,
    AngularEditorModule ,
    QRCodeModule,VoucherWalletHistoryModule,VoucherReportModule,TemplatesModule,NgChartsModule,CommonTablesModule
    
    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
