import { HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as bootstrap from "bootstrap";
import { Modal } from "bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RequestProductmap } from "src/app/models/productmapping";
import {
  apiurl,
  environment,
  environmentfiles,
} from "src/environments/environment";
import { AppService } from "src/app/restful-services/restful.service";

@Component({
  selector: "app-client-product-mapping",
  templateUrl: "./client-product-mapping.component.html",
  styleUrls: ["./client-product-mapping.component.scss"],
})
export class ClientProductMappingComponent implements OnInit {
  @ViewChild("addForm", { static: true }) addForm: any;
  @ViewChild("closeProductmapBtn", { static: true })
  closeProductmapBtn!: ElementRef;
  @ViewChild("inputFile") inputFile: any;
  limit: number = 25;
  skip: number = 0;
  d: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  tooltipList: any;
  editDatas: boolean = false;
  maxmin: boolean = false;
  minvalue: any;
  maxvalue: any;
  fieldDisabled: boolean = true;

  role: any;
  userDetails: any;

  public name: string = "";
  public names: string = "";
  private headers: any = {};
  apiLoading: boolean = false;

  productmapList: any;
  Addrequestproductmap: RequestProductmap;
  categoryData: any = [];
  Edit: Boolean;

  catid: any;
  productsku: any;

  productData: any = [];
  selectproductData: any = [];

  productKeyword = "ProductName";
  selectproductKeyword = "BrandName";
  skuhide: boolean = true;
  sourcevalue: any;
  BrandName: any;
  brandName: any = "";
  external_id: any;
  skuinput: any;
  discountinput: any;
  editedValue: any;
  roletype: any;
  currentId: any;
  updatedData: any;
  paramValue: any;
  editDatas1: boolean = false;
  editImport: boolean = true;
  fileurls: any;
  empproduct: any;
  sourceData: any;
  sourceList: any = "";
  sourceParam: any = "";
  empParam: any = "";
  clientName: any = "";

  constructor(
    private route: ActivatedRoute,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this._http.getClientName().subscribe((data: any) => {
      //console.log(data,"data of prod");

      this.clientName = data.clientName;
    });
    this.Addrequestproductmap = new RequestProductmap();
    this.Edit = false;

    // this.productmapList = new Array<RequestProductmap>();
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.paramValue = params["id"];
    });
    console.log("ID", this.paramValue);
    this.role = localStorage.getItem("role");
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }
    this.getproductmaplist();
    this.getsource();
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;
  }

  getproductmaplist() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.apiLoading = true;
    this.loader.start();
    this._http
      .getClientproductmaplist(
        this.productsku,
        this.limit,
        this.skip,
        this.paramValue,
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          this.productmapList = data.list;
          // this.empproduct = data.list[0].discount;
          // console.log("12330",this.empproduct)
          console.log(this.productmapList);

          this.count = data.totalCount;
          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
          if (data) {
            this.apiLoading = false;
            this.count = data.totalCount;
            this.productmapList = data.list;
            this.toolTipInit();
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  getsource() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    let item = "";
    this._http.getSource(item, this.headers).subscribe(
      (data: any) => {
        if (!this.sourceList) {
          this.sourceData = data.data;
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  filterList(event:any) {
    if(event){
      if(this.Addrequestproductmap.product_name){
        this.Addrequestproductmap.product_name = "";
        this.addForm.form.markAsUntouched();
      }
      if(this.Addrequestproductmap.rewardkart_sku){
        this.Addrequestproductmap.rewardkart_sku = ""
      }
      if(this.Addrequestproductmap.source){
        this.Addrequestproductmap.source = "";
      }
      if(this.maxmin){
        this.Addrequestproductmap.price = ""; 
        this.maxmin = false;
      }
      if(this.Addrequestproductmap.discount){
        this.Addrequestproductmap.discount = "";
      }
    } 
    this.fieldDisabled = false;
    this.onChangeProduct(this.empParam);
  }

  getproductmaplistfilter() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.apiLoading = true;
    this._http
      .getClientproductmaplist(
        this.productsku,
        this.limit,
        this.skip,
        this.paramValue,
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          this.productmapList = data.list;
          console.log(this.productmapList);

          this.count = data.totalCount;
          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
          if (data) {
            this.apiLoading = false;
            this.count = data.totalCount;
            this.productmapList = data.list;
            this.toolTipInit();
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  statusChange(id: any, status: any) {
    let requestParams = {
      id: id,
      status: status,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.apiLoading = true;
    this.loader.start();

    this._http
      .clienProuducttStatus(requestParams, this.headers)
      .subscribe((data: any) => {
        this.apiLoading = false;
        this.loader.stop();
        if (data.status == 1) {
          this.toastr.success("Status Updated Successfully");
          this.getproductmaplist();
        } else if (data.status === 0) {
          this.toastr.success("Something Went Wrong");
        } else {
          this.toastr.error(data.msg);
        }
      });
  }

  newPopup() {
    this.Edit = false;
    this.editDatas = true;
    this.editDatas1 = false;
    this.Addrequestproductmap.product_name = "";
    this.Addrequestproductmap.rewardkart_sku = "";
    this.Addrequestproductmap.price = "";
    this.Addrequestproductmap.discount = "";
    this.Addrequestproductmap.clientDiscount = "";
    this.Addrequestproductmap.issuance_fee = "";
    this.Addrequestproductmap.source = "";

    const element = document.getElementById("productmapModal") as HTMLElement;
    const addproductmapModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    addproductmapModal.show();
  }

  AddProductmap(Addrequestproductmap: RequestProductmap) {
    if (
      this.Addrequestproductmap.clientDiscount >
      this.Addrequestproductmap.discount
    ) {
      this.toastr.error(
        "Client discount must not be greater than product discount"
      );
      return;
    }

    if (
      !this.Addrequestproductmap.issuance_fee ||
      !this.Addrequestproductmap.clientDiscount
    ) {
      this.toastr.error("Please Fill the Mandatory Fields");
      return;
    }

    // this.Edit = false;
    // this.editDatas = false;

    // if (

    //   !this.Addrequestproductmap.rewardkart_sku || !this.Addrequestproductmap.product_name ||  !this.Addrequestproductmap.product

    // ) {
    //   this.toastr.error("Please fill all the mandatory fields");
    //   return;
    // }

    if (this.Addrequestproductmap.source === "QwikCilver") {
      this.Addrequestproductmap.source = "QC";
    }
    if (this.Addrequestproductmap.source === "Ticket Express") {
      this.Addrequestproductmap.source = "TE";
    }
    if (this.Addrequestproductmap.source === "Amazon") {
      this.Addrequestproductmap.source = "Amazon";
    }

    let postparams = {
      client_id: this.paramValue,
      product_sku: this.Addrequestproductmap.rewardkart_sku,
      issuance_fee: this.Addrequestproductmap.issuance_fee,
      discount: this.Addrequestproductmap.clientDiscount,
      product_id: this.Addrequestproductmap.id,
      created_by: this.userDetails.UserID,
      created_by_role: this.role,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.clientNewproductmap(postparams, this.headers).subscribe({
      next: (data: any) => {
        console.log("Client Prodcut Data " + data);

        this.loader.stop();
        if (data.status == 1) {
          this.loader.stop();
          this.closeProductmapBtn.nativeElement.click();
          this.toastr.success("product map added successfully");
          this.addForm.resetForm();
          this.getproductmaplist();
        } else if (data.status === 0) {
          this.loader.stop();
          this.toastr.error(data.msg);
          this.closeProductmapBtn.nativeElement.click();
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
        this.closeProductmapBtn.nativeElement.click();
      },
    });
  }

  productsClear(item: any) {
    this.Addrequestproductmap.rewardkart_sku = "";
  }

  importProduct(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";

    if (getImg.toLowerCase() !== ".csv") {
      this.toastr.error("File not supported");
      return;
    } else {
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("product_list", event.target.files[0]);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_role", this.userDetails.role);
      formData.append("client_id", this.paramValue);

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importClientProduct(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data.status === 1) {
            this.toastr.success(data.msg);
            this.getproductmaplist();
          } else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getproductmaplist();
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }

          this.inputFile.nativeElement.value = "";
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  selectproductselectEvent(item: any) {
    let rowvalue = this.selectproductData.filter(
      (x: any) => x.BrandName === item.BrandName
    );
    this.skuinput = rowvalue[0].sku;
    this.discountinput = rowvalue[0].discount;
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  restrictEnter(args: any) {
    if (args.key === "Enter" && args.keyCode == 13) args.preventDefault();
  }

  onChangeProduct(item: any) {
    this.Addrequestproductmap.rewardkart_sku = "";
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    if (this.sourceList === "Ticket Express") {
      this.sourceParam = "Ticket Express";
      // this.category =  this.catid;
    }

    if (this.sourceList === "QwikCilver") {
      this.sourceParam = "QwikCilver";
    }

    if (this.sourceList === "Amazon") {
      this.sourceParam = "Amazon";
    }

    if (this.sourceList === "FlipKart") {
      this.sourceParam = "FlipKart";
    }

    if (this.sourceList === "voucherkart") {
      this.sourceParam = "voucherkart";
    }

    if (this.sourceList === "EZPIN") {
      this.sourceParam = "EZPIN";
    }
    if (this.sourceList === "MEA") {
      this.sourceParam = "MEA";
    }
    if (this.sourceList === "DT ONE") {
      this.sourceParam = "DT";
    }
    if (this.sourceList === "YOU GOTA GIFT") {
      this.sourceParam = "YGG";
    }
    if(this.sourceList === "Joyalukkas"){
      this.sourceParam = "Joyalukkas";
    }

    this._http
      .getClientproducts(
        item,
        this.headers,
        null,
        this.sourceParam,
        this.sourceList
      )
      .subscribe(
        (data: any) => {
          this.productData = data.data;
          if (data) {
            data.data.forEach((e: any) => {
              this.productData.push(e.name);
            });
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedCharacters = "0123456789."; // Define the allowed characters
    const inputChar = event.key;

    if (
      inputChar === " " ||
      (inputChar !== "." && allowedCharacters.indexOf(inputChar) === -1)
    ) {
      event.preventDefault(); // Prevent the input
    }
  }

  selectproductEvent(item: any) {
    let skuval = this.productData.filter((x: any) => x.name === item);
    this.Addrequestproductmap.rewardkart_sku = skuval[0].sku;
    this.Addrequestproductmap.source = skuval[0].product_type;
    // this.Addrequestproductmap.price = skuval[0].face_value;
    if (
      skuval[0].face_value == 0 ||
      skuval[0].face_value == "0" ||
      skuval[0].face_value == null
    ) {
      this.maxmin = true;
      this.maxvalue = skuval[0].maxface_value;
      this.minvalue = skuval[0].minface_value;
    } else {
      this.maxmin = false;
      this.Addrequestproductmap.price = skuval[0].face_value;
    }
    this.Addrequestproductmap.discount = skuval[0].discount;
    this.editDatas = true;
    this.Addrequestproductmap.id = skuval[0].id;
  }

  onChangeProductselect(item: any) {
    let source = this.sourcevalue;
    if (source === "QwikCilver") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "QC";
      this._http.getQCproducts(item, type, this.headers).subscribe(
        (data: any) => {
          this.selectproductData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          if (this.selectproductData.length > 0) {
            for (let data of this.selectproductData) {
              if (data.BrandName === item.brandName) {
                // this.noBrandFound = false;
                return;
              } else {
                // this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "Ticket Express") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let type = "TE";
      this._http.getTEproductsmap(item, type, this.headers).subscribe(
        (data: any) => {
          this.selectproductData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          if (this.selectproductData.length > 0) {
            for (let data of this.selectproductData) {
              if (data.BrandName === item.brandName) {
                // this.noBrandFound = false;
                return;
              } else {
                // this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "Amazon" || source === "FlipKart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let type = "AMAZON";
      if (source === "FlipKart") {
        type = "FlipKart";
      }
      this._http.getAmazonproducts(item, type, this.headers).subscribe(
        (data: any) => {
          this.selectproductData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          if (this.selectproductData.length > 0) {
            for (let data of this.selectproductData) {
              if (data.BrandName === item.brandName) {
                // this.noBrandFound = false;
                return;
              } else {
                // this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else {
    }
  }

  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem("pageNo", pageNo);
    this.getproductmaplist();
  }

  edit(id: string) {
    this.Edit = true;
    this.editDatas = true;
    this.editDatas1 = true;
    // this.currentid = id;
    // console.log(this.currentid);
    this.editedValue = this.productmapList.find((p: any) => {
      return p.id == id;
    });
    //console.log("edited values  " ,this.editedValue);
    this.Addrequestproductmap.product_name = this.editedValue.name;
    this.Addrequestproductmap.rewardkart_sku = this.editedValue.product_sku;
    // this.Addrequestproductmap.source = this.editedValue.
    this.Addrequestproductmap.discount = this.editedValue.product_discount;
    this.Addrequestproductmap.issuance_fee = this.editedValue.issuance_fee;
    this.Addrequestproductmap.clientDiscount = this.editedValue.discount;
    this.currentId = this.editedValue.id;
    // this.Addrequestproductmap.price = this.editedValue.face_value;
    if (
      this.editedValue.face_value == 0 ||
      this.editedValue.face_value == "0" ||
      this.editedValue.face_value == null
    ) {
      this.maxmin = true;
      this.maxvalue = this.editedValue.maxface_value;
      this.minvalue = this.editedValue.minface_value;
    } else {
      this.maxmin = false;
      this.Addrequestproductmap.price = this.editedValue.face_value;
    }

    this.Addrequestproductmap.source = this.editedValue.product_type;
  }

  updateClientProduct() {
    if (
      this.Addrequestproductmap.clientDiscount >
      this.Addrequestproductmap.discount
    ) {
      this.toastr.error(
        "Client discount must not be greater than product discount"
      );
      return;
    }
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
      this.roletype = this.userDetails.UserID;
    }
    let postParams = {
      id: this.currentId,
      issuance_fee: this.Addrequestproductmap.issuance_fee,
      discount: this.Addrequestproductmap.clientDiscount,
      updated_by: this.roletype,
      updated_by_role: this.role,
    };
    this.apiLoading = true;
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.updateClientProduct(postParams, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        this.updatedData = data;
        this.getproductmaplist();

        if (data.status == 1) {
          this.toastr.success(data.msg);
          this.router.navigate(["/client-product-mapping"], {
            replaceUrl: false,
          });
        } else if (data.status == 0) {
          this.toastr.error("Client Product Update Failed");
        }
      },

      (error) => {
        this.toastr.error(error.error.Message);
      }
    );
  }

  filter() {
    if (this.limit || this.skip || this.productsku) {
      this.skip = 0;
      this.d = 1;
    }
    this.getproductmaplistfilter();
  }

  backStage() {
    this.router.navigate(["/clients"], { replaceUrl: false });
  }
}
