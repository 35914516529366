import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Supplier } from '../../models/supplierList';
import { AppService } from '../../restful-services/restful.service';
import { environment,environmentfiles } from "../../../environments/environment";
import * as XLSX from 'xlsx';
declare var bootstrap: any;


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {


  public supplierName: string = '';
  public statuss: any = '';
  supplierDetail: Supplier;
  supplierList: Supplier[];
  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  lastvalue: number = 0;
  @ViewChild('closeSupplierBtn', { static: true }) closeSupplierBtn!: ElementRef;
  role: any;
  userDetails: any;
  @ViewChild('inputFile') inputFile: any;
  supplierId: any;
  supplierStatus: any;
  duplicateSupplier: boolean = false;
  UPDATE:any;
  tooltipList: any;
  private headers:any={};
  apiLoading: boolean = false;
  fileurls:any;




  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.supplierDetail = new Supplier();
    this.supplierList = new Array<Supplier>();
  }

  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    this.getSupplierList();

    this.supplierDetail.status = '2';
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL; 
  }



  getSupplierListfilter() {
   

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

   
     this.apiLoading = true;
    this._http.getSupplierList(this.limit, this.skip, this.supplierName, this.statuss,this.headers).subscribe({
      next:(data: any) => {

       
        this.apiLoading = false;
        this.supplierList = data.data;
        this.count = data.count;
        this.toolTipInit();
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
        this.loader.stop();
      },
      error: _error=>{
        this.apiLoading = false;
        this.loader.stop();
      }
    })
  }





  getSupplierList() {
   

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
     this.apiLoading = true;
    this._http.getSupplierList(this.limit, this.skip, this.supplierName, this.statuss,this.headers).subscribe({
      next:(data: any) => {

        this.loader.stop();
        this.apiLoading = false;
        this.supplierList = data.data;
        this.count = data.count;
        this.toolTipInit();
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
        this.loader.stop();
      },
      error: _error=>{
        this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  filterList() {  
   
    if ( this.limit || this.skip || this.supplierName || this.statuss) {
      this.skip = 0;
      this.p = 1;
    }
    this.getSupplierListfilter();
  }

  pageChanged(pageNo: any) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getSupplierList();
  }

  clear() {
    this.supplierName = '';
    this.statuss = '';
    this.getSupplierList();

    this.p = 1;
    let page = this.p;
    this.pageChanged(page);   
  }

  backclear(){
    location.reload();
  }

  editSupplier(list: Supplier, status: any) {
    
    this.supplierStatus = status;
    this.supplierDetail = list;

    const element = document.getElementById('addModal') as HTMLElement;
    const addSupplierModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addSupplierModal.show();
  }


  addNewSupplier(status: any) {
  
    this.supplierStatus = status;
    this.supplierDetail = new Supplier();
    const element = document.getElementById('addModal') as HTMLElement;
    const addSupplierModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addSupplierModal.show();   
    
  }

  deleteModal(supplierId: any) {

    this.supplierId = supplierId;
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }

  importSupplier(event: any) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.csv')) {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierImportType == "" || this.supplierImportType == undefined || this.supplierImportType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    // this.closeImportBtn.nativeElement.click();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData = new FormData();
      formData.append('filename', event.target.files[0]);
      // formData.append('supplier_type', this.supplierImportType);
      formData.append('created_by_id', this.userDetails.UserID);
      formData.append('created_by_name', this.userDetails.UserName);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);

      let token = localStorage.getItem("token");
      this.headers = {
         headers: new HttpHeaders({
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
         })
       };
      this.loader.start();
      this._http.importUserDataVoucher(formData,this.headers).subscribe({
        next: (data: any) => {
        this.loader.stop();
        if (data.status === 1) {
          this.toastr.success("Your file uploaded successfully");
          this.getSupplierList();
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
        this.inputFile.nativeElement.value = '';
      },
        error: (error:any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        }
     })
    }
  }



  addSupplier() {
    
    if (
      
      this.supplierDetail.supplier_name == "" || !this.supplierDetail.supplier_name || 
      this.supplierDetail.status === "" || this.supplierDetail.status === undefined
    //   this.supplierDetail.api_url === "" || 
    //   this.supplierDetail.api_key === "" || 
    //   this.supplierDetail.api_authentication_code === ""||      
    // /^ *$/.test(this.supplierDetail.supplier_name.trim()) ||
    //  /^ *$/.test(this.supplierDetail.api_url.trim())|| 
    //  /^ *$/.test(this.supplierDetail.api_key.trim())|| 
    //  /^ *$/.test(this.supplierDetail.api_authentication_code.trim())
     
     ) 

     {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    // if (this.duplicateSupplier == true) {
    //   this.toastr.error("Aggregator name is already exist. Please enter other aggregator");
    //   return;
    // }

    // var expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=].[com]*)/;
    // var regex = new RegExp(expression);
    // const result = this.supplierDetail.api_url.match(regex);
    // if (result == null) {
    //   this.toastr.error("Please enter valid API URL");
    //   return;
    // }


    
    if (this.supplierStatus == 'create') {

      this.supplierDetail.action = 'CREATE';
      this.supplierDetail.created_by_name = this.userDetails.UserName;
      this.supplierDetail.created_by_id = this.userDetails.UserID;

    }

    else {
      this.supplierDetail.action = 'UPDATE';
      this.supplierDetail.modify_by_name = this.userDetails.UserName;
      this.supplierDetail.modify_by_id = this.userDetails.UserID;
    }

    if (this.supplierStatus == 'create') {

      
      let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
    
      this._http.addNewSupplier(this.supplierDetail,this.headers).subscribe({
        next:(data: any) => {
          this.closeSupplierBtn.nativeElement.click();
        
        if (data.status == 1) {
       
          this.toastr.success("Source request send successfully");
          // this.getSupplierList();
          this.loader.stop(); 
                   
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
       
        }
      },
       error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        
        }
      })
    }


    else {

      let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

      this._http.updateNewSupplier(this.supplierDetail,this.headers).subscribe({
        next: (data: any) => {
        this.loader.stop();     

        if (data.status == 1) {
          this.closeSupplierBtn.nativeElement.click();
          this.toastr.success("Source request send successfully");
          this.getSupplierList();
        
       
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        
        }
      },
       error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
       
        }
      })
    }
  }




  deleteSupplier() {  

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
    this._http.deleteSupplierList(this.supplierId,this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        this.closeSupplierBtn.nativeElement.click();
   

        if (data.status == 1) {
          this.toastr.success("Source deleted successfully");
          // this.getSupplierList();
        
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      })
  }

  statusChange(supplierId: any, status: any) {


    let requestParams = {
      'supplier_id': supplierId,
      'status': status
    }
   

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
    this._http.supplierStatus(requestParams,this.headers).subscribe((data: any) => {
      this.loader.stop();
      this.closeSupplierBtn.nativeElement.click();
      if (status == 0) {
        this.toastr.success("Source Actived Successfully");
      }
      else if (status === 1) {
        this.toastr.success("Source Inactived Successfully");
      }
      else {
        this.toastr.error(data.msg);
      }
     
      // this.getSupplierList();
    })
  }

  onChangeSupplier(event: any) {

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

    this._http.getSupplierItems(event.target.value,this.headers).subscribe((data: any) => {
      if (data.data.length > 0) {
        this.duplicateSupplier = true;
      }
      else {
        this.duplicateSupplier = false;
      }
    })
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {trigger : 'hover'})
      })
    },500)
  }

  ngOnDestroy(){
    if(this.tooltipList){
      this.tooltipList.forEach( (tooltip: { dispose: () => void; })=>{
        tooltip.dispose();
      });
    }
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }


}
