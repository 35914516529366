export class RequestProductmgt {

    // dashboard
    name:any;
    brand_name:any;
    sku:any;
    validity:any;
    face_value:any;
    minface_value:any;
    maxface_value:any;
    image:any;
    brand:any = '';
    product:any;
    id:any;

    //edit

    varient:any = '';
    varienttype:any = '';
    usage_mode:any = '';
    variant:any;
    product_name:any;
    discount:any;
    processing_fee:any;
    issuance_fee:any;
    authentication_charge:any;
    description:any;
    file:any;
    terms_condition:any;
    authendication_charges: any;
   
    htmlContent:any;
    brand_id:any;
    internal_face_value:any;
    internal_usage_mode:any;

    storelocator:any;
    instrction:any;
    howtouse:any;
    reward:any;
    price:any;

    display_voucherkart: any;
    display_rewardkart: any;
    type:any;
    important_instruction: any;
    reward_points: any;
    how_to_use: any;
    store_locator: any;
    b2cflag: any= '';
    // display_rewardkart: any;
  }