import { HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from 'src/app/restful-services/restful.service';
import { apiurl } from 'src/environments/environment';
import { company } from 'src/app/models/company';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-company-add-edit',
  templateUrl: './company-add-edit.component.html',
  styleUrls: ['./company-add-edit.component.scss']
})
export class CompanyAddEditComponent {


  requestbrandedit: company;
  requestbrandedit1: any;
  role: any;
  userDetails: any;
  private headers: any = {};
  @ViewChild('inputFile1') inputFile1: any;
  @ViewChild('inputFile2') inputFile2: any;

  dynamicFields: { user_name: any, password: any, confirmpassword: any,user_type?:string,id?:any,enabled?:boolean}[] = [];
  submitted: boolean = false;
  idvalue: any;
  pdfvalue : any;
  

  file: any = '';
  //category

  categoryData: any = [];


  Edit: boolean;
  filevalue: any;
  isValidUrl: boolean = true;
  user_id:any[] = [];



  userpassword: boolean = false;
  category: any = '';
  brand_name: any;
  imagevalue: any;
  imagevalues: any;
  imagevalue1:any;
  pathurl: any;

  previews: any;
  previews1: any;
  preshow: boolean = false;
  preshow1: boolean = false;

  currencyData:any = [];
  company_name: any;
  user_name: any;
  password: any;
  confirmpassword: any;
  email: any;
  support_email: any
  contact: any;
  description: any;
  loading: boolean = false;
  filevalue1: any;
  clientId: any;
  editedForm:boolean = false;
  pdfshow:boolean = false;
  pdfhide:boolean = false;
  // companyList: company;

  constructor(private route:ActivatedRoute, private sanitizer: DomSanitizer, private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    this.requestbrandedit = new company();
    // this.companyList =new company();

  }



  ngOnInit() {
     
    

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    const { id } = history.state;
    if (id) {
      // localStorage.setItem('editID', id);
      this.idvalue = id;
      this.Edit = true;
      this.getclientdatas(id);
    }else{
      this.route.queryParams.subscribe(params => {
        this.idvalue   = params['id'];
      });  
      if( this.idvalue){
        this.Edit = true;
        this.getclientdatas(this.idvalue);
      }
      
    }
    this.pathurl = apiurl;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getCurrency(this.headers).subscribe((data: any) => {
      
      this.currencyData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
    
  }


  getclientdatas(id: any) {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getClientDetails(this.headers, this.idvalue).subscribe(
      (data: any) => {
        if (data.status == 1) {
          this.requestbrandedit = data.result;
          this.requestbrandedit1 = data.userlist;

          this.requestbrandedit.currency = this.requestbrandedit.currency_code;
          this.requestbrandedit.company_name = this.requestbrandedit.CompanyName;
          this.requestbrandedit.user_name = this.requestbrandedit1[0].user_name;
          this.requestbrandedit.email = this.requestbrandedit.ContactEmail;
          this.requestbrandedit.password = this.requestbrandedit1[0].password;
          
          this.requestbrandedit.confirmpassword = this.requestbrandedit1[0].password;
          this.requestbrandedit.contact = this.requestbrandedit.ContactNumber;
          this.requestbrandedit.Wallet = this.requestbrandedit.wallet_threshold;
          if(this.requestbrandedit.contact == null || this.requestbrandedit.contact == undefined){
            this.requestbrandedit.contact == ""
          }
          this.requestbrandedit.url = this.requestbrandedit.Website;
          if(this.requestbrandedit.url === null || this.requestbrandedit.url === undefined || this.requestbrandedit.url === "undefined"){
            this.requestbrandedit.url === "";
          }
          this.requestbrandedit.support_email = this.requestbrandedit.SupportEmail;
          if(this.requestbrandedit.support_email === null || this.requestbrandedit.support_email === undefined || this.requestbrandedit.support_email === "undefined"){
            this.requestbrandedit.support_email === "";
          }

          if (data.userlist.length > 1) {
            this.editedForm = true;
            this.dynamicFields = data.userlist.slice(1).map((user:any) => {
              return {

                id:user.id,
                user_name: user.user_name,
                password: user.password,
                confirmpassword: user.password,

              };
            });
          }

          this.preshow = true;
          if (this.preshow) {
            this.imagevalue = this.requestbrandedit.ImagePath;
          }
          this.preshow1 = true;
          if (this.preshow1) {
            this.imagevalue1 = this.requestbrandedit.kyc_path;
            this.imagevalues = this.requestbrandedit.kyc_path;
          }
          if(this.imagevalue1.includes(".pdf")){
            this.pdfshow = true;
          }else{
            this.pdfshow = false;
          }
          this.userpassword = true;



        }

      })

  }

  validateDynamicFieldsPasswords(): boolean {
    for (let field of this.dynamicFields) {
      if (field.password !== field.confirmpassword) {
        this.toastr.error("Passwords do not match");
        return false; 
      }
    }
    return true; 
  }

  validateinput(): boolean {
    for (let field of this.dynamicFields) {
      if (field.password == "" || field.confirmpassword == "" || field.user_name == "") {
        this.toastr.error("Please Fill the Fields");
        return false; 
      }else if(field.password == null || field.confirmpassword == null || field.user_name == null){
        this.toastr.error("Please Fill the Fields");
        return false;
      }
    }
    return true; 
  }



  AddBrand(requestbrandedit: company) {

    if (
      !this.requestbrandedit.company_name ||
      !this.requestbrandedit.email  || !this.requestbrandedit.user_name || !this.requestbrandedit.password
      || !this.requestbrandedit.confirmpassword || !this.requestbrandedit.Wallet || !this.requestbrandedit.currency  
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    if (this.requestbrandedit.password !== this.requestbrandedit.confirmpassword) {
      this.toastr.error("Some Passwords do not match");
      return;
    }

    if (!this.validateDynamicFieldsPasswords()) {
      this.toastr.error("Some Passwords do not match");
      return;
    }

    if(!this.validateinput()){
      return;
    }

   
  
  let valueArr = this.dynamicFields.map(function(item){ return item.user_name });
  let isDuplicate = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
  });
  
  

  if(isDuplicate){
      this.toastr.error("User Name Cannot Be Same!")
      return;
    }
   

    if(!this.Edit){
      if (!this.filevalue || !this.filevalue1 ) {
        this.toastr.error("Please Upload the Mandatory Documents")
        return;
      }
    }
    



    var expressions = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    var regex = new RegExp(expressions);
    const result = this.requestbrandedit.email.match(regex);
    if (result == null) {
      this.toastr.error("Please enter valid Email");
      return;
    }

    if (this.requestbrandedit.actnumber) {
      var expression = /^[0-9]{9,18}$/;
      var regex = new RegExp(expression);
      const resul = this.requestbrandedit.actnumber.match(regex);
      if (resul == null) {
        this.toastr.error("Please enter valid Account Number");
        return;
      }
    }


    if (this.requestbrandedit.ifsc) {
      var expression = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
      var regex = new RegExp(expression);
      const results = this.requestbrandedit.ifsc.match(regex);
      if (results == null) {
        this.toastr.error("Please enter valid IFSC code");
        return;
      }
    }


    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', "multipart/form-data");
    const formData: any = new FormData();


    formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
    formData.append('name', null);
    formData.append('company_name', this.requestbrandedit.company_name);
    // formData.append('user_name', this.requestbrandedit.user_name);
    // formData.append('password', this.requestbrandedit.password);
    formData.append('email', this.requestbrandedit.email);
    formData.append('contact_number', this.requestbrandedit.contact);
    formData.append('company_website', this.requestbrandedit.url);
    formData.append('support_email', this.requestbrandedit.support_email);
    formData.append('created_by', this.userDetails.UserID);
    formData.append('wallet_threshold', this.requestbrandedit.Wallet);
    formData.append('currency_code', this.requestbrandedit.currency);
    if(this.Edit){
      // console.log(JSON.stringify(this.user_id),"12345");
      this.user_id.map((val,index)=>{
        formData.append(`users_delete_ids[${index}]`, val);
        })
    }
    const defaultObject = [
      {
        "user_name": this.requestbrandedit.user_name,
        "password": this.requestbrandedit.password,
        "confirmpassword": this.requestbrandedit.confirmpassword
      }
    ];
    
    let userDetailsData;
    if (this.dynamicFields.length >= 1) {
      userDetailsData = this.dynamicFields.concat(defaultObject);
    } else {
      userDetailsData = defaultObject;
    }

    
      if(this.dynamicFields.length >= 1){
        formData.append('userDetails',JSON.stringify(userDetailsData))
      }
      else{
        formData.append('userDetails',JSON.stringify([{
          "user_name":this.requestbrandedit.user_name,
          "password":this.requestbrandedit.password,
          "confirmpassword":this.requestbrandedit.confirmpassword
        }])
        )
      
    }

    
    

    if (this.filevalue) {
      formData.append('company_image', this.filevalue);
    }
    if (this.filevalue1) {
      formData.append('company_kyc', this.filevalue1);
    }
    if (this.Edit) {
      formData.append('file_path', this.requestbrandedit.ImagePath);
      formData.append('kyc_path', this.requestbrandedit.kyc_path);
      formData.append('id', this.Edit ? this.idvalue : "");
    }





    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.createNewClient(formData, this.headers, this.Edit).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data) {

          if (data.status == 1) {
            this.toastr.success("Client Created Successfully");
            this.clientId = data.id;
            // this.router.navigate(['/clients'], { replaceUrl: false })
            const element = document.getElementById('approveModals') as HTMLElement;
            const approveModals = new Modal(element,{backdrop: 'static',
            keyboard: false
          });
          approveModals.show();


          }
          else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
        }
      }
    })
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }


  backclear() {
    this.router.navigate(['/clients'], { replaceUrl: false });
    // localStorage.removeItem("editlist"); 
  }

  selectfile(event: any) {
    
    
    this.filevalue = event.target.files[0];
    // console.log(this.filevalue.type ="application/pdf","event of image");

    if (this.filevalue) {

      this.pathurl = apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue);
      const safeblobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      this.previews = safeblobUrl;
      this.preshow = true;

      this.toastr.success("Client Logo upload successfully");
      return;
    }
    else {
      this.preshow = false;
    }
  }

  selectfile1(event: any) {

    this.filevalue1 = event.target.files[0];
    this.pdfvalue = this.filevalue1.type
    if(this.pdfvalue === "application/pdf"){
      this.pdfshow = true;
    }else{
      this.pdfshow = false;
    }

    if (this.filevalue1) {

      this.pathurl = apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue1);
      const safeblobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      this.previews1 = safeblobUrl;
      this.preshow1 = true;

      this.toastr.success("Client KYC upload successfully");
      return;
    }
    else {
      this.preshow1 = false;
    }
  }


  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  letterOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }


  alphabetsonly(event: KeyboardEvent) {
    // Get the key that was pressed
    const key = event.key;

    // Allow backspace, delete, arrow keys, and spaces
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === ' ') {
      return;
    }

    // Allow alphabets only
    if (!/[a-zA-Z]/.test(key)) {
      event.preventDefault();
    }
  }

  addDynamicField() {
    if (this.Edit) {
    
      this.dynamicFields.push({ user_name: '', password: '', confirmpassword: '', user_type: "new",enabled: true });
      
      
      
    }
  // if(this.dynamicFields.slice(0,-1).some(field => 
      //   field.user_name == "" || field.password == ""|| field.confirmpassword== ""
      // )
      // )
      // {
      //   this.editedForm = false;
      // } 
    else{
      this.dynamicFields.push({ user_name: '', password: '', confirmpassword: '',enabled: true});
    }
  }

  removeDynamicField(index: any,id:any) {
    this.dynamicFields.splice(index, 1);
    // console.log(this.requestbrandedit1);
    // const dfd = this.requestbrandedit1.filter((data:any) => {
    //   if(data.id === id){
    //       return data.id
    //   }
      
    // })
    // console.log("aaa",dfd);
    if(id){
      this.user_id.push(id);
    }
    
    // console.log(this.user_id);
    
    
    
  }

  onSubmit() {
    this.submitted = true;
  }

 

  clientProductMap(requestbrandedit: company){
    // this._http.clientProduct(requestbrandedit);
    const id = this.clientId 
    this.router.navigate(['/client-product-mapping'], {
      queryParams:{"id":id},
      state: { id },
      replaceUrl: true
    });  }

  
  back(){
    this.router.navigate(["/clients"],{replaceUrl:false})
  }


}
