<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-6">
                    <label class="texts"><b>{{companylist}}</b> Wallet  Available Balance : <b>{{companyWallet | currency : 'INR'}}</b></label>
                </div>
                

                <div class="col-6 text-end d-flex justify-content-end">
                    <button class="btn btn-success mr-5" style="width: 169px;" (click)="newPopup()">Add Wallet Balance</button>
                
                </div>
                
            </div>
            <!-- <div class="row mb-4">
            <div class="col-12">
                <label class="texts">Client Name : {{clientName}} &nbsp;&nbsp;<span>Available Balance : {{clientwallet}}</span></label>
            </div> -->
            <!-- <div class="col-3 text-end">
                <label class="texts">Available Balance : {{clientName}}</label>
            </div> -->
           <!-- </div> -->
        </div>
    </div>
</div>


<div class="row mx-0">
    <div  class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
  <table class="table mb-0">
      <thead class="adcolor py-4 px-3 mx-0 opacity-75">
        <tr>
          <th scope="col" class="pt1">SI.No</th>
          <th scope="col" class="pt1 text-center">Log</th>
          <th scope="col" class="pt2">Amount</th>
          <th scope="col" class="pt1 text-center">Date</th>
          <th scope="col" class="pt1 text-center">Status</th>
        </tr>
      </thead>

                        
      <tbody>
        <tr *ngFor="let value of values | paginate: {itemsPerPage: limit, currentPage: d,totalItems:totalCount}; let i = index;">
          <th scope="row">{{i+1+skip}}</th>
          <td class="text-center">{{value.log}}</td>
          <td class="text-end">{{value.amount | currency : 'INR'}}</td>
          <td class="text-center">{{value.created_on | date : 'dd/MM/yyyy'}}</td>
          <td class="text-center"*ngIf="role !== 'SuperAdmin'">{{value.status}}</td>
          <td class="text-center" *ngIf="value.order_id !== null">
            {{value.order_status !== null ? value.order_status : '-' }}
          </td>
          <td class="text-center" *ngIf="role === 'SuperAdmin' && value.order_id === null">
            <a class="linkColorActive downloadIcon" *ngIf="value.status == 'Active'" style="pointer-events: none; opacity: 0.5;">
                <i class="fa fa-toggle-on cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active" (click)="statusChange(value.id,'InActive')"></i>
            </a>
            <a class="linkColor downloadIcon" *ngIf="value.status == 'InActive'">
                <i class="fa fa-toggle-off cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(value.id,'Active')"></i>
            </a>
          </td>
        </tr>
        
      </tbody>
    </table>
    <table class="w-100" *ngIf="values && values.length === 0 ">
        <tr class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light" >
            <td class="card-body text-center m-1" >
                No Record Found.
            </td>
        </tr>
    </table>
    <div class="row" *ngIf=" values.length > 0">
        <div class="col-6 pt-3">
            <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > totalCount? totalCount : lastvalue}} of
                {{totalCount}} entries</p>    
        </div>
        <div class="col-6">
          <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
      </div>
    </div>
    <div class="col-12 text-end my-3 mr-3">
        <button class="btn backcolor margin" type="button" (click)="backStage()">Back</button>
    </div>
    </div>
    
    </div>
    <ngx-ui-loader></ngx-ui-loader>








<div class="modal fade" id="productmapModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add Wallet Amount</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-5 no-icon">
                            <div class="md-form form-sm my-3">
                                <label for="product_name" class="mb-2">Enter Wallet Amount
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control w-auto" [(ngModel)]="wallet"
                                    placeholder="Enter wallet" name="wallet" required
                                    (keypress)="allowOnlyNumbers($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" (click)="addWallet()"
                        data-bs-dismiss="modal">Add</button>
                </div>
            </div>
        </div>
    </div>
</div>