import { HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../restful-services/restful.service';
import { DomSanitizer } from '@angular/platform-browser';
import { apiurl } from 'src/environments/environment';

@Component({
  selector: 'app-rewardkart-email-templates',
  templateUrl: './rewardkart-email-templates.component.html',
  styleUrls: ['./rewardkart-email-templates.component.scss']
})
export class RewardkartEmailTemplatesComponent {

  @ViewChild('inputFile1') inputFile1: any;
  @ViewChild('inputFile2') inputFile2: any;

  public name: string = "";
  public names: string = "";
  userDetails:any;
  roletype:any;
  fileUrl:any;
  private headers: any = {};
  values: any;
  limit: number = 50;
  skip: number = 0;
  datas: any;
  currentid: any;
  body: any;
  currentBody: any;
  apiLoading: boolean = false;
  updatedDatas: any;
  value: any;
  emailInput: any;
  updatedData: any;
  currentId: any;
  previews: any;
  previews1: any;
  preshow: boolean = false;
  preshow1: boolean = false;
  filevalue: any;
  pathurl: any;
  filevalue1: any;
  imagevalue: any;
  imagevalues: any;
  imagevalue1:any;
file2: any;
file1: any;
  headerImage: any;
  categoryImage: any;
  changeImg:boolean=false
  changeImg1:boolean=false

 







  constructor(private sanitizer: DomSanitizer,private _http:AppService, private loader: NgxUiLoaderService,  private router: Router, private toastr: ToastrService ){}
  ngOnInit(): void {

    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;

      this.roletype = this.userDetails.UserID;
    }

  this.getCatSub();
  this.fileUrl=apiurl
  // this.editedDatas()
  
  }


getCatSub(){
  this.loader.start();  

  let token = localStorage.getItem("token");
  this.headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  };

  this.apiLoading = true;

  this._http.getCategorySubject(this.name, this.limit, this.skip ,this.headers, "Rewardkart").subscribe((data:any) => {
    this.values = data.result;
    this.loader.stop();

    // console.log(this.values);
    
  },(error) => {
    this.toastr.error(error.error.Message);
    this.loader.stop();

  }
)}

cancel(){
  this.preshow1 = false;
  this.preshow = false;
  this.changeImg1 = false;
  this.changeImg = false;
}

statusChange(id: any, status: any) {
  let requestParams = {
    'id': id,
    'status': status
  }

  let token = localStorage.getItem("token");
  this.headers = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
     })
   };

  // this.loader.start();
  this._http.rewaardkartStatus(requestParams,this.headers).subscribe((data: any) => {
    //this.loader.stop();
    if (data.status == 1) {
      this.toastr.success("Status Updated Successfully");
      this.getCatSub();
    }
    else if (data.status ===0) {
      this.toastr.success("Something Went Wrong");
    }
    else {
      this.toastr.error(data.msg);
    }
  })
}

async loadData(ID : any){
    this.currentBody = this.values.find((p:any) => {
     return p.id == ID;
   
   });
     // console.log(this.currentBody.body);
     this.datas = this.currentBody.body;
     this.emailInput = this.currentBody.subject;
     this.currentId = this.currentBody.id;
     this.headerImage = this.currentBody.header_image;
     this.categoryImage = this.currentBody.category_image;
}

async edit(id:string){
  // this.currentid = id;
  // console.log(this.currentid);
  this.loader.start()
 await this.loadData(id)
this.loader.stop()
  // console.log(this.datas);
}


//Edited datas

editedDatas(){
  this.preshow1 = false;
  this.preshow = false;
  this.changeImg1 = false;
  this.changeImg = false;
  this.loader.start();

  let httpHeaders = new HttpHeaders();
  httpHeaders.set('Accept', "multipart/form-data");
  const formData: any = new FormData();
  
  

  formData.append('id',this.currentId);
  formData.append('body',this.datas);
  formData.append('subject',this.emailInput);
  formData.append('updated_by',this.roletype);
  formData.append('category_image',this.filevalue);
  formData.append('header_image',this.filevalue1);
  formData.append('category_image_path',this.categoryImage);
  formData.append('header_image_path',this.headerImage);
  formData.append('platform',"Rewardkart");

this.apiLoading = true;


let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    
    

    this._http.updateTemplateBody(formData ,this.headers).subscribe((data:any) =>{
      this.loader.stop();
      this.updatedData = data;
      this.getCatSub();
      console.log(this.updatedData,"edited datas");
      

      if (data.status == 1){
        this.loader.stop();
        this.toastr.success(data.msg)
      }else if(data.status == 0){
        this.loader.stop();
        this.toastr.error("Data updated failure")
      }

      // data = this.updatedDatas;
      },
      
      (error) => {
        this.toastr.error(error.error.Message)        
      }
    )
}

selectfile(event: any) {

  this.changeImg=true;

  this.filevalue = event.target.files[0];

  if (this.filevalue) {

    this.pathurl = apiurl;
    const blobUrl = URL.createObjectURL(this.filevalue);
    const safeblobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    this.previews = safeblobUrl;
    this.preshow = true;
    // this.toastr.success("Client Logo upload successfully");
    return;
  }
  else {
    this.preshow = false;
  }
}

openTabFile(){
  window.open(this.fileUrl+this.categoryImage, "_blank");
}

selectfile1(event: any) {

  this.changeImg1=true;
  this.filevalue1 = event.target.files[0];
  console.log("file1",this.filevalue1);

  if (this.filevalue1) {

    this.pathurl = apiurl;
    const blobUrl = URL.createObjectURL(this.filevalue1);
    const safeblobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    this.previews1 = safeblobUrl;
    this.preshow1 = true;
    // this.toastr.success("Client KYC upload successfully");
    return;
  }
  else {
    this.preshow1 = false;
  }
}




//Editor
config: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '15rem',
  minHeight: '5rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [
    ['bold'],['toggleEditorMode']
    ],
   sanitize: false,
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ]
};

}
