import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VKDashboardComponent } from './dashboard/dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { VoucherKartEditComponent } from './dashboard/voucher-kart-edit/voucher-kart-edit.component';
import { InternationalvoucherComponent } from "../internationalvoucher/internationalvoucher.component";
import { VKCreateOrderComponent } from './create-order/create-order.component';
import { SearchComponent } from './search/search.component';
import { ToastrModule } from 'ngx-toastr';
import { ClientDigitalVoucherComponent } from "./dashboard/client-digital-voucher/client-digital-voucher.component";

@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        ToastrModule.forRoot()
    ],
    declarations: [
        SearchComponent,
        VKCreateOrderComponent,
        VKDashboardComponent,
        VoucherKartEditComponent,
        InternationalvoucherComponent,
        ClientDigitalVoucherComponent
    ],
    exports: [],
    providers: []
})
export class VoucherKartModule {}