export class company{
    url: any = "";
    company_name:any;
    name:any;
    names:any;
    description:any;
    parent_category : any;
    created_by:any;
    created_date:any;
    modify_by : any;
    modify_date:any;
    parent_category_name:any; 
    category:any = '';
    id:any;
    contact_number:any;
    currency:any = "";

  
     

    entity:any;
    entity_name:any;
    brand_name:any;
    user_name:any;
    password:any;
    email:any;
    contact:any = "";
    wallet:any;
    actnumber:any;
    actholdername:any;
    bankname:any;
    ifsc:any;
    branch:any;
    file1: any;
    file2:any;
    confirmpassword:any;
    support_email:any = "";

    CompanyName:any;
    ContactEmail:any;
    ContactNumber:any;
    ContactPerson:any;
    CustomerID:any;
    DomainName:any;
    ImageName:any;
    ImagePath:any;
    Name:any;
    SupportEmail:any;
    Website:any;
    created_by_role:any;
    created_on:any;
    username:any;
    kyc_path: any;
    Wallet:any;
  wallet_threshold: any;
  currency_code: any;


    
    
}