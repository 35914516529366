<h6 class="font-18 font-weight-400 mt-4 mb-3 text-left product-heading title">
  Currency Markup
</h6>
<div class="card" >
  <div class="card-body">
    <div class="row mb-4">
      <div class="col-3">
        <label class="texts"> </label>
    </div>
      <div class="trees col-9">
        <div class="dropdown">
            <button class="btn btn-success mr-10 float-right" (click)="addNewCurrency()">Add New </button>
        </div>
        </div>
    </div>
    <div>
      <app-common-table [action]="'Edit'" [columns]="columns" [tableDatas]="currencyListdata" [limit]="limit" [d]="d" [count]="count" (EditValue)="editEvent($event)"
      (pagination)="page($event)" [skip]="skip" [lastvalue]="lastvalue" [primaryKeys]="primary" [index]="true"></app-common-table>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
</div>
<div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
  <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
          <div class="modal-body text-center">    
              <!-- <form #currencyForm="ngForm"> -->
              <div class="row">
              <!-- <p class="texts">{{Edit ? 'Update':'Add'}} Category</p> -->
              <div class="col-4">
                <div class="md-form form-sm my-3">
                  <label for="product" class="mb-2 text-start w-100">Product Currency </label>
                  <select class="form-select" placeholder="Select Currency" [(ngModel)]="currency"  
                           (change)="selectcurrency()">
                             
                           <option value="" selected hidden>Select Currency</option>
                            <option *ngFor="let option of currencyData" [value]="option.currency_code">{{ option.currency_code}}</option>            
                        </select>  
                </div>
              </div>
              <div class="col-4">
                <div class="md-form form-sm my-3">
                  <label for="payment" class="mb-2 text-start w-100">Payment Currency </label>
                  <select class="form-select" placeholder="Select Currency" [(ngModel)]="payment"  
                           (change)="selectpaycurrency()">
                             
                           <option value="" selected hidden>Select Currency</option>
                                <option *ngFor="let dolar of currencyPay" [value]="dolar.currency_code">{{ dolar.currency_code}}</option>            
                        </select>  
                </div>
              </div>
              <div class="col-4">
                <div class="md-form form-sm my-3">
                  <label for="markup" class="mb-2 text-start w-100">Markup</label>
                  <input
                    type="text" (keypress)="allowOnlyNumbers($event)"
                    name="markup"
                    class="form-control" placeholder="Enter Markup"
                    [(ngModel)]="markup" 
                    mdbInput
                    autocomplete="off" />
                </div>
              </div>
              <div class="col-4">
                <div class="md-form form-sm my-3">
                  <label for="markup" class="mb-2 text-start w-100">Percentage(%)</label>
                  <input
                    type="text" (keypress)="allowOnlyNumbers($event)"
                    name="markup"
                    class="form-control"
                    [(ngModel)]="percentage" 
                    mdbInput
                    autocomplete="off" />
                </div>
              </div>
              <!-- <div class="col-4">
                <div class="md-form form-sm my-3">
                  <label for="source" class="mb-2 text-start w-100">Source</label>
                  <select name="source" id="source" placeholder="Select Source"  class="form-select" [(ngModel)]="source">
                    <option value="" selected>Select Source</option>
                    <option value="DT">DT ONE</option>
                  </select>
                </div>
              </div> -->
              <div class="col-12 text-end">
                <div class="md-form form-sm my-2">
                    <button class="btn btn-success me-3"data-bs-dismiss="modal" #closeCategoryBtn (click)="clear()">
                      Clear
                    </button>
                  <button class="btn btn-success" *ngIf="!update" (click)="addTextBox()">
                    Add
                  </button>
                  <button class="btn btn-success" *ngIf="update" (click)="editUpdate()">
                    Update
                  </button>
                </div>  
              </div>
              <!-- <div class="col-2">
                <div class="md-form form-sm my-5">
                  <button class="btn btn-success"data-bs-dismiss="modal" #closeCategoryBtn (click)="clear()">
                    Clear
                  </button>
                </div>  
              </div> -->
            </div>
          <!-- </form> -->
          </div>
      </div>
  </div>
</div>
