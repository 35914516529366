import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './templates.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';




@NgModule({
  declarations: [TemplatesComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    NgxMyDatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule,HttpClientModule
    ,AngularEditorModule

  ]
})
export class TemplatesModule { }
