import { DatePipe } from "@angular/common";
import { Component, ElementRef, NgZone } from "@angular/core";
import { Router } from "@angular/router";

import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

import { environmentfiles } from "src/environments/environment";
import { IMyDateModel, INgxMyDpOptions } from "@mksasi/ngx-mydatepicker";
import { HttpHeaders } from "@angular/common/http";
import * as bootstrap from "bootstrap";
import { Modal } from "bootstrap";
import { RequestVoucher } from "src/app/models/RequestVoucher";
import { AppService } from "src/app/restful-services/restful.service";

@Component({
  selector: "app-client-digital-voucher",
  templateUrl: "./client-digital-voucher.component.html",
  styleUrls: ["./client-digital-voucher.component.scss"],
})
export class ClientDigitalVoucherComponent {
  public string: "[restrictChars]" | undefined;

  public startOptions: INgxMyDpOptions = {};
  public issuanceOptions: INgxMyDpOptions = {};
  public ExpiryOptions: INgxMyDpOptions = {};

  d: Date = new Date();
  optionsStatus = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  categoryOption = [
    { label: "Online", value: 0 },
    { label: "Offline", value: 1 },
  ];
  source: any = "";
  type: any;
  modal: any;
  brandName: any = "";
  category: any;
  denomination: any;
  payment_status: any = "";
  voucher_delivery_type: any = "";
  quantity: any;
  validity: any;
  vouchergeneratedStatus: any;
  company: any;
  requestCouponVoucher: RequestVoucher;
  isEdit: boolean = false;
  userDetails: any;
  voucherId: any;
  supplierType: any;
  companyKeyword = "CompanyName";
  brandKeyword = "BrandName";
  productKeyword = "ProductName";
  companyData: any = [];
  brandData: any = [];
  QCedit: any = [];
  Amazonedit: any = [];
  sourceName: any = "";
  brandDisabled: boolean = false;
  noBrandFound: boolean = false;
  noCompanyFound: boolean = false;
  noSourceFound: boolean = false;
  endDate: any;
  issuanceDate: any;
  getstartDateOptions: any;
  public role: any;
  isValidUrl: boolean = true;
  brandId: any;
  start_date: any;

  getIssuanceDate: any;
  getExpiryDate: any;

  startDate: any;
  ExpiryDate: any;
  IssuanceDate: any;

  public getstartDate: any;
  public getendDate: any;
  public getissuanceDate: any;

  send_date = new Date();
  formattedDate: any;

  total: any;
  comname: any;
  discount: any;
  payment_received: any;
  issuance: any;
  start: any;
  kart_type: any = "";
  issuance_fee: any;
  voucher_delivery_email: any;

  private headers: any = {};

  comitem: any = {};
  minparam: any = {};

  authentication_type: any = "";
  voucher_redemption_type: any = "";
  otpshow: boolean = true;
  otpDisabled: boolean = false;

  uniquesource = [];
  uniquebrands = [];

  disvalue: any;
  distype: any;
  pay: any;
  issfee: any;
  fetchDisabled: boolean = false;

  minmaxshow: boolean = false;
  isDatepickerDisabled: boolean = false;
  expiryvalueint: any;
  expiryvalue: any;
  expiryvalues: any;
  maxprice: any;
  productid: any;
  minprice: any;
  NewArray: any;
  externalproductid: any;

  totalval: boolean = false;
  voucher_redemption_url: any;

  sourceData: any = [];
  sourcesName: any = "";
  SourceName: any = "";
  sourceKeyword = "SourceName";
  fetchdata: any = [];
  denomDisabled: boolean = false;
  hidebtns: boolean = true;
  hideauthfield: boolean = true;
  usagehide: boolean = false;
  productData: any = [];
  branid: any;
  brandedid: any;
  BrandName: any;

  brandidvalue: any;
  editvoucherId: any;

  totalshow: boolean = false;
  totalfacevalueshow: boolean = false;
  discountvalue: any;
  maxdiscountshow: boolean = false;
  discounttypehide: boolean = true;

  productshow: boolean = true;
  sourceid: any;

  editbrandId: any;
  brandlabelhide: boolean = false;
  brandlabelshow: boolean = true;

  clientchange: boolean = false;
  brandchange: boolean = false;
  productchange: boolean = false;

  editbrandidval: any;
  tooltipList: any;
  postParams: any;
  inputFile: any;
  fileurls: any;
  response: any;
  voucherDetails: any;

  constructor(
    private router: Router,
    private _http: AppService,
    public datePipe: DatePipe,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private el: ElementRef,
    private ngZone: NgZone
  ) {
    this.requestCouponVoucher = new RequestVoucher();
    this.companyData.forEach((CompanyName: any) => {
      if (!this.uniquesource.includes(CompanyName as never)) {
        this.uniquesource.push(CompanyName as never);
      }
    });

    this.brandData.forEach((BrandName: any) => {
      if (!this.uniquebrands.includes(BrandName as never)) {
        this.uniquebrands.push(BrandName as never);
      }
    });

    let tdate = new Date();
    this.getIssuanceDate = {
      date: {
        year: tdate.getFullYear(),
        month: tdate.getMonth() + 1,
        day: tdate.getDate(),
      },
    };

    let sdate = new Date();
    this.getstartDate = {
      date: {
        year: sdate.getFullYear(),
        month: sdate.getMonth() + 1,
        day: sdate.getDate(),
      },
    };

    this.startDate = new Date();
    this.IssuanceDate = new Date();

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.issuanceOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.ExpiryOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };
  }

  ngOnInit(): void {
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }
    this.role = localStorage.getItem("role");
    console.log(this.role);
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;
    if (this.userDetails.entity_name) {
      this.requestCouponVoucher.company_name = this.userDetails.entity_name;
      this.requestCouponVoucher.companyId = this.userDetails.UserID;
    } else {
      this.requestCouponVoucher.company_name = this.userDetails.UserName;
    }
    this.requestCouponVoucher.type = "ID";
    this.clientchange = true;

    const { id } = history.state;
    const { validity } = history.state;

    if (validity) {
      this.voucherDetails = validity;
    }
    if (id) {
      this.isEdit = true;
      this.voucherId = id;
    } else {
      let data = localStorage.getItem("edit");
      this.isEdit = false;
      if (data) {
        this.requestCouponVoucher = JSON.parse(data);

        this.startDate = this.requestCouponVoucher.start_date;
        this.issuanceDate = this.requestCouponVoucher.issuance_date;

        const issuanceDate = new Date(this.requestCouponVoucher.issuance_date);
        this.getissuanceDate = {
          date: {
            year: issuanceDate.getFullYear(),
            month: issuanceDate.getMonth() + 1,
            day: issuanceDate.getDate(),
          },
        };

        const startDate = new Date(this.requestCouponVoucher.start_date);
        this.getstartDate = {
          date: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
          },
        };
      }
    }
    if (this.role === "Company") {
      this.requestCouponVoucher.type = "ID";
      this.clientchange = true;
    }
    if (this.isEdit) {
      this._http.voucherDetailItem.subscribe({
        next: (data) => {
          console.log("datadatadatadata", data);
          if (data) {
            this.requestCouponVoucher = data;
            localStorage.setItem("edit", JSON.stringify(data));
            if (
              this.requestCouponVoucher.usage_mode &&
              this.requestCouponVoucher.source === "Ticket Express"
            ) {
              this.usagehide = true;
              this.requestCouponVoucher.usagemode =
                this.requestCouponVoucher.usage_mode;
            } else if (
              this.requestCouponVoucher.internal_usage_mode &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.usagehide = true;
              this.requestCouponVoucher.usagemode =
                this.requestCouponVoucher.internal_usage_mode;
            } else {
              this.denomDisabled = false;
              this.usagehide = false;
              this.requestCouponVoucher.usagemode = "";
            }
            if (this.requestCouponVoucher.source === "Ticket Express") {
              this.denomDisabled = true;
            } else {
              this.denomDisabled = false;
            }
            if (this.requestCouponVoucher.source === "voucherkart") {
              this.totalshow = true;
              this.totalfacevalueshow = true;
              this.calculate();
            } else {
              this.totalshow = false;
              this.totalfacevalueshow = false;
            }
            if (this.requestCouponVoucher.source === "MEA") {
              this.requestCouponVoucher.validity = this.voucherDetails;
            }
            if (
              (this.requestCouponVoucher.product_discount &&
                this.requestCouponVoucher.source === "Ticket Express") ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "MEA"
            ) {
              this.discountvalue = this.requestCouponVoucher.product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            } else if (
              this.requestCouponVoucher.internal_product_discount &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.discountvalue =
                this.requestCouponVoucher.internal_product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            } else {
              this.discountvalue = "";
              this.maxdiscountshow = false;
              this.discounttypehide = true;
            }
            if (
              (this.requestCouponVoucher.minPrice &&
                this.requestCouponVoucher.maxPrice &&
                this.requestCouponVoucher.source === "QwikCilver") ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "FlipKart"
            ) {
              this.minprice = this.requestCouponVoucher.minPrice;
              this.maxprice = this.requestCouponVoucher.maxPrice;
              this.minmaxshow = true;
            } else if (
              this.requestCouponVoucher.minface_value &&
              this.requestCouponVoucher.maxface_value &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.minmaxshow = true;
              this.minprice = this.requestCouponVoucher.minface_value;
              this.maxprice = this.requestCouponVoucher.maxface_value;
            } else {
              this.minprice = "";
              this.maxprice = "";
              this.minmaxshow = false;
            }
            if (
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "MEA"
            ) {
              this.hideauthfield = false;
            } else {
              this.hideauthfield = true;
            }
            if (
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "voucherkart" ||
              this.requestCouponVoucher.source === "FlipKart"
            ) {
              this.isDatepickerDisabled = true;
            } else {
              this.isDatepickerDisabled = false;
            }
            if (
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "voucherkart" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "FlipKart"
            ) {
              this.requestCouponVoucher.kart_type = "0";
            } else {
              this.requestCouponVoucher.kart_type =
                this.requestCouponVoucher.kart_type;
            }
            this.startDate = this.requestCouponVoucher.start_date;
            this.IssuanceDate = this.requestCouponVoucher.issuance_date;
            this.ExpiryDate = this.requestCouponVoucher.validity;
            const i = new Date(this.requestCouponVoucher.issuance_date);
            this.getIssuanceDate = {
              date: {
                year: i.getFullYear(),
                month: i.getMonth() + 1,
                day: i.getDate(),
              },
            };
            const s = new Date(this.requestCouponVoucher.start_date);
            this.getstartDate = {
              date: {
                year: s.getFullYear(),
                month: s.getMonth() + 1,
                day: s.getDate(),
              },
            };
            const d = new Date(this.requestCouponVoucher.validity);
            this.getExpiryDate = {
              date: {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate(),
              },
            };
            if (this.role === "admin" || this.role === "SuperAdmin") {
              this.brandidvalue = this.requestCouponVoucher.brandId;
            }
          }
        },
      });
    } else {
      let sourceval: any = localStorage.getItem("edit");
      let val = JSON.parse(sourceval);
      if (val != null) {
        let sourcevalue = val.source;
        this.editvoucherId = val.voucher_request_id;
        let minvalue = val.minPrice;
        let maxvalue = val.maxPrice;
        let maxdiscount = val.product_discount;
        let internalminvalue = val.minface_value;
        let internalmaxvalue = val.maxface_value;
        let internalmaxdiscount = val.internal_product_discount;
        let kartval = val.kart_type;
        let typevalue = val.type;
        if (sourcevalue) {
          this.isEdit = true;
          this.requestCouponVoucher.voucher_request_id = val.voucher_request_id;
        } else {
          this.isEdit = false;
          this.requestCouponVoucher.voucher_request_id = val.voucher_request_id;
        }
        if (
          sourcevalue === "QwikCilver" ||
          sourcevalue === "Ticket Express" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart"
        ) {
          this.hideauthfield = false;
        } else {
          this.hideauthfield = true;
        }
        if (sourcevalue === "Ticket Express") {
          this.usagehide = true;
          this.requestCouponVoucher.usagemode = val.usage_mode;
        } else if (sourcevalue === "voucherkart") {
          this.usagehide = true;
          this.requestCouponVoucher.usagemode = val.internal_usage_mode;
        } else {
          this.usagehide = false;
          this.requestCouponVoucher.usagemode = "";
        }
        if (sourcevalue === "voucherkart") {
          this.totalshow = true;
          this.totalfacevalueshow = true;
          this.calculate();
        } else {
          this.totalshow = false;
          this.totalfacevalueshow = false;
        }
        if (
          sourcevalue === "QwikCilver" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "Ticket Express" ||
          sourcevalue === "voucherkart"
        ) {
          this.isDatepickerDisabled = true;
        } else {
          this.isDatepickerDisabled = false;
        }
        if (sourcevalue === "Ticket Express") {
          this.denomDisabled = true;
        } else {
          this.denomDisabled = false;
        }
        if (
          (minvalue && maxvalue && sourcevalue === "QwikCilver") ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart" ||
          sourcevalue !== "voucherkart"
        ) {
          this.minprice = val.minPrice;
          this.maxprice = val.maxPrice;
          this.minmaxshow = true;
        } else if (
          internalminvalue &&
          internalmaxvalue &&
          sourcevalue === "voucherkart"
        ) {
          this.minprice = val.minface_value;
          this.maxprice = val.maxface_value;
          this.minmaxshow = true;
        } else {
          this.minprice = "";
          this.maxprice = "";
          this.minmaxshow = false;
        }
        if (
          sourcevalue === "Ticket Express" ||
          sourcevalue === "voucherkart" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "QwikCilver" ||
          sourcevalue === "FlipKart"
        ) {
          this.requestCouponVoucher.kart_type = "0";
        } else {
          this.requestCouponVoucher.kart_type = val.kart_type;
        }
        if (
          (sourcevalue === "QwikCilver" ||
            sourcevalue === "Amazon" ||
            sourcevalue === "FlipKart") &&
          this.role !== "Company"
        ) {
          console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
          this.brandlabelhide = true;
          this.brandlabelshow = false;
        } else {
          this.brandlabelhide = false;
          this.brandlabelshow = true;
        }
        if (
          (maxdiscount && sourcevalue === "Ticket Express") ||
          sourcevalue === "Amazon" ||
          sourcevalue === "QwikCilver" ||
          sourcevalue === "FlipKart"
        ) {
          this.discountvalue = val.product_discount;
          this.maxdiscountshow = true;
          this.discounttypehide = false;
        } else if (internalmaxdiscount && sourcevalue === "voucherkart") {
          this.discountvalue = val.internal_product_discount;
          this.maxdiscountshow = true;
          this.discounttypehide = false;
        } else {
          this.discountvalue = "";
          this.maxdiscountshow = false;
          this.discounttypehide = true;
        }
      }
    }
  }
  cancelVoucherList() {
    this.router.navigate(["/digital-dashboard"], { replaceUrl: false });
  }
  validateUrl() {
    console.log(this.requestCouponVoucher.voucher_redemption_url);
    const pattern =
      /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/g;
    this.isValidUrl = pattern.test(
      this.requestCouponVoucher.voucher_redemption_url
    );
  }

  keyDownHandler(event: any) {
    if (event.code === "Space" && event.which === 32) {
      event.preventDefault();
    }
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes(".")) {
      event.target.value = inputValue.substring(0, inputValue.indexOf("."));
    }
  }

  // setDateOptions() {
  //   let a = new Date(this.requestCouponVoucher.issuance_date);
  //   a.setDate(a.getDate() - 1);
  //   a = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  //   this.startOptions = {
  //     dateFormat: 'dd-mm-yyyy',
  //     alignSelectorRight: true,
  //     openSelectorTopOfInput: false,
  //     disableUntil: { year: a.getFullYear(), month: a.getMonth() + 1, day: a.getDate() },
  //   };
  //   let b = new Date(this.requestCouponVoucher.start_date);
  //   b.setDate(b.getDate());
  //   b = new Date(b.getFullYear(), b.getMonth(), b.getDate());
  //   this.ExpiryOptions = {
  //     dateFormat: 'dd-mm-yyyy',
  //     alignSelectorRight: true,
  //     openSelectorTopOfInput: false,
  //     disableUntil: { year: b.getFullYear(), month: b.getMonth() + 1, day: b.getDate() },
  //   };
  // }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1))
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
    this.ExpiryOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: dp.getFullYear(),
        month: dp.getMonth() + 1,
        day: dp.getDate(),
      },
    };
    this.ExpiryDate = "";
    this.getExpiryDate = "";
    console.log("start date: " + this.startDate);
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1))
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    this.ExpiryDate = this.endDate;
  }

  onIssuanceDateChanged(event: IMyDateModel): void {
    this.IssuanceDate = new Date(
      event.jsdate.setDate(event.jsdate.getDate() + 1)
    )
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    let dp = new Date(this.IssuanceDate);
    console.log("dp date" + dp);

    dp.setDate(dp.getDate() - 1);
    dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate());
    console.log("date " + dp);
    console.log("year " + dp.getFullYear());
    console.log("Month " + dp.getMonth());
    console.log("da " + dp.getDate());
    this.startOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: dp.getFullYear(),
        month: dp.getMonth() + 1,
        day: dp.getDate(),
      },
    };
    this.startDate = "";
    this.getstartDate = "";
    console.log("this.IssuanceDate: " + this.IssuanceDate);
  }

  selectModelEvent(value: any) {
    if (value === "I") {
      this.otpshow = false;
      this.otpDisabled = true;
      this.requestCouponVoucher.authentication_type = "PIN";
      this.hidebtns = false;
    } else {
      this.requestCouponVoucher.authentication_type = "";
      this.otpDisabled = false;
      this.otpshow = true;
      this.hidebtns = true;
    }
  }

  validityClear(event: any) {
    this.brandchange = false;
    this.productData = [];
    this.requestCouponVoucher.product = "";
    this.branid = "";
    this.brandidvalue = "";
    this.requestCouponVoucher.usagemode = "";
    this.requestCouponVoucher.validity = "";
    this.brandName = "";
  }

  productClear(event: any) {
    let source = this.requestCouponVoucher.source;
    this.clientchange = false;
    if (
      (source != "voucherkart" && this.role === "admin") ||
      this.role === "SuperAdmin"
    ) {
      this.requestCouponVoucher.discount = "";
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.requestCouponVoucher.payment_status = "";
      this.requestCouponVoucher.voucher_delivery_type = "";
      this.requestCouponVoucher.total = "";
      this.fetchDisabled = false;
    }
  }
  changeemail() {
    this.requestCouponVoucher.voucher_delivery_email = "";
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = '0123456789.'; // Define the allowed characters
    const inputChar = event.key;
  
    if (inputChar === ' ' || (inputChar !== '.' && allowedCharacters.indexOf(inputChar) === -1)) {
      
      
    
      event.preventDefault(); // Prevent the input
    }
  }
  onChangeCompany2(item: any) {
    this.minmaxshow = false;
    this.requestCouponVoucher.discount = "";
    this.requestCouponVoucher.issuance_fee = "";
    this.requestCouponVoucher.voucher_delivery_email = "";
    this.requestCouponVoucher.payment_status = "";
    this.requestCouponVoucher.voucher_delivery_type = "";
    this.requestCouponVoucher.total = "";
    this.fetchDisabled = false;
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this._http.getCompany(item, this.headers).subscribe(
      (data: any) => {
        this.companyData = data.data;
        if (this.companyData.length === 0) {
          this.noCompanyFound = true;
        } else {
          this.noCompanyFound = false;
        }
        if (this.companyData.length > 0) {
          for (var data of this.companyData) {
            if (data.CompanyName === item.CompanyName) {
              this.noCompanyFound = false;
              return;
            } else {
              this.noCompanyFound = true;
            }
          }
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  onChangeCompany(item: any) {
    let source: any = this.requestCouponVoucher.source;
    if (this.isEdit) {
      this.clientchange = true;
    }
    if (
      (source =
        (!"voucherkart" && this.role === "admin") || this.role === "SuperAdmin")
    ) {
      this.requestCouponVoucher.discount = "";
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.requestCouponVoucher.payment_status = "";
      this.requestCouponVoucher.voucher_delivery_type = "";
      this.requestCouponVoucher.total = "";
      this.fetchDisabled = false;
    }
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this._http.getCompany(item, this.headers).subscribe(
      (data: any) => {
        this.companyData = data.data;
        if (this.companyData.length === 0) {
          this.noCompanyFound = true;
        } else {
          this.noCompanyFound = false;
        }
        if (this.companyData.length > 0) {
          for (var data of this.companyData) {
            if (data.CompanyName === item.CompanyName) {
              this.noCompanyFound = false;
              return;
            } else {
              this.noCompanyFound = true;
            }
          }
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  onChangeSource() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    let item = "";
    this._http.getSource(item, this.headers).subscribe(
      (data: any) => {
        this.sourceData = data.data;
      },
      (error) => {
        this.loader.stop();
      }
    );
  }

  onChangeBrand(item: any) {
    this.productData = [];
    this.requestCouponVoucher.product = "";
    // this.requestCouponVoucher.issuance_fee = '';
    this.branid = "";
    this.brandidvalue = "";
    this.requestCouponVoucher.usagemode = "";
    this.requestCouponVoucher.validity = "";
    this.brandName = "";
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this._http
      .getClientproducts(item, this.headers, this.userDetails.UserID)
      .subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          if (this.brandData) {
            this.productid = this.brandData[1];
            this.expiryvalue = this.brandData[4];
          } else {
            this.productid = "";
            this.expiryvalue = "";
          }
          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }
          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
  }

  restrictEnter(args: any) {
    if (args.key === "Enter" && args.keyCode == 13) args.preventDefault();
  }

  selectSourceEvent(item: any) {
    this.requestCouponVoucher.usagemode = "";
    this.maxdiscountshow = false;
    this.brandName = "";
    this.requestCouponVoucher.discount = "";
    this.requestCouponVoucher.issuance_fee = "";
    this.requestCouponVoucher.voucher_delivery_email = "";
    this.requestCouponVoucher.payment_status = "";
    this.requestCouponVoucher.voucher_delivery_type = "";
    this.requestCouponVoucher.total = "";
    this.requestCouponVoucher.totalfacevalue = "";
    this.requestCouponVoucher.brand_name = "";
    this.requestCouponVoucher.validity = "";
    this.requestCouponVoucher.quantity = "";
    this.requestCouponVoucher.denomination = "";
    this.denomDisabled = false;
    this.brandData = [];

    if (item != "voucherkart") {
      this.requestCouponVoucher.company_name = "";
    }
    if (item === "voucherkart") {
      this.totalshow = true;
      this.totalfacevalueshow = true;
    } else {
      this.totalshow = false;
      this.totalfacevalueshow = false;
      this.discounttypehide = true;
    }
    this.requestCouponVoucher.kart_type = "0";
    if (item === "Ticket Express") {
      this.usagehide = true;
      this.discounttypehide = false;
    } else if (item === "voucherkart") {
      this.discounttypehide = false;
      this.usagehide = true;
    } else if (
      item === "Amazon" ||
      item === "FlipKart" ||
      item === "QwikCilver"
    ) {
      this.discounttypehide = false;
      this.usagehide = false;
    } else {
      this.usagehide = false;
      this.discounttypehide = true;
    }
    if (
      item === "QwikCilver" ||
      item === "Ticket Express" ||
      item === "Amazon" ||
      item === "FlipKart"
    ) {
      this.expiryvalues = this.expiryvalue;
      this.hideauthfield = false;
    } else {
      this.hideauthfield = true;
    }
    if (item) {
      this.isDatepickerDisabled = true;
    } else {
      this.isDatepickerDisabled = false;
    }
    if (item === "QwikCilver" || item === "Amazon" || item === "FlipKart") {
      this.brandlabelshow = false;
    } else {
      this.brandlabelshow = true;
    }
    if (item) {
      this.calculate();
    }
  }

  // onChangeProduct(item: any) {
  //   if (this.role === 'Company') {
  //     this.requestCouponVoucher.issuance_fee = '';
  //     this.requestCouponVoucher.voucher_delivery_email = '';
  //     this.minprice = '';
  //     this.maxprice = '';
  //     this.discountvalue = ''
  //     this.denomDisabled = false;
  //     this.maxdiscountshow = false;
  //     this.minmaxshow = false;
  //     this.usagehide = false;
  //   }
  //   let source: any = this.requestCouponVoucher.source;
  //   // if (this.role === 'admin' || this.role === 'SuperAdmin' && source === 'Ticket Express') {
  //   //   this.requestCouponVoucher.validity = '';
  //   //   this.requestCouponVoucher.denomination = '';
  //   //   this.requestCouponVoucher.usagemode = '';
  //   //   this.denomDisabled = false;
  //   // }
  //   if (source === 'Ticket Express' && (this.branid || this.brandidvalue)) {
  //     let token = localStorage.getItem("token");
  //     this.headers = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token
  //       })
  //     };
  //     let brandid = this.branid || this.brandidvalue;
  //     const type = 'TE';
  //     this._http.getTEproducts(item, type, brandid, this.headers).subscribe((data: any) => {
  //       this.productData = data.data;
  //       if (data) {
  //         data.data.forEach((e: any) => {
  //           this.productData.push(e.name);
  //         })
  //       }
  //     },
  //       error => {
  //         this.loader.stop();
  //         this.toastr.error(error.error.Message);
  //       })
  //   }
  //   else if (source === 'voucherkart') {
  //     let token = localStorage.getItem("token");
  //     this.headers = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token
  //       })
  //     };
  //     let brandid = this.branid || this.brandidvalue || this.userDetails.UserID;
  //     let variant = 'Digital';
  //     this._http.getvoucherproducts(item, brandid, variant, this.headers).subscribe((data: any) => {
  //       this.productData = data.data;
  //       if (this.productData !== null && this.productData.length > 0) {
  //         this.requestCouponVoucher.issuance_fee = this.productData[0].issuance_fee;
  //         this.requestCouponVoucher.voucher_delivery_email = this.productData[0].voucher_delivery_email;
  //         this.requestCouponVoucher.usage_mode = this.productData[0].internal_usage_mode;
  //         this.requestCouponVoucher.validity = this.productData[0].validity;
  //         this.minprice = this.productData[0].minface_value;
  //         this.maxprice = this.productData[0].maxface_value;
  //         this.discountvalue = this.productData[0].internal_product_discount;
  //         this.maxdiscountshow = true;
  //         this.minmaxshow = true;
  //         this.usagehide = true;
  //         this.isDatepickerDisabled = true;
  //         this.calculate();
  //       }
  //       else {
  //         this.requestCouponVoucher.issuance_fee = '';
  //         this.requestCouponVoucher.voucher_delivery_email = '';
  //         this.minprice = '';
  //         this.maxprice = '';
  //         this.discountvalue = '';
  //         this.maxdiscountshow = false;
  //         this.minmaxshow = false;
  //         this.usagehide = false;
  //         this.isDatepickerDisabled = false;
  //       }
  //       if (data) {
  //         data.data.forEach((e: any) => {
  //           this.productData.push(e.name);
  //         })
  //       }
  //     },
  //       error => {
  //         this.loader.stop();
  //         this.toastr.error(error.error.Message);
  //       })
  //   }
  //   else if (this.role === 'Company') {
  //     this._http.getClientproducts(item, this.headers).subscribe((data: any) => {
  //       if (data) {
  //         data.data.forEach((e: any) => {
  //           this.productData.push(e.name);
  //         })
  //       }
  //     })
  //   }
  // }

  selectCompanyEvent(item: any) {
    this.clientchange = true;
    if (item) {
      this.calculate();
    }
    this.requestCouponVoucher.kart_type = "0";
    this.requestCouponVoucher.companyId = item.id;
    this.sourceName = item.CompanyName;
    if (this.companyData.length > 0) {
      for (var data of this.companyData) {
        if (data.CompanyName === item.CompanyName) {
          this.noCompanyFound = false;
          if (this.role === "admin" || this.role === "SuperAdmin") {
            this.getfetchlist(item);
          }
          return;
        } else {
          this.noCompanyFound = true;
        }
      }
    }
  }
  getfetchlist(item: any) {
    let sourceval = this.requestCouponVoucher.source;
    if (item && sourceval != "voucherkart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      this._http.getfetchlist(item, this.headers).subscribe(
        (data: any) => {
          this.fetchdata = data.data;
          console.log(this.fetchdata[0]);

          if (
            this.fetchdata !== null &&
            this.fetchdata.length > 0 &&
            this.requestCouponVoucher.company_name
          ) {
            this.requestCouponVoucher.discount = this.fetchdata[0].discount;
            this.requestCouponVoucher.issuance_fee =
              this.fetchdata[0].issuance_fee;
            console.log("ttttt", this.fetchdata[0].issuance_fee);
            this.requestCouponVoucher.voucher_delivery_email =
              this.fetchdata[0].voucher_delivery_email;
            this.requestCouponVoucher.payment_status =
              this.fetchdata[0].payment;
            this.requestCouponVoucher.voucher_delivery_type =
              this.fetchdata[0].voucher_delivery_type;
            let sourceval = this.requestCouponVoucher.source;
            if (sourceval !== "Ticket Express") {
              this.requestCouponVoucher.kart_type =
                this.fetchdata[0].discount_type;
            }
            this.calculate();
            this.selectdistypeEvent(item, true);
          } else {
            this.requestCouponVoucher.kart_type = "0";
            // this.requestCouponVoucher.discount = '';
            this.requestCouponVoucher.issuance_fee = "";
            this.requestCouponVoucher.voucher_delivery_email = "";
            this.requestCouponVoucher.payment_status = "";
            this.requestCouponVoucher.voucher_delivery_type = "";
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
  }

  uploadEmailFile(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (getImg.toLowerCase() !== ".csv") {
      this.toastr.error("File not supported");
      return;
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("deliveryfile", event.target.files[0]);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importbulkemail(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.requestCouponVoucher.voucher_delivery_email = data.data;
            this.toastr.success(data.msg);
          } else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  selectBrandEvent(item: any) {
    console.log("item", item);
    this.brandchange = true;
    this.branid = item.BrandId;

    this.requestCouponVoucher.source = item.source_full;
    this.requestCouponVoucher.source_id = item.supplier_id;
    let sourceval: any = item.source_full;
    if (
      sourceval === "QwikCilver" ||
      sourceval === "Amazon" ||
      sourceval === "FlipKart" ||
      sourceval === "MEA"
    ) {
      this.requestCouponVoucher.brand_name = item.name;
      this.requestCouponVoucher.brandId = item.id;
      this.requestCouponVoucher.product = item.name;
      this.requestCouponVoucher.product_id = item.id;
      this.usagehide = false;
    } else if (sourceval === "Ticket Express" || sourceval === "voucherkart") {
      this.requestCouponVoucher.brand_name = item.brand_name;
      this.requestCouponVoucher.brandId = item.brandId;
      this.requestCouponVoucher.product = item.name;
      this.requestCouponVoucher.product_id = item.id;
      this.usagehide = true;
      this.requestCouponVoucher.issuance_fee = item.issuance_fee
        ? item.issuance_fee
        : "0";
    }
    this.requestCouponVoucher.usagemode = item.usage_mode;
    this.requestCouponVoucher.issuance_fee = item.issuance_fee
      ? item.issuance_fee
      : "0";
    this.requestCouponVoucher.voucher_delivery_email =
      item.voucher_delivery_email;
    this.requestCouponVoucher.kart_type = "0";
    this.minprice = item.minface_value;
    this.maxprice = item.maxface_value;
    this.discountvalue = item.discount;
    this.requestCouponVoucher.discount = item.discount;
    this.maxdiscountshow = false;
    this.minmaxshow = true;

    this.calculate();
    if (
      sourceval === "QwikCilver" ||
      sourceval === "Ticket Express" ||
      sourceval === "Amazon" ||
      sourceval === "FlipKart" ||
      sourceval === "MEA"
    ) {
      this.hideauthfield = false;
      this.requestCouponVoucher.validity = item.validity;
      this.isDatepickerDisabled = true;
    } else if (sourceval == "voucherkart") {
      this.requestCouponVoucher.validity = item.validity;
      this.isDatepickerDisabled = true;
      this.hideauthfield = true;
    } else {
      this.requestCouponVoucher.validity = item.expiry;
    }
    this.requestCouponVoucher.denomination = item.face_value
      ? item.face_value
      : "";
    if (this.requestCouponVoucher.denomination) {
      this.denomDisabled = true;
      this.calculate();
    } else {
      this.denomDisabled = false;
    }
    this.brandName = item.BrandName;
    if (this.brandData.length > 0) {
      for (var data of this.brandData) {
        if (data.BrandName === item.BrandName) {
          this.noBrandFound = false;
          return;
        } else {
          this.noBrandFound = true;
        }
      }
    }
  }
  requestVoucher(requestCouponVoucher: RequestVoucher) {
    let source: any = this.requestCouponVoucher.source;
    console.log(this);
    let brandval = this.brandData;
    if (this.brandchange) {
      if (brandval.length === 0) {
        this.toastr.error("Please enter valid Product");
        return;
      }
    }
    this.requestCouponVoucher.start_date = this.startDate;
    this.requestCouponVoucher.issuance_date = this.IssuanceDate;
    this.requestCouponVoucher.user_name = this.userDetails.Name;
    this.requestCouponVoucher.denomination =
      this.requestCouponVoucher.denomination;
    this.requestCouponVoucher.payment_status =
      this.requestCouponVoucher.payment_status;
    this.requestCouponVoucher.voucher_delivery_type =
      this.requestCouponVoucher.voucher_delivery_type;
    this.requestCouponVoucher.total = this.requestCouponVoucher.total;
    this.requestCouponVoucher.discount = this.requestCouponVoucher.discount;
    this.requestCouponVoucher.payment_received =
      this.requestCouponVoucher.payment_received;
    this.requestCouponVoucher.category = this.requestCouponVoucher.category;
    this.requestCouponVoucher.source = this.requestCouponVoucher.source;
    if (this.brandName !== null && this.brandName !== "") {
      this.requestCouponVoucher.brand_name = this.brandName;
    }
    if (this.sourceName !== null && this.sourceName !== "") {
      this.requestCouponVoucher.company_name = this.sourceName;
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "MEA"
    ) {
      if (
        this.requestCouponVoucher.authentication_type === "" ||
        this.requestCouponVoucher.authentication_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "MEA"
    ) {
      if (
        this.requestCouponVoucher.voucher_redemption_type === "" ||
        this.requestCouponVoucher.voucher_redemption_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "MEA"
    ) {
      if (
        this.requestCouponVoucher.voucher_delivery_type === "" ||
        this.requestCouponVoucher.voucher_delivery_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "MEA"
    ) {
      if (
        this.requestCouponVoucher.voucher_delivery_email === "" ||
        this.requestCouponVoucher.voucher_delivery_email === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "MEA"
    ) {
      if (
        this.requestCouponVoucher.voucher_redemption_url === "" ||
        this.requestCouponVoucher.voucher_redemption_url === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
      if (
        !this.requestCouponVoucher.voucher_redemption_url == this.isValidUrl
      ) {
        this.toastr.error("Please enter the valid URL");
        return;
      }
    } else if (source !== "Ticket Express") {
      if (
        this.requestCouponVoucher.kart_type === "" ||
        this.requestCouponVoucher.kart_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    } else if (source !== "voucherkart") {
      if (
        this.requestCouponVoucher.kart_type === "" ||
        this.requestCouponVoucher.kart_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      this.requestCouponVoucher.payment_status === "" ||
      this.requestCouponVoucher.voucher_delivery_type === "" ||
      this.requestCouponVoucher.voucher_delivery_type === null ||
      this.requestCouponVoucher.payment_status === null ||
      this.requestCouponVoucher.discount === "" ||
      this.requestCouponVoucher.discount === null ||
      this.requestCouponVoucher.issuance_fee === "" ||
      this.requestCouponVoucher.voucher_delivery_email === "" ||
      this.requestCouponVoucher.voucher_delivery_email === null ||
      this.requestCouponVoucher.brand_name === "" ||
      this.requestCouponVoucher.brand_name === null ||
      this.requestCouponVoucher.quantity === "" ||
      !this.requestCouponVoucher.quantity === null ||
      this.requestCouponVoucher.validity === "" ||
      this.requestCouponVoucher.validity === null ||
      this.requestCouponVoucher.issuance_date === null ||
      this.requestCouponVoucher.issuance_date === "" ||
      this.requestCouponVoucher.start_date === null ||
      this.requestCouponVoucher.start_date === "" ||
      this.requestCouponVoucher.denomination === "" ||
      this.requestCouponVoucher.denomination === null
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    let sourceref: any = this.requestCouponVoucher.source;
    if (
      sourceref === "QwikCilver" ||
      sourceref === "Ticket Express" ||
      sourceref === "Amazon" ||
      sourceref === "FlipKart" ||
      sourceref === "MEA"
    ) {
      this.requestCouponVoucher.authentication_type = null;
      this.requestCouponVoucher.voucher_redemption_type = null;
      this.requestCouponVoucher.voucher_redemption_url = null;
    } else {
      this.requestCouponVoucher.authentication_type =
        this.requestCouponVoucher.authentication_type;
      this.requestCouponVoucher.voucher_redemption_type =
        this.requestCouponVoucher.voucher_redemption_type;
      this.requestCouponVoucher.voucher_redemption_url =
        this.requestCouponVoucher.voucher_redemption_url;
    }
    if (
      (this.discount && this.discount.includes(",")) ||
      (this.discount && this.discount.includes(".")) ||
      (this.quantity && this.quantity.includes(",")) ||
      (this.quantity && this.quantity.includes(".")) ||
      (this.denomination && this.denomination.includes(",")) ||
      (this.denomination && this.denomination.includes(".")) ||
      (this.issuance_fee && this.issuance_fee.includes(",")) ||
      (this.issuance_fee && this.issuance_fee.includes("."))
    ) {
      this.toastr.error("Input contains invalid characters");
      return;
    }
    if (
      this.requestCouponVoucher.validity === "0" ||
      this.requestCouponVoucher.validity === 0 ||
      /^0*$/.test(this.requestCouponVoucher.validity)
    ) {
      this.toastr.error("Please enter validity greater than 0");
      return;
    }
    if (
      this.requestCouponVoucher.quantity === 0 ||
      this.requestCouponVoucher.quantity === "0" ||
      /^0*$/.test(this.requestCouponVoucher.quantity)
    ) {
      this.toastr.error("Please enter quantity value greater than 0");
      return;
    }
    if (
      this.requestCouponVoucher.denomination === "0" ||
      this.requestCouponVoucher.denomination === 0 ||
      /^0*$/.test(this.requestCouponVoucher.denomination)
    ) {
      this.toastr.error("Please enter denomination value greater than 0");
      return;
    }

    if (this.requestCouponVoucher.source != "Ticket Express") {
      if (
        this.minprice &&
        this.minprice > this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination min values");
        return;
      }
      if (
        this.maxprice &&
        this.maxprice < this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination max values");
        return;
      }
    }
    if (this.requestCouponVoucher.source === "voucherkart") {
      if (!/^[0-9]+$/.test(this.requestCouponVoucher.validity)) {
        this.toastr.error("Please enter validity in numbers");
        return;
      }
    }
    if (!/^[0-9]+$/.test(this.requestCouponVoucher.quantity)) {
      this.toastr.error("Please enter quantity in numbers");
      return;
    }
    // if (!/^[0-9]+$/.test(this.requestCouponVoucher.denomination)) {
    //   this.toastr.error("Please enter denomination in numbers");
    //   return;
    // }
    // if (!/^[0-9.]+$/.test(this.requestCouponVoucher.discount)) {
    //   this.toastr.error("Please enter discount in numbers");
    //   return;
    // }
    // if (!/^[0-9]+$/.test(this.requestCouponVoucher.issuance_fee)) {
    //   this.toastr.error("Please enter issuance fee in numbers");
    //   return;
    // }
    if (this.requestCouponVoucher.voucher_delivery_type === "Single") {
      var expressions = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
      var regex = new RegExp(expressions);
      const result =
        this.requestCouponVoucher.voucher_delivery_email.match(regex);
      if (result == null) {
        this.toastr.error("Please enter valid Email");
        return;
      }
    }
    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "QwikCilver"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }
    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "FlipKart"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }
    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "Ticket Express"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }
    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "Amazon"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }

    if (this.discountvalue) {
      if (this.discountvalue < this.requestCouponVoucher.discount) {
        this.toastr.error("Please enter valid discount");
        return;
      }
    }
    if (this.discountvalue) {
      this.requestCouponVoucher.kart_type = "0";
      let discountpercent = Number(this.discountvalue) * (75 / 100);
      if (this.requestCouponVoucher.discount >= discountpercent) {
        this.requestCouponVoucher.voucher_approve_admin = "YES";
      } else {
        this.requestCouponVoucher.voucher_approve_admin = "NO";
      }
    } else {
      this.requestCouponVoucher.kart_type = this.requestCouponVoucher.kart_type;
    }
    this.requestCouponVoucher.brand_total = this.requestCouponVoucher.totalfacevalue;
    let dat: any = this.requestCouponVoucher.source;
    console.log(dat);
    if (
      dat !== "QwikCilver" &&
      dat !== "Ticket Express" &&
      dat !== "Amazon" &&
      dat !== "FlipKart" &&
      dat !== "MEA"
    ) {
      this.send_date = new Date(this.startDate);
      this.send_date.setDate(
        this.send_date.getDate() + Number(this.requestCouponVoucher.validity)
      );
      this.formattedDate = this.send_date.toISOString().slice(0, -1);
      this.requestCouponVoucher.validity = this.formattedDate;
    }
    this.requestCouponVoucher.created_by_role = this.userDetails.role;
    if (this.isEdit) {
      this.requestCouponVoucher.action = "UPDATE";
      this.requestCouponVoucher.voucher_request_id =
        this.voucherId || this.editvoucherId;
      this.requestCouponVoucher.modified_by = this.userDetails.UserID;
      this.requestCouponVoucher.modiedfy_by_name = this.userDetails.UserName;
      this.requestCouponVoucher.modifieddate = new Date();
    } else {
      this.requestCouponVoucher.action = "CREATE";
      this.requestCouponVoucher.created_by = this.userDetails.UserID;
      this.requestCouponVoucher.status = "Y";
      this.requestCouponVoucher.created_by_name = this.userDetails.UserName;
      delete this.requestCouponVoucher.modified_by;
    }
    this.requestCouponVoucher.voucher_type = "voucher";
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http
      .voucherRequest(this.requestCouponVoucher, this.headers)
      .subscribe(
        (data: any) => {
          this.loader.stop();
          if (data.status == 1) {
            this.toastr.success("Voucher request send successfully");
            this.cancelVoucherList();
            this.router.navigate(["/digital-dashboard"], { replaceUrl: false });
          } else {
            if (data.status == 0)
              this.toastr.error(data.msg || "Voucher request Failed");
            return;
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
  }
  importUserData(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (
      getImg.toLowerCase() !== ".xlsx" &&
      getImg.toLowerCase() !== ".xls" &&
      getImg.toLowerCase() !== ".csv"
    ) {
      return;
    }
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e: any) => {
        let binaryDara = e.target.result;
      };
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      this._http.importUserDataVoucher(formData, this.headers).subscribe(
        (data: any) => {
          this.loader.stop();
          if (data) {
            this.toastr.success("Your file uploaded successfully");
          }
        },
        (_error) => {
          this.loader.stop();
        }
      );
    }
  }

  restrictspecialchars(event: KeyboardEvent): boolean {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error("Number only accepted here");
      return false;
    } else {
      return true;
    }
  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  selectdistypeEvent(item: any, type: boolean) {
    if (!type) this.requestCouponVoucher.discount = "";
    let self = this;
    setTimeout(() => {
      let {
        discount = 0,
        quantity = 0,
        denomination = 0,
        issuance_fee = 0,
        kart_type,
      } = self.requestCouponVoucher;
      if (!issuance_fee) {
        issuance_fee = "0";
      }
      if (kart_type == "0") {
        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);
        if (
          finalval <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }
        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }
        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalval;
        } else {
          this.requestCouponVoucher.total = "";
        }
      } else if (kart_type == "1") {
        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountvalue = qdvalue - parseInt(discount || 0);
        let finalvalue = discountvalue + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);
        if (
          finalval <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }
        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }
        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalval;
        } else {
          this.requestCouponVoucher.total = "";
        }
      }
    }, 200);
  }
  calculate() {
    let self = this;
    setTimeout(() => {
      console.log(self.requestCouponVoucher);

      let {
        discount = 0,
        quantity = 0,
        denomination = 0,
        issuance_fee = 0,
        kart_type,
      } = self.requestCouponVoucher;
      if (!issuance_fee) {
        issuance_fee = "0";
        console.log("issuhjkl;'");
      }
      if (kart_type == "0") {
        let qdvalue = parseInt(quantity || 0) * parseFloat(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseFloat(issuance_fee);
        // let finalval = Math.round(finalvalue);
        if (
          finalvalue <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }
        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }
        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalvalue;
        } else {
          this.requestCouponVoucher.total = "";
        }
      } else if (kart_type == "1") {
        let qdvalue = parseInt(quantity || 0) * parseFloat(denomination || 0);
        let discountvalue = qdvalue - parseFloat(discount || 0);
        let finalvalue = discountvalue + parseFloat(issuance_fee);
        // let finalval = Math.round(finalvalue);
        if (
          finalvalue <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }
        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }
        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalvalue;
        } else {
          this.requestCouponVoucher.total = "";
        }
      }
    }, 200);
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
    localStorage.removeItem("edit");
  }
}
