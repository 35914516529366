import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { ToastrModule } from 'ngx-toastr';
import { BrandComponent } from "./brand.component";


@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        ToastrModule.forRoot()
    ],
    declarations: [
    BrandComponent
    ],
    exports: [],
    providers: []
})
export class BrandModule {}