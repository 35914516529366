export class RequestCompany {
    CustomerID:any;
    CompanyName:any;
    supplierType:any;
    BrandName:any;
    BrandId:any;
    supplier:any;
    Status:any;
    status:any;
    type:any;
    internal:any;
    external:any;
    id:any;
    contract_count:any;
    source:any;
    wallet:any;
    wallet_threshold:any;

// Add contract

    company_id:any;
    companyName:any;
    discount_type:any;
    discount_value:any;
    issuance_fee:any;
    payment:any;
    start_date:any;
    end_date:any;
    kart_type:any;
    action:any;
    payment_type:any;
    payment_status:any;
}