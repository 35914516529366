<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Brand Management List</label>
                </div>
                
                <div class="text-end col-9">
                    <div class="dropdown " *ngIf="role === 'admin'">
                        <button class="btn btn-success mr-10 float-right" (click)="brandSync()">Brand Sync</button>
                    </div>
                    </div>
            </div>


            <div class="d-flex">                          
                                
                <div class="form-floating me-3">
                    <select id="type" class="form-select border-0 bg-light" [(ngModel)]="brandtype"
                        style="width:210px;padding-top: 13px;" (change)="filterList()">

                        <option value="" selected disabled hidden class="lts">Type</option>
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                        <!-- <option value="QC">QC</option>   -->

                    </select>
                </div>
               
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>
            </div>


            <div class="mt-4 batch-data px-1">

                <div class="row mx-0 adcolor py-4 opacity-75 px-3">
                    <div class="col-2 px-0 labelText ps-4"> Brand ID </div>
                    <div class="col-4 pe-0 labelText text-center"> Brand Name </div>
                    <div class="col labelText text-center"> Type</div>
                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                *ngIf="companyList && companyList.length === 0 && !apiLoading">
                <div class="card-body row mx-0 ps-0">
                    <div class="text-center">
                        No Record Found.
                    </div>
                </div>
            </div>



                <div>
                    <div class=""
                        *ngFor="let list of companyList | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count};let i = index">
                        <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 ps-0">
                                <div class="col-2 text-truncate pe-0 ps-4"> {{list.BrandId}} </div>
                                <div class="col-4 text-truncate ps-5 text-start"> {{list.BrandName}} </div>


                               
                                <div class="col px-0 text-center">
                                    <div *ngIf = "list.type === 'Internal' ">


                                        <input type="radio" id="list{{i}}" [(ngModel)]="selectinternal" value="Internal" name="supplierType{{i}}" 
                                        (change)="type(list,0)">                                    
                                        <label for=list{{i}} class="ms-2 me-4">Internal</label>
    
    
                                        <input type="radio"  id="liste{{i}}{{i+1}}" [(ngModel)]="list.type" value="External" name="supplierType{{i}}{{i+1}}" 
                                        (change)="type(list,1)" >
                                        <label for=liste{{i}}{{i+1}} class="ms-2 me-4">External</label>


                                    </div>

                                    <div *ngIf = "list.type === 'External' ">


                                        <input type="radio" id="list{{i}}"  value="Internal" name="supplierType{{i}}" 
                                        (change)="type(list,0)">                                    
                                        <label for=list{{i}} class="ms-2 me-4">Internal</label>
    
    
                                        <input type="radio"  id="liste{{i}}{{i+1}}" [(ngModel)]="selectexternal" value="External" name="supplierType{{i}}{{i+1}}" 
                                        (change)="type(list,1)">
                                        <label for=liste{{i}}{{i+1}} class="ms-2 me-4">External</label>


                                    </div>

                                    <div *ngIf ="list.type !== 'Internal' && list.type !== 'External'">

                                        <label>External ({{list.type}})</label>

                                    </div>
                                
                                </div> 

                                <!-- <div class="col px-0 text-center">

                                    <input type="radio" [value]="backendValue" [(ngModel)]="supplierType.value" (change)="type(list,0)">                                   
                                    <label class="ms-2 me-4">{{list.type}}</label>


                                    <input type="radio" value="External" [(ngModel)]="supplierType.value">                                    
                                    <label class="ms-2 me-4" (change)="type(list,1)" >External</label>
                                </div>  -->




                                <!-- <div class="col text-truncate pe-0 leftAlign"> {{list.supplierType ?'Internal':
                                    'External'}} </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="supplierList && supplierList.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6">
                            <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>                    

                
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>