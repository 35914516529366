import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherReportComponent } from './voucher-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';







@NgModule({
  declarations: [VoucherReportComponent],
  imports: [
    CommonModule,NgxPaginationModule,ReactiveFormsModule,FormsModule,
    NgxMyDatePickerModule,NgxUiLoaderModule
  ]
})
export class VoucherReportModule { }
