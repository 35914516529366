<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
<div class="row mb-4">
    <div class="col-3">
        <label class="texts">{{companynames}} Contract List</label>
    </div>
    <div class="text-end col-9">
    <div class="dropdown">
        <button class="btn btn-success mr-10 float-right" (click)="AddModal()">Add New</button>
    </div>
    </div>
</div>


<div class="mt-4 batch-data px-1">   

    <div class="row mx-0 adcolor py-4 opacity-75">
      
        <!-- <div class="col px-0 text-start" style="margin-left: 8px;"> Company Name </div>                   
        <div class="col px-0 text-start"> Discount Type </div> -->
        <div class="col labelText text-end"> Discount Value</div>
        <div class="col labelText text-end"> Issuance Fee</div>
        <div class="col labelText text-end"> Payment </div>
        <div class="col labelText text-end"> Start Date </div>
        <div class="col labelText text-end"> End Date</div>
        <div class="col labelText text-center"> Action</div>
          
    </div>

    <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
    *ngIf="companycontractList.length === 0 && !apiLoading">
    <div class="card-body row mx-0 ps-0">
        <div class="text-center">
            No Record Found.
        </div>
    </div>
</div>
        
<div *ngFor="let list of companycontractList | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count};let i = index">
            <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                ng-reflect-ng-class="bg-light">
                
                    <div class="card-body row mx-0 px-0">                      
                   
                        <!-- <div class="col text-truncate text-start px-0" style="margin-left: 8px;"> {{list.companyName}} </div>
                        <div class="col text-truncate text-start px-0">                          
                         
                            {{list.discount_type == "0" ?
                            "Percentage"
                            : list.discount_type == "1" ? "Value" : ""}}                        
                        </div>                            -->
                        <div class="col text-truncate text-end" *ngIf = "list.discount_type == '0'"> {{list.discount_value}}%
                                               
                        </div>

                        <div class="col text-truncate text-end" *ngIf = "list.discount_type == '1'"> {{list.discount_value | currency : 'INR'}}
                                               
                        </div>


                        <div class="col text-truncate text-end"> {{list.issuance_fee | currency : 'INR'}} </div>  
                        <div class="col text-truncate text-end"> {{list.payment == "0"? "Advance" : list.payment == "1" ? "Credit" : ""}} </div>
                        <div class="col text-truncate text-end"> {{list.start_date | date : 'dd/MM/yyyy'}} </div>  
                        <div class="col text-truncate text-end"> {{list.end_date | date : 'dd/MM/yyyy'}} </div>
                        <div class="col text-truncate text-center">                          


                            <a class="linkColor downloadIcon" #Edit (click)="editContract(list)">
                                <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
                            </a>

                            <a class="linkColor" (click)="deleteModal(list.id)">
                                <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                            </a>           
                        </div>
                        
                        </div>
                </div>

              
            </div>
        </div> 

        <div class="row" *ngIf="companycontractList && companycontractList.length > 0">
            <div class="col-6 pt-3">
                <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of
                    {{count}} entries</p>
            </div>
            <div class="col-6">
                <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                </pagination-controls>
            </div>
        </div>

        <div class="d-flex align-items-end justify-content-end mt-4">
            <button class="btn backcolor" (click)="back()">Back</button>
        </div>
        </div>        
    </div>

        <div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <p>Are you sure to delete the contract?</p>
                        <p class="mt-5">
                            <button class="btn btn-secondary mr-5" data-bs-dismiss="modal">Cancel</button>
                            <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deletecontract()">Confirm</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="AddModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center">                    
                        <p class="texts"> {{companynames}} Contract</p>
                        <form #addForm="ngForm" name="form" (ngSubmit)="AddContract(AddContractCompany)" class="mt-4">  
                            
                            <div class="md-form form-sm my-3 leftAlign">
                                <label for="discount_type" class="mb-2">Discount Type 
                                    <span class="text-danger">*</span></label>
                                <select id="discount_type" class="form-select" [(ngModel)]="AddContractCompany.discount_type" name="discount_type"
                                    #discount_type="ngModel" (change)="selectdistypeEvent($event)" required>
                                    <option value="" selected disabled hidden>Select Payment</option>
                                    <option value="0">Percentage</option>
                                    <option value="1">Value</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(discount_type.touched || addForm.submitted) && discount_type.errors?.['required']">
                                    Discount Type is required
                                </span>
                            </div>
                                                                          
                            <div class="md-form form-sm my-3  leftAlign">
                                <label class="mb-2">Discount
                                    <span class="text-danger">*</span>
                                </label>
                                 <input type="text" [attr.maxlength]="AddContractCompany.discount_type == 0 ? '2' : '8' " [(ngModel)]="AddContractCompany.discount_value" name="discount_value"
                                    #discount_value="ngModel" class="form-control" placeholder="Enter Discount" mdbInput
                                    autocomplete="off" 
                                    
                                    (input)="restrictDecimals($event)" 
                                    (keypress)="numberOnly($event);restrictAlphabets($event)" (keydown)="keyDownHandler($event)"   
                                    
                                    required >
                                <span class="text-danger" 
                                    *ngIf="(discount_value.touched || addForm.submitted) && discount_value.errors?.['required']">
                                    Discount is required
                                </span>
                            </div>         
                            
                            
                            <div class="md-form form-sm my-3  leftAlign position-relative">
                                <label class="mb-2">Issuance Fee
                                    <span class="text-danger">*</span>  
                                    <!-- <span class="rs">(INR)</span>                               -->
                                </label>
                                <i class="fa fa-inr currency-icon"></i>
                                 <input type="text" [(ngModel)]="AddContractCompany.issuance_fee" name="issuance_fee"
                                    #issuance_fee="ngModel" class="form-control custom-input-style" placeholder="Enter Issuance Fee" mdbInput
                                    autocomplete="off"
                                    
                                    (input)="restrictDecimals($event)" 
                                    (keypress)="numberOnly($event);restrictAlphabets($event)" (keydown)="keyDownHandler($event)"   
                                    
                                    
                                    required >
                                    <!-- <span>&#x20B9;</span> -->
                                    <span class="text-danger"
                                    *ngIf="(issuance_fee.touched || addForm.submitted) && issuance_fee.errors?.['required']">
                                    Issuance Fee is required
                                </span>
                            </div>    

                            <div class="md-form form-sm my-3  leftAlign">
                                <label for="payment" class="mb-2">Payment 
                                    <span class="text-danger">*</span></label>
                                <select id="payment" class="form-select" [(ngModel)]="AddContractCompany.payment" name="payment"
                                    #payment="ngModel" required>
                                    <option value="" selected disabled hidden>Select Payment</option>
                                    <option value="0">Advance</option>
                                    <option value="1">Credit</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(payment.touched || addForm.submitted) && payment.errors?.['required']">
                                    Payment is required
                                </span>
                            </div>



                            <div class="md-form form-sm my-3  leftAlign position-relative">
                                <label for="start_date" class="mb-2">Start Date 
                                    <span class="text-danger">*</span>
                                </label>
        
                                <input (click)="dp.openCalendar()" [(ngModel)]="getstartDate" name="start"
                                    placeholder="Select A Date" class="form-control datepicker" ngx-mydatepicker
                                    [options]="startOptions" readonly #dp="ngx-mydatepicker"
                                    type="text" #start="ngModel" (dateChanged)="onStartDateChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 36px; right: 0">
                                    <a href="javascript:void(0)" (focus)="dp.openCalendar()" (click)="dp.openCalendar()"><img
                                            src="../../../assets/images/icons/calendar.svg" width="32"></a>
                                </span>
                                <span class="text-danger" *ngIf="(addForm.submitted) && start.errors?.['required']">
                                    start date is required
                                </span>
                            </div>

                            <div class="md-form form-sm my-3  leftAlign position-relative">
                                <label for="start_date" class="mb-2">End Date 
                                    <span class="text-danger">*</span>
                                </label>
        
                                <input (click)="edp.openCalendar()" [(ngModel)]="getendDate" name="end"
                                    placeholder="Select A Date" class="form-control datepicker" ngx-mydatepicker
                                    [options]="endOptions" readonly #edp="ngx-mydatepicker"
                                    type="text" #end="ngModel" (dateChanged)="onEndDateChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 36px; right: 0">
                                    <a href="javascript:void(0)" (focus)="edp.openCalendar()" (click)="edp.openCalendar()"><img
                                            src="../../../assets/images/icons/calendar.svg" width="32"></a>
                                </span>
                                <span class="text-danger" *ngIf="(addForm.submitted) && end.errors?.['required']">
                                    End date is required
                                </span>
                            </div>




                         
                            <p class="mt-3">
                                <button class="btn btn-secondary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeSupplierBtn
                                    type="button"  (click)="backclear()">Back</button>
                                    <button class="btn btn-success px-4 py-2" type="submit"> {{Edit ? 'Update':'Add'}}</button>
                                   
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>

    <ngx-ui-loader></ngx-ui-loader>
