export class Supplier {
    
    supplier_id:any;
    supplier_name: string = '';
    api_url: string = '';
    api_key: string = '';
    api_authentication_code: string = '';
    statuss: string = '';
    status:any;
    created_by_id:any;
    created_by_name:any;
    action:any;
    modify_by_id:any;
    modify_by_name:any;
}
