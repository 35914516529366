<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Multiple Denomination</label>
                </div>
                <div class="text-end col-9">

                    <label class="rounded px-3 py-2 bg-success text-center border-0 labelText  mr-10" for="inputFile">
                        <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                            (change)="groupbulkimportfileupload($event)">
                        <i class="fa fa-upload pr-1"></i> Bulk Import</label>

                    <a href="{{this.fileurls}}sample/{{role==='Company'?'sample_client_voucher_group.csv':'sample_voucher_group.csv'}}" class="add_bt btn btn-success mr-10"
                        type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download pr-1"></i> Sample file
                    </a>
                    <a href="{{this.fileurls}}downloadAllProduct" class="add_bt btn btn-success mr-10"
                        type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download pr-1"></i> Product List
                    </a>
                </div>
            </div>
            <div class="d-flex">
                <div class="form-floating me-3">
                    <input type="text" class="form-control border-0 bg-light" [(ngModel)]="company" name="company"
                        id="mname" placeholder="Clients" (keypress)="onKeyPresscomma($event);filterList()">
                    <label for="mname">Clients</label>
                </div>
                <div class="form-floating me-3">
                    <select id="status" class="form-select border-0 bg-light" [(ngModel)]="approved" name="approved"
                        style="width:150px;padding-top: 13px;" (change)="filterList()">
                        <option value="" selected disabled hidden>Status</option>
                        <option value="Y">Pending</option>
                        <option value="A">Approved</option>
                        <option value="R">Rejected</option>
                        <option value="G">Generated</option>
                    </select>
                </div>
                <button class="btn btn-secondary mr-5" (click)="clear()" style="width: 90px;">Clear</button>
            </div>

            <div class="mt-4 batch-data px-1">
                <div class="row mx-0 py-4 opacity-75 {{ this.role === 'Admin' || this.role === 'SuperAdmin' || this.role === 'admin' ? 'adcolor' : (this.role === 'brand' ? 'side1' : (this.role === 'Company' ? 'sides1' : '')) }}">
                    <div class="col labelText px-2 text-center "> Clients </div>
                    <div class="col labelText px-2 text-center "> No. Of Brands </div>
                    <div class="col labelText px-2 text-center"> No. Of Brands Denominations
                    </div>
                    <div class="col labelText px-2 text-center"> Quantity</div>
                    <div class="col labelText px-2 text-center"> Payment </div>
                    <div class="col labelText px-2 text-center"> Status</div>
                    <div class="col labelText px-2 text-center"> Action </div>
                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                    *ngIf="multidenomilist && multidenomilist.length === 0 !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center">
                            No data found.
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        *ngFor="let list of multidenomilist | paginate: {itemsPerPage: limit, currentPage: c,totalItems:count};let i = index">
                        <div class=" card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 px-0">

                                <div class="col-1 text-center px-2 test-class" title="{{list.client_name}}">
                                    {{list.client_name}} </div>

                                <div class="col text-center px-2 test-class" title="{{list.no_of_brands
                                        }}"> {{list.no_of_brands
                                    }} </div>



                                <div class="col text-center px-2 test-brand" title="{{list.no_of_denomination
                                    }}">
                                    {{list.no_of_denomination}} </div>

                                <div class="col text-start px-2 test-brand" title="{{list.quantity
                                        }}">
                                    {{list.quantity}} </div>

                                <div *ngIf="list.payment_status == '0'" class="col" title="Advance">
                                    <img src="../../../assets/images/icons/advance_icon.svg" height="20">
                                </div>

                                <div *ngIf="list.payment_status == '1'" class="col" title="Credit">
                                    <img src="../../../assets/images/icons/credit_icon.svg" height="20">
                                </div>

                                <div class="col">

                                    <span *ngIf="list.status == 'Y'" title="Pending"> <img
                                            src="../../../assets/images/icons/pending_icon.svg" height="20"></span>

                                    <span *ngIf="list.status == 'A'" title="Approved"> <img
                                            src="../../../assets/images/icons/approved_icon.svg" height="20"></span>

                                    <span *ngIf="list.status == 'R'" title="Rejected"> <img
                                            src="../../../assets/images/icons/rejected_icon.svg" height="20"></span>

                                    <span *ngIf="list.status == 'G'" title="Generated"> <img
                                            src="../../../assets/images/icons/generated_icon.svg" height="20"></span>
                                    <span *ngIf="list.status == null  " title="Rejected"> <img
                                            src="../../../assets/images/icons/rejected_icon.svg" height="20"></span>

                                </div>

                                <div class="col">

                                    <a class="linkColor"
                                        *ngIf="(list.status == 'Y') && (( role =='admin' || role === 'SuperAdmin' ))"
                                        [ngClass]="{'disabled': multidenomilist.length === 0}"
                                        (click)="rejectshowModal('R',list)" #closeBtn>
                                        <i class="fa fa-times cursor col" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="Reject"></i>
                                    </a>

                                    <a class="linkColor"
                                        *ngIf="((list.status == 'Y' || list.status == 'R')) && ((role =='admin' || role === 'SuperAdmin'))"
                                        [ngClass]="{'disabled': multidenomilist.length === 0}"
                                        (click)="approveshowModal('A',list)" #closeBtn>
                                        <i class="fa fa-check cursor col" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="Approve"></i>
                                    </a>

                                    <a class="linkColor" (click)="viewGenerationgroupList(list)">
                                        <i class="fa fa-eye cursor col" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="View"></i>
                                    </a>


                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="multidenomilist && multidenomilist.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{c > 1 ? skip + 1 :c}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6"  *ngIf="role === 'Admin' || role === 'SuperAdmin' ||role === 'admin'">
                            <pagination-controls class="list-pagination adpage"
                                (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                        <div class="col-6" *ngIf="role === 'company' || role === 'Company'">
                            <pagination-controls class="list-pagination companypage"
                                (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>

                        <div class="col-6" *ngIf="role === 'brand'">
                            <pagination-controls class="list-pagination brandpage"
                                (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ngx-ui-loader></ngx-ui-loader>
    </div>
</div>



<div class="modal fade" id="rejectModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure want to reject the voucher?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal"
                        #closeBtn>Cancel</button>
                    <button class="btn btn-success" data-bs-dismiss="modal"
                        (click)="Rejectbrand(requestGroupVoucher)">Confirm
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="approveModals" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure want to approve the voucher?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal"
                        #closeBtn>Cancel</button>
                    <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveVouchers()">Okay
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>


<!-- <div class="modal fade" id="approveModalss" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" (click)="clear()"
                        ##closeApproveeBtn>Cancel</button>
                    <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveVoucher()">Okay
                    </button>
                </p>
            </div>
        </div>
    </div>
</div> -->