<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-4">
                    <label class="texts">Digital Voucher Order List</label>
                </div>
            </div>

            <div class="col-12 card border-0 shadow-sm px-0">
                <div class="card-body bg-white pb-2">

                    <div class="row mx-0 py-4 opacity-75">
                        <div class="col px-1 ">
                            <span class="{{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}">  Ref. No. </span> <br>
                             <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.id}}
                            </span>
                        </div>
                        <div class="col px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }} " > Brand Count
                            <br> <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.no_of_brands}}
                            </span>
                        </div>
                        <div class="col px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}" > Quantity
                            <br> <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.quantity}}
                            </span>
                        </div>
                        <div class="col px-1 " >
                            <span class="{{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}">  Denomination  </span> <br>
                            <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.no_of_denomination}}
                           </span>
                        </div>
                        <div class="col px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}" > Issuance Date
                            <br> <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.issuance_date | date : 'dd/MM/yyyy'}}
                            </span>
                        </div>
                        <div class="col px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}" > Total Amt.
                            <br> <span class="mr-5" style="color: #0c0c0c;">
                                {{requestGroupVoucher.total_amount | currency : 'INR' }}
                            </span>
                        </div>
                        <div class="col  px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'advcolor' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}"> Status
                            <br> <span class="mr-5" style="color: #0c0c0c;" *ngIf="requestGroupVoucher.status === 'Y'">
                                Pending
                            </span>
                            <span class="mr-5" style="color: #0c0c0c;" *ngIf="requestGroupVoucher.status === 'A'">
                                Approved
                            </span>
                            <span class="mr-5" style="color: #0c0c0c;" *ngIf="requestGroupVoucher.status === 'R'">
                                Rejected
                            </span>
                            <span class="mr-5" style="color: #0c0c0c;" *ngIf="requestGroupVoucher.status === 'G'">
                                Generated
                            </span>
                        </div>

                        <!-- <div class="col text-center px-1 advcolor"> 
                            <a class="accordion advcolor" (click)="toggleAccordian()" >
                                <i *ngIf="showAccordion" class='fas fa-angle-up'></i> 
                                <i *ngIf="!showAccordion" class='fas fa-angle-down'></i>
                            </a>
                        </div> -->



                        <div class="mt-4 batch-data px-1" *ngIf="showAccordion">

                            <div class="row mx-0   py-4 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'adcolor' : (this.role === 'brand' ? 'side1' : (this.role === 'Company' ? 'sides1' : '')) }}">
                                <div class="col labelText px-2 text-start"> Brand</div>
                                <div class="col labelText px-2 text-start"> Denomination</div>
                                <div class="col labelText px-2 text-start"> Quantity</div>
                                <div class="col labelText px-2 text-start"> Sum Total</div>
                                <div class="col labelText px-2 text-start"> Discount</div>
                                <div class="col labelText px-2 text-start"> Amount Payable</div>
                                <div class="col labelText px-2 text-center"> Action </div>
                            </div>

                            <div>

                                <div *ngFor="let list of voucherList | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count}; index as idx">
                                    <div class=" card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                                        ng-reflect-ng-class="bg-light">
                                        <div class="card-body row mx-0 px-0">

                                            <div class="col text-start px-2 test-class" title="{{list.brand_name}}"> {{list.brand_name}} </div>

                                            <div class="col text-start px-2 test-class" title="{{list.denomination}}"> {{list.denomination  | currency : 'INR'}} </div>

                                            <div class="col text-start px-2 test-brand" title="{{list.quantity}}"> {{list.quantity}} </div>

                                            <div class="col  text-start px-2 test-brand" title="{{list.total}}">
                                                {{list.total  | currency : 'INR'}}
                                            </div>
                                            <div class="col text-start px-2 test-brand" title="{{list.discount
                                            }}">
                                                {{list.discount}}%
                                            </div>
                                            <div class="col text-center px-2 test-brand" title="{{list.brand_total
                                            }}">
                                                {{list.brand_total | currency : 'INR'}}
                                            </div>
                                            <!-- <div class="col text-center">
                                                <a class="linkColor" (click)="viewReport(list.voucher_request_id, idx,list.source)">
                                                    <i class="fa fa-eye cursor col" data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom" title="View"></i>
                                                </a>

                                            </div> -->
                                            <div class="col text-center">
                                                <a class="linkColor" (click)="toggleViewReport(list.voucher_request_id, idx, list.source, list.status)">
                                                  <i class="fa cursor" [ngClass]="{'fa-eye': !showGroupVoucher[idx], 'fa-eye-slash': showGroupVoucher[idx]}" data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom" [title]="showGroupVoucher[idx] ? 'Hide' : 'View'"></i>
                                                </a>
                                              </div>
                                              


                                        </div>
                                    </div>

                                    <div class="card my-0 py-0 border-0 rounded-0 shadow-item " *ngIf="showGroupVoucher[idx]">
                                        <div class="mt-4 px-1 {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'admin-bottom' : (this.role === 'brand' ? 'brand-bottom' : (this.role === 'Company' ? 'company-bottom' : '')) }}">
                                            <div class="row  mx-0 py-1 opacity-75">
                                                <div class="col px-2 text-start {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'headtext' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}"> Voucher Code </div>
                                                <div class="col px-2 text-start {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'headtext' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}"> Voucher Pin </div>
                                                <div class="col px-2 text-start {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'headtext' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}"> Status</div>
                                                <div class="col px-2 text-start {{ this.role === 'Admin' || this.role === 'SuperAdmin' ? 'headtext' : (this.role === 'brand' ? 'side' : (this.role === 'Company' ? 'sides' : '')) }}"> Allocated User Email</div>
                                            </div>
                                        </div>
                                        <div class="card-body row mx-0 px-0">
                                            <div class="row mx-0 py-1 border-0 rounded-0 shadow-items bg-light" *ngFor="let voucher of voucherGenerationList">
                                                <div class="col px-2 text-start"> {{voucher.voucher_code ||voucher.card_number }} </div>
                                                <div class="col px-2 text-start"> {{voucher.voucher_pin || voucher.card_pin}} </div>
                                                <div class="col px-2 text-start">
                                                    Generated
                                                    <!-- <span *ngIf="voucher.status == 'Y'">Pending</span>
                                                    <span *ngIf="voucher.status == 'A'">Approved</span>
                                                    <span *ngIf="voucher.status == 'R'">Rejected</span>
                                                    <span *ngIf="voucher.status == 'G'">Generated</span> -->
                                                </div>
                                                <div class="col px-2 text-start">
                                                    {{voucher.voucher_allocated_user_email}}</div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="voucherList.length > 0">
                                            <div class="col-6 pt-3">
                                                <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}}
                                                    of {{count}}
                                                    entries</p>
                                            </div>
                                            <div class="col-6"  *ngIf="role === 'Admin' || role === 'SuperAdmin'">
                                                <pagination-controls class="list-pagination adpage"
                                                    (pageChange)="pageChanged($event)"></pagination-controls>
                                            </div>
                                            <div class="col-6" *ngIf="role === 'company' || role === 'Company'">
                                                <pagination-controls class="list-pagination companypage"
                                                    (pageChange)="pageChanged($event)">
                                                </pagination-controls>
                                            </div>
                
                                            <div class="col-6" *ngIf="role === 'brand'">
                                                <pagination-controls class="list-pagination brandpage"
                                                    (pageChange)="pageChanged($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>

                               

                            </div>

                        </div>

                    </div>
                    

                </div>

            </div>
            <div class="d-flex align-items-end justify-content-end mt-4">
                <button class="btn backcolor" (click)="back()">Back</button>
            </div>

        </div>
    </div>
</div>