import { Component, ElementRef, OnInit, ViewChild,HostListener } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppService } from './../restful-services/restful.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RequestContractCompany } from '../models/RequestContract';
import { DatePipe } from '@angular/common';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { Modal } from 'bootstrap';
declare var bootstrap: any;




@Component({
  selector: 'app-company-contract',
  templateUrl: './company-contract.component.html',
  styleUrls: ['./company-contract.component.scss']
})


export class CompanyContractComponent implements OnInit {


  public startOptions: INgxMyDpOptions = {};
  public endOptions: INgxMyDpOptions = {};


  private headers: any = {};
  limit: number = 25;
  supplierType: any;
  skip: number = 0;
  p: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  companycontractList: RequestContractCompany[];
  AddContractCompany: RequestContractCompany;
  role: any;
  userDetails: any;
  ContractId: any;
  apiLoading: boolean = false;

  companyName: any;
  kart_type: any;
  discount_type: any;
  issuance_fee: any;
  payment_status: any;

  endDate: any;
  Edit: Boolean;
  IssuanceDate: any;
  ExpiryDate: any;
  getstartDateOptions: any;
  send_date = new Date();
  formattedDate: any;
  issuanceDate: any;
  startDate: any;
  getExpiryDate: any;
  public getstartDate: any;
  public getendDate: any;
  @ViewChild('closeSupplierBtn', { static: true }) closeSupplierBtn!: ElementRef;
  RequestContractCompany: any;
  tooltipList: any;
  idpass: any;
  // addForm: any;
  @ViewChild('addForm', { static: true }) addForm: any;

  isEditing:boolean = true; 
  companyids: any;
  companynames:any;


  constructor(private _http: AppService, private router: Router,
    private toastr: ToastrService, private loader: NgxUiLoaderService, private datePipe: DatePipe) {

    this.companycontractList = new Array<RequestContractCompany>();
    this.AddContractCompany = new RequestContractCompany();

    this.startDate = new Date();
    this.endDate = new Date();

    this.Edit = false;

    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    let tdate = new Date();
    this.getendDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };
    // this.setDateOptions();

  }



  ngOnInit() {

    let { id }:any = history.state;
    let { companyname }:any = history.state;    

    if(!id)id = localStorage.getItem('companyid');
    if(!companyname)companyname = localStorage.getItem('companyname');

    // console.log(id,companyname,history);

    if (id || companyname) {
     
      this.getcompanycontarctList(id);
      this.companynames = companyname;    
      this.companyids = id;    
      localStorage.setItem('companyid', id);
      localStorage.setItem('companyname', companyname);

    }
    else{
     
    }

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    this.getcompanycontarctList(this.companyids);

    
 

    if (this.Edit) { 
    
      if (this.AddContractCompany.kart_type === '0') {
        this.AddContractCompany.kart_type = 'Percentage';
      }

      else {
        this.AddContractCompany.kart_type = 'Value';
      }


      if (this.AddContractCompany.payment_status === '0') {
        this.AddContractCompany.payment_status = 'Advance';
      }
      else {
        this.AddContractCompany.payment_status = 'Credit';
      }

    //  this.setDateOptions();

    // this.startDate = new Date();
    // this.endDate = new Date();

    this.Edit = false;

    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    let tdate = new Date();
    this.getendDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };
   
    }    
  
  }



  setDateOptions() {

    let a = new Date(this.AddContractCompany.end_date);
    a.setDate(a.getDate() - 1);
    a = new Date(a.getFullYear(), a.getMonth(), a.getDate());
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: a.getFullYear(), month: a.getMonth() + 1, day: a.getDate() },
    };

    let b = new Date(this.AddContractCompany.start_date);
    b.setDate(b.getDate());
    b = new Date(b.getFullYear(), b.getMonth(), b.getDate());
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: b.getFullYear(), month: b.getMonth() + 1, day: b.getDate() },
    };
  }

  onStartDateChanged(event: IMyDateModel): void {

    this.startDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    let dp = new Date(this.startDate);
    dp.setDate(dp.getDate() - 2);
    dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate())
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
    };
    this.endDate = '';
    this.getendDate = '';
  } 


  onEndDateChanged(event: IMyDateModel): void {

    this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    // this.ExpiryDate = this.endDate;
    let d: any = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate())
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    }; 

  }


  // onEndDateChanged(event: IMyDateModel): void {

  //   this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
  //   this.ExpiryDate = this.endDate;
  //   let d: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
  //   this.endOptions = {
  //     dateFormat: 'dd-mm-yyyy',
  //     alignSelectorRight: true,
  //     openSelectorTopOfInput: false,
  //     disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
  //   };

  // }

  back(){
    
      this.router.navigate(['/clients'], { replaceUrl: false });
        
  }


  getcompanycontarctList(ids: number) {
   
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    
    this.loader.start();
    this.apiLoading = true;
    this._http.getCompanycontractList(ids, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {
        this.apiLoading= false;
        this.loader.stop();
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
        this.loader.stop();
        if (data) {
          this.count = data.count;
          this.companycontractList = data.data;
         
        }
      },
      error: _error => {
        this.apiLoading= false;
        this.loader.stop();
      }
    })
  }


  deleteModal(id: any) {

    this.ContractId = id;
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }

  AddModal() {

    this.startDate = new Date();
    this.endDate = new Date();

    // this.Edit = false;

    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    let tdate = new Date();
    this.getendDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    const element = document.getElementById('AddModal') as HTMLElement;
    const AddModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    AddModal.show();
  }


  editContract(list: RequestContractCompany) {   

    this.AddContractCompany = { ...list, discount_type: list.discount_type.toString() };
    this.AddContractCompany = { ...list, payment: list.payment.toString() };
  
    this.Edit = true;
    const element = document.getElementById('AddModal') as HTMLElement;
    const AddModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });

    if (this.Edit) {

       this.startDate = this.AddContractCompany.start_date;
       this.endDate = this.AddContractCompany.end_date;

      const i = new Date(this.AddContractCompany.start_date)
      this.getstartDate = { date: { year: i.getFullYear(), month: i.getMonth() + 1, day: i.getDate()  } };

      const s = new Date(this.AddContractCompany.end_date)
      this.getendDate = { date: { year: s.getFullYear(), month: s.getMonth() + 1, day: s.getDate() } };


      // this.setDateOptions();
    }
    AddModal.show();
  }


  deletecontract() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }; 

    this._http.deleteContract(this.ContractId, this.headers).subscribe(
      (data: any) => {      

        if (data.status == 1) {
          this.toastr.success("Contract deleted successfully");
          this.getcompanycontarctList(this.companyids);
          this.loader.stop();
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      })
  }


  AddContract(AddContractCompany: RequestContractCompany) {   


    if (
      this.AddContractCompany.discount_type === "" || this.AddContractCompany.discount_type === undefined || this.AddContractCompany.discount_type === null || this.AddContractCompany.discount_value === "" || this.AddContractCompany.discount_value === undefined 
      || this.AddContractCompany.discount_value === null|| this.AddContractCompany.payment === "" || this.AddContractCompany.payment === undefined || this.AddContractCompany.payment === null||
      this.AddContractCompany.issuance_fee === "" || this.AddContractCompany.issuance_fee === undefined || this.AddContractCompany.issuance_fee === null||
      this.startDate == "" || this.startDate == undefined || this.startDate == null ||
      this.endDate == "" || this.endDate == undefined || this.endDate == null
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    if (this.AddContractCompany.discount_value === '0' || this.AddContractCompany.discount_value === 0
 
    ) {
      this.toastr.error("Please enter discount value greater than 0");
      return;
    }
  
    if (this.AddContractCompany.issuance_fee === '0' || this.AddContractCompany.issuance_fee === 0
   
    ) {
      this.toastr.error("Please enter issuance fee value greater than 0");
      return;
    }

    if (!/^[0-9]+$/.test(this.AddContractCompany.discount_value)) {
   
      this.toastr.error("Please enter discount value in numbers");
      return;
    }  

    if (!/^[0-9]+$/.test(this.AddContractCompany.issuance_fee)) {
   
      this.toastr.error("Please enter issuance fee in numbers");
      return;
    }  

    if (this.startDate > this.endDate) {   

      this.toastr.error("End date cannot be earlier than the start date. Please choose a correct date.");
      return;
    }  


    let postparams = {

      discount_type: this.AddContractCompany.discount_type,
      discount_value: this.AddContractCompany.discount_value,
      payment_type: this.AddContractCompany.payment,
      start_date: this.startDate,
      end_date: this.endDate,
      company_id: this.companyids,
      action: this.Edit ? 'UPDATE' : "CREATE",
      issuance_fee: this.AddContractCompany.issuance_fee,
      id: this.Edit ? this.AddContractCompany.id : null

    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    if (this.Edit) {
      this.loader.start();
      this._http.updatecontractor(postparams, this.headers).subscribe({
        next: (data: any) => {
        this.loader.stop();
        if (data.status == 1) {
          this.closeSupplierBtn.nativeElement.click();
          this.toastr.success("Contract Updated successfully");
          this.Edit = false;
          this.addForm.resetForm();
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
          this.closeSupplierBtn.nativeElement.click(); 
          this.Edit = false;                    
         
        }
      },
        error: error => {
          this.loader.stop();         
          this.toastr.error(error.error.Message);
          this.closeSupplierBtn.nativeElement.click();
          this.Edit = false;
        }
      })
    
    }

    else {

      this.loader.start();
      this._http.addNewcontractor(postparams, this.headers).subscribe({
        next: (data: any) => {

        if (data.status == 1) {
          this.loader.stop();
          this.closeSupplierBtn.nativeElement.click();
          this.toastr.success("Contract Added successfully");
          this.addForm.resetForm();
          // this.getcompanycontarctList(this.companyids);      
          // this.backclear();          
          // this.addForm.resetForm();

        }

        else if (data.status === 0) {
          this.loader.stop();
          this.toastr.error(data.msg);
          this.closeSupplierBtn.nativeElement.click();
         
          // this.getcompanycontarctList(this.companyids);
          // this.backclear();         
          // this.addForm.resetForm();
        }

      },
       error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeSupplierBtn.nativeElement.click();
          // this.getcompanycontarctList(this.companyids);       
          // this.backclear();        
          // this.addForm.resetForm();
        
        }
      })
    }

  }



  backclear() {

    this.getcompanycontarctList(this.companyids);    
    this.router.navigate(['/clientcontract'], { replaceUrl: false });
    this.addForm.resetForm();
    this.Edit = false;
    // location.reload();
  }


  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }

  // numberOnly(event: any) {
  //   var x = event.which || event.keyCode;
  //   if (x == 45) {
  //     this.toastr.error('Number only accepted here');
  //     return false
  //   }
  //   else {
  //     return true
  //   }
  // }

  // restrictAlphabets(evt: any) {

  //   var theEvent = evt || window.event;
  //   var key = theEvent.keyCode || theEvent.which;
  //   key = String.fromCharCode(key);
  //   if (key.length == 0) return;
  //   var regex = /^[0-9.,\b]+$/;
  //   if (!regex.test(key)) {
  //     theEvent.returnValue = false;
  //     if (theEvent.preventDefault) theEvent.preventDefault();
  //   }
  // }

  // restrictDecimals(event: any) {

  //   const inputValue = event.target.value;
  //   if (inputValue.includes('.')) {
  //     event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
  //   }
  
  // } 
  

  // ngOnDestroy(){
  //   this.tooltipList.forEach((tooltip: { dispose: () => void; })=>{
  //     tooltip.dispose();
  //   })
  // }


  pageChanged(pageNo: any) {

    this.p = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getcompanycontarctList(this.companyids);

  }

  selectdistypeEvent(event:any){

    this.AddContractCompany.discount_value = '';
  }


  
  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (keyCode === 190 || keyCode === 188) {
      event.preventDefault();
    }
  }


  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

}
