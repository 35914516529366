import { Component, ElementRef, OnInit, ViewChild,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { AppService } from './../restful-services/restful.service';
import { ChartData, ChartType } from 'chart.js';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { browserRefresh } from './../app.component';
import { Supplier } from './../models/supplierList';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RequestCompany } from '../models/RequestCompany';
import { company } from '../models/company';
declare var bootstrap: any;


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})


export class CompanyComponent implements OnInit {

  public startOptions: INgxMyDpOptions = {};
  public endOptions: INgxMyDpOptions = {};
  @ViewChild('closebrandBtn', { static: true }) closebrandBtn!: ElementRef;



  selectinternal: string = 'Internal';
  selectexternal: string = 'External';
  Internal: any;
  selectedValue: any;
  public supplierName: string = '';
  public status: string = '';
  supplierDetail: Supplier;
  supplierList: Supplier[];
  companyList: RequestCompany[];
  clientList:any = [];
  limit: number = 25;
  supplierType: any;
  skip: number = 0;
  c: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  @ViewChild('closeSupplierBtn', { static: true }) closeSupplierBtn!: ElementRef;
  role: any;
  userDetails: any;
  @ViewChild('inputFile') inputFile: any;
  supplierId: any;
  supplierStatus: any;
  duplicateSupplier: boolean = false;
  user_type:any;
  
  public external="external";
  private headers:any={};
  viewid:any;
  apiLoading: boolean = false;
 
  public company: string = "";
  @ViewChild('addForm', { static: true }) addForm: any;
  endDate: any;
  Edit: Boolean;
  IssuanceDate: any;
  ExpiryDate: any;
  getstartDateOptions: any;
  send_date = new Date();
  formattedDate: any;
  issuanceDate: any;
  startDate: any;
  getExpiryDate: any;
  public getstartDate: any;
  public getendDate: any;
  AddContractCompany: RequestCompany;
  idvalue: any;

  companyName: any;
  kart_type: any;
  discount_type: any;
  issuance_fee: any;
  payment_status: any;
  isEditing: boolean = false;
  paramValue: any;
  clientDetails: any;
  source: any;
  userId: any;
  walletId: any;

  constructor(private route: ActivatedRoute,private datePipe: DatePipe, private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.supplierDetail = new Supplier();
    this.supplierList = new Array<Supplier>();
    this.companyList = new Array<RequestCompany>();
    this.AddContractCompany = new RequestCompany();
    this.clientList = new Array<company>();


    this.startDate = new Date();
    this.endDate = new Date();

    this.Edit = false;
    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    let tdate = new Date();
    this.getendDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };










  }

  ngOnInit() {

    this.role = localStorage.getItem('role');    
    let user = localStorage.getItem("userDetails");
    console.log("role",this.role)
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
      this.userId = parse.data.UserID;
      console.log("user",this.userDetails)
      
    }
    this.getcompany();

  }

 

  deletebrand() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.deleteClient(this.viewid, this.headers).subscribe(
      (data: any) => {

        this.loader.stop();
        this.closebrandBtn.nativeElement.click();

        if (data.status == 1) {
          this.toastr.success("Client deleted successfully");
          this.getcompany();

        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);

        }
      })
  }

  statusChange(id: any, status: any) {
    let requestParams = {
      'id': id,
      'status': status
    }
  
    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

    this.loader.start();
    this._http.clientStatus(requestParams,this.headers).subscribe((data: any) => {
      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Status Updated Successfully");
        this.getcompany();
      }
      else if (data.status ===0) {
        this.toastr.success("Something Went Wrong");
      }
      else {
        this.toastr.error(data.msg);
      }
    })
  }



  AddModal(id: number) {

    // let ids = id;
    this.idvalue = id;
    
    this.startDate = new Date();
    this.endDate = new Date();

    this.Edit = false;
    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    let tdate = new Date();
    this.getendDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };


    const element = document.getElementById('AddModal') as HTMLElement;
    const AddModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    AddModal.show();
  }



  AddContract(AddContractCompany: RequestCompany) {

    if (
      this.AddContractCompany.discount_type == "" || this.AddContractCompany.discount_type == undefined || this.AddContractCompany.discount_value == "" || this.AddContractCompany.discount_value == undefined || this.AddContractCompany.payment == "" || this.AddContractCompany.payment == undefined ||
      this.AddContractCompany.issuance_fee == "" || this.AddContractCompany.issuance_fee == undefined ||
      this.startDate == "" || this.startDate == undefined || this.startDate == null ||
      this.endDate == "" || this.endDate == undefined || this.endDate == null
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    if (this.AddContractCompany.discount_value === '0' || this.AddContractCompany.discount_value === 0

    ) {
      this.toastr.error("Please enter discount value greater than 0");
      return;
    }

    if (this.AddContractCompany.issuance_fee === '0' || this.AddContractCompany.issuance_fee === 0

    ) {
      this.toastr.error("Please enter issuance fee value greater than 0");
      return;
    }

    if (!/^[0-9]+$/.test(this.AddContractCompany.discount_value)) {
   
      this.toastr.error("Please enter discount value in numbers");
      return;
    }  

    if (!/^[0-9]+$/.test(this.AddContractCompany.issuance_fee)) {
   
      this.toastr.error("Please enter issuance fee in numbers");
      return;
    }  

    if (this.startDate > this.endDate) {   

      this.toastr.error("End date cannot be earlier than the start date. Please choose a correct date.");
      return;
    }  


    const { id } = history.state;

    let postparams = {

      discount_type: this.AddContractCompany.discount_type,
      discount_value: this.AddContractCompany.discount_value,
      payment_type: this.AddContractCompany.payment,
      start_date: this.startDate,
      end_date: this.endDate,
      company_id: this.idvalue,
      action: this.Edit ? 'UPDATE' : "CREATE",
      issuance_fee: this.AddContractCompany.issuance_fee,
      id: this.Edit ? this.AddContractCompany.id : null

    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    if (this.Edit) {

      this._http.updatecontractor(postparams, this.headers).subscribe({
        next: (data: any) => {
        // this.loader.stop()
        if (data.status == 1) {

          this.closeSupplierBtn.nativeElement.click();
          this.toastr.success("Contract Updated successfully");
          this.Edit = false;
          this.getcompany();
          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();
          this.addForm.resetForm();
        }
        else if (data.status === 0) {
          this.closeSupplierBtn.nativeElement.click();
          this.toastr.error(data.msg);
          this.Edit = false;
          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();
          this.addForm.resetForm();
        }
      },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.Edit = false;
          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();
          this.addForm.resetForm();
          this.closeSupplierBtn.nativeElement.click();

        }
      })
       
    }

    else {
    
      this._http.addNewcontractor(postparams, this.headers).subscribe({
        next: (data: any) => {
        if (data.status == 1) {
          this.closeSupplierBtn.nativeElement.click();
          this.toastr.success("Contract Added successfully");

          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();
          this.addForm.resetForm();
          this.getcompany();
        }

        else if (data.status === 0) {

          this.toastr.error(data.msg);
          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();

        }

      },
       error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          const element = document.getElementById('AddModal') as HTMLElement;
          const AddModal = new Modal(element,
            {
              backdrop: 'static',
              keyboard: false
            });
          AddModal.hide();

        }
      })
    }

  }

  // numberOnly(event: any) {
  //   var x = event.which || event.keyCode;
  //   if (x == 45) {
  //     this.toastr.error('Number only accepted here')
  //     return false
  //   }
  //   else {
  //     return true
  //   }
  // }

  // restrictAlphabets(evt: any) {
  //   var theEvent = evt || window.event;
  //   var key = theEvent.keyCode || theEvent.which;
  //   key = String.fromCharCode(key);
  //   if (key.length == 0) return;
  //   var regex = /^[0-9.,\b]+$/;
  //   if (!regex.test(key)) {
  //     theEvent.returnValue = false;
  //     if (theEvent.preventDefault) theEvent.preventDefault();
  //   }
  // }

  

  // keyDownHandler(event: any) {

  //   if (event.code === 'Space' && event.which === 32) {
  //     event.preventDefault();
  //   }

  // }


  // restrictDecimals(event: any) {
  //   const inputValue = event.target.value;
  //   if (inputValue.includes('.')) {
  //     event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
  //   }
  // }


  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  refreshModal(list: any) {

    this.walletId = list.id;
    const element = document.getElementById('refreshModal') as HTMLElement;
    const refreshModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
      refreshModal.show();
  } 
  
  walletRefresh(){
    
    
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      this._http.getWalletRefresh(this.userId,this.role,this.walletId,this.headers).subscribe({
        next: (data: any) => {                       
          console.log("rrrrr",data);
          this.getcompany();
        },
        error: _error => {
          this.apiLoading = false;
          // this.loader.stop();
        }
      })
      // this.service.clientDetailswallet({
      //   clientwallet: this.getWalletData.wallet,
      // })
    }

    

  

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (keyCode === 190 || keyCode === 188) {
      event.preventDefault();
    }
  }


  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  backclear() {    
    
    this.router.navigate(['/company'], { replaceUrl: false });
    this.addForm.resetForm();
    // this.getcompany();
    // location.reload();

  } 


  setDateOptions() {

    let a = new Date(this.AddContractCompany.end_date);
    a.setDate(a.getDate() - 1);
    a = new Date(a.getFullYear(), a.getMonth(), a.getDate());
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: a.getFullYear(), month: a.getMonth() + 1, day: a.getDate() },
    };

    let b = new Date(this.AddContractCompany.start_date);
    b.setDate(b.getDate());
    b = new Date(b.getFullYear(), b.getMonth(), b.getDate());
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: b.getFullYear(), month: b.getMonth() + 1, day: b.getDate() },
    };
  }


  onStartDateChanged(event: IMyDateModel): void {

    this.startDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    let dp = new Date(this.startDate);
    // let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1));
    dp.setDate(dp.getDate() - 2);
    dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate())
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
    };
    this.endDate = '';
    this.getendDate = '';
  }


  onEndDateChanged(event: IMyDateModel): void {

    this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    // this.ExpiryDate = this.endDate;
    let d: any = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate())
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    }; 

  }

  getcompanyfilter() {

    
    this.supplierType = "external";

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this.apiLoading = true;
  
    this._http.getCompanyList(this.company, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;
        this.count = data.count;
        if(this.count != 0){
          this.viewid = data.data[0].id;
        }
       

        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
      

        if (data) {
          this.count = data.count;
          this.companyList = data.data;
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  getcompany() {

    // this.loader.start();
    // this.supplierType = "external";

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();
    this._http.getCompanyList(this.company, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;
        this.count = data.count;
        if(this.count != 0){
          this.viewid = data.data[0].id;
        }
        
        

        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
      

        if (data) {
          this.count = data.count;
          this.companyList = data.data;
          // this.walletId = data.data.id;

        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  companySync() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.companySync(this.headers).subscribe((data: any) => {

      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Company sync successfully");
      }
    })
  }

  pageChanged(pageNo: any) {

    this.c = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getcompany();
  }



  type(companyList: RequestCompany, status: any,) {

    if (status == 1 || status == "1") {
      this.supplierType = "External"

    }
    else if (status == 0 || status == "0") {
      this.supplierType = "Internal"

    }
    else {
      this.toastr.error();
    }

    const params = {

      id: companyList.CustomerID,
      action: 'company',
      status: status,
      type: this.supplierType
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this._http.updateSupplier(params, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();

        if ((data.status == 1 && status == 1) || status == "1") {
          this.supplierType = "External";
          this.getcompany();

        }
        else if ((data.status == 1 && status == 0) || status == "0") {
          this.supplierType = "Internal";
          this.getcompany();
        }

      },

    });
  }



  viewcontract(id: number, companyname: string) {

    this.router.navigate(['/clientcontract'], { state: { id, companyname }, replaceUrl: false });

  }


  filterList() {
    if (this.company) {
      this.skip = 0;
      this.c = 1;
    }
    this.getcompanyfilter();
  }


  clear() {

    this.company = '';
    this.companyList = [];
    this.getcompany();

    this.c = 1;
    let page = this.c;
    this.pageChanged(page);   

  }


  selectdistypeEvent(event: any) {

    this.AddContractCompany.discount_value = '';
  }
  addCompany(){
    this.router.navigate(['/company-edit'], { replaceUrl: false })
  }

  productMap(clientList: RequestCompany){
    this._http.clientDetails({
      clientName:clientList.CompanyName,
    })
    this._http.companyDetails(clientList);
    const id = clientList.id;
    this.router.navigate(['/client-product-mapping'], {
      queryParams:{"id":id},
      state: { id },
      replaceUrl: true
    });  }

    wallet(clientList: RequestCompany){
      //console.log(clientList,"data of client");
      this._http.clientDetails({
        clientName:clientList.CompanyName,
      })
      this._http.clientDetailswallet({
        availablewallet:clientList.wallet,
      })
      
      this._http.companyDetails(clientList);
      const id = clientList.id;
      this.router.navigate(['/wallet-threshold'], {
        queryParams:{"id":id},
        state: { id },
        replaceUrl: true
      });  }


  
  editCompany(clientList: RequestCompany) {

    this._http.companyDetails(clientList);
    const id = clientList.id;
    this.router.navigate(['/company-edit'], {
      queryParams:{"id":id},
      state: { id },
      replaceUrl: true
    });
  }

}



