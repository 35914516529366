import { Component, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/restful-services/restful.service';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { RequestDeals } from 'src/app/models/RequestDeal';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AnimateTimings } from '@angular/animations';
import { last, min } from 'rxjs';

import { Directive, ElementRef, HostListener } from '@angular/core';
import { identifierName } from '@angular/compiler';

export const noFullStopRegex = /^[^.]*$/;



@Component({
  selector: 'app-deal-kart-edit',
  templateUrl: './deal-kart-edit.component.html',
  styleUrls: ['./deal-kart-edit.component.scss']
})


export class DealKartEditComponent implements OnDestroy {

  public string: '[restrictChars]' | undefined

  public startOptions: INgxMyDpOptions = {};
  public issuanceOptions: INgxMyDpOptions = {};
  public ExpiryOptions: INgxMyDpOptions = {};


  d: Date = new Date();
  optionsStatus = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
  categoryOption = [{ label: 'Online', value: 0 }, { label: 'Offline', value: 1 }]
  source: any = "";
  type: any;
  modal: any;
  brandName: any = "";
  category: any;
  denomination: any;
  payment_status: any = "";
  quantity: any;
  validity: any;
  vouchergeneratedStatus: any;
  company: any;
  requestCouponDeal: RequestDeals;
  isEdit: boolean = false;
  userDetails: any;
  voucherId: any;
  supplierType: any;
  companyKeyword = 'CompanyName';
  brandKeyword = 'BrandName';
  productKeyword = "ProductName";
  companyData: any = [];
  brandData: any = [];
  QCedit: any = [];
  Amazonedit: any = [];
  sourceName: any = "";
  brandDisabled: boolean = false;
  noBrandFound: boolean = false;
  noCompanyFound: boolean = false;
  noSourceFound: boolean = false;
  endDate: any;
  issuanceDate: any;
  getstartDateOptions: any;
  public role: any;

  getIssuanceDate: any;
  getExpiryDate: any;

  startDate: any;
  ExpiryDate: any;
  IssuanceDate: any;

  public getstartDate: any;
  public getendDate: any;
  public getissuanceDate: any;

  send_date = new Date();
  formattedDate: any;

  total: any;
  comname: any;
  discount: any;
  payment_received: any;
  issuance: any;
  start: any;
  kart_type: any = "";
  issuance_fee: any;

  private headers: any = {};

  comitem: any = {};
  minparam: any = {};

  authentication_type: any = '';
  otpshow: boolean = true;
  otpDisabled: boolean = false;

  uniquesource = [];
  uniquebrands = [];

  disvalue: any;
  distype: any;
  pay: any;
  issfee: any;
  fetchDisabled: boolean = false;


  minmaxshow: boolean = false;
  isDatepickerDisabled: boolean = false;
  expiryvalueint: any;
  expiryvalue: any;
  expiryvalues: any;
  maxprice: any;
  productid: any;
  minprice: any;
  NewArray: any;
  externalproductid: any;

  totalval: boolean = false;

  sourceData: any = [];
  sourcesName: any = "";
  SourceName: any = "";
  sourceKeyword = 'SourceName';
  fetchdata: any = [];
  denomDisabled: boolean = false;
  hidebtns: boolean = true;
  hideauthfield: boolean = true;

  producthide: boolean = true;
  usagehide: boolean = false;
  productData: any = [];
  branid: any;
  brandedid: any;
  BrandName: any;

  brandidvalue: any;
  editvoucherId: any;

  totalshow: boolean = false;
  totalfacevalueshow: boolean = false;
  discountvalue: any;
  maxdiscountshow: boolean = false;
  discounttypehide: boolean = true;

  productshow: boolean = true;
  sourceid: any;

  editbrandId: any;

  
  clientchange: boolean = false;
  brandchange: boolean = false;
  productchange: boolean = false;


  constructor(private router: Router, private _http: AppService, public datePipe: DatePipe,
    private toastr: ToastrService, private loader: NgxUiLoaderService, private el: ElementRef, private ngZone: NgZone,


  ) {

    this.requestCouponDeal = new RequestDeals();

    this.companyData.forEach((CompanyName: any) => {
      if (!this.uniquesource.includes(CompanyName as never)) {
        this.uniquesource.push(CompanyName as never);
      }
    });

    this.brandData.forEach((BrandName: any) => {
      if (!this.uniquebrands.includes(BrandName as never)) {
        this.uniquebrands.push(BrandName as never);
      }
    });

    let tdate = new Date();
    this.getIssuanceDate = { date: { year: tdate.getFullYear(), month: tdate.getMonth() + 1, day: tdate.getDate() } };

    let sdate = new Date();
    this.getstartDate = { date: { year: sdate.getFullYear(), month: sdate.getMonth() + 1, day: sdate.getDate() } };

    this.startDate = new Date();
    this.IssuanceDate = new Date();

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.issuanceOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

    this.ExpiryOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };
  }


  ngOnInit(): void {

    this.onChangeSource();

    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.role = localStorage.getItem('role');
    const { id } = history.state;

    if (id) {
      this.isEdit = true;
      this.voucherId = id;

    }

    else {

      let data = localStorage.getItem('edit');
      this.isEdit = false;

      if (data) {

        this.requestCouponDeal = JSON.parse(data);

        this.startDate = this.requestCouponDeal.start_date;
        this.issuanceDate = this.requestCouponDeal.issuance_date;

        const issuanceDate = new Date(this.requestCouponDeal.issuance_date)
        this.getissuanceDate = { date: { year: issuanceDate.getFullYear(), month: issuanceDate.getMonth() + 1, day: issuanceDate.getDate() } };

        const startDate = new Date(this.requestCouponDeal.start_date)
        this.getstartDate = { date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() } };

      }

    }


    if (this.userDetails.CompanyName !== '') {
      this.brandDisabled = true;
    }
    else {
      this.brandDisabled = false;
    }

    if (this.role === 'company') {
      this.requestCouponDeal.type = "ID";
    }
    if (this.role === 'brand') {
      this.requestCouponDeal.type = "I";
    }

    if (this.role === 'company') {

      if (this.userDetails.CompanyName) {
        this.requestCouponDeal.company_name = this.userDetails.CompanyName;
      }
      else {

        this.requestCouponDeal.company_name = this.userDetails.UserName;
      }
      this.brandDisabled = true;
      this.requestCouponDeal.type = "ID";
    }

    if (this.role === 'brand') {

      if (this.userDetails.CompanyName) {

        this.requestCouponDeal.brand_name = this.userDetails.CompanyName;
      }
      else {

        this.requestCouponDeal.brand_name = this.userDetails.UserName;
      }

      this.brandDisabled = true;
      this.requestCouponDeal.type = "ID";
    }



    if (this.isEdit) {

      // this.productshow = false;
      // this.producthide = false;

      this._http.dealDetailItem.subscribe({
        next: data => {

          if (data) {

            this.requestCouponDeal = data;     
            localStorage.setItem('edit', JSON.stringify(data));      
                        
            if (this.requestCouponDeal.usage_mode && this.requestCouponDeal.source === 'Ticket Express') { 

              this.usagehide = true;
              this.requestCouponDeal.usagemode = this.requestCouponDeal.usage_mode;                     
             
            }

              else if (this.requestCouponDeal.internal_usage_mode && this.requestCouponDeal.source  === 'voucherkart') {
           
              this.usagehide = true;
              this.requestCouponDeal.usagemode = this.requestCouponDeal.internal_usage_mode;
       
            }

            else {

              // this.denomDisabled = false;
              // this.producthide = false;
              this.usagehide = false;
              this.requestCouponDeal.usagemode = '';                
            
            }

            if (this.requestCouponDeal.source === 'voucherkart') {

              this.denomDisabled = true;
            }
            else if(this.role === 'brand'){
        
              this.denomDisabled = true;
            }
            
            else if(this.requestCouponDeal.source === 'Ticket Express'){
              this.denomDisabled = true;
            }
            else{
              this.denomDisabled = false;
            }                

            if (this.requestCouponDeal.source  === 'voucherkart') {

              // this.productshow = true;
              this.totalshow = true;
              this.totalfacevalueshow = true;            
              this.calculate();
            }
        
            else {
        
              // this.productshow = false;
              this.totalshow = false;
              this.totalfacevalueshow = false;
             
            }



            
            if (this.requestCouponDeal.product_discount && this.requestCouponDeal.source === 'Ticket Express') {

              this.discountvalue = this.requestCouponDeal.product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            }

            else if(this.requestCouponDeal.internal_product_discount && this.requestCouponDeal.source === 'voucherkart'){

              this.discountvalue = this.requestCouponDeal.internal_product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            }

            else {
              this.discountvalue = '';
              this.maxdiscountshow = false;
              this.discounttypehide = true;
            }         
       
         
            if (this.requestCouponDeal.minPrice && this.requestCouponDeal.maxPrice && this.requestCouponDeal.source === 'QwikCilver' || this.requestCouponDeal.source === 'Amazon') {

              this.minprice = this.requestCouponDeal.minPrice;
              this.maxprice = this.requestCouponDeal.maxPrice;
              this.minmaxshow = true;
            }

            else if(this.requestCouponDeal.minface_value && this.requestCouponDeal.maxface_value && this.requestCouponDeal.source === 'voucherkart' ){

              this.minmaxshow = true;
              this.minprice = this.requestCouponDeal.minface_value;
              this.maxprice = this.requestCouponDeal.maxface_value;
            }

            else {

              this.minprice = '';
              this.maxprice = '';
              this.minmaxshow = false;
            }
          

            if (this.requestCouponDeal.minPrice && this.requestCouponDeal.maxPrice && this.requestCouponDeal.source === 'QwikCilver' || this.requestCouponDeal.source === 'Amazon') {
              this.minprice = this.requestCouponDeal.minPrice;
              this.maxprice = this.requestCouponDeal.maxPrice;
              this.minmaxshow = true;
            }
            else {

              this.minprice = '';
              this.maxprice = '';
              this.minmaxshow = false;
            }

            if (this.requestCouponDeal.source === 'QwikCilver' || this.requestCouponDeal.source === 'Ticket Express' || this.requestCouponDeal.source === 'Amazon') {

              this.hideauthfield = false;
            }

            else {
              this.hideauthfield = true;
            }


            if (this.requestCouponDeal.source === 'QwikCilver' || this.requestCouponDeal.source === 'Amazon' || this.requestCouponDeal.source === 'Ticket Express'  || this.requestCouponDeal.source === 'voucherkart' || this.role === 'brand') {

              this.isDatepickerDisabled = true;

            }
            else {

              this.isDatepickerDisabled = false;
            }


            if (this.requestCouponDeal.product) {

              this.producthide = true;
         
            }

            else{
              this.producthide = false;
            }          
          

            if(this.requestCouponDeal.source === 'Ticket Express' || this.requestCouponDeal.source === 'voucherkart'){

          this.requestCouponDeal.kart_type = '0';
            }
            else{

              this.requestCouponDeal.kart_type = this.requestCouponDeal.kart_type;
            }

            this.startDate = this.requestCouponDeal.start_date;
            this.IssuanceDate = this.requestCouponDeal.issuance_date;
            this.ExpiryDate = this.requestCouponDeal.validity;

            const i = new Date(this.requestCouponDeal.issuance_date)
            this.getIssuanceDate = { date: { year: i.getFullYear(), month: i.getMonth() + 1, day: i.getDate() } };

            const s = new Date(this.requestCouponDeal.start_date)
            this.getstartDate = { date: { year: s.getFullYear(), month: s.getMonth() + 1, day: s.getDate() } };

            const d = new Date(this.requestCouponDeal.validity)
            this.getExpiryDate = { date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() } };                    

            if(this.role === 'admin')
            {
              this.brandidvalue = this.requestCouponDeal.brandId;
            }
            

          }

          else {

          }
        }
      })
    }


    else {

      let sourceval: any = localStorage.getItem("edit");
      let val = JSON.parse(sourceval);

    
  if(val != null){
    
  let sourcevalue = val.source;

  this.editvoucherId = val.voucher_request_id;
  let minvalue = val.minPrice;
  let maxvalue = val.maxPrice;
  let maxdiscount = val.product_discount;

  let internalminvalue = val.minface_value;
  let internalmaxvalue = val.maxface_value;
  let internalmaxdiscount = val.internal_product_discount;
  let kartval = val.kart_type;
  let typevalue = val.type;

  if (sourcevalue) {

    this.isEdit = true;
    this.requestCouponDeal.voucher_request_id = val.voucher_request_id;
  }

  else {
    this.isEdit = false;
    this.requestCouponDeal.voucher_request_id = val.voucher_request_id;
  }

  if (sourcevalue === 'QwikCilver' || sourcevalue === 'Ticket Express' || sourcevalue === 'Amazon') {

    this.hideauthfield = false;
  }

  else {
    this.hideauthfield = true;
  }  
 
  if (sourcevalue === 'Ticket Express') {

    // this.producthide = true;
   this.usagehide = true;
   this.requestCouponDeal.usagemode = val.usage_mode;
  }
  else if(sourcevalue === 'voucherkart'){

    // this.producthide = true;
    this.usagehide = true;
    this.requestCouponDeal.usagemode = val.internal_usage_mode;
  }

  else {
    // this.producthide = false;
     this.usagehide = false;
     this.requestCouponDeal.usagemode = '';
  }

  if (sourcevalue === 'voucherkart') {

    // this.productshow = true;
    this.totalshow = true;
    this.totalfacevalueshow = true;
    this.calculate();
 
  }

  else {

    // this.productshow = false;
    this.totalshow = false;
    this.totalfacevalueshow = false;
   
  }



  if (sourcevalue === 'QwikCilver' || sourcevalue === 'Amazon' || sourcevalue === 'Ticket Express' || sourcevalue === 'voucherkart' || this.role === 'brand') {

    this.isDatepickerDisabled = true;

  }
  else {

    this.isDatepickerDisabled = false;
  }


  if (sourcevalue === 'voucherkart') {

    this.denomDisabled = true;
  }
  else if(this.role === 'brand'){

    this.denomDisabled = true;
  }
  
  else if(sourcevalue === 'Ticket Express'){
    this.denomDisabled = true;
  }
  else{
    this.denomDisabled = false;
  }

  if (minvalue && maxvalue && sourcevalue === 'QwikCilver' || sourcevalue === 'Amazon' || sourcevalue !== 'voucherkart') {

    this.minprice = val.minPrice;
    this.maxprice = val.maxPrice;
    this.minmaxshow = true;
  }

  else if (internalminvalue && internalmaxvalue && sourcevalue === 'voucherkart') {

    this.minprice = val.minface_value;
    this.maxprice = val.maxface_value;
    this.minmaxshow = true;
  }
  
  else {

    this.minprice = '';
    this.maxprice = '';
    this.minmaxshow = false;
  }


    if(sourcevalue === 'Ticket Express' || sourcevalue === 'voucherkart'){

    this.requestCouponDeal.kart_type = '0';
      }
      else{
        this.requestCouponDeal.kart_type = val.kart_type;
      }


  if (maxdiscount && sourcevalue === 'Ticket Express') {

    this.discountvalue = val.product_discount;
    this.maxdiscountshow = true;
    this.discounttypehide = false;
  }
  else if (internalmaxdiscount && sourcevalue === 'voucherkart') {

    this.discountvalue = val.internal_product_discount;
    this.maxdiscountshow = true;
    this.discounttypehide = false;
  }
  else {
    this.discountvalue = '';
    this.maxdiscountshow = false;
    this.discounttypehide = true;
  }

}    
     
   

}

    if (this.role === 'brand') {

      // this.productshow = true;
      // this.producthide = true;
    }
  }



  cancelVoucherList() {

    this.router.navigate(['/deal-dashboard'], { replaceUrl: false });
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (keyCode === 190 || keyCode === 188) {
      event.preventDefault();
    }
  }


  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }



  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  setDateOptions() {
    let a = new Date(this.requestCouponDeal.issuance_date);
    a.setDate(a.getDate() - 1);
    a = new Date(a.getFullYear(), a.getMonth(), a.getDate());
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: a.getFullYear(), month: a.getMonth() + 1, day: a.getDate() },
    };
    let b = new Date(this.requestCouponDeal.start_date);
    b.setDate(b.getDate());
    b = new Date(b.getFullYear(), b.getMonth(), b.getDate());
    this.ExpiryOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: b.getFullYear(), month: b.getMonth() + 1, day: b.getDate() },
    };
  }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
    this.ExpiryOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
    };
    this.ExpiryDate = '';
    this.getExpiryDate = '';
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    this.ExpiryDate = this.endDate;
  }

  onIssuanceDateChanged(event: IMyDateModel): void {
    this.IssuanceDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString();
    let dp = new Date(this.IssuanceDate);
    dp.setDate(dp.getDate() - 2);
    dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate())
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
    };
    this.startDate = '';
    this.getstartDate = '';
  }


  selectModelEvent(value: any) {

    if (value === 'I') {

      this.otpshow = false;
      this.otpDisabled = true;
      this.requestCouponDeal.authentication_type = 'PIN';
      this.hidebtns = false;

    }

    else {
      this.requestCouponDeal.authentication_type = '';
      this.otpDisabled = false;
      this.otpshow = true;
      this.hidebtns = true;
    }
  }



  validityClear(event: any) {

    this.brandchange = false;

    this.productData = [];
    this.requestCouponDeal.product = '';
    this.branid = '';
    this.brandidvalue = '';
    this.requestCouponDeal.usagemode = '';
    this.requestCouponDeal.validity = '';
    this.brandName = '';

  }


  productClear(event: any) {

    this.clientchange=false;  
    
    let source: any = this.requestCouponDeal.source;

    if (source = ! 'voucherkart' && this.role === 'admin') {

      this.requestCouponDeal.kart_type = '';
      this.requestCouponDeal.discount = '';
      this.requestCouponDeal.issuance_fee = '';
      this.requestCouponDeal.payment_status = '';
      // this.requestCouponDeal.validity = '';
      this.requestCouponDeal.total = '';
      this.fetchDisabled = false;
    }

  } 


  onChangeCompany(item: any) {

    if(this.isEdit){
      this.clientchange=true;
   }    

    let source: any = this.requestCouponDeal.source;

    if (source = ! 'voucherkart' && this.role === 'admin') {

      this.requestCouponDeal.kart_type = '';
      this.requestCouponDeal.discount = '';
      this.requestCouponDeal.issuance_fee = '';
      this.requestCouponDeal.payment_status = '';
      // this.requestCouponDeal.validity = '';
      this.requestCouponDeal.total = '';
      this.fetchDisabled = false;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getCompany(item, this.headers).subscribe((data: any) => {
      this.companyData = data.data;
      if (this.companyData.length === 0) {
        this.noCompanyFound = true;
      }
      else {
        this.noCompanyFound = false;
      }
      if (this.companyData.length > 0) {
        for (var data of this.companyData) {
          if (data.CompanyName === item.CompanyName) {
            this.noCompanyFound = false;
            return;
          }
          else {
            this.noCompanyFound = true;
          }
        }
      }
    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }


  onChangeSource() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    let item = '';
    this._http.getSource(item, this.headers).subscribe((data: any) => {

      this.sourceData = data.data;      

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }




  onChangeBrand(item: any) {

    if(this.isEdit){
      this.brandchange=true;
   }

    this.productData = [];
    this.requestCouponDeal.product = '';
    this.branid = '';
    this.brandidvalue = '';
    this.requestCouponDeal.usagemode = '';
    this.requestCouponDeal.validity = '';
    this.brandName = '';

    let source: any = this.requestCouponDeal.source;

    // if (source === 'QwikCilver') {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   // const type = 'QC';
    //   // this._http.getQCproducts(item, type, this.headers).subscribe((data: any) => {

    //   let type = 'QC';

    //   this._http.getBrand(item, type, this.headers).subscribe((data: any) => {

    //     this.brandData = data.data;


    //     if (this.brandData.length === 0) {
    //       this.noBrandFound = true;
    //     }
    //     else {
    //       this.noBrandFound = false;
    //     }


    //     if (this.brandData.length > 0) {
    //       for (var data of this.brandData) {

    //         if (data.BrandName === item.brandName) {

    //           this.noBrandFound = false;
    //           return;
    //         }
    //         else {
    //           this.noBrandFound = true;
    //         }
    //       }
    //     }   
    //   },
    //     error => {
    //       this.loader.stop();
    //       this.toastr.error(error.error.Message);
    //     })

    // }

    // else if (source === 'Ticket Express') {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   let type = 'TE';

    //   this._http.getBrand(item, type, this.headers).subscribe((data: any) => {

    //     this.brandData = data.data;


    //     if (this.brandData.length === 0) {
    //       this.noBrandFound = true;
    //     }
    //     else {
    //       this.noBrandFound = false;
    //     }


    //     if (this.brandData.length > 0) {
    //       for (var data of this.brandData) {

    //         if (data.BrandName === item.brandName) {

    //           this.noBrandFound = false;
    //           return;
    //         }
    //         else {
    //           this.noBrandFound = true;
    //         }
    //       }
    //     }
    //   },
    //     error => {
    //       this.loader.stop();
    //       this.toastr.error(error.error.Message);
    //     })
    // }


    // else if (source === 'Amazon') {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   // const type = 'AMAZON';
    //   // this._http.getAmazonproducts(item, type, this.headers).subscribe((data: any) => {

    //   let type = 'AMAZON';

    //   this._http.getBrand(item, type, this.headers).subscribe((data: any) => {


    //     this.brandData = data.data;


    //     if (this.brandData.length === 0) {
    //       this.noBrandFound = true;
    //     }
    //     else {
    //       this.noBrandFound = false;
    //     }


    //     if (this.brandData.length > 0) {
    //       for (var data of this.brandData) {

    //         if (data.BrandName === item.brandName) {

    //           this.noBrandFound = false;
    //           return;
    //         }
    //         else {
    //           this.noBrandFound = true;
    //         }
    //       }
    //     }

    //   },
    //     error => {
    //       this.loader.stop();
    //       this.toastr.error(error.error.Message);
    //     })

    // }

     if (source === 'voucherkart') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      // let type = item.type;

      this._http.getbrandloads(item, this.headers).subscribe((data: any) => {

        // this.brandData = data.data;

        this.brandData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
    
        if (this.brandData.length === 0) {
          this.noBrandFound = true;
        }
        else {
          this.noBrandFound = false;
        }


        if (this.brandData.length > 0) {
          for (var data of this.brandData) {

            if (data.BrandName === item.brandName) {

              this.noBrandFound = false;
              return;
            }
            else {
              this.noBrandFound = true;
            }
          }
        }
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
  }



  restrictEnter(args: any) {
    if (args.key === "Enter" && args.keyCode == 13)
      args.preventDefault();
  }



  selectSourceEvent(item: any) {

    this.requestCouponDeal.usagemode = '';
    this.maxdiscountshow = false;
    this.brandName = '';

    this.requestCouponDeal.kart_type = '';
    this.requestCouponDeal.discount = '';
    this.requestCouponDeal.issuance_fee = '';
    this.requestCouponDeal.payment_status = '';
    this.requestCouponDeal.total = '';
    this.requestCouponDeal.totalfacevalue = '';

    if (item === 'voucherkart') {
    
      this.totalshow = true;
      this.totalfacevalueshow = true;
    
    }

    else {
  
      this.totalshow = false;
      this.totalfacevalueshow = false;
   
    }

    
    if(item != 'voucherkart'){

      this.requestCouponDeal.company_name = '';
      
    }

    if (this.role === 'admin') {
      this.requestCouponDeal.brand_name = '';
      this.brandData = [];

      this.requestCouponDeal.product = '';
      this.productData = [];

      this.requestCouponDeal.validity = '';
      this.isDatepickerDisabled = false;
      this.requestCouponDeal.denomination = '';
      this.denomDisabled = false;
      this.minmaxshow = false;
    }   

    if (item === 'Ticket Express') {

      // this.producthide = true;
      this.usagehide = true;
      this.discounttypehide = false;     
      this.requestCouponDeal.kart_type = '0';
      
    }
    else if(item === 'voucherkart'){

      this.discounttypehide = false;
      this.requestCouponDeal.kart_type = '0';
      this.usagehide = true;
    }
    else {
      // this.producthide = false;
      this.usagehide = false;
      this.discounttypehide = true;
      this.requestCouponDeal.kart_type = '';
    
    }

    if (item === 'QwikCilver' || item === 'Ticket Express' || item === 'Amazon') {

      // this.isDatepickerDisabled = true;
      this.expiryvalues = this.expiryvalue;
      this.hideauthfield = false;     

    }
    else {
      // this.isDatepickerDisabled = false;
      this.hideauthfield = true;
     
    }

    if(item){
      this.isDatepickerDisabled = true;
    }
      else{
        this.isDatepickerDisabled = false;
      }


    if(item){
      this.calculate();
    }

    // this.getfetchlist(item);
  }


  onChangeProduct(item: any) {   

    if(this.role === 'brand'){

      this.requestCouponDeal.issuance_fee = '';
      this.requestCouponDeal.denomination = '';
      this.minprice = '';
      this.maxprice = '';
      this.discountvalue = '';
      this.denomDisabled = false;
      this.maxdiscountshow = false;
      this.minmaxshow = false;
      this.usagehide = false;
    }

    if (this.isEdit) {
      this.productchange = true;
    }

    let source: any = this.requestCouponDeal.source;
 
    if(this.role === 'brand'){
      source = 'voucherkart';      
   }


    if (this.role === 'admin' && source === 'Ticket Express') {

      this.requestCouponDeal.validity = '';
      this.requestCouponDeal.denomination = '';
      this.requestCouponDeal.usagemode = '';
      this.denomDisabled = false;
    }

    //   if (source === 'QwikCilver') {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   const type = 'QC';
    //   this._http.getQCproducts(item, type, this.headers).subscribe((data: any) => {

    //   this.productData = data.data;

    //   if (data) {
    //     data.data.forEach((e: any) => {

    //       this.productData.push(e.name);

    //     })
    //   }

    // },
    //   error => {
    //     this.loader.stop();
    //     this.toastr.error(error.error.Message);
    //   })

    // }

    // else if (source === 'Amazon' && item) {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   const type = 'AMAZON';
    //   this._http.getAmazonproducts(item, type, this.headers).subscribe((data: any) => {

    //   this.productData = data.data;

    //   if (data) {
    //     data.data.forEach((e: any) => {

    //       this.productData.push(e.name);

    //     })
    //   }

    // },
    //   error => {
    //     this.loader.stop();
    //     this.toastr.error(error.error.Message);
    //   })

    // }

    // else if (source === 'Ticket Express' && (this.branid || this.brandidvalue)) {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   let brandid = this.branid || this.brandidvalue;

    //   const type = 'TE';
    //   this._http.getTEproducts(item, type, brandid, this.headers).subscribe((data: any) => {

    //     this.productData = data.data;

    //     if (data) {
    //       data.data.forEach((e: any) => {

    //         this.productData.push(e.name);

    //       })
    //     }

    //   },
    //     error => {
    //       this.loader.stop();
    //       this.toastr.error(error.error.Message);
    //     })
    // }

    if (source === 'voucherkart') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      let brandid = this.branid || this.brandidvalue || this.userDetails.UserID;   
          
      let variant = 'Deal';

      this._http.getvoucherproducts(item, brandid, variant, this.headers).subscribe((data: any) => {

        this.productData = data.data;      

        if (this.productData !== null && this.productData.length > 0) {

          this.requestCouponDeal.issuance_fee = this.productData[0].issuance_fee;
          this.requestCouponDeal.usage_mode = this.productData[0].internal_usage_mode;
          this.minprice = this.productData[0].minface_value;
          this.maxprice = this.productData[0].maxface_value;
          this.discountvalue = this.productData[0].internal_product_discount;
          this.denomDisabled = true;
          this.maxdiscountshow = true;
          this.minmaxshow = true;
          this.usagehide = true;
          this.calculate();
        }


        else {         

          this.requestCouponDeal.issuance_fee = '';
          this.minprice = '';
          this.maxprice = '';
          this.discountvalue = '';
          this.denomDisabled = false;
          this.maxdiscountshow = false;
          this.minmaxshow = false;
          this.usagehide = false;
        }




        if (data) {
          data.data.forEach((e: any) => {

            this.productData.push(e.name);

          })
        }

      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })

    }

  }




  selectCompanyEvent(item: any) {

    this.clientchange = true;  

   if(item){

      this.calculate();
    }

    let sourceval = this.requestCouponDeal.source;

    if(sourceval === 'Ticket Express' || sourceval === 'voucherkart' || this.role === 'brand'){
      this.requestCouponDeal.kart_type = '0';
    }
    else{
      this.requestCouponDeal.kart_type = '';
    }  


    this.requestCouponDeal.companyId = item.id;
    this.sourceName = item.CompanyName;
    if (this.companyData.length > 0) {
      for (var data of this.companyData) {
        if (data.CompanyName === item.CompanyName) {
          this.noCompanyFound = false;
          if (this.role === 'admin') {
            this.getfetchlist(item);
          }
          return;
        }
        else {
          this.noCompanyFound = true;
        }
      }
    }
    this.calculate();
  }


  getfetchlist(item: any) {

    let sourceval = this.requestCouponDeal.source;

    if (item && sourceval != 'voucherkart') {


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      // this.loader.start();    

      this._http.getfetchlist(item, this.headers).subscribe((data: any) => {
        // this.loader.stop();  

        this.fetchdata = data.data;

        if (this.fetchdata !== null && this.fetchdata.length > 0 && this.requestCouponDeal.company_name) {

          this.requestCouponDeal.discount = this.fetchdata[0].discount_value;
          this.requestCouponDeal.issuance_fee = this.fetchdata[0].issuance_fee;
          this.requestCouponDeal.payment_status = this.fetchdata[0].payment;
          // this.fetchDisabled = true;

          let sourceval = this.requestCouponDeal.source;
          if (sourceval !== 'Ticket Express') {
            this.requestCouponDeal.kart_type = this.fetchdata[0].discount_type;
          }

          this.calculate();
          this.selectdistypeEvent(item, true);

        } else {
          let sourceval = this.requestCouponDeal.source;
          if (sourceval === 'Ticket Express') {
            this.requestCouponDeal.kart_type = '0';
          } else {
            this.requestCouponDeal.kart_type = '';
          }

          this.requestCouponDeal.discount = '';
          this.requestCouponDeal.issuance_fee = '';
          this.requestCouponDeal.payment_status = '';
          // this.fetchDisabled = false;
        }

      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })


    }
  }



  selectBrandEvent(item: any) {

    this.brandchange = true;

    this.branid = item.BrandId;

    if(item){
        this.calculate();
    }

    this.NewArray = this.requestCouponDeal.source;

    if (this.NewArray === 'QwikCilver') {
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.expiryvalue = item.expiry;
      this.productid = item.id;
      this.externalproductid = item.id;
      this.minmaxshow = true;
      this.requestCouponDeal.validity = this.expiryvalue;
      this.isDatepickerDisabled = true;

    }

    else if (this.NewArray === 'Ticket Express') {
  
      this.expiryvalue = item.expiry;
      this.requestCouponDeal.validity = this.expiryvalue;
      this.isDatepickerDisabled = true;
      this.requestCouponDeal.denomination = item.price;
      this.denomDisabled = true;

      this.productid = item.id;
      this.externalproductid = item.id;
      // this.brandedid = item.BrandId;
      // this.requestCouponDeal.brandId = item.BrandId;
      this.requestCouponDeal.kart_type = '0';
    }

    else if(this.NewArray === 'voucherkart'){

      this.requestCouponDeal.kart_type = '0';
    }

    else if (this.NewArray === 'Amazon') {
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.expiryvalue = item.expiry;
      this.productid = item.id;
      this.externalproductid = item.id;
      this.minmaxshow = true;
      this.requestCouponDeal.validity = this.expiryvalue;
      this.isDatepickerDisabled = true;

    }

    else {

      this.maxprice = '';
      this.minprice = '';
      this.expiryvalue = '';
      this.productid = item.id;
      this.minmaxshow = false;
      this.requestCouponDeal.validity = '';
      this.isDatepickerDisabled = false;
      this.requestCouponDeal.denomination = '';
      this.denomDisabled = false;
      this.requestCouponDeal.brandId = item.BrandId;
      this.requestCouponDeal.kart_type = '';

    }


    // let sr = this.expiryvalue.split(' ');

    // // if (sr[1].toLowerCase() === 'year') {
    //   if (sr[1] === 'Year' || 'year') {

    //   this.expiryvalueint = Number(sr[0]) * 365;

    //   if (this.isEdit) {
    //     this.isDatepickerDisabled = true;
    //   }
    // }
    // else {
    //   this.expiryvalueint = sr[0];
    // }

    // if (this.isEdit) {
    //   this.isDatepickerDisabled = true;
    // }
    // else{
    //   this.isDatepickerDisabled = false;

    // }



    // this.calculate();
    this.brandName = item.BrandName;


    if (this.brandData.length > 0) {
      for (var data of this.brandData) {
        if (data.BrandName === item.BrandName) {
          this.noBrandFound = false;
          return;
        }
        else {
          this.noBrandFound = true;
        }
      }
    }
  }


  selectProductEvent(item: any) {

    this.productchange = true;

    if (this.role === 'admin' || this.role === 'brand' ) {

      let row = this.productData.filter((x: any) => x.name === item);   

      this.requestCouponDeal.usagemode = row[0].usage_mode;

      if(this.role === 'admin'){
    this.requestCouponDeal.validity = row[0].expiry;
      }
    if(row[0].price){
      this.requestCouponDeal.denomination = row[0].price;
    }

    if(row[0].face_value){
      this.requestCouponDeal.denomination = row[0].face_value;
    }
   
    if(row[0].issuance_fee){
      this.requestCouponDeal.issuance_fee = row[0].issuance_fee;
    }     

      
      if (this.requestCouponDeal.denomination) {

        this.calculate();
      }

      this.productid = row[0].id;
      this.externalproductid = item.id;
      this.discountvalue = row[0].discount;

    }  

    if (this.discountvalue) {

      this.maxdiscountshow = true;
      this.discounttypehide = false;
    }
    else {
      this.maxdiscountshow = false;
      this.discounttypehide = true;
    }

    let sourceref: any = this.requestCouponDeal.source;
    let row = this.productData.filter((x: any) => x.name === item);  

    if (sourceref === 'QwikCilver') {

      // this.isDatepickerDisabled = true;     
      this.minmaxshow = true;
      this.minprice = row[0].minPrice;
      this.maxprice = row[0].maxPrice;
      this.expiryvalue = row[0].expiry;
      this.productid = row[0].id;
      this.externalproductid = row[0].id;    
      this.requestCouponDeal.validity = this.expiryvalue;
   

    }  

    else if (sourceref === 'Amazon') {

      // this.isDatepickerDisabled = true;    
      this.minmaxshow = true;
      this.minprice = row[0].minPrice;
      this.maxprice = row[0].maxPrice;
      this.expiryvalue = row[0].expiry;
      this.productid = row[0].id;
      this.externalproductid = row[0].id;    
      this.requestCouponDeal.validity = this.expiryvalue;
    

    }

    else if (sourceref === 'Ticket Express') {

      this.requestCouponDeal.validity = row[0].expiry;    
    }

    else if (sourceref === 'voucherkart' || this.role === 'brand') {

      this.requestCouponDeal.denomination = row[0].face_value;   
      this.requestCouponDeal.validity = row[0].validity;  
    }  

    else {

      this.maxprice = '';
      this.minprice = '';
      this.expiryvalue = '';
      this.productid = item.id;
      this.minmaxshow = false;
      this.requestCouponDeal.validity = '';
      // this.isDatepickerDisabled = false;
      this.requestCouponDeal.denomination = '';
      this.denomDisabled = false;
      this.requestCouponDeal.brandId = item.BrandId;
      this.requestCouponDeal.kart_type = '';

    }

    if (sourceref === 'Ticket Express') {

      this.isDatepickerDisabled = true;
      // this.denomDisabled = true;
    }

    else if (sourceref === 'QwikCilver') {

      this.isDatepickerDisabled = true;
      
    }

    else if (sourceref === 'Amazon') {

      this.isDatepickerDisabled = true;
     
    }

    else if (sourceref === 'voucherkart' || this.role === 'brand' ) {

      this.isDatepickerDisabled = true;
     
    }

    else {
      this.isDatepickerDisabled = false;
      // this.denomDisabled = false;

    }

    if (sourceref === 'voucherkart') {

      this.denomDisabled = true;
    }
    else if(this.role === 'brand'){

      this.denomDisabled = true;
    }

    else if(sourceref === 'Ticket Express'){
      this.denomDisabled = true;
    }
    else{
      this.denomDisabled = false;
    }

    if (this.role === 'brand' || this.role === 'admin')     
    {
this.requestCouponDeal.kart_type = '0';
    }


    // else{
    //   this.requestCouponDeal.kart_type = '';
    // }

    if (this.role === 'brand') {
        
      this.totalshow = true;
      this.totalfacevalueshow = true;  
    }
    else if(sourceref === 'voucherkart'){

      this.totalshow = true;
      this.totalfacevalueshow = true; 
    }

    else {
    
      this.totalshow = false;
      this.totalfacevalueshow = false;  
     
    }

    //   let sr = this.expiryvalue.split(' ');

    // // if (sr[1].toLowerCase() === 'year') {
    //   if (sr[1] === 'Months' || 'months') {

    //   this.expiryvalueint = Number(sr[0]) * 30;

    //   if (this.isEdit) {
    //     this.isDatepickerDisabled = true;
    //   }
    // }
    // else {
    //   this.expiryvalueint = sr[0];
    // }

    // let selectproduct = this.productData.filter((a:any)=>a.usage_mode === item);
    // console.log(selectproduct)
  }



  productsClear(event: any) {

    this.productchange = true;

    let sourceref: any = this.requestCouponDeal.source;

    if (this.role === 'admin' && sourceref === 'Ticket Express') {

      this.requestCouponDeal.validity = '';
      this.requestCouponDeal.denomination = '';
      this.requestCouponDeal.usagemode = '';
      this.denomDisabled = false;

    }
    else if(this.role === 'admin' && sourceref === 'QwikCilver' || sourceref === 'Amazon'){

      this.requestCouponDeal.validity = '';
      this.minprice = '';
      this.maxprice = '';
      this.minmaxshow = false;
    }

if(this.role === 'brand'){

  this.requestCouponDeal.issuance_fee = '';
  this.requestCouponDeal.denomination = '';
  this.minprice = '';
  this.maxprice = '';
  this.discountvalue = '';
  this.denomDisabled = false;
  this.maxdiscountshow = false;
  this.minmaxshow = false;
  this.usagehide = false;
}
  } 



  RequestDeal(requestCouponDeal: RequestDeals) {


    let source: any = this.requestCouponDeal.source;

    //client manual input validate
    
    let comapanyval = this.companyData;

    if(this.clientchange){

      let new_company='';

      if(typeof this.requestCouponDeal.company_name ==='object')
      {
        const selectedCompany:any=this.requestCouponDeal.company_name
        new_company=selectedCompany.CompanyName
      }
      else{
        new_company=this.requestCouponDeal.company_name
      }
      // console.log('this.requestCouponDeal.company_name',this.requestCouponDeal.company_name)
      const companyfilter=comapanyval.filter((val:any)=>val.CompanyName===new_company)
      // console.log('fdfdffarrray',companyfilter)
      if(companyfilter.length === 0){
  
        this.toastr.error("Please enter valid client");
        return;
  
      }

    } 

    else if(!this.isEdit && !this.clientchange){
      this.toastr.error("Please enter valid client");
      return;
    }
  


    //brand manual input validate

    // let brandval = this.brandData;   

    // if(this.brandchange){

    //   if((source !== 'QwikCilver'&& source !== 'Amazon') && brandval.length === 0){

    //     this.toastr.error("Please enter valid brand");
    //     return;
  
    //   } 
    // }

    // else if(!this.isEdit && !this.brandchange){
    //   this.toastr.error("Please enter valid brand");
    //   return;
    // }

    if (this.role === 'admin') {

      let brandval = this.brandData;

      // console.log('1',brandval)
      // console.log('2',this.requestCouponDeal.brand_name)

      let new_brand = '';
      
      if(typeof this.requestCouponDeal.brand_name ==='object')
      {
        const selectedbrand:any = this.requestCouponDeal.brand_name;
        new_brand = selectedbrand.BrandName;
      }
      else{
        new_brand = this.requestCouponDeal.brand_name;
      }

      // console.log('3',new_brand)
      const brandfilter = brandval.filter((val:any) => val.BrandName === new_brand);

      // console.log('4',this.brandchange,brandfilter)

      if (this.brandchange) {

        if (brandfilter.length === 0) {

          this.toastr.error("Please enter valid brand");
          return;
        }    
      }

      else if (!this.isEdit && !this.brandchange) {

        // this.toastr.error(`Please enter valid ${(source === 'Amazon' || source === 'QwikCilver') ? "Product" : "Brand"}`);
        // return;
        this.toastr.error("Please enter valid brand");
        return;
      }
    }



     //product manual input validate

    let productval = this.productData;   

    // console.log(productval)

    if(this.productchange){

      let new_product='';
      
      new_product = this.requestCouponDeal.product;

      // console.log(new_product)

      const productfilter = productval.filter((val:any) => val.name === new_product);

      // console.log(productfilter)

      if(productfilter.length === 0){

        this.toastr.error("Please enter valid product");
        return;
  
      } 
    }

    else if(!this.isEdit && !this.productchange){
      this.toastr.error("Please enter valid product");
      return;
    }     


    this.requestCouponDeal.start_date = this.startDate;
    this.requestCouponDeal.issuance_date = this.IssuanceDate;
    this.requestCouponDeal.user_name = this.userDetails.Name;
    this.requestCouponDeal.denomination = this.requestCouponDeal.denomination;
    this.requestCouponDeal.payment_status = this.requestCouponDeal.payment_status;

    this.requestCouponDeal.total = this.requestCouponDeal.total;
    this.requestCouponDeal.discount = this.requestCouponDeal.discount;
    this.requestCouponDeal.payment_received = this.requestCouponDeal.payment_received;
    this.requestCouponDeal.category = this.requestCouponDeal.category;

    this.requestCouponDeal.product_id = this.externalproductid || this.productid;
    this.requestCouponDeal.brandId = this.branid || this.externalproductid || this.productid;

    this.requestCouponDeal.source = this.requestCouponDeal.source;

    if (this.brandName !== null && this.brandName !== "") {
      this.requestCouponDeal.brand_name = this.brandName;
      // this.requestCouponDeal.brand_name = this.BrandName;
    }


    if (this.sourceName !== null && this.sourceName !== "") {
      this.requestCouponDeal.company_name = this.sourceName;
    }

    if (this.role === 'admin') {

      if (!this.requestCouponDeal.type || this.requestCouponDeal.payment_status === "" || this.requestCouponDeal.payment_status === null ||
        this.requestCouponDeal.discount === "" || this.requestCouponDeal.discount === undefined || this.requestCouponDeal.issuance_fee === "" || this.requestCouponDeal.issuance_fee === undefined || this.requestCouponDeal.source === "" || this.requestCouponDeal.source === null || this.requestCouponDeal.brand_name === "" || this.requestCouponDeal.quantity === "" || requestCouponDeal.quantity === undefined || this.requestCouponDeal.validity === "" || this.requestCouponDeal.validity === undefined
        || this.requestCouponDeal.issuance_date === "" || this.requestCouponDeal.start_date === "" || this.requestCouponDeal.denomination === "" || this.requestCouponDeal.denomination === undefined ||
        /^ *$/.test(this.requestCouponDeal.source) ||
        /^ *$/.test(this.requestCouponDeal.brand_name)) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }

    }

    else if (this.role === 'company') {


      if (this.requestCouponDeal.payment_status === "" || this.requestCouponDeal.payment_status === null ||
        this.requestCouponDeal.discount === "" || this.requestCouponDeal.discount === undefined || this.requestCouponDeal.issuance_fee === "" || this.requestCouponDeal.issuance_fee === undefined || this.requestCouponDeal.brand_name === "" || this.requestCouponDeal.quantity === "" || requestCouponDeal.quantity === undefined || this.requestCouponDeal.validity === "" || this.requestCouponDeal.validity === undefined
        || this.requestCouponDeal.issuance_date === "" || this.requestCouponDeal.start_date === "" || this.requestCouponDeal.denomination === "" || this.requestCouponDeal.denomination === undefined ||
        /^ *$/.test(this.requestCouponDeal.brand_name)) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    else if (this.role === 'brand') {

      if (this.requestCouponDeal.type === '' || this.requestCouponDeal.type === null || this.requestCouponDeal.payment_status === '' || this.requestCouponDeal.payment_status === null ||
        this.requestCouponDeal.discount === '' ||
        this.requestCouponDeal.discount === null || this.requestCouponDeal.issuance_fee === '' || this.requestCouponDeal.issuance_fee === null || this.requestCouponDeal.quantity === '' || !this.requestCouponDeal.quantity === null || this.requestCouponDeal.validity === '' || this.requestCouponDeal.validity === null
        || this.requestCouponDeal.issuance_date === '' || this.requestCouponDeal.issuance_date === null || this.requestCouponDeal.start_date === '' || this.requestCouponDeal.start_date === null || this.requestCouponDeal.denomination === '' || this.requestCouponDeal.denomination === null) {

        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }


    // else {


    //   if (!this.requestCouponDeal.product || !this.requestCouponDeal.type || this.requestCouponDeal.payment_status === "" || this.requestCouponDeal.payment_status === null || requestCouponDeal.company_name === "" || requestCouponDeal.company_name === null ||
    //     this.requestCouponDeal.discount === "" || this.requestCouponDeal.discount === undefined || this.requestCouponDeal.issuance_fee === "" || this.requestCouponDeal.issuance_fee === undefined || this.requestCouponDeal.quantity === "" || requestCouponDeal.quantity === undefined || this.requestCouponDeal.validity === "" || this.requestCouponDeal.validity === undefined
    //     || this.requestCouponDeal.issuance_date === "" || this.requestCouponDeal.start_date === "" || this.requestCouponDeal.denomination === "" || this.requestCouponDeal.denomination === undefined) {
    //     this.toastr.error("Please fill all the mandatory fields");
    //     return;
    //   }
    // }


    // if (!this.discountvalue) {

    //   if (!this.requestCouponDeal.kart_type) {

    //     this.toastr.error("Please fill all the mandatory fields");
    //     return;
    //   }

    // }


    let sourceref: any = this.requestCouponDeal.source;

    if (sourceref !== 'QwikCilver' && sourceref !== 'Ticket Express' && sourceref !== 'Amazon') {

      if (!this.requestCouponDeal.authentication_type) {

        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }


    let sourceval: any = this.requestCouponDeal.source;

    if (sourceval !== 'Ticket Express') {

      if (!this.requestCouponDeal.kart_type) {

        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }


    if (sourceref === 'QwikCilver' || sourceref === 'Ticket Express' || sourceref === 'Amazon') {


      this.requestCouponDeal.authentication_type = null;
    }
    else {

      this.requestCouponDeal.authentication_type = this.requestCouponDeal.authentication_type;
    }


    if (

      this.discount && this.discount.includes(',') || this.discount && this.discount.includes('.') ||
      this.quantity && this.quantity.includes(',') || this.quantity && this.quantity.includes('.') ||
      this.denomination && this.denomination.includes(',') || this.denomination && this.denomination.includes('.') ||
      this.issuance_fee && this.issuance_fee.includes(',') || this.issuance_fee && this.issuance_fee.includes('.')

    ) {

      this.toastr.error("Input contains invalid characters");
      return;
    }


    if (this.role === 'admin') {

      let selectsource = this.sourceData.filter((e: any) => e.supplier_name === this.requestCouponDeal.source);

      if (selectsource.length === 0) {

        this.toastr.error("Please select source");
        return;
      }

      let sourceid = selectsource[0].supplier_id;
      // this.sourceid = sourceid;
   
      this.requestCouponDeal.source_id = this.role === 'admin' ? sourceid : null;
    } else {
      this.requestCouponDeal.source_id = null;
    }



    // if(this.role === 'brand'){

    //   let selectsource = this.sourceData.filter((e: any) => e.supplier_name === this.requestCouponDeal.source);   

    //   let sourceid = selectsource[0].supplier_id;
    //   this.requestCouponDeal.source_id = sourceid;

    // }

    if (this.requestCouponDeal.validity === '0' || this.requestCouponDeal.validity === 0 || /^0*$/.test(this.requestCouponDeal.validity)) {
      this.toastr.error("Please enter validity greater than 0");
      return;
    }

    // if (this.requestCouponDeal.discount === '0' || this.requestCouponDeal.discount === 0 || /^0*$/.test(this.requestCouponDeal.discount)) {
    //   this.toastr.error("Please enter discount value greater than 0");
    //   return;
    // }

    if (this.requestCouponDeal.quantity === 0 || this.requestCouponDeal.quantity === '0' || /^0*$/.test(this.requestCouponDeal.quantity)) {
      this.toastr.error("Please enter quantity value greater than 0");
      return;
    }

    if (this.requestCouponDeal.denomination === '0' || this.requestCouponDeal.denomination === 0 || /^0*$/.test(this.requestCouponDeal.denomination)) {
      this.toastr.error("Please enter denomination value greater than 0");
      return;
    }


    if (this.requestCouponDeal.source != 'Ticket Express') {

      if (this.minprice && this.minprice > this.requestCouponDeal.denomination) {
        this.toastr.error("Please enter correct denomination min values");
        return;

      }

      if (this.maxprice && this.maxprice < this.requestCouponDeal.denomination) {
        this.toastr.error("Please enter correct denomination max values");
        return;

      }
    }


    if (this.role === 'brand') {

      if (this.minprice && this.minprice > this.requestCouponDeal.denomination) {
        this.toastr.error("Please enter correct denomination min values");
        return;

      }

      if (this.maxprice && this.maxprice < this.requestCouponDeal.denomination) {
        this.toastr.error("Please enter correct denomination max values");
        return;

      }
    }


    if(this.requestCouponDeal.source === 'voucherkart'){

      if (!/^[0-9]+$/.test(this.requestCouponDeal.validity)) {
   
        this.toastr.error("Please enter validity in numbers");
        return;
      } 
    }
   
    if (!/^[0-9]+$/.test(this.requestCouponDeal.quantity)) {
   
      this.toastr.error("Please enter quantity in numbers");
      return;
    }  

    if (!/^[0-9]+$/.test(this.requestCouponDeal.denomination)) {
   
      this.toastr.error("Please enter denomination in numbers");
      return;
    }  

    if (!/^[0-9]+$/.test(this.requestCouponDeal.discount)) {
   
      this.toastr.error("Please enter discount in numbers");
      return;
    }  

    if (!/^[0-9]+$/.test(this.requestCouponDeal.issuance_fee)) {
   
      this.toastr.error("Please enter issuance fee in numbers");
      return;
    }  



    if (this.expiryvalueint && this.expiryvalueint < this.requestCouponDeal.validity
      && requestCouponDeal.source !== 'QwikCilver') {
      this.toastr.error("Please enter correct validity value");
      return;

    }

    if (this.expiryvalueint && this.expiryvalueint < this.requestCouponDeal.validity
      && requestCouponDeal.source !== 'Ticket Express') {
      this.toastr.error("Please enter correct validity value");
      return;

    }

    if (this.expiryvalueint && this.expiryvalueint < this.requestCouponDeal.validity
      && requestCouponDeal.source !== 'Amazon') {
      this.toastr.error("Please enter correct validity value");
      return;

    }


    if(this.requestCouponDeal.source === 'Ticket Express' || this.requestCouponDeal.source === 'voucherkart'){

      if (this.discountvalue < this.requestCouponDeal.discount) {
    
        this.toastr.error("Please enter valid discount");
        return;
      }
    }  


    if (this.role === 'brand') {

      if (this.discountvalue < this.requestCouponDeal.discount) {

        this.toastr.error("Please enter valid discount");
        return;
      }
    }



    if (this.discountvalue) {

      this.requestCouponDeal.kart_type = "0";
      this.requestCouponDeal.brand_total = this.requestCouponDeal.totalfacevalue;

      let discountpercent = Number(this.discountvalue) * ((75) / 100);    

      if (this.requestCouponDeal.discount >= discountpercent) {

        this.requestCouponDeal.voucher_approve_admin = 'YES';
      }
      else {
        this.requestCouponDeal.voucher_approve_admin = 'NO';
      }
    }

    else {

      this.requestCouponDeal.kart_type = this.requestCouponDeal.kart_type;
    }


    let dat: any = this.requestCouponDeal.source;

    if (dat !== 'QwikCilver' && dat !== 'Ticket Express' && dat !== 'Amazon') {

      this.send_date = new Date(this.startDate);

      this.send_date.setDate(this.send_date.getDate() + (Number(this.requestCouponDeal.validity)));
      this.formattedDate = this.send_date.toISOString().slice(0, -1);

      this.requestCouponDeal.validity = this.formattedDate;

    }

    this.requestCouponDeal.created_by_role = this.userDetails.role;

    if (this.isEdit) {
      this.requestCouponDeal.action = "UPDATE";
      this.requestCouponDeal.voucher_request_id = this.voucherId || this.editvoucherId;
      this.requestCouponDeal.modified_by = this.userDetails.UserID;
      this.requestCouponDeal.modiedfy_by_name = this.userDetails.UserName;
      this.requestCouponDeal.modifieddate = new Date();
    }
    else {
      this.requestCouponDeal.action = "CREATE";
      this.requestCouponDeal.created_by = this.userDetails.UserID;
      this.requestCouponDeal.status = "Y";
      this.requestCouponDeal.created_by_name = this.userDetails.UserName;
      delete this.requestCouponDeal.modified_by;
    }


    this.requestCouponDeal.voucher_type = 'deal';

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };  

    this.loader.start();
    this._http.voucherRequest(this.requestCouponDeal,this.headers).subscribe((data: any) => {
      this.loader.stop();

      if (data.msg == "Success") {
        this.toastr.success("Voucher request send successfully");
        this.cancelVoucherList();
        this.router.navigate(['/deal-dashboard'], { replaceUrl: false });
      }
    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }




  showSupplierModal() {
    const element = document.getElementById('myModal') as HTMLElement;
    const myModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    myModal.show();
  }


  importUserData(event: any) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.xlsx' && getImg.toLowerCase() !== '.xls' && getImg.toLowerCase() !== '.csv')) {
      // this.showImageError = true;
      return;
    } else {
      // this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e: any) => {
        let binaryDara = e.target.result;
        let workbook = XLSX.read(binaryDara, { type: 'binary' });
        // workbook.SheetNames.forEach(sheet=>{
        //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        //   console.log(this.batchData)
        // })
      }
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      // this.loader.start();
      this._http.importUserDataVoucher(formData, this.headers).subscribe((data: any) => {
        this.loader.stop();
        if (data) {
          this.toastr.success("Your file uploaded successfully");
          // this.upldSucess = true;
          // const element = document.getElementById('myModal') as HTMLElement;
          // const myModal = new Modal(element,
          //   {backdrop: 'static',
          //   keyboard: false});
          // myModal.show();
        }
      }, _error => {
        this.loader.stop();
      });

    }
  }


  restrictspecialchars(event: KeyboardEvent): boolean {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }


  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('edit');

  }


  selectdistypeEvent(item: any, type: boolean) {

    if (!type) this.requestCouponDeal.discount = '';

    let self = this
    setTimeout(() => {

      let { discount = 0, quantity = 0, denomination = 0, issuance_fee = 0, kart_type } = self.requestCouponDeal;

      //  console.log('discount', discount, 'quantity', quantity, 'denomination', denomination, 'issuance_fee', issuance_fee, 'kart_type', kart_type);

       if(!issuance_fee){
        issuance_fee = '0';
      }      

      if (kart_type == '0') {

        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (finalval <= 0 && kart_type && discount && quantity && denomination && issuance_fee) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        }
        else {
          this.totalval = false;
        }

        if (denomination && quantity) {

          this.requestCouponDeal.totalfacevalue = qdvalue;
        }
        else {
          this.requestCouponDeal.totalfacevalue = '';
        }
       
        if (!isNaN(denomination) && !isNaN(quantity) && !isNaN(issuance_fee) && !isNaN(discount)) {
          this.requestCouponDeal.total = finalval;
        }
        else {
          this.requestCouponDeal.total = '';
        }

      } 
      else if (kart_type == '1')  {

        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountvalue = qdvalue - parseInt(discount || 0);
        let finalvalue = discountvalue + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (finalval <= 0 && kart_type && discount && quantity && denomination && issuance_fee) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {

          this.requestCouponDeal.totalfacevalue = qdvalue;
        }
        else {
          this.requestCouponDeal.totalfacevalue = '';
        }
    
        if (!isNaN(denomination) && !isNaN(quantity) && !isNaN(issuance_fee) && !isNaN(discount)) {
          this.requestCouponDeal.total = finalval;
        }
        else {
          this.requestCouponDeal.total = '';
        }
      }

    }, 200);


  }

  calculate() {

    let self = this
    setTimeout(() => {

      let { discount = 0, quantity = 0, denomination = 0, issuance_fee = 0, kart_type } = self.requestCouponDeal;

        // console.log('discount', discount, 'quantity', quantity, 'denomination', denomination, 'issuance_fee', issuance_fee, 'kart_type', kart_type);

        if(!issuance_fee){
          issuance_fee = '0';
        }      

      if (kart_type == '0') {

        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (finalval <= 0 && kart_type && discount && quantity && denomination && issuance_fee) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {

          this.requestCouponDeal.totalfacevalue = qdvalue;
        }
        else {
          this.requestCouponDeal.totalfacevalue = '';
        }

  
        if (!isNaN(denomination) && !isNaN(quantity) && !isNaN(issuance_fee) && !isNaN(discount)) {
          this.requestCouponDeal.total = finalval;
        }
        else {
          this.requestCouponDeal.total = '';
        }

      }

      else if (kart_type == '1') {

        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountvalue = qdvalue - parseInt(discount || 0);
        let finalvalue = discountvalue + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (finalval <= 0 && kart_type && discount && quantity && denomination && issuance_fee) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        }
        else {
          this.totalval = false;
        }

        if (denomination && quantity) {

          this.requestCouponDeal.totalfacevalue = qdvalue;
        }
        else {
          this.requestCouponDeal.totalfacevalue = '';
        }
      
        if (!isNaN(denomination) && !isNaN(quantity) && !isNaN(issuance_fee) && !isNaN(discount)) {
          this.requestCouponDeal.total = finalval;
        }
        else {
          this.requestCouponDeal.total = '';
        }
      }
      
    }, 200);
  }

}
