export class RequestAddBrand{
    nameList : any;
    descriptionList : any;
    discount : any;
    storelocator : any;
    howtouse : any;
    reward : any;
    instrction : any;
    terms_condition : any;
    price : any;

}