<h6 class="font-18 font-weight-400 mt-4 mb-3 text-left product-heading title">
    Client {{Edit ? 'Update' : 'Creation'}}
  </h6>
  <form #brandEditForm="ngForm" name="form" autocomplete="off" (ngSubmit)="AddBrand(requestbrandedit)">
  <div class="card">
    <div class="card-body">
      
        <div class="row flex-wrap">
  
          <h4 class="adcol text-left">
            Client Details :
          </h4>
  
          <!-- <div class="col-4">
            <div class="md-form form-sm my-3">
              <label for="category" class="mb-2">Category
                <span class="text-danger">*</span>
              </label>
  
              <select id="category" class="form-select" placeholder="Enter Category" [(ngModel)]="requestbrandedit.category" [ngModelOptions]="{ standalone: true }" required>
                <option value="" selected disabled hidden>Select Parent Category</option>
                <option *ngFor="let option of categoryData" [value]="option.id">{{ option.name }}</option>
              </select>
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['category']?.touched || brandEditForm.submitted) && brandEditForm.controls['category']?.errors?.['required']">
                Category is required
              </span>
            </div>
          </div> -->
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="company_name" class="mb-2">
                Client Name <span class="text-danger">*</span>
              </label>
              <input id="company_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.company_name" placeholder="Enter Entity Name" (keypress)="onKeyPresscomma($event)" mdbInput autocomplete="off" name="company_name" required (input)="restrictDecimals($event)" />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['company_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['company_name']?.errors?.['required']">
                Client name is required
              </span>
            </div>
          </div>
  
          <!-- <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="brand_name" class="mb-2">
                Brand name <span class="text-danger">*</span>
              </label>
              <input id="brand_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.brand_name" placeholder="Enter Brand Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="brand_name" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['brand_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['brand_name']?.errors?.['required']">
                Brand name is required
              </span>
            </div>
          </div> -->
  
          <!-- <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="user_name" class="mb-2">
                User Name <span class="text-danger">*</span>
              </label>
              <input id="user_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.user_name" maxlength="20" placeholder="Enter User Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="user_name" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['user_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['user_name']?.errors?.['required']">
                User name is required
              </span>
            </div>
          </div> -->

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="email" class="mb-2">
                Email <span class="text-danger">*</span>
              </label>
              <input id="email" type="email" class="form-control" [(ngModel)]="requestbrandedit.email" maxlength="100" placeholder="Enter Email" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="email" required email />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['email']?.touched || brandEditForm.submitted) && brandEditForm.controls['email']?.errors?.['required']">
                Email is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['email']?.touched || brandEditForm.submitted) && brandEditForm.controls['email']?.errors?.['email']">
                Invalid email format
              </span>
            </div>
          </div>

  
          <!-- <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="password" class="mb-2">
                Password <span class="text-danger">*</span>
              </label>
              <input id="password" type="password" class="form-control" [(ngModel)]="requestbrandedit.password" maxlength="50" placeholder="Enter Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="password" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['password']?.touched || brandEditForm.submitted) && brandEditForm.controls['password']?.errors?.['required']">
                Password is required
              </span>
            </div>
          </div> -->
  
          <!-- <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="confirmpassword" class="mb-2">
                Confirm Password <span class="text-danger">*</span>
              </label>
              <input id="confirmpassword" type="password" class="form-control" [(ngModel)]="requestbrandedit.confirmpassword" maxlength="50" placeholder="Confirm Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="confirmpassword" required compare="password" />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['required']">
                Confirm password is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['compare']">
                Passwords must match
              </span>
            </div>
          </div> -->
  
          

  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="contact" class="mb-2">
                Mobile Number 
              </label>
              <input id="contact" type="text" class="form-control" [(ngModel)]="requestbrandedit.contact" maxlength="15" placeholder="Enter Mobile Number" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event);restrictAlphabets($event)" name="contact" required number />
  
              <!-- <span class="text-danger" *ngIf="(brandEditForm.controls['contact']?.touched || brandEditForm.submitted) && brandEditForm.controls['contact']?.errors?.['required']">
                Contact is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['contact']?.touched || brandEditForm.submitted) && brandEditForm.controls['contact']?.errors?.['number']">
                Invalid contact number
              </span> -->
            </div>
          </div>

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="url" class="mb-2">Website</label>
              <!-- <span class="text-danger">*</span> -->
              <input id="url" type="text" class="form-control"
                [(ngModel)]="requestbrandedit.url" placeholder="Enter URL" name="url" 
                #url="ngModel" mdbInput autocomplete="off"
                >
              <!-- <span class="text-danger" *ngIf="
                (brandEditForm.controls['url']?.touched || brandEditForm.submitted) &&
                brandEditForm.controls['url']?.errors?.['required']">
                URL is required
              </span>
              <span class="text-danger" *ngIf="(!isValidUrl)">
                Please enter valid URL
            </span> -->
            </div>
          </div>

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="support_email" class="mb-2">
                Support Email 
              </label>
              <input id="support_email" type="email" class="form-control" [(ngModel)]="requestbrandedit.support_email" maxlength="100" placeholder="Enter Support Email" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="support_email" email />
  
              <!-- <span class="text-danger" *ngIf="(brandEditForm.controls['support_email']?.touched || brandEditForm.submitted) && brandEditForm.controls['support_email']?.errors?.['required']">
                Support Email is required
              </span>-->
              <span class="text-danger" *ngIf="brandEditForm.controls['support_email']?.errors?.['email']">
                Invalid Support Email format
              </span> 
            </div>
          </div>
                    

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="Wallet" class="mb-2">
                Wallet threshold: <span class="text-danger">*</span>  
              </label>
              <input id="Wallet" [(ngModel)]="requestbrandedit.Wallet"
                        class="form-control"
                        placeholder="Enter Wallet"
                        mdbInput
                        autocomplete="off"
                        (keypress)="onKeyPresscomma($event);numberOnly($event);restrictAlphabets($event);"
                        required
                        name="Wallet" />
                                
              <span class="text-danger" *ngIf="
                (brandEditForm.controls['Wallet']?.touched || brandEditForm.submitted) &&
                brandEditForm.controls['Wallet']?.errors?.['required']">
                Wallet  is required
              </span>
            </div>
          </div>

          <div class="col-4">
            <div class="md-form form-sm my-3">
              <label for="currency" class="mb-2"
                >Currency
                <span class="text-danger">*</span>
              </label>
              <select
                id="currency"
                class="form-select"
                [(ngModel)]="requestbrandedit.currency"
                name="currency"
                #currency="ngModel"
                required>
                <option value="" selected disabled hidden>Select Currency</option>
                <option 
                  *ngFor="let option of currencyData"
                  [value]="option.currency_code">
                  {{ option.currency_code }}
                </option>
              </select>
              <span
                class="text-danger"
                *ngIf="(currency.touched || brandEditForm.submitted) && currency.errors?.['required']">
                currency is required
              </span>
            </div>
          </div>
          

          <div class="col-4 d-flex space-between">
            <div class="md-form form-sm my-3">
              <div class="mb-2">
                  Upload Client Logo 
                  <span class="text-danger">*</span>
              </div>
               
              <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile1">  
                              
                <input id="inputFile1" type="file" accept="image/*" class="form-control d-none" id="inputFile1" #inputFile1
                       (change)="selectfile($event)" [(ngModel)]="requestbrandedit.file1"
                       [ngModelOptions]="{ standalone: true }">
                <i class="fa fa-upload pr-1"></i> Client Logo
              </label>
            </div>
            <div class="prealig my-3">
              <label *ngIf="preshow">Preview Image :</label>
              <br>
              <img [src]="this.previews" class="preview" />
            
              <img *ngIf="Edit && !this.previews" class="preview" [src]="this.pathurl + this.imagevalue" />
            </div>
          </div>

          <div class="col-4 d-flex space-between">
            <div class="md-form form-sm my-3">
              <div class="mb-2">
                Upload Client KYC
                <span class="text-danger">*</span>
              </div>
               
              <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile2">                  
                <input id="inputFile2" type="file" accept=".pdf,image/*" class="form-control d-none" id="inputFile2" #inputFile2
                       (change)="selectfile1($event)" [(ngModel)]="requestbrandedit.file2"
                       [ngModelOptions]="{ standalone: true }">
                <i class="fa fa-upload pr-1"></i> Client KYC
              </label>
            </div>
            <div class="prealig my-3">
              <label *ngIf="preshow1">Preview Image :</label>
              <br>
              <img *ngIf="!pdfshow" [src]="this.previews1" class="preview" />
              <img *ngIf="pdfshow && !Edit" src="../../../assets/images/images.png" alt="pdf image" class="preview" />

              <img *ngIf="Edit && pdfshow" class="preview"  src="../../../assets/images/images.png"  />
              <img *ngIf="Edit && !pdfshow && !this.previews1" class="preview" [src]="this.pathurl + this.imagevalues" />
            </div>
          </div>
          
          <!-- <div class="col-2 prealig">
            <label *ngIf="preshow">Preview Image :</label>
            <br>
            <img [src]="this.previews" class="preview" />
          
            <img *ngIf="Edit && !this.previews" class="preview" [src]="this.pathurl + this.imagevalue" />
          </div> -->

          <!-- <div class="col-2 prealig">
            <label *ngIf="preshow1">Preview Image :</label>
            <br>
            <img [src]="this.previews1" class="preview" />
          
            <img *ngIf="Edit && !this.previews1" class="preview" [src]="this.pathurl + this.imagevalue" />
          </div> -->
        </div>
      </div>
    </div>

        <div class="card mb-3 mt-4">
          <div class="card-body">
            <div class="row flex-wrap">
              <h4 class="adcol text-left">
                Client Users:
              </h4>

              <!-- <div class="col-3 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="user_name" class="mb-2">
                    User Name <span class="text-danger">*</span>
                  </label>
                  <input id="user_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.user_name" maxlength="20" placeholder="Enter User Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="user_name" required />
                  <span class="text-danger" *ngIf="(brandEditForm.controls['user_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['user_name']?.errors?.['required']">
                    User name is required
                  </span>
                </div>
              </div> -->
              <div class="col-3 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="user_name" class="mb-2">
                    User Name <span class="text-danger">*</span>
                  </label>
                  <input id="user_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.user_name" maxlength="100" minlength="4" placeholder="Enter User Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" (keydown)="keyDownHandler($event)" name="user_name" required />
                  <span class="text-danger" *ngIf="(brandEditForm.controls['user_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['user_name']?.errors?.['required']">
                    User name is required
                  </span>
                  <span class="text-danger" *ngIf="brandEditForm.controls['user_name']?.hasError('minlength')">
                    User name must be at least 4 characters long
                  </span>
                </div>
              </div>
              
  
  
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="password" class="mb-2">
                    Password <span class="text-danger">*</span>
                  </label>
                  <input id="password" type="password" minlength="4" class="form-control" [(ngModel)]="requestbrandedit.password" maxlength="100" placeholder="Enter Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="password" required />
                  <span class="text-danger" *ngIf="(brandEditForm.controls['password']?.touched || brandEditForm.submitted) && brandEditForm.controls['password']?.errors?.['required']">
                    Password is required
                  </span>
                  <span class="text-danger" *ngIf="brandEditForm.controls['password']?.hasError('minlength')">
                    Passwword must be at least 4 characters long
                  </span>
                </div>
              </div>
  
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="confirmpassword" class="mb-2">
                    Confirm Password <span class="text-danger">*</span>
                  </label>
                  <input id="confirmpassword" type="password" minlength="4" class="form-control" [(ngModel)]="requestbrandedit.confirmpassword" maxlength="50" placeholder="Confirm Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="confirmpassword" required compare="password" />
      
                  <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['required']">
                    Confirm password is required
                  </span>
                  <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['compare']">
                    Passwords must match
                  </span>
                  <span class="text-danger" *ngIf="brandEditForm.controls['confirmpassword']?.hasError('minlength')">
                    Password must be at least 4 characters long
                  </span>
                </div>
              </div>
              
  
              <div class="col-1" style="margin-top: 2rem;"> 
                <div class="form-sm my-3">
                  <button type="button" class="btn btn-success" (click)="addDynamicField()"><i class="fa fa-plus" style="color:white"></i></button>
                </div>
              </div>
  
              </div>
              <div class="row flex-wrap">
              <div class="col-12" *ngFor="let field of dynamicFields; let i = index">
                <div class="row">
                  <div class="col-3">
                    <div class="md-form form-sm my-3">
                      <label for="user_name{{i}}" class="mb-2">
                        User Name <span class="text-danger">*</span>
                      </label>
                      <input
                        id="user_name{{i}}"
                        type="text"
                        class="form-control"
                        [(ngModel)]="field.user_name"
                        placeholder="Enter User Name"
                        mdbInput
                        autocomplete="off"
                        name="user_name{{i}}"
                        required
                        minlength="4"
                        [disabled]="!field.enabled" (keydown)="keyDownHandler($event)"
                      />
                      <span class="text-danger" *ngIf="(submitted || brandEditForm.controls['user_name' + i]?.touched) && brandEditForm.controls['user_name' + i]?.errors?.['required']">
                        User name is required
                      </span>
                      <span class="text-danger" *ngIf="brandEditForm.controls['user_name' + i]?.hasError('minlength')">
                        User name must be at least 4 characters long
                      </span>
                    </div>
                  </div>
            
                  <div class="col-4">
                    <div class="md-form form-sm my-3">
                      <label for="password{{i}}" class="mb-2">
                        Password <span class="text-danger">*</span>
                      </label>
                      <input
                        id="password{{i}}"
                        type="password"
                        class="form-control"
                        [(ngModel)]="field.password"
                        placeholder="Enter Password"
                        mdbInput
                        autocomplete="off"
                        name="password{{i}}"
                        required
                        minlength="4"
                        [disabled]="!field.enabled"
                      />
                      <span class="text-danger" *ngIf="(submitted || brandEditForm.controls['password' + i]?.touched) && brandEditForm.controls['password' + i]?.errors?.['required']">
                        Password is required
                      </span>
                      <span class="text-danger" *ngIf="brandEditForm.controls['password' + i]?.hasError('minlength')">
                        Password must be at least 4 characters long
                      </span>
                    </div>
                  </div>
            
                  <div class="col-4">
                    <div class="md-form form-sm my-3">
                      <label for="confirmpassword{{i}}" class="mb-2">
                        Confirm Password <span class="text-danger">*</span>
                      </label>
                      <input
                        id="confirmpassword{{i}}"
                        type="password"
                        class="form-control"
                        [(ngModel)]="field.confirmpassword"
                        placeholder="Confirm Password"
                        mdbInput
                        autocomplete="off"
                        name="confirmpassword{{i}}"
                        required
                        minlength="4"
                        [disabled]="!field.enabled"
                      />
                      <span class="text-danger" *ngIf="(submitted || brandEditForm.controls['confirmpassword' + i]?.touched) && brandEditForm.controls['confirmpassword' + i]?.errors?.['required']">
                        Confirm password is required
                      </span>
                      <span class="text-danger" *ngIf="brandEditForm.controls['confirmpassword' + i]?.hasError('minlength')">
                        Confirm password must be at least 4 characters long
                      </span>
                    </div>
                  </div>
                  <div class="col-1" style="margin-top: 2rem;">
                    <div class="form-sm my-3">
                      <button type="button" class="btn btn-success" (click)="removeDynamicField(i,field.id)"><i class="fa fa-minus" style="color:white"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="form-sm my-3">
                  <button type="button" class="btn btn-success" (click)="addDynamicField()"><i class="fa fa-plus" style="color:white"></i></button>
                </div>
              </div> -->
            </div>
            

            </div>
            </div>


        <!-- <div class="row flex-wrap">
          <div class="col-12 text-right">
            <button class="btn btn-primary btn-rounded waves-effect waves-light btn-md" type="submit" [disabled]="brandEditForm.invalid || loading">
              {{ Edit ? 'Update' : 'Create' }}
            </button>
          </div>
        </div> -->

        <div class="d-flex align-items-end justify-content-end">
            <button  class="btn btn-secondary mr-2" type="button" (click)="backclear()" >Cancel</button>
            <!-- <button  class="btn btn-success mr-2" type="button">Save</button> -->
            <button class="btn btn-success mr-2" type="submit" >{{Edit ? 'Update':'Submit'}}</button>
        </div>
</form>
<ngx-ui-loader></ngx-ui-loader>


<div class="modal fade" id="approveModals" tabindex="-1" aria- labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <div>
                                <label class="adcol">Do you want to add Products?</label>
                            </div>
                            <div class="mt-5 mb-4 product-modal">
                                <button class="btn btn-secondary mr-5" style="width: 150px;padding: 8px;" data-bs-dismiss="modal" (click)="back()"
                                    #closeBtn>No</button>
                                <button class="btn btn-success" style="width: 150px;padding: 8px;" data-bs-dismiss="modal" (click)="clientProductMap(requestbrandedit)"
                                    >Yes
                                </button>
                              </div>
                        </div>
                    </div>
                </div>
            </div>