import { Component, ChangeDetectorRef, ViewChild } from "@angular/core";
import { AppService } from '../restful-services/restful.service';
import { HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { INgxMyDpOptions, IMyDateModel, IMyCalendarViewChanged } from '@mksasi/ngx-mydatepicker';
import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, NgChartsModule } from "ng2-charts";




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent {

  public role: any;
  private headers: any = {};
  public ExpiryOptions: INgxMyDpOptions = {};
  countsdata: any;

  //Digital 

  digitalapprove: any;
  digitalreject: any;
  digitalgenerate: any;
  digitalpend: any;
  digitalreedeem: any;

  //Internal Digital 

  internaldigitalapprove: any;
  internaldigitalreject: any;
  internaldigitalgenerate: any;
  internaldigitalpend: any;
  internaldigitalreedeem: any;

  //Product  

  productapprove: any;
  productreject: any;
  productgenerate: any;
  productpend: any;
  productreedeem: any;


  //Internal Product 

  internalproductapprove: any;
  internalproductreject: any;
  internalproductgenerate: any;
  internalproductpend: any;
  internalproductreedeem: any;

  //VoucherRedemption
  issuedVoucher: any;
  issuedVoucherValue: any;
  redeemValue: any;



  //Deal

  dealapprove: any;
  dealreject: any;
  dealgenerate: any;
  dealpend: any;
  dealreedeem: any;

  //date
  start_date: any;
  end_date: any;
  public getstartDate: string = "";
  public getendDate: string = "";
  isButtonDisabled = true;
  dateRequire: boolean = false;
  public startOptions: INgxMyDpOptions = {};
  public endOptions: INgxMyDpOptions = {};

  //issuance
  dealorder: any
  dealamount: any
  dealdiscount: any
  dealEarn: any
  dealIssuance:any
  // @ViewChild('chart') chart? : BaseChartDirective;
  @ViewChild(BaseChartDirective) chartComponent!: BaseChartDirective;
  @ViewChild('barchart') barchart?: Chart;
  chartType: ChartType | any = 'doughnut';
  Label: string = "Product Price";
  meaData: any = [];
  chartMeaData:any = [];
  chartMeaVal:any = [];
  flipkart:any = [];
  qwik:any = [];
  tcktExp:any = [];
  voucher:any = [];
  chartfli:any = [];
  chartqwik:any = [];
  charttckexp:any = [];
  chartvoucher:any = [];
  chartfliVal:any = [];
  chartqwikVal:any = [];
  charttcktVal:any = [];
  chartvoucherVal:any = [];
  MEAdata: ChartData<'doughnut'> = {
    labels: this.chartMeaData,
    datasets: [
      {
        label: this.Label,
        data: this.chartMeaVal
      }
    ]
  };
  chartamaData:any = [];
  chartamaVal: any = [];
  amazData:any = [];
  amazonData: ChartData<'doughnut'> = {
    labels: this.chartamaData,
    datasets: [
      {
        label: this.Label,
        data: this.chartamaVal
      }
    ]
  };

  eZData: any = [];
  chartEzData:any = [];
  chartEzVal:any = [];
  Ezdata: ChartData<'doughnut'> = {
    labels: this.chartEzData,
    datasets: [
      {
        label: this.Label,
        data: this.chartEzVal
      }
    ]
  };

  flipkartData: ChartData<'doughnut'> = {
    labels: this.chartfli,
    datasets: [
      {
        label: this.Label,
        data: this.chartfliVal
      }
    ]
  };

  

  dTData: any = [];
  chartDtData:any = [];
  chartDtVal:any = [];
  Dtdata: ChartData<'doughnut'> = {
    labels: this.chartDtData,
    datasets: [
      {
        label: this.Label,
        data: this.chartDtVal
      }
    ]
  };

  qwikdata: ChartData<'doughnut'> = {
    labels: this.chartqwik,
    datasets: [
      {
        label: this.Label,
        data: this.chartqwikVal
      }
    ]
  };

  yggData: any = [];
  chartYggData:any = [];
  chartYggVal:any = [];
  Yggdata: ChartData<'doughnut'> = {
    labels: this.chartYggData,
    datasets: [
      {
        label: this.Label,
        data: this.chartYggVal,
      }
    ]
  };
  

  ticketVAL: ChartData<'doughnut'> = {
    labels: this.charttckexp,
    datasets: [
      {
        label: this.Label,
        data: this.charttcktVal,
      }
    ]
  };

  voucherValue: ChartData<'doughnut'> = {
    labels: this.chartvoucher,
    datasets: [
      {
        label: this.Label,
        data: this.chartvoucherVal,
      }
    ]
  };

  options: ChartOptions<'doughnut'> = {
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //     type: 'linear',
    //     min: 0,
    //     max: 100
    //   }
    // },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start'
      },
    }
  };
  ezBinBar:number = 0;
  dtBar:number = 0;
  meaBar:number = 0;
  yggBar:number = 0;
  barArray: any = [];
  internationalBarData: any = [];
  domesticBarData: any = [];
  month:string[] = [];
  domMonth:string[] = [];
  monthLabel: string = "Month Wise Data";
  data: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  interNationalOverall: ChartData<'doughnut'> = {
    labels: ['EZBIN', 'DT', 'MEA', 'YGG'],
    datasets: [
      {
        label: "OVERALL AMOUNT",
        data: this.barArray,
      }
    ]
  };
  
  domesticBarArray : any = [];
  domesticOverall: ChartData<'doughnut'> = {
    labels: ['AMAZON', 'FLIPKART', 'QWIKCILVER', 'TICKETEXPRESS','VOUCHERKART'],
    datasets: [
      {
        label: "OVERALL AMOUNT",
        data: this.domesticBarArray,
      }
    ]
  };
  domesticDatas: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  barOptions: ChartOptions<'bar'> = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start'
      },
    }
  }
  chartTypes:string = "I"
  public myChart?: Chart;
 
  constructor(private _http: AppService, private loader: NgxUiLoaderService, private cdRef: ChangeDetectorRef) {
    
    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    let dp = new Date();
    dp.setDate(dp.getDate() + 1);
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay:true,
      disableSince: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate()},
    
    };
    let edp: any = new Date();
    edp.setDate(edp.getDate() + 1);
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay:true,
      disableSince: { year: edp.getFullYear(), month: edp.getMonth() + 1, day: edp.getDate() },
    }; 
    this.ExpiryOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };
  }


  ngOnInit(): void {
    this.getDashboardData();
  }

  ngAfterViewInit(){
    // this.getChartData();  
  }

  chartTypeChange(type:string){    
      this.chartTypes = type && type === "I" ? "I" : "D";
       
      if(type && type !== "I"){
        if(this.domesticBarArray.length === 0){
          this.getIntData(); 
        }
      }
      
  }

  getChartData(){
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.chartData(this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        // console.log(data);
        if(data && (data.status === 1 || data.status === "1")){
            this.meaData = data.data.mea;
            this.eZData = data.data.ezpin;
            this.dTData = data.data.dt;
            this.yggData = data.data.ygg;
            this.meaData.forEach((element:any) => {
              this.chartMeaData.push(element.brand_name);
              this.chartMeaVal.push(element.total_price);
            });
            this.eZData.forEach((element:any) => {
              this.chartEzData.push(element.brand_name);
              this.chartEzVal.push(element.total_price);
            });
            this.dTData.forEach((element:any) => {
              this.chartDtData.push(element.brand_name);
              this.chartDtVal.push(element.total_price);
            });
            this.yggData.forEach((element:any) => {
              this.chartYggData.push(element.brand_name);
              this.chartYggVal.push(element.total_price);
            });
              if(data.data.ezpin_total){
                this.ezBinBar = data.data.ezpin_total;
                this.barArray.push(this.ezBinBar);
              }
              if(data.data.dt_total){
                this.dtBar = data.data.dt_total;
                this.barArray.push(this.dtBar);
              }
              if(data.data.mea_total){
                this.meaBar = data.data.mea_total;
                this.barArray.push(this.meaBar);
              }
              if(data.data.ygg_total){
                this.yggBar = data.data.ygg_total;
                this.barArray.push(this.yggBar);
              }
              if(data.data.month_wise_data){
                this.internationalBarData = data.data.month_wise_data[0].data;
                if(this.internationalBarData.length === 0){
                  
                }
                this.internationalBarData.forEach((element:any,index:any) => {
                  this.data.datasets.push({
                    label: index === 0 ? "DT" : index === 1 ? "EzpiN" : index === 2 ? "MEA" : index === 3 ? "ygg" : "",
                    data: index === 0 ? element.DT : index === 1 ? element.EzpiN : index === 2 ? element.MEA : index === 3 ? element.ygg : [],
                  })
                });
                this.month = data.data.month_wise_data[0].month_name;
                this.month.forEach((monthElements:any) => {
                  this.data.labels?.push(monthElements);
                })
                this.myChart?.update();
              }
        }
        else{
          
        }
    }})
  }

  getIntData(){
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.chartintData(this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        if(data && (data.status === 1 || data.status === "1")){

            this.amazData = data.data.amazon;
            this.flipkart = data.data.flipKart;
            this.qwik = data.data.QwikCilver;
            this.tcktExp = data.data.ticket_express;
            this.voucher = data.data.voucherkart;
            
          
          this.amazData.forEach((element:any) => {
            this.chartamaData.push(element.brand_name);
            this.chartamaVal.push(element.total_price);
          });
          this.flipkart.forEach((element:any) => {
            this.chartfli.push(element.brand_name);
            this.chartfliVal.push(element.total_price);
          });
          this.qwik.forEach((element:any) => {
            this.chartqwik.push(element.brand_name);
            this.chartqwikVal.push(element.total_price);
          });
          this.tcktExp.forEach((element:any) => {
            this.charttckexp.push(element.brand_name);
            this.charttcktVal.push(element.total_price);
          });
          this.voucher.forEach((element:any) => {
            this.chartvoucher.push(element.brand_name);
            this.chartvoucherVal.push(element.total_price);
          });
            if(data.data.amazon_total){
              this.domesticBarArray.push(data.data.amazon_total);
            }
            if(data.data.flipkart_total){
              this.domesticBarArray.push(data.data.flipkart_total);
            }
            if(data.data.qwikcilver_total){
              this.domesticBarArray.push(data.data.qwikcilver_total);
            }
            if(data.data.ticket_express_total){
              this.domesticBarArray.push(data.data.ticket_express_total);
            }
            if(data.data.voucherkart_express_total){
              this.domesticBarArray.push(data.data.voucherkart_express_total);
            }
            if(data.data.month_wise_data){
              this.domesticBarData = data.data.month_wise_data[0].data;
              if(this.domesticBarData.length === 0){
                
              }
              this.domesticBarData.forEach((element:any,index:any) => {
                this.domesticDatas.datasets.push({
                  label:index === 0 ? "AMAZON" : index === 1 ? "FLIPKART" : index === 2 ? "QWIKCILVER" : index === 3 ? "TICKETEXPRESS" : index === 4 ? "VOUCHER KART" : "",
                  data: index === 0 ? element.Amazon : index === 1 ? element.FlipKart : index === 2 ? element.QwikCilver : index === 3 ? element.TicketExpress : index === 4 ? element.voucherkart : [],
                })
              });
              this.domMonth = data.data.month_wise_data[0].month_name;
              this.domMonth.forEach((monthElements:any) => {
                this.domesticDatas.labels?.push(monthElements);
              })
              this.myChart?.update();
            }
        }
        else{
          
        }
    }})
  }
  

  getDashboardData() {
    const d: Date = new Date();
    this.role = localStorage.getItem('role');
    let user: any = localStorage.getItem("userDetails");
    let userDetails = JSON.parse(user);

    if (this.role === 'brand' || this.role === 'Company') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this.loader.start();
      let user_id = userDetails.data.UserID;

      this._http.getcount(user_id, this.start_date, this.end_date, this.headers, this.getstartDate).subscribe({
        next: (data: any) => {

          this.loader.stop();
          this.countsdata = data;

          this.digitalapprove = data.dashboardCounts[0].voucher_apporved_count;
          this.digitalreject = data.dashboardCounts[0].voucher_reject_count;
          this.digitalgenerate = data.dashboardCounts[0].voucher_generated_count;
          this.digitalpend = data.dashboardCounts[0].voucher_pending_count;

          this.internaldigitalapprove = data.dashboardCounts[0].internal_voucher_apporved_count;
          this.internaldigitalreject = data.dashboardCounts[0].internal_voucher_reject_count;
          this.internaldigitalgenerate = data.dashboardCounts[0].internal_voucher_generated_count;
          this.internaldigitalpend = data.dashboardCounts[0].internal_voucher_pending_count;

          this.productapprove = data.dashboardCounts[0].brand_apporved_count;
          this.productreject = data.dashboardCounts[0].brand_reject_count;
          this.productgenerate = data.dashboardCounts[0].brand_generated_count;
          this.productpend = data.dashboardCounts[0].brand_pending_count;

          this.internalproductapprove = data.dashboardCounts[0].brand_internal_voucher_apporved_count;
          this.internalproductreject = data.dashboardCounts[0].brand_internal_voucher_reject_count;
          this.internalproductgenerate = data.dashboardCounts[0].brand_internal_voucher_generated_count;
          this.internalproductpend = data.dashboardCounts[0].brand_internal_voucher_pending_count;

          this.issuedVoucher = data.dashboardCounts[0].no_of_issued_voucher_count;
          this.issuedVoucherValue = data.dashboardCounts[0].issued_voucher_amount;
          this.redeemValue = data.dashboardCounts[0].voucher_redeem_amout;

          this.dealapprove = data.dashboardCounts[0].deal_apporved_count;
          this.dealreject = data.dashboardCounts[0].deal_reject_count;
          this.dealgenerate = data.dashboardCounts[0].deal_generated_count;
          this.dealpend = data.dashboardCounts[0].deal_pending_count;

          this.dealorder = data.dashboardCounts[0].total_order;
          this.dealamount = data.dashboardCounts[0].total_amount;         
          this.dealdiscount = data.dashboardCounts[0].total_discount;
          this.dealEarn = data.dashboardCounts[0].net_earning;
          this.dealIssuance= data.dashboardCounts[0].issuance_fee_total;


        }
      });


    }

    else if (this.role === 'admin' || this.role === 'SuperAdmin'|| this.role === 'Company') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this.loader.start();
      this._http.getcounts(this.start_date, this.end_date, this.headers, this.getstartDate).subscribe({
        next: (data: any) => {

          this.loader.stop();
          this.countsdata = data;

          this.digitalapprove = data.dashboardCounts[0].voucher_apporved_count;
          this.digitalreject = data.dashboardCounts[0].voucher_reject_count;
          this.digitalgenerate = data.dashboardCounts[0].voucher_generated_count;
          this.digitalpend = data.dashboardCounts[0].voucher_pending_count;

          this.internaldigitalapprove = data.dashboardCounts[0].internal_voucher_apporved_count;
          this.internaldigitalreject = data.dashboardCounts[0].internal_voucher_reject_count;
          this.internaldigitalgenerate = data.dashboardCounts[0].internal_voucher_generated_count;
          this.internaldigitalpend = data.dashboardCounts[0].internal_voucher_pending_count;

          this.productapprove = data.dashboardCounts[0].brand_apporved_count;
          this.productreject = data.dashboardCounts[0].brand_reject_count;
          this.productgenerate = data.dashboardCounts[0].brand_generated_count;
          this.productpend = data.dashboardCounts[0].brand_pending_count;

          this.internalproductapprove = data.dashboardCounts[0].brand_internal_voucher_apporved_count;
          this.internalproductreject = data.dashboardCounts[0].brand_internal_voucher_reject_count;
          this.internalproductgenerate = data.dashboardCounts[0].brand_internal_voucher_generated_count;
          this.internalproductpend = data.dashboardCounts[0].brand_internal_voucher_pending_count;

          this.issuedVoucher = data.dashboardCounts[0].no_of_issued_voucher_count;
          this.issuedVoucherValue = data.dashboardCounts[0].issued_voucher_amount;
          this.redeemValue = data.dashboardCounts[0].voucher_redeem_amout;

          this.dealapprove = data.dashboardCounts[0].deal_apporved_count;
          this.dealreject = data.dashboardCounts[0].deal_reject_count;
          this.dealgenerate = data.dashboardCounts[0].deal_generated_count;
          this.dealpend = data.dashboardCounts[0].deal_pending_count;

          this.dealorder = data.dashboardCounts[0].total_order;
          this.dealamount = data.dashboardCounts[0].total_amount;         
          this.dealdiscount = data.dashboardCounts[0].total_discount;
          this.dealEarn = data.dashboardCounts[0].net_earning;
          this.dealIssuance= data.dashboardCounts[0].issuance_fee_total;

        }
      });

    }
  }

  filterData() {
    this.getDashboardData();
  }

  openCalendarAndDetectChanges(dp: any) {
    dp.openCalendar();
    this.cdRef.detectChanges();
  }


  onEndDateChanged(event: IMyDateModel): void {
    // console.log("end date " , new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 11).replace('T', ' '));
    // this.end_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');
    // console.log("end date " + this.end_date);
    this.end_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');
    let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
    this.ExpiryOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
    };
    // this.ExpiryDate = '';
    // this.getExpiryDate = '';

  }

  onStartDateChanged(event: IMyDateModel): void {
    this.start_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');
    console.log("start date " + this.start_date)
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged): void {
    // console.log(event);
    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
  }

  checkButtonStatus() {
    this.isButtonDisabled = !this.getstartDate || !this.getendDate;
  }

  openCalendarAndDetectChange(edp: any) {
    edp.openCalendar();
    this.cdRef.detectChanges();
  }

  clear() {
    this.dateRequire = true;
    this.getstartDate = '';
    this.getendDate = '';
    this.getDashboardData();
  }



}