<!-- Modal -->
<div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
       <div class="modal-dialog modal-xl modal-dialog-centered">
         <div class="modal-content">
           <div class="modal-header custom-model">
             <h5 class="modal-title pl-4" id="exampleModalLabel">Update Email Template</h5>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
           </div>
           <div class="row mx-0">
            <div class="sub col-12" class="my-1">
                <label for="emailSubject" class="lab1">Email Subject: </label>
                <input type="text" class="input1" [(ngModel)]="this.emailInput" >
            </div>
          </div>
            <div class="row mx-0">

           
            <div class="col-md-4 pl-36">
                <div class="md-form form-sm my-3">
                  <div class="mb-2">
                      
                      <label for="emailSubject" class="lab1">Category Image<span class="text-danger">* </span> : </label>
                      
                      <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile1">  
                                  
                        <input id="inputFile1" type="file" accept="image/*" class="form-control d-none" id="inputFile1" #inputFile1
                               (change)="selectfile($event)" [(ngModel)]="file1"
                               [ngModelOptions]="{ standalone: true }">
                        <i class="fa fa-upload pr-1"></i> Category Image
                      </label>
                  </div>
                   
                  
                </div>
                
              </div>
              <div class="col-md-4">
                <div class="show" *ngIf="!changeImg" >
                  <img [src]="this.fileUrl+this.categoryImage" class="image clip-circle"  style="max-width: 100%;" (click)="openTabFile()"/>
                </div>
                </div>
              <div class="col-md-4" *ngIf="preshow">
                <div class="prealig my-3">
                  <label >Preview Image :</label>
                  <br>
                  <img [src]="this.previews" class="preview" style="max-width: 100%;height:100px;cursor:pointer" />
                
                  <!-- <img *ngIf="this.previews" class="preview" [src]="this.pathurl + this.imagevalue" /> -->
                </div>

              </div>
            </div>
            <div class="row mx-0"> 
              <div class="col-md-4 pl-36">
                <div class="md-form form-sm my-3">
                  <div class="mb-2">
                     <label for="emailSubject" class="lab1">Header Image <span class="text-danger">* </span> : </label>
                    <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile2">                  
                      <input id="inputFile2" type="file" accept="image/*" class="form-control d-none" id="inputFile2" #inputFile2
                             (change)="selectfile1($event)" [(ngModel)]="file2"
                             [ngModelOptions]="{ standalone: true }">
                      <i class="fa fa-upload pr-1"></i> Header Image
                    </label>
                  </div>
                   
                 
                </div>
              </div>
              <div class="col-md-4">
                <div class="show" *ngIf="!changeImg1">
                  <img [src]="this.fileUrl+this.headerImage" class="image1"  style="max-width: 100%;" (click)="openTabFile()" />
                </div>
                </div>
              <div  *ngIf="preshow1" class="col-md-4">
                <div class="prealig my-3 modal-body">
                  <label>Preview Image :</label>
                  <br>
                  <img [src]="this.previews1" class="preview" style="max-width: 100%;height:100px;cursor:pointer" />
                
                  <!-- <img *ngIf="this.previews1" class="preview" [src]="this.pathurl + this.imagevalue1" /> -->
                </div>
              </div>
            </div>
    
              
            
           
           
           <div>
             <label for="emailBody" style="padding: 10px ;  padding-left: 50px;font-weight: 500; ">Email Body: </label>
            </div>
           <div class="modal-body" style="width: 800px; left: 150px; bottom: 44px;">
             <angular-editor [(ngModel)]="datas" [config]="config" #editor></angular-editor>
             <!-- <h4>HTML Output</h4>
             <div class="html">
               {{this.datas}}
             </div> -->
   
           </div>
         
           <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  (click)="cancel()">Cancel</button>
             <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="editedDatas()">Save changes</button>
           </div>
         </div>
       </div>
     </div> 
   </div>
   <div class="row mx-0">
     <div  class="col-12 card border-0 shadow-sm px-0">
         <div class="card-body pb-4">
   <table class="table">
       <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
         <tr>
           <th scope="col" class="pt1">SI.No</th>
           <th scope="col" class="pt1">Category</th>
           <th scope="col" class="pt1">Subject</th>
           <th scope="col" class="pt1">Action</th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let value of values; let i = index">
           <th scope="row">{{i + 1}}</th>
           <td>{{value.category}}</td>
           <td>{{value.subject}}</td>
           <td>
             <a class="linkColor downloadIcon" #Edit (click)="edit(value.id)">
               <i class="fa fa-edit mr-10 icolor " data-bs-toggle="modal" data-bs-placement="bottom" data-bs-target="#exampleModal" title="Edit"></i>
           </a>
           <a class="linkColor1 downloadIcon" *ngIf="value.status == 'ACTIVE'">
            <i class="fa fa-toggle-on cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active" (click)="statusChange(value.id,'INACTIVE')"></i>
           </a>


            <a class="linkColor downloadIcon" *ngIf="value.status == 'INACTIVE'">
            <i class="fa fa-toggle-off cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(value.id,'ACTIVE')"></i>
           </a>
           </td>
           <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="edit(value.id)">
               <i class="fa fa-edit"></i>
             </button></td> -->
         </tr>
         
       </tbody>
     </table>
     </div>
     </div>
     </div>
     <ngx-ui-loader></ngx-ui-loader>
   
   