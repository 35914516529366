import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { apiurl, environmentfiles } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";
import { RequestCategory } from '../models/RequestCategory';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
declare var bootstrap: any;


@Component({
  selector: 'app-b2c-product',
  templateUrl: './b2c-product.component.html',
  styleUrls: ['./b2c-product.component.scss']
})
export class B2cProductComponent implements OnInit {

  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('inputFile') inputFile: any;
  @ViewChild('closeCategoryBtn', { static: true }) closeCategoryBtn!: ElementRef;

  public external: string = "";
  private headers: any = {};
  productList: string = '';
  skip: number = 0;
  limit: number = 10;
  getExternal: any;
  apiLoading = true;
  lastvalue: number = 0;
  count: number = 0;
  d: number = 1;
  pathurl: any;
  tooltipList: any;
  currentBody: any;
  emailInput: any;
  datas: any;
  idvalue: any;
  Edit: Boolean;
  userDetails: any;
  descriptionList: any;
  nameList: any;
  b2cname: any;
  updatedData: any;
  sourceList:any;
  sourceData:any;
  sourceDatas:any =[];
  fileurls:any;
  brandId:any;
  productid:any;
  productlistId:any;
  sourceData1:any
  userName: boolean = false;
  clientName:any;

  categoryList: RequestCategory[];
  AddrequestCategory: RequestCategory;
  closeproductBtn: any;
  paramValue:any;
  productmapid:any;

  constructor(private route:ActivatedRoute ,private service: AppService, private loader: NgxUiLoaderService, private toastr: ToastrService, private router:Router) {
    this.Edit = false;
    this.service.getClientNameproduct().subscribe((data:any) =>{
      
      this.clientName = data.clientName;
    })
    this.categoryList = new Array<RequestCategory>();
    this.AddrequestCategory = new RequestCategory();
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paramValue = params['id'];
    });
    
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.pathurl = apiurl;
    this.getDigitalBrandList();
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL; 
    this.getBrandSource();
    // console.log("drop",this.productList)

  }

  // addProduct(){
  //   this.getBrandSource();
  // }

  filterList() {
     this.getProductbrandlistfilter();
  }

  importProduct(event: any) {

    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    
    if ((getImg.toLowerCase() !== '.csv')) {
      this.toastr.error("File not supported");
      return;
    } else {    
    }
  
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData: any = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('created_by', this.userDetails.UserID);
      formData.append('created_by_role', this.userDetails.role);
      formData.append('brand_id', this.paramValue );
      // if(this.productList) {
      // formData.append('productType',this.productList);
      // }   

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this.service.importBrandProduct(formData, this.headers).subscribe({     
        next: (data: any) => {

          this.loader.stop();

          if (data.status === 1) {
            this.toastr.success(data.msg);
            // this.closeCategoryBtn.nativeElement.click();
            this.getDigitalBrandList();
          }
          else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.closeCategoryBtn.nativeElement.click();
            this.getDigitalBrandList();
          }
          else if (data.status === 0) {
            this.toastr.error(data.msg);
            // this.closeCategoryBtn.nativeElement.click();
          }

          this.inputFile.nativeElement.value = '';
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
          this.closeCategoryBtn.nativeElement.click();
        }
      })
    }
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  getBrandSource() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
   
    let item = '';
    this.service.getBrandSource(this.headers).subscribe((data: any) => {
     
         if(data.data){
        this.sourceData = data.data;  
        this.sourceDatas = this.sourceData.filter((data:any) =>{
          return data.name === this.clientName
        })
        console.log("array",this.sourceDatas)
      
       this.sourceData1=this.sourceData.map((x:any)=> {
         this.brandId=x.id
        
       })
       }
    
         

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

  addNewBrand(){
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.getBrandSource()

  }

  clear() {

    this.external = '';
    // this.categoryList = [];
    this.getDigitalBrandList();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);
  }

  editCategory(list: any) {

    this.Edit = true;
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.idvalue = list.id;
    this.currentBody = this.getExternal.find((p: any) => {
      return p.id == list.id;

    });
    // console.log(this.currentBody.body);
    this.descriptionList = this.currentBody.description;
    this.nameList = this.currentBody.brand_name;
    this.b2cname = this.currentBody.b2c_name;
    this.userName = true
    
    // this.currentId = this.currentBody.id;
    // this.getcategoryeditlist(this.idvalue);

  }


  editedDatas() {


    let postParams = {
      id: this.idvalue,
      name: this.b2cname,
      description: this.descriptionList,
      b2c_name: this.nameList

    }
    this.apiLoading = true;


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.service.getExternalBrandeditlist(postParams, this.headers).subscribe((data: any) => {
      this.updatedData = data;
      this.getDigitalBrandList();

      if (data.status == 1) {
        this.toastr.success(data.msg)
      } else if (data.status == 0) {
        this.toastr.error("Data updated failure")
      }

      // data = this.updatedDatas;
    },

      (error) => {
        this.toastr.error(error.error.Message)
        // console.log("error",error);

      }
    )
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  getProductbrandlistfilter() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();
   
    this.service.getProductBrandlist(this.paramValue,this.external, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;

        this.count = data.totalCount;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {

          this.count = data.totalCount;
          this.getExternal = data.list;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }


  getDigitalBrandList() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getproductBrandlist(this.paramValue,this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.getExternal = data.list;
        // for(let value of this.getExternal){
        //    this.productmapid=value.product_mapping_id
        // }
        this.getExternal=this.getExternal.map((x:any)=> {
          
          // this.getExternalimage = x.image;
          if(x.thumbnail&&(!x.thumbnail.includes('http') || !x.thumbnail.includes('https'))){
            
            x.thumbnail=this.fileurls+x.thumbnail;
          }
          return x
        })
        // this.sourceData1=this.getExternal.map((x:any)=> {
        //   this.productmapid=x.product_mapping_id;
        //   console.log("wwwwzzz",this.productmapid)
         
        // })
        
        
        if (data) {
          this.apiLoading = false;
          this.count = data.totalCount;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }

        }
      },
      error: _error => {
        console.error(_error),
          this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * 10;
    this.lastvalue = this.skip + 10;
    localStorage.setItem('pageNo', pageNo);
    this.getDigitalBrandList();

  }

  deleteModal(productid: any,productmapid:any) {

    this.productid = productid;
    this.productmapid =productmapid; 
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }

  
  deleteproduct() {  
    
    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
    this.service.deleteBrandproductList(this.productmapid,this.headers).subscribe(
      (data: any) => {
         
        // this.closeproductBtn.nativeElement.click();
   

        if (data.status == 1) {
          this.toastr.success("product deleted successfully");
          this.getDigitalBrandList();
          this.loader.stop();
        
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      })
  }
  back(){
    this.router.navigate(['/b2c-brand'],{replaceUrl:false})
  }


}

