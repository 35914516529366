import { Component,ElementRef, OnInit, ViewChild,HostListener} from '@angular/core';
import { AppService } from './../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RequestProductmgt } from '../models/Requestproductmgt';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
declare var bootstrap: any;

import { apiurl } from "src/environments/environment";
import { HttpClient,HttpHeaders ,HttpErrorResponse } from "@angular/common/http";



@Component({
  selector: 'app-productmanagement',
  templateUrl: './productmanagement.component.html',
  styleUrls: ['./productmanagement.component.scss']
})



export class ProductmanagementComponent implements OnInit {
  
  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('closeproductBtn', { static: true }) closeproductBtn!: ElementRef;

  // limit: number = 10; 
  // skip: number = 0;
  // p: number = 1;
  // search:any;
  // test:any;
  // count: number = 0;
  // lastvalue: number = 0;
  // tooltipList: any;

  limit: number = 25; 
  skip: number = 0;
  d: number = 1;
  search:any;
  test:any;
  count: number = 0;
  lastvalue: number = 0;
  tooltipList: any;
 

  role: any;
  userDetails: any;

  public name: string = "";
  public names: string = "";
  private headers:any={}; 
  apiLoading: boolean = false;

  ProductmgtList: RequestProductmgt[];
  Addrequestproductmgt: RequestProductmgt;   
  categoryData: any = [];
  Edit: Boolean;
  parcatid: any;
  description:any;
  idvalue:any;
  productid:any;

  pathurl:any;
  brand:any = '';
  product:any;

  brandData: any = [];
  brandidvalue : any;
  displayhide:boolean =true;
  roletype: any;
  selectvoucherkart : string = '';
  selectrewardkart : string = '';
  
  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    this.ProductmgtList = new Array<RequestProductmgt>();
    this.Addrequestproductmgt = new RequestProductmgt();

  }



  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
      if(this.roletype === 'Admin'){

        this.displayhide = false;
          }
          else{
    
            this.displayhide = true;
          }
    }

    this.onChangebrand();

    this.pathurl =  apiurl;
    this.getproductmgtlist();    

  }

  type(productList:RequestProductmgt , status:any , value:any){  

    var vval = "";
    var rval = "";
  
        if(status==0 ||status=="0"){
  
          vval = value;
          if(value == "OFF"){
            rval = "OFF";
          }
          else{
            rval = productList.display_rewardkart;
          }
        
        }
        else if(status==1 || status=="1"){
  
          vval = productList.display_voucherkart;
          rval = value;
        
        
        }
        else{
          this.toastr.error();
        }
   
        const params = {
    
          id: productList.id,
          display_voucherkart: vval,
          display_rewardkart: rval,    
        
           }
   
      
        let token = localStorage.getItem("token");
        this.headers = {
           headers: new HttpHeaders({
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + token
           })
         };
        
        this._http.updateInternalproduct(params,this.headers).subscribe({
          next: (data: any)=>{
          
    
            if((data.status == 1 && status == 1) ||status=="1"){
              this.selectrewardkart = "Rewardkart";
              // this.toastr.success("Product Status Changed");
              this.getproductmgtlist();
             
            }
            else if((data.status == 0 && status == 0) || status=="0"){
              this.selectvoucherkart = "Voucherkart";
              // this.toastr.success("Product Status Changed");
              this.getproductmgtlist();        
            }
          
        
        },
    
      });
    }
  




  getproductmgtlist() {   


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();
    this._http.getproductmgtlist(this.product, this.brandidvalue ,this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {    

  this.loader.stop();                  
      
        this.ProductmgtList = data.data;      
               
        if (data) {
          this.apiLoading = false;  
          this.count = data.count;
          this.ProductmgtList = data.data;
          this.toolTipInit();

          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  getproductmgtlistfilter() {   


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;

    this._http.getproductmgtlist(this.product, this.brandidvalue ,this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {    
                        
        this.ProductmgtList = data.data;          
        
        if (data) {
          this.apiLoading = false;   
          this.count = data.count;
          this.ProductmgtList = data.data;
          this.toolTipInit();

          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
         
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  backclear() {

    // this.getcategorylist();    
    // this.router.navigate(['/category'], { replaceUrl: false });
    // this.addForm.resetForm();
    // this.Edit = false;
 
  }

  addNewProduct(){

    this.router.navigate(['/internalproduct-add'], { replaceUrl: false });
    this.ProductmgtList = [];
    localStorage.removeItem('producteditlist');
  }

  filterList() {  
   
    if ( this.limit || this.skip || this.product || this.brand ) {
      this.skip = 0;
      this.d = 1;
    }
    this.getproductmgtlistfilter();
  }


  clear() {

    this.product = '';
    this.brand = '';
    this.ProductmgtList = [];
    this.brandidvalue = ''; 
    this.getproductmgtlist();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);   

  }

selectbrand(item:any){

this.brandidvalue = item;
  }


  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {trigger : 'hover'})
      })
    },500)
  }

  ngOnDestroy(){
    if(this.tooltipList){
    this.tooltipList.forEach( (tooltip: { dispose: () => void; })=>{
      tooltip.dispose();
    });
  }
  }


  addNewCategory() {  
  
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
      addcategoryModal.show();   
    
  }



  editProduct(productmgtList: RequestProductmgt) {     
    
    // this._http.brandmgtDetails(productmgtList);
    const id = productmgtList.id;
    this.router.navigate(['/internalproduct-add'], {
      state: { id },
      replaceUrl: true
    });
  } 



  getcategoryeditlist(id:any){

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    let idval = id;  
    
    this._http.getcategoryeditlist(idval,this.headers).subscribe((data: any) => {
     
      this.Addrequestproductmgt = data.data;
      // this.Addrequestproductmgt.category = this.Addrequestproductmgt.parent_category;
          
    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })

  }


  deleteModal(productid: any) {

    this.productid = productid;
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }


  deleteproduct() {  

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

     this.loader.start();
    this._http.deleteproductList(this.productid,this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        this.closeproductBtn.nativeElement.click();
   

        if (data.status == 1) {
          this.toastr.success("product deleted successfully");
          this.getproductmgtlist();
        
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      })
  }


  onChangebrand() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getbrandload(this.headers).subscribe((data: any) => {

      this.brandData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

 

  // pageChanged(pageNo: any) {

  //   this.p = pageNo;
  //   this.skip = (pageNo - 1) * 10;
  //   this.lastvalue = this.skip + 10;
  //   localStorage.setItem('pageNo', pageNo);
  //   // this.getproductlist();
  // }

  pageChanged(pageNo: any) {

    this.d = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getproductmgtlist();
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }

}
