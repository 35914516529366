export class WalletHistory {

    id: any;
    voucher_request_id: any;
    voucher_id: any;
    voucher_amount: number = 0;
    voucher_redeem_amount: number = 0;
    voucher_redeem_date: any;
    redeem_user_email:any;
   

}