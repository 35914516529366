import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Clipboard } from '@angular/cdk/clipboard';
import { Supplier } from '../models/supplierList';
import { MultiDenomination } from '../models/MultiDenomination';
import { AppService } from '../restful-services/restful.service';
import { VoucherGeneration } from '../models/VoucherGeneration';
@Component({
  selector: 'app-denomination-order-viewlist',
  templateUrl: './denomination-order-viewlist.component.html',
  styleUrls: ['./denomination-order-viewlist.component.scss']
})
export class DenominationOrderViewlistComponent {

  requestGroupVoucher!: MultiDenomination;
  private headers: any = {};
  public role: string = 'company';
  paramValue: any = '';
  supplierDetail: Supplier;
  voucherList: any = [];
  showGroupVoucher: any = [];
  totalAmount: any = 0;
  userDetails: any;
  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  lastvalue: number = 0;
  voucherId: any;
  voucherGenerationList: VoucherGeneration[] | undefined;
  showAccordion: boolean = true;
  issuancedate: any;
  requestCouponVoucher: any;
  created_by_role: any;


  constructor(private route: ActivatedRoute, private clipboard: Clipboard, private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.requestGroupVoucher = new MultiDenomination();
    this.voucherGenerationList = new Array<VoucherGeneration>();
    this.supplierDetail = new Supplier();
    this.role = localStorage.getItem('role') || '';
  }

  ngOnInit() {

    this.created_by_role = localStorage.getItem('role');

    this.route.queryParams.subscribe(params => {
      this.paramValue = params['voucher_group_id'];
    });
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getMultigroupGenerationList(this.paramValue, this.headers).subscribe({
      next: (data: any) => {

        if (data.status === 1) {
          this.requestGroupVoucher = data.data;
          this.voucherList = this.requestGroupVoucher.voucher_list;
          this.showGroupVoucher = this.voucherList.map((e: any) => { return false })
          // console.log('showGroupVoucher', this.showGroupVoucher)
        }

      }
    })

  }

  toggleViewReport(id: number, idx: number, source: string, status:string) {
    if(status == "G"){
      if (this.showGroupVoucher[idx]) {
        this.showGroupVoucher[idx] = false;
        this.voucherGenerationList = [];
      } else {
        this.showGroupVoucher[idx] = true;
        this.viewReport(id, idx, source);
      }
    }else{
      this.toastr.info("Voucher Not Generated")
    }
   
  }

  viewReport(id: number, idx: number, source: string) {
    this.voucherId = id;
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };


    console.log('source', source)
    this.loader.start();
    let action = 'GET';
    if (source == "QwikCilver") {
      action = "QC"
    } else if (source == "QwikCilver") {
      action = "QC"
    }
    else if (source == "FlipKart") {
      action = "FlipKart"
    }
    else if (source == "Amazon") {
      action = "AMAZON"
    }
    else if (source == "Ticket Express") {
      action = "TE"
    }
    this._http.getVoucherGenerationListone(this.voucherId, this.limit, this.skip, this.headers, action,this.created_by_role).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data) {
          this.count = data.count;
          this.voucherGenerationList = data.data;
          this.showGroupVoucher = this.voucherList.map((e: any, index: number) => { return index === idx && this.voucherGenerationList?.length !== 0 ? true : false })

          // console.log("sadsfds", this.voucherGenerationList)
          // this.toolTipInit();
          if (this.skip === 0) {
            this.lastvalue = this.skip + this.limit;
          }
        }
      }, error: (_error) => {
        this.loader.stop();
      }
    })

  }

  getVoucherGenerationList(ids: number) {

    this._http.voucherDetailItem.subscribe(data => {

      // this.requestCouponVoucher = data;
    })

    this.issuancedate = this.requestCouponVoucher.issuance_date;

    if (this.requestCouponVoucher.source == 'QwikCilver') {

      let sourcevalue = this.requestCouponVoucher;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'QC';

      this._http.getVoucherGenerationLists(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            // this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.voucherGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else if (this.requestCouponVoucher.source == 'Ticket Express') {

      let sourcevalue = this.requestCouponVoucher;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'TE';

      this._http.getVoucherGenerationListTE(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            // this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.voucherGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else if (this.requestCouponVoucher.source == 'Amazon') {

      let sourcevalue = this.requestCouponVoucher;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'AMAZON';

      this._http.getVoucherGenerationListAmazon(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            // this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.voucherGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else if (this.requestCouponVoucher.source == 'FlipKart') {

      let sourcevalue = this.requestCouponVoucher;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'FlipKart';

      this._http.getVoucherGenerationListAmazon(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            // this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.voucherGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this.loader.start();
      this._http.getVoucherGenerationList(ids, this.limit, this.skip, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            // this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.voucherGenerationList));

            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })

    }
  }



  pageChanged(pageNo: number) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * this.limit;
    this.lastvalue = this.skip + 25;
    this.viewReport(this.voucherId, 0, '');
    localStorage.removeItem('logvalue');
  }

  back(){
    this.router.navigate(['/denomination-dashboard'],{replaceUrl:false})
  }

  // toggleAccordian() {
  //   this.showAccordion = !this.showAccordion;
  // }


}
