<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Internal Products List</label>
                </div>


                <div class="text-end col-9">
                    <div class="dropdown">
                        <button class="btn btn-success mr-10 float-right" (click)="addNewProduct()">Add New</button>
                    </div>
                </div>
            </div>


            <div class="d-flex">


                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="product" name="product" class="form-control border-0 bg-light"
                        placeholder="Product" style="width:210px;padding-top: 13px;"
                        (keypress)="filterList();onKeyPresscomma($event)">
                    <label for="tnums">Product</label>
                </div>

                <div class="form-floating me-3">

                    <select class="form-select" placeholder="Select Brand" [(ngModel)]="brand"
                        (change)="selectbrand(brand);filterList()">

                        <option value="" selected hidden>Select Brand</option>
                        <option *ngFor="let option of brandData" [value]="option.id">{{ option.name }}</option>

                    </select>

                </div>



                <button class="btn btn-success mr-5" style="width: 100px;" (click)="filterList()">Search</button>
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>

            </div>






            <div class="mt-4 batch-data px-1">

                <div class="row mx-0 adcolor py-4 opacity-75 px-3">

                    <div class="col labelText text-start"> Product </div>
                    <div class="col labelText text-start"> Brand </div>
                    <div class="col labelText text-start"> SKU </div>
                    <div class="col labelText text-start"> Face Value </div>
                    <div class="col labelText text-start"> Validity in Days</div>
                    <div class="col labelText text-start"> Image </div>
                    <div class="col labelText text-start " *ngIf="displayhide"> Display in
                        Voucherkart
                        <br><br>
                        <div class="row colinside">
                            <div class="col border-end">
                                BtoB
                            </div>
                            <div class="col border-end">
                                BtoC
                            </div>
                        </div>
                    </div>
                    <div class="col-1 labelText text-center"> Action </div>

                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                    *ngIf="ProductmgtList && ProductmgtList.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center">
                            No Record Found.
                        </div>
                    </div>
                </div>




                <div class=""
                    *ngFor="let list of ProductmgtList | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                    <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light">
                        <div class="card-body row mx-0 ps-0">


                            <div class="col text-truncate text-start" title="{{list.name}}"> {{list.name}} </div>

                            <div class="col text-truncate text-start" title="{{list.brand_name}}"> {{list.brand_name}}
                            </div>

                            <div class="col text-truncate text-start" title="{{list.sku}}"> {{list.sku}} </div>

                            <!-- <div class="col text-truncate text-center" title="(min {{list.minface_value}} - max {{list.maxface_value}})"> (min {{list.minface_value}} - max {{list.maxface_value}}) </div>     -->
                            <div *ngIf="list.variant === 'Digital'" class="col text-truncate text-center">
                                <ng-container *ngIf="list.face_value; else noFaceValue">
                                  {{ list.face_value | currency:'INR' }}
                                </ng-container>
                                <ng-template #noFaceValue>
                                  (min {{ list.minface_value | currency:'INR' }} - max {{ list.maxface_value | currency:'INR' }})
                                </ng-template>
                              </div>
                            <!-- <div *ngIf="list.variant !== 'Digital'" class="col text-truncate text-center" title="{{list.face_value | currency : 'INR'}}">{{list.face_value | currency : 'INR'}} </div>   -->



                            <div class="col text-truncate text-center" title="{{list.validity}}"> {{list.validity}}
                            </div>

                            <div class="col text-truncate"> <img class="imageval" [src]="this.pathurl+list.image">
                            </div>
                            <div class="col text-truncate text-start py-3 w-10" *ngIf="displayhide">

                                <div class="row">
                                    <div class="col">
                                        <div class="col text-start" *ngIf="displayhide">

                                            <div *ngIf="list.display_voucherkart === 'ON' ">
                                                <input type="checkbox" checked="true" class="filled-in form-check-input"
                                                    (click)="type(list,0,'OFF')" value="Voucherkart">
                                            </div>

                                            <div *ngIf="list.display_voucherkart === 'OFF' ">
                                                <input type="checkbox" class="filled-in form-check-input"
                                                    (click)="type(list,0,'ON')" value="Voucherkart">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col ">
                                        <div class="col text-start" *ngIf="displayhide">

                                            <div class="col text-center px-0">
                                                <div *ngIf="list.display_rewardkart === 'ON' ">
                                                    <input type="checkbox" checked="true"
                                                        class="filled-in form-check-input" (click)="type(list,1,'OFF')"
                                                        value="Rewardkart">
                                                </div>

                                                <div *ngIf="list.display_rewardkart === 'OFF' ">
                                                    <input type="checkbox" class="filled-in form-check-input"
                                                        (click)="type(list,1,'ON')" value="Rewardkart">
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-1 text-truncate text-center">

                                <a class="linkColor downloadIcon" #Edit (click)="editProduct(list)">
                                    <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                        title="Edit"></i>
                                </a>

                                <a class="linkColor" (click)="deleteModal(list.id)">
                                    <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                        title="Delete"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>



                <!-- 
            <div>
                <div class="row" *ngIf="ProductmgtList && ProductmgtList.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of
                            {{count}} entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>



            </div> -->



                <div>
                    <div class="row" *ngIf="ProductmgtList && ProductmgtList.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6">
                            <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>

<!-- <div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">    
                
                
                <p class="texts">{{Edit ? 'Update':'Add'}} Category</p>


                <form #addForm="ngForm" name="form" (ngSubmit)="AddCategory(AddrequestCategory)" class="mt-4">   
                    

                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Name
                            <span class="text-danger">*</span>
                        </label>
                         <input type="text" [(ngModel)]="AddrequestCategory.name" 
                            class="form-control" placeholder="Enter Name" mdbInput
                            autocomplete="off" name="name"
                            #nameval="ngModel"                                                      
                            (keypress)="letterOnly($event)"
                            (input)="restrictDecimals($event)" 
                           (keydown)="keyDownHandler($event)"  
                            
                            required >            
                                                        
                           
                        <span class="text-danger" 
                            *ngIf="(nameval.touched || addForm.submitted) && nameval.errors?.['required']">
                            name is required
                        </span>
                    </div>         
                    
            

                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Description
                            <span class="text-danger">*</span>
                        </label>
                         <textarea type="text" [(ngModel)]="AddrequestCategory.description" 
                            class="form-control" placeholder="Enter Description" mdbInput
                            autocomplete="off" name="description"
                            #description="ngModel"
                            (keypress)="letterOnly($event)"
                            (input)="restrictDecimals($event)" 
                            (keydown)="keyDownHandler($event)" 
                            required > </textarea>
                            
                          

                        <span class="text-danger" 
                            *ngIf="(description.touched || addForm.submitted) && description.errors?.['required']">
                            description is required
                        </span>
                    </div>         
                    


                    <div class="md-form form-sm my-3 leftAlign">

                        <label for="category" class="mb-2">Parent Category 
                            <span class="text-danger">*</span>
                        </label>
                        <select id="category" class="form-select" [(ngModel)]="AddrequestCategory.category"  (change)='selectCategoryEvent(AddrequestCategory.category)'
                        name="category" #category="ngModel" required>
                         
                        <option value="" selected disabled hidden>Select Parent Category</option>
                        <option *ngFor="let option of categoryData" [value]="option.id">{{ option.name }}</option>                       
                                              
                    </select>

                        <span class="text-danger"
                            *ngIf="(category.touched || addForm.submitted) && category.errors?.['required']">
                            Parent category is required
                        </span>
                    </div>
                                                                  
                  
                    
                

                   

                 
                    <p class="mt-3">
                        <button class="btn btn-primary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeproductBtn
                            type="button"  (click)="backclear()">Back</button>
                            <button class="btn btn-success px-4 py-2" type="submit"> {{Edit ? 'Update':'Add'}}</button>                           
                    </p>


                </form>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to delete the product?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closeproductBtn>Cancel</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteproduct()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div>