<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Clients Management List</label>
                </div>
                <!-- <div class="trees text-end col-3">
                    <div class="dropdown" *ngIf="role === 'SuperAdmin'">
                        <button class="btn btn-success mr-10 float-right" (click)="addCompany()">Refresh</button>
                    </div>
                    </div> -->

                <div class="trees text-end col-9">
                    <div class="dropdown" *ngIf="role === 'SuperAdmin'">
                        <!-- <button class="btn btn-success mr-10 float-right" (click)="walletRefresh()">Refresh</button> -->
                        <button class="btn btn-success mr-10 float-right" (click)="addCompany()">Add New</button>
                    </div>
                    </div>
                <!-- <div class="text-end col-9">
                <div class="dropdown " *ngIf="role === 'admin'">
                    <button class="btn btn-success mr-10 float-right" (click)="companySync()">Clients Sync</button>
                </div>
                </div> -->
            </div>

            <div class="d-flex">                        
                                            

                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="company" name="company"
                        class="form-control border-0 bg-light" placeholder="Clients"
                        style="width:210px;padding-top: 13px;" (keypress)="filterList()">
                        <label for="tnums">Clients</label>
                </div>
                <button class="btn btn-success mr-5" (click)="filterList()" style="width: 100px;">Search</button>
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>
            </div>


            <div class="mt-4 batch-data px-1">

                <div class="row mx-0 adcolor py-4 opacity-75 px-3">
                    <div class="col-2 px-0 labelText ps-4"> Clients ID </div>
                    <div class="col-4 pe-0 labelText text-center"> Clients Name </div>
                    <div class="col pe-0 labelText text-end"> Wallet </div>
                    <div class="col pe-0 labelText text-center"> Products </div>
                    <div class="col pe-0 labelText text-center"> Action </div>
                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                *ngIf="companyList && companyList.length === 0 && !apiLoading">
                <div class="card-body row mx-0 ps-0">
                    <div class="text-center">
                        No Record Found.
                    </div>
                </div>
            </div>


            
                <div>
                    <div class=""
                        *ngFor="let list of companyList | paginate: {itemsPerPage: limit, currentPage: c,totalItems:count};let i = index">
                        <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 ps-0 px-3">
                                <div class="col-2 text-truncate pe-0 ps-4"> {{list.id}} </div>
                                <div class="col-4 text-truncate ps-5 text-center"> {{list.CompanyName}} </div>
                                <div  class="col px-0 text-end productMap position-relative" >{{list.wallet | currency : 'INR' }}
                                    <a class="linkColor ms-2 position-absolute top-0" (click)="wallet(list)">
                                        <i class="fa fa-eye cursor col" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="View"></i>
                                    </a>
                                <!-- <span class="bg-5 position-absolute top-0 start-5 translate-middle badge rounded-pill">{{list.wallet | currency : 'INR' }}</span> -->
                                </div>
                               
                                <!-- <div class="col px-0 text-center" *ngIf = "list.contract_count !='0'">
                                    <button class="btn btn-success" (click)="viewcontract(list.id,list.CompanyName)">View Contract</button>
                                </div>

                                <div class="col px-0 text-center" *ngIf = "list.contract_count == '0'">
                                    <button class="btn btn-success" (click)="AddModal(list.id)">Add New</button>
                                </div> -->
                                <div class="col px-0 text-center productMap">
                                    <a (click)="productMap(list)">View</a>
                                </div>
                                <div class="col px-0 text-center">
                                    <!-- <button class="btn btn-success">Add New</button> -->
 
                                    <a class="linkColor downloadIcon" #Edit (click)="editCompany(list)">
                                        <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
                                    </a>
        
                                    
                                    <a class="linkColor1 downloadIcon" *ngIf="list.status == 'Active'">
                                        <i class="fa fa-toggle-on cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active" (click)="statusChange(list.id,'In-Active')"></i>
                                    </a>


                                    <a class="linkColor downloadIcon" *ngIf="list.status == 'In-Active'">
                                        <i class="fa fa-toggle-off cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(list.id,'Active')"></i>
                                    </a>
                                    
                                        <a *ngIf="role === 'SuperAdmin'" (click)="refreshModal(list)"><i class="fa fa-refresh btn btn-light "></i></a>
                                    
                                    
                                    <!-- <a class="linkColor" (click)="deleteModal(list.id)">
                                        <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                                    </a> -->
                                </div>
<!-- 
                                <div class="col px-0 text-center">
                                    <div *ngIf = "list.type === 'Internal' || list.type === 'internal' ">

                                        <input type="radio" id="list{{i}}" [(ngModel)]="selectinternal" value="Internal" name="supplierType{{i}}" 
                                        (change)="type(list,0)">                                    
                                        <label for=list{{i}} class="ms-2 me-4">Internal</label>
    
    
                                        <input type="radio"  id="liste{{i}}{{i+1}}" [(ngModel)]="list.type" value="External" name="supplierType{{i}}{{i+1}}" 
                                        (change)="type(list,1)" >
                                        <label for=liste{{i}}{{i+1}} class="ms-2 me-4">External</label>


                                    </div>

                                    <div *ngIf = "list.type === 'External' ||list.type === 'external'  ">


                                        <input type="radio" id="list{{i}}"  value="Internal" name="supplierType{{i}}" 
                                        (change)="type(list,0)">                                    
                                        <label for=list{{i}} class="ms-2 me-4">Internal</label>
    
    
                                        <input type="radio"  id="liste{{i}}{{i+1}}" [(ngModel)]="selectexternal" value="External" name="supplierType{{i}}{{i+1}}" 
                                        (change)="type(list,1)">
                                        <label for=liste{{i}}{{i+1}} class="ms-2 me-4">External</label>


                                    </div>
                                
                                </div>  -->



                               
                          


                             
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="companyList && companyList.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{c > 1 ? skip + 1 :c}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6">
                            <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="AddModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">                    
                <p class="texts"> {{Edit ? 'Update':'Add'}} Contract</p>
                <form #addForm="ngForm" name="form" (ngSubmit)="AddContract(AddContractCompany)" class="mt-4">  
                    



                    <div class="md-form form-sm my-3 leftAlign">
                        <label for="discount_type" class="mb-2">Discount Type 
                            <span class="text-danger">*</span></label>
                        <select id="discount_type" class="form-select" [(ngModel)]="AddContractCompany.discount_type" name="discount_type"
                            #discount_type="ngModel" (change)="selectdistypeEvent($event)" required>
                            <option value="" selected disabled hidden>Select Payment</option>
                            <option value="0">Percentage</option>
                            <option value="1">Value</option>
                        </select>
                        <span class="text-danger"
                            *ngIf="(discount_type.touched || addForm.submitted) && discount_type.errors?.['required']">
                            Discount Type is required
                        </span>
                    </div>


                                                                  
                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Discount
                            <span class="text-danger">*</span>
                        </label>
                         <input type="text" [attr.maxlength]="AddContractCompany.discount_type == 0 ? '2' : '8' " [(ngModel)]="AddContractCompany.discount_value" name="discount_value"
                            #discount_value="ngModel" class="form-control" placeholder="Enter Discount" mdbInput
                            autocomplete="off" required                           
                                                     
                            (input)="restrictDecimals($event)" 
                            (keypress)="numberOnly($event);restrictAlphabets($event)" (keydown)="keyDownHandler($event)"                            
                            >
                        <span class="text-danger"
                            *ngIf="(discount_value.touched || addForm.submitted) && discount_value.errors?.['required']">
                            Discount is required
                        </span>
                    </div>         
                    
                    
                    <div class="md-form form-sm my-3  leftAlign position-relative">
                        <label class="mb-2">Issuance Fee
                            <span class="text-danger">*</span>
                            <!-- <span class="rs">(INR)</span> -->
                        </label>
                        <i class="fa fa-inr currency-icon"></i>
                         <input type="text" [(ngModel)]="AddContractCompany.issuance_fee" name="issuance_fee"
                            #issuance_fee="ngModel" class="form-control custom-input-style" placeholder="Enter Issuance Fee" mdbInput
                            autocomplete="off" 

                               (input)="restrictDecimals($event)" 
                            (keypress)="numberOnly($event);restrictAlphabets($event)" (keydown)="keyDownHandler($event)"     
                             
                             required >                      
                          
                            <span class="text-danger"
                            *ngIf="(issuance_fee.touched || addForm.submitted) && issuance_fee.errors?.['required']">
                            Issuance Fee is required
                        </span>
                    </div>    

                    <div class="md-form form-sm my-3  leftAlign">
                        <label for="payment" class="mb-2">Payment 
                            <span class="text-danger">*</span></label>
                        <select id="payment" class="form-select" [(ngModel)]="AddContractCompany.payment" name="payment"
                            #payment="ngModel" required>
                            <option value="" selected disabled hidden>Select Payment</option>
                            <option value="0">Advance</option>
                            <option value="1">Credit</option>
                        </select>
                        <span class="text-danger"
                            *ngIf="(payment.touched || addForm.submitted) && payment.errors?.['required']">
                            Payment is required
                        </span>
                    </div>



                    <div class="md-form form-sm my-3  leftAlign position-relative">
                        <label for="start_date" class="mb-2">Start Date 
                            <span class="text-danger">*</span>
                        </label>

                        <input (click)="dp.openCalendar()" [(ngModel)]="getstartDate" name="start"
                            placeholder="Select A Date" class="form-control datepicker" ngx-mydatepicker
                            [options]="startOptions" readonly #dp="ngx-mydatepicker"
                            type="text" #start="ngModel" (dateChanged)="onStartDateChanged($event)"
                            (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="dp.openCalendar()" (click)="dp.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger" *ngIf="(addForm.submitted) && start.errors?.['required']">
                            start date is required
                        </span>
                    </div>

                    <div class="md-form form-sm my-3  leftAlign position-relative">
                        <label for="start_date" class="mb-2">End Date 
                            <span class="text-danger">*</span>
                        </label>

                        <input (click)="edp.openCalendar()" [(ngModel)]="getendDate" name="end"
                            placeholder="Select A Date" class="form-control datepicker" ngx-mydatepicker
                            [options]="endOptions" readonly #edp="ngx-mydatepicker"
                            type="text" #end="ngModel" (dateChanged)="onEndDateChanged($event)"
                            (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="edp.openCalendar()" (click)="edp.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger" *ngIf="(addForm.submitted) && end.errors?.['required']">
                            End date is required
                        </span>
                    </div>
                 
                    <p class="mt-3">
                        <button class="btn btn-secondary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeSupplierBtn
                            type="button"  (click)="backclear()">Back</button>
                        <button class="btn btn-success px-4 py-2" type="submit">Add</button>                      
                     
                    </p>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>

<div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to delete the client?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closebrandBtn>Cancel</button>
                    <button class="btn btn-success" data-bs-dismiss="modal" (click)="deletebrand()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="refreshModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to reset the wallet amount?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closeproductBtn>Cancel</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="walletRefresh()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div>