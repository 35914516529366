<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mx-0">
                <div class="col-3">
                    <label class="texts">Digital Voucher MIS Report </label>
                </div>
                <div class="text-end col-9">
                    <div class="dropdown">
                        <a class="add_bt btn btn-success mr-10" type="button" (click)="voucherDownload()" target="_self"
                            download="sample-file.csv">
                            <i class="fa fa-download pr-1"></i> Download
                        </a>
                        <!-- <button class="btn btn-success mr-10 float-right fa fa-download pr-1" download="sample-file.csv" (click)="addDownload()">Download</button> -->
                    </div>
                </div>
                <div>
                    <div class="row mx-0 mt-3">
                        <div class="col">
                            <div class="md-form form-sm my-3 position-relative">
                                <input
                                    (click)="dp.openCalendar(); openCalendarAndDetectChanges(dp)"
                                    [(ngModel)]="getstartDate" name="start" placeholder="Select Start Date"
                                    class="form-control datepicker p-3 bg-light border-0 " (ngModelChange)="checkButtonStatus()"
                                    ngx-mydatepicker [options]="startOptions" readonly #dp="ngx-mydatepicker"
                                    type="text" #start="ngModel" (dateChanged)="onStartDateChanged($event)"
                                    (calendarViewChanged)="onCalendarViewChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 12px; right: 0">
                                    <a href="javascript:void(0)" (focus)="dp.openCalendar()"
                                        (click)="dp.openCalendar()"><img src="../../../assets/images/icons/calendar.svg"
                                            width="32"></a>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(start.dirty || start.touched) && start.errors?.['required'] && !dateRequire">
                                    start date is required
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="md-form form-sm my-3 position-relative">
                                <input
                                    (click)="edp.openCalendar(); openCalendarAndDetectChange(edp)"
                                    [(ngModel)]="getendDate" name="end" placeholder="Select End Date"
                                    class="form-control p-3 bg-light border-0 datepicker" ngx-mydatepicker [options]="endOptions" readonly
                                    #edp="ngx-mydatepicker" type="text" (ngModelChange)="checkButtonStatus()"
                                    #end="ngModel" (dateChanged)="onEndDateChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 12px; right: 0">
                                    <a href="javascript:void(0)" (focus)="edp.openCalendar()"
                                        (click)="edp.openCalendar()"><img
                                            src="../../../assets/images/icons/calendar.svg" width="32"></a>
                                </span>
                                <span class="text-danger"
                                    *ngIf="end.touched && end.errors?.['required'] && !dateRequire">
                                    End date is required
                                </span>
                            </div>

                        </div>
                        <div class="col">
                            <div class="md-form form-sm my-3">
                                <select class="form-select p-3 bg-light border-0" placeholder="Select Currency" [(ngModel)]="payment_type"  
                                         (change)="paymentType(payment_type)">
                                         <option value="" selected hidden>Payment Type</option>
                                          <option *ngFor="let option of paymentDatas" [value]="option.value">{{ option.label}}</option>            
                                      </select>  
                              </div>
                        </div>
                        <div class="col">
                            <div class="md-form form-sm my-3">
                                <select class="form-select p-3 bg-light border-0" placeholder="Select Currency" [(ngModel)]="sourceName"  
                                         (change)="sourceType(sourceName)">
                                         <option value="" selected hidden>Client Type</option>
                                          <option *ngFor="let option of sourceDatas" [value]="option.value">{{ option.label}}</option>            
                                      </select>  
                              </div>
                        </div>
                        <div class="col align-self-center">
                            <div class="form-floating me-3">
                                <input type="text" [(ngModel)]="supplierName" name="sourcename"
                                    class="form-control border-0 bg-light" placeholder="Client Name">
                                    <label for="tnums">Client Name</label>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row mx-0 mt-2">
                        <div class="col-6">

                        </div>
                        <div class="col-6 text-end align-self-center">
                            <div class="row mx-0 justify-content-end">
                                <div class="col-3">
                                    <button class="btn btn-success p-3" style="width:100px;" (click)="filterData()">Search</button>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-secondary p-3" style="width:100px;" (click)="clear()">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 card border-0 shadow-sm px-0">
                    <div class="card-body">
                        <table class="table small">
                            <thead class="adcolor py-4 px-3 mx-0 opacity-75">
                                <tr>
                                    <th scope="col" class="text-white p-2" *ngFor="let column of columns">{{column}}</th>
                                    <!-- <th scope="col" class="pt1">Order Number</th>
                                    <th scope="col" class="pt1">Quantity</th>
                                    <th scope="col" class="pt2">Invoice Amount</th>
                                    <th scope="col" class="pt2">Total Amount</th>
                                    <th scope="col" class="pt1">Create Date</th> -->
                                </tr>
                            </thead>

                            <tbody *ngIf="getDigital.length > 0">
                                <tr *ngFor="let value of getDigital | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                                    <td *ngFor="let key of keys(value)" scope="row">{{ value[key] !== null && value[key] !== undefined ? checkDecimal(value[key],key) : '-' }}</td> 
                                    <!-- <th scope="row">{{value.voucher_request_id}}</th>
                                    <td class="text-center">{{value.quantity}}</td>
                                    <td class="text-end">{{value.currency_symbol ? value.currency_symbol + value.invoice_value : value.invoice_value | currency:"₹"}}</td>
                                    <td class="text-end">{{value.currency_symbol ? value.currency_symbol + value.total : value.total | currency:"₹"}}</td>
                                    <td class="text-center">{{value.created_on}}</td> -->
                                </tr>
                            </tbody>
                            <tbody *ngIf="getDigital.length === 0">
                                <tr>
                                    <td [colSpan]="columns.length" class="p-4 fw-bold text-center border-0">No Records Found</td>
                                </tr>
                            </tbody>

                        </table>
                        <div>
                            <div class="row" *ngIf="getDigital.length > 0">
                                <div class="col-6 pt-3">
                                    <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                                        {{count}} entries</p>
                                </div>
                                <div class="col-6">
                                    <pagination-controls class="list-pagination adpage"
                                        (pageChange)="pageChanged($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
