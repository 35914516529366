import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Login } from '../models/Login';
import { AppService } from '../restful-services/restful.service';
import { CookieService } from "ngx-cookie-service";
import { HttpHeaders } from '@angular/common/http';



@Component({
  selector: 'loyalty-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  login: Login;
  @Output() loggedStatus = new EventEmitter<boolean>();
  role: string | null;

  private headers: any = {};

  voucher: any;
  vouchers: any;


  public CID: string = "";

  constructor(private route: ActivatedRoute, private router: Router, private _http: AppService, private toastr: ToastrService, private cookieService: CookieService) {
    this.role = 'admin';
    this.login = new Login();
    // this.role = localStorage.getItem('role')

    // if (!this.role) {
    //   localStorage.setItem('role', 'admin');
    //   this.roleType('admin');
    // }
    // else {   
    //   // this.roleType(this.role);
    //   this.roleType('admin');
    //   localStorage.setItem('role', 'admin');
    // }



    let logged = localStorage.getItem('logged');

    if (logged && logged === 'in') {
      this.router.navigate(['/dashboard']);
    }

  }


  onSubmit() {

    if (this.role === 'admin') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      const params = {

        "username": this.login.UserName,
        "password": this.login.Password

      }

      this._http.adminlogin(params, this.headers).subscribe({
        next: data => {

          if (data.status == "0") {

            this.toastr.error(data.msg);
          }


          else if (data.status == "1") {

            if (data) {
          
              let token = data.token;
              localStorage.setItem('token', token);
              localStorage.setItem('role', data.data
              .role==='Admin'?'admin':data.data
              .role);
              localStorage.setItem('logged', 'in');
              localStorage.setItem('userDetails', JSON.stringify(data));
              this.loggedStatus.emit(true);

              this.CID = (window.location.href);

              if (this.CID.indexOf("/digital-order-view") !== -1) {                      

              }
              else if (this.CID.indexOf("/product-order-view") !== -1) {

              }
              else {
                this.router.navigate(['/dashboard'], { replaceUrl: false });
              }
            }

            let token = localStorage.getItem("token");
            this.headers = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              })
            };


            this._http.getsettingslist(this.headers).subscribe({
              next: (res: any) => {

                if (res) {

                  let setvalue = res.data.order_limit_amount;

                  localStorage.setItem('settingslist', setvalue);

                }
              }
            })        
          };

        },
        error: _error => {

          this.toastr.error("User does not exist.");

        }
      })
    }

    else if (this.role === 'brand') {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      const params = {

        "username": this.login.UserName,
        "password": this.login.Password

      }

      this._http.brandlogin(params, this.headers).subscribe({
        next: data => {

          if (data.status == "0") {

            this.toastr.error(data.msg);
          }


          else if (data.status == "1") {

            if (data) {

              let token = data.token;
              localStorage.setItem('token', token);

              localStorage.setItem('role', data.data
              .role==='Brand'?'brand':data.data
              .role);

              localStorage.setItem('logged', 'in');
              localStorage.setItem('userDetails', JSON.stringify(data));
              this.loggedStatus.emit(true);
              this.router.navigate(['/dashboard'], { replaceUrl: false });
            }
          };

        },
        error: _error => {
          this.toastr.error("User does not exist.");
        }
      })

    }

    else {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      const params = {

        "username": this.login.UserName,
        "password": this.login.Password

      }

      this._http.companylogin(params, this.headers).subscribe({
        next: data => {

          if (data.status == "0") {

            this.toastr.error(data.msg);
          }


          else if (data.status == "1") {

            if (data) {

              let token = data.token;
              localStorage.setItem('token', token);
              // localStorage.setItem('role','brand')

              localStorage.setItem('role', data.data
              .role==='Brand'?'brand':data.data
              .role);

              localStorage.setItem('logged', 'in');
              localStorage.setItem('userDetails', JSON.stringify(data));
              this.loggedStatus.emit(true);
              this.router.navigate(['/dashboard'], { replaceUrl: false });
            }
          };

        },
        error: _error => {
          this.toastr.error("User does not exist.");
        }
      })
    }

  }


  loggedIn() {
    return this._http.getToken() !== '';
  }


  roleType(role: string) {
 
    this.role = role;
    // localStorage.setItem('role', role)
  }

}
