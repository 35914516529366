<div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true"
    style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">

                <p class="texts">Update Brand</p>


                <!-- <form #addForm="ngForm" name="form"  class="mt-4">    -->



                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">Name <span class="text-danger">*</span>
                    </label>
                     <input type="text" [(ngModel)]="nameList" class="form-control" placeholder="Enter Name" mdbInput
                        autocomplete="off" name="name" #nameval="ngModel" (keypress)="onKeyPresscomma($event)"
                        [disabled]="userName" (input)="restrictDecimals($event)" required>

                    <span class="text-danger" *ngIf="nameval.touched  && nameval.errors?.['required']">
                        name is required
                    </span>


                </div>

                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">Description
                        <span class="text-danger">*</span>
                    </label>
                     <textarea type="text" [(ngModel)]="descriptionList" class="form-control"
                        placeholder="Enter Description" mdbInput autocomplete="off" name="description"
                        #description="ngModel" (keypress)="onKeyPresscomma($event)" [disabled]="userName"
                        (input)="restrictDecimals($event)" required>
                            <span class="text-danger" 
                            *ngIf="description.touched && description.errors?.['required']">
                            description is required
                        </span> </textarea>

                </div>
                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">B2C Name

                    </label>
                     <textarea type="text" [(ngModel)]="b2cname" class="form-control" placeholder="Enter Description"
                        mdbInput autocomplete="off" name="description" #description="ngModel"
                        (keypress)="onKeyPresscomma($event)" (input)="restrictDecimals($event)" required> </textarea>

                </div>


                <p class="mt-3">
                    <button class="btn btn-secondary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                        type="button">Back</button>
                    <button class="btn btn-success px-4 py-2" data-bs-dismiss="modal"
                        (click)="editedDatas()">Update</button>
                </p>


                <!-- </form> -->
            </div>
        </div>
    </div>
</div>

<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">External Brand </label>
                </div>

                <div class="d-flex">


                    <div class="form-floating me-5">
                        <input type="text" [(ngModel)]="external" name="external" class="form-control border-0 bg-light"
                            placeholder="Name" style="width:210px;padding-top: 13px;" (keypress)="filterList()">
                        <label for="tnums">Brand Name</label>
                    </div>
                    <button class="btn btn-success mr-5 " (click)="filterList()" style="width: 100px;">Search</button>
                    <button class="btn btn-secondary mr-5 " style="width: 90px;" (click)="clear()">Clear</button>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 card border-0 shadow-sm px-0">
                    <div class="card-body pb-4">
                        <table class="table mb-0">
                            <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
                                <tr>
                                    <th scope="col" class="pt1">SI.No</th>
                                    <th scope="col" class="pt1">Brand Name</th>
                                    <th scope="col" class="pt1">B2C Name</th>
                                    <th scope="col" class="pt1 text-center">Description</th>
                                    <th scope="col" class="pt1 text-center">Brand Image</th>
                                    <!-- <th scope="col" class="pt1">Action</th> -->
                                </tr>
                            </thead>                

                            <tbody>
                                <tr
                                    *ngFor="let value of getExternal | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                                    <th scope="row">{{i + 1+skip}}</th>
                                    <td>{{value.brand_name}}</td>
                                    <td>{{value.b2c_name}}</td>
                                    <td class="text-center">{{value.description || '-'}}</td>
                                    <td class="text-center"><img class="imageval" [src]="value.brand_image"></td>

                                    <!-- <td class="text-center"><a class="linkColor downloadIcon" #Edit
                                            (click)="editCategory(value)">
                                            <i class="fa fa-edit mr-10" data-bs-toggle="modal"
                                                data-bs-placement="bottom" data-bs-target="#exampleModal"
                                                title="Edit"></i>
                                        </a></td> -->
                                </tr>
                            </tbody>

                        </table>
                        <table class="w-100" *ngIf="getExternal && getExternal.length === 0 && !apiLoading">
                            <tr class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light" >
                                <td class="card-body text-center m-1" >
                                    No Record Found.
                                </td>
                            </tr>
                        </table>
                        
                        <div>
                            <div class="row"  *ngIf="getExternal && getExternal.length > 0">
                                <div class="col-6 pt-3">
                                    <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                                        {{count}} entries</p>
                                </div>
                                <div class="col-6">
                                    <pagination-controls class="list-pagination adpage"
                                        (pageChange)="pageChanged($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngx-ui-loader></ngx-ui-loader>
        </div>
    </div>