<div class="row">
  <div col="3">
    <label for="voucher" class="voucher">Voucher Wallet History</label>
  </div>
  <table class="table ">
    <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
      <tr>
        <th scope="col" class="pt1">S.No</th>
        <th scope="col" class="pt1">Redemption Amount</th>
        <th scope="col" class="pt1">Redemption Date</th>
        <th scope="col" class="pt1">Redeem User Email</th>
      </tr>
    </thead>
    <div style="width: 419%;" class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
      *ngIf="voucherWalletHistory.length === 0 || !apiLoading">
      <div class="card-body row mx-0 ps-0">
        <div class="text-center">
          No Record Found.
        </div>
      </div>
    </div>
    <tbody>
      <tr
        *ngFor="let value of voucherWalletHistory | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count} ;let i  = index">
        <th scope="row">{{i+1+skip}}</th>
        <td>{{value.voucher_redeem_amount}}</td>
        <td>{{value.voucher_redeem_date | date: 'dd/MM/yyyy'}}</td>
        <td>{{value.redeem_user_email}}</td>
      </tr>
    </tbody>

  </table>


  <ngx-ui-loader></ngx-ui-loader>



  <!-- Pagination controls -->
  <!-- <div class="pagination-controls">
  <pagination-controls (pageChange)="p = $event" class="float-right" ></pagination-controls>
</div> -->

  <div class="row" *ngIf=" voucherWalletHistory.length > 0">
    <div class="col-6 pt-3">
      <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of {{count}}
        entries</p>

    </div>
    <div class="col-6">
      <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </div>
  <div>
    <button class="btn backcolor" style="display: flex; float: right;" (click)="back()">Back</button>
  </div>
</div>