<header class="p-0 shadow-sm bg-white">
    <div class="d-flex">
        <div  class="text-end my-3 mr-3 px-4 flex-fill bd-highlight my-4 example" *ngIf="walletrole ==='Company' ">
            <a (click)="getWallet()">
                 Wallet Amount <i class="fa fa-refresh btn btn-light "></i>
                 <span>{{wallet !== null && wallet !==
                    undefined ? (wallet | currency:"₹") : 0 | currency:"₹"}}</span>
            </a>
        </div>
        <div id="logoutDrop" class="px-4 flex-fill bd-highlight text-end border-end my-4"  >
            <div>
                <h6 class="mr-5 mb-0">{{companyName ? companyName : userName}}</h6>
            </div>
            <div class="dropdown small">
                <button class="btn btn-sm dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-
                    bs-toggle="dropdown" aria-expanded="false">
                    {{rolename}}
                </button>
                <div class="dropdown-content text-center" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item waves-light logOutBtn" style="cursor: pointer;" (click)="logout()">Log
                        out</a>
                </div>
            </div>
        </div>

        <div class="px-4 my-4">
            <img *ngIf = "imageURL" [src]="this.pathurl + imageURL" alt="no img" width="50" height="50">
            <img *ngIf = "!imageURL" src="../../assets/images/vo_dp_1.png" alt="no img" width="50" height="50">
        </div>
    
    </div>
</header>   