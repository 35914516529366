import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DKDashboardComponent } from './dashboard/dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { DealKartEditComponent } from './dashboard/deal-kart-edit/deal-kart-edit.component';
import { DKCreateOrderComponent } from './create-order/create-order.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        ToastrModule.forRoot()
    ],
    declarations: [
        DKCreateOrderComponent,
        DKDashboardComponent,
        DealKartEditComponent
    ],
    exports: [],
    providers: []
})
export class DealKartModule {}