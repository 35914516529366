import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from "../restful-services/restful.service";
import { ToastrService } from "ngx-toastr";
import { Modal } from 'bootstrap';

@Component({
  selector: "app-currency-markup",
  templateUrl: "./currency-markup.component.html",
  styleUrls: ["./currency-markup.component.scss"],
})
export class CurrencyMarkupComponent implements OnInit {
  @ViewChild('closeCategoryBtn', { static: true }) closeCategoryBtn!: ElementRef;
  // @ViewChild("currencyForm", { static: true }) currencyForm: any;
  textBoxes: string[] = [""];
  private headers: any = {};
  currencyData : any = [];
  currencyPay : any =[];
  currencyAdd : any = [];
  currencyListdata:any =[];
  currency : any ='';
  payment :any = '';
  markup:any= '';
  percentage:any = "";
  skip: number = 0;
  limit: number = 10;
  lastvalue: number = 0;
  count: number = 0;
  d: number = 1;
  apiLoading: boolean = false;
  removeBox: boolean = false;
  addCurrency: boolean = false;
  update:boolean = false;
  addBox:boolean = false;
  showUpdate:boolean = false;
  i: any;
  currencyValue: any
  idvalue: any;
  source: any = "";
  columns:Array<string> = [];
  primary:Array<string> = [];
  constructor(private _http: AppService,private toastr: ToastrService,private loader: NgxUiLoaderService) {}
  ngOnInit(): void {
    this.onChangeParcategory();
    this.currencyList();
  }


  addNewCurrency(){
    this.update = false;
    this.currency=''
    this.payment=''
    this.markup = '';
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
  }  

  addTextBox() {
    
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    const params = {

      "from_currency": this.currency,
      "to_currency": this.payment,
      "percentage" : this.percentage,
      "markup": this.markup,
      "source": this.source

    }
  
    this._http.getCurrencymarkup(params,this.headers).subscribe((data: any) => {

      this.loader.stop();
      
      this.currencyAdd = data.data;
      this.currencyList();
      
      if (data.status == 1) {
        this.loader.start();
        this.closeCategoryBtn.nativeElement.click();
        this.toastr.success(data.msg)
      } else {
        // this.currencyForm.form.reset();
        this.toastr.error(data.msg)
      }
      this.clear();


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

  editUpdate(){
  const params = {
    "id": this.idvalue,
    "from_currency": this.currency,
    "to_currency": this.payment,
    "percentage" : this.percentage,
    "markup": this.markup,
    "source": this.source
  }
  this.apiLoading = true;
  let token = localStorage.getItem("token");
  this.headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  };

  this._http.getEditCurrencymarkup(params, this.headers).subscribe((data: any) => {
    this.currencyAdd = data;
    this.currencyList();

    if (data.status == 1) {
      this.closeCategoryBtn.nativeElement.click();
      this.toastr.success(data.msg)
    } else {
      this.toastr.error(data.msg)
    }
    this.clear();
    // data = this.updatedDatas;
  },

    (error) => {
      this.toastr.error(error.error.Message)
      // console.log("error",error);

    }
  )
  }

  selectcurrency(){
   
  }
  selectpaycurrency(){

  }

  currencyList(){
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this._http.getCurrencyList(this.headers).subscribe((data: any) => {
      this.count = data.count;
      this.currencyListdata = data.data;
      this.columns = [
        "SI.No",
        "Product Currency",
        "Payment Currency",
        "Percentage(%)",
        "Markup"
      ]
      this.primary = [
        "from_currency",
        "to_currency",
        "percentage",
        "markup"
      ]
      this.count = data.count;
      this.loader.stop();


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }
  onChangeParcategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getCurrency(this.headers).subscribe((data: any) => {
      
      this.currencyData = data.data;
      
      this.currencyPay = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }
  
  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = "0123456789.,"; // Define the allowed characters
    const inputChar = event.key;

    if (
      inputChar === " " ||
      (inputChar !== "." && allowedCharacters.indexOf(inputChar) === -1)
    ) {
      event.preventDefault(); // Prevent the input
    }
  } 
  clear(){
     this.currency = "";
     this.payment = "";
     this.markup = "";
    
  }

  editEvent(event:any) {
    if(event.isEdit){
    this.update = true;
    this.addBox = false;
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.idvalue = event.data.id;
    this.currencyValue = this.currencyListdata.find((p: any) => {
      return p.id == event.data.id;

    });
    this.currency = this.currencyValue.from_currency;
    this.payment = this.currencyValue.to_currency;
    this.markup = this.currencyValue.markup;
    this.percentage = this.currencyValue.percentage;
    this.source = this.currencyValue.source;
    }
  }

  page(event:any) {
    if(event) {
      this.currencyList();
    }
  }
}
