export let apiurl = "";
export let filesapiurl = "";

let host = location.host;

if(host && (host === "localhost:5301" || host === "dev.voucherkart.co.in")){
  apiurl = "https://dev-api.voucherkart.co.in/";
  filesapiurl = "https://dev-api.voucherkart.co.in";
}
else if(host && host === "demo.voucherkart.co.in"){
  apiurl = "https://demo-api.voucherkart.co.in/";
  filesapiurl = "https://demo-api.voucherkart.co.in";
}
else if(host && host === "admin.voucherkart.co.in"){
  apiurl = "https://api.voucherkart.co.in/";
  filesapiurl = "https://api.voucherkart.co.in";
}
else{
  apiurl = "http://54.70.101.219:8080/";
  filesapiurl = "http://54.70.101.219:8080";
}


export const environment = {
  production: false,
  ENVIRONMENT: {
    baseURL: apiurl,
  },
};

// file upload url

export const environmentfiles = {
  production: false,
  ENVIRONMENT: {
    baseURL: filesapiurl + "/",
  },
};
