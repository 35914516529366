<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Brand Management List</label>
                </div>

                    
                <div class="trees col-9">
                    <div class="dropdown">
                        <button class="btn btn-success mr-10 float-right" (click)="addNewcategory()">Add New</button>
                    </div>
                    </div>
            </div>


            <div class="d-flex">      
                
                <div class="form-floating me-3">              

                    <select class="form-select" placeholder="Select Category" [(ngModel)]="category"  
                   (change)="selectcategory(category);filterList()">
                     
                   <option value="" selected hidden>Select Category</option>
                        <option *ngFor="let option of categoryData" [value]="option.id">{{ option.name }}</option>            
                </select>        

            </div>

                                            

                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="brand_name" name="brand_name"
                        class="form-control border-0 bg-light" placeholder="Name"
                        style="width:210px;padding-top: 13px;" (keypress)="filterList();onKeyPresscomma($event)">
                        <label for="tnums">Brand Name</label>
                </div>


                <!-- <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="category" name="category"
                        class="form-control border-0 bg-light" placeholder="Category"
                        style="width:210px;padding-top: 13px;" (keypress)="filterList()">
                        <label for="tnums">Category</label>
                </div> -->

           

                <button class="btn btn-success mr-5" style="width: 100px;" (click)="filterList()">Search</button>
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>
           
            </div>




          

            <div class="mt-4 batch-data px-1">

                <div class="row mx-0 adcolor py-4 opacity-75 px-3">                

                    <div class="col labelText text-start">Entity Name</div>
                    <div class="col labelText text-start"> Brand Name</div>
                    <div class="col labelText text-start"> Description</div>                                                     
                    <div class="col labelText text-start"> Category </div>  
                    <div class="col labelText text-start"> Brand Image </div>  
                    <div class="col labelText text-center"> Action </div>           
                   

                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                *ngIf="brandmgtList && brandmgtList.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center">
                            No Record Found.
                        </div>
                    </div>
                </div>


                <div class=""
                *ngFor="let list of brandmgtList | paginate: {itemsPerPage: limit, currentPage: b,totalItems:count};let i = index">
                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light">
                    <div class="card-body row mx-0 ps-0">


                        <div class="col text-truncate text-start" title="{{list.entity_name}}"> {{list.entity_name}} </div>                      

                        <div class="col text-truncate text-start" title="{{list.name}}"> {{list.name}} </div>                       
                       
                        <div class="col text-truncate text-start" title="{{list.description}}"> {{list.description}} </div>                      

                        <div class="col text-truncate text-start" title="{{list.description}}"> {{list.category_name}} </div>                       
                       
                        <div class="col text-center text-truncate"> <img class="imageval" [src]="this.pathurl+list.image">  </div> 

                        <div class="col text-center">      

                            <a class="linkColor downloadIcon" #Edit (click)="editbrand(list)">
                                <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
                            </a>

                            <a class="linkColor" (click)="deleteModal(list.id)">
                                <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                            </a>


                        </div>


                    
                      </div>
                </div>
            </div>
              
                <div>

                    <div class="row" *ngIf="brandmgtList && brandmgtList.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{b > 1 ? skip + 1 :b}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6">
                            <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>


<div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to delete the brand?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closebrandBtn>Cancel</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deletebrand()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div>




