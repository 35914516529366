import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BKDashboardComponent } from './dashboard/dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { BrandKartEditComponent } from './dashboard/brand-kart-edit/brand-kart-edit.component';
import { BKCreateOrderComponent } from './create-order/create-order.component';
import { ToastrModule } from 'ngx-toastr';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        QRCodeModule,
        ToastrModule.forRoot()
    ],
    declarations: [
        BKCreateOrderComponent,
        BKDashboardComponent,
        BrandKartEditComponent
    ],
    exports: [],
    providers: []
})
export class BrandKartModule {}