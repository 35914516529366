export class RequestBrandmgt {

    // dashboard
    name:any;
    names:any;
    description:any;
    parent_category : any;
    created_by:any;
    created_date:any;
    modify_by : any;
    modify_date:any;
    parent_category_name:any; 
    category:any = '';
    id:any;
    contact_number:any;
    account_number:any;
    account_holder_name:any;
    bank_name:any;
    bank_ifsc_code:any;
    bank_branch:any;
    image:any;

  
     
//edit
    entity:any;
    entity_name:any;
    brand_name:any;
    user_name:any;
    password:any;
    email:any;
    contact:any;
    wallet:any;
    actnumber:any;
    actholdername:any;
    bankname:any;
    ifsc:any;
    branch:any;
    file: any;
    confirmpassword:any;



}