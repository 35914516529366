import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonTableComponent } from './common-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [CommonTableComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot()
  ],
  exports : [
    CommonTableComponent
  ]
})
export class CommonTablesModule { }
