import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { VoucherWalletHistoryComponent } from './voucher-wallet-history.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';



@NgModule({
  declarations: [VoucherWalletHistoryComponent],
  imports: [
    CommonModule,NgxPaginationModule,
    NgxUiLoaderModule
  ]
})
export class VoucherWalletHistoryModule { }
