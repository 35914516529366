export class RequestContractCompany {

    company_id:any;
    companyName:any;
    id:any;
    discount_type:any;
    discount_value:any;
    issuance_fee:any;
    payment:any;
    start_date:any;
    end_date:any;
    kart_type:any;
    action:any;
    payment_type:any;
    payment_status:any;
   
}