import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { AppService } from './../restful-services/restful.service';
import { ChartData, ChartType } from 'chart.js';
import { RequestBrand } from './../models/RequestBrand';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { browserRefresh } from './../app.component';
import { Supplier } from './../models/supplierList';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RequestCompany } from '../models/RequestCompany';
declare var bootstrap: any;

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit{

  selectinternal : string = 'Internal';
   selectexternal : string = 'External';
  Internal:any;
  selectedValue: any;
  public supplierName: string = '';
  public status: string = '';
  supplierDetail: Supplier;
  supplierList: Supplier[];
  companyList:RequestCompany[];
  limit: number = 25;
  supplierType: any;
  skip: number = 0;
  p: number = 1;
  search:any;
  test:any;
  count: number = 0;
  lastvalue: number = 0;
  @ViewChild('closeSupplierBtn', { static: true }) closeSupplierBtn!: ElementRef;
  role: any;
  public external="external";
  userDetails: any;
  @ViewChild('inputFile') inputFile: any;
  supplierId: any;
  supplierStatus: any;
  duplicateSupplier: boolean = false;
  user_type:any;
  RequestCompany: any;

  backendValue: any;
  private headers:any={};
  public brandtype: string = "";
  apiLoading: boolean = false;


  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.supplierDetail = new Supplier();
    this.supplierList = new Array<Supplier>();
    this.companyList=new Array<RequestCompany>();
  }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    this.getbrand();
    // this.getSupplierList();

    // this.backendValue = this.companyList;
  }

  filterList() {

    if (this.brandtype) {
      this.skip = 0;
      this.p = 1;
    }
    this.getbrand();
  }

  getbrand(){

    this.supplierType = "internal";

    let token = localStorage.getItem("token");
  this.headers = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
     })
   }; 

   this.apiLoading = true;
   this.loader.start();
    this._http.getBrandList(this.limit, this.skip, this.brandtype,this.headers).subscribe(
      (data: any) => {    

        this.apiLoading = false;
        this.loader.stop();
     
        this.supplierList = data.data;
        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
       
        
        if (data) {
          this.count = data.count;
          this.companyList = data.data;
        }
      }, _error=>{
        this.apiLoading = false;
        this.loader.stop();
      })
  }


  brandSync() {
  

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };
     
     this.loader.start();
    this._http.brandSync(this.headers).subscribe((data: any) => {
      this.loader.stop();
     
      if (data.status == 1) {
        this.toastr.success("Brand Sync Successfully");
      }
    })
  }


  clear() {

    this.brandtype = '';   
    this.getbrand();

  }

 
  pageChanged(pageNo: any) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getbrand();
  }

  type(companyList:RequestCompany,status:any,){
   
    if(status==1 ||status=="1"){
      this.supplierType="External"
    
    }
    else if(status==0 || status=="0"){
      this.supplierType="Internal"
    
    }
    else{
      this.toastr.error();
    }

    const params = {
      id:companyList.BrandId,
      action:'brand',
      status:status,     
      type:this.supplierType
    }
 
  
    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };
     this.loader.start();
    this._http.updateSupplier(params,this.headers).subscribe({
      next: (data: any)=>{

        this.loader.stop();

        if((data.status==1 && status==1) ||status=="1"){
          this.supplierType="External";
          this.getbrand();
         
        }
        else if((data.status==1 && status==0) || status=="0"){
          this.supplierType="Internal";
          this.getbrand();        
        }
      
    
    },

  });
}

  }

