import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {AppService} from './restful-services/restful.service'
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [AppService],
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  public login:boolean = false;
  title = 'Voucherkart';
  //subscription: Subscription;

 

  constructor(private router: Router){
   
    // let role = localStorage.getItem('role')
    // if(!role){
    //   localStorage.setItem('role', 'brand')
    // }
    let logged = localStorage.getItem('logged');
   
    if(logged && logged === 'in'){
      this.login = true;
    }else{
      this.login = false;
    }
    
   /* this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
  });*/
  }


  session(status: boolean){

    if(status){
      this.login = true;
    }else{
      this.login = false;
    }
  }
}
