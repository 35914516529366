import { Component, ElementRef, OnInit, ViewChild, HostListener , Input  } from '@angular/core';
import { AppService } from 'src/app/restful-services/app-services/app.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestProductmgt } from 'src/app/models/Requestproductmgt';
import { Modal } from 'bootstrap';

import { Subscription } from 'rxjs';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';

import { apiurl } from "src/environments/environment";
import { DomSanitizer} from '@angular/platform-browser';
import { RequestAddBrand } from 'src/app/models/RequestAddBrand';
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var bootstrap: any;



@Component({
  selector: 'app-productmgt-edit',
  templateUrl: './productmgt-edit.component.html',
  styleUrls: ['./productmgt-edit.component.scss']
})


export class ProductmgtEditComponent {

  public validityOptions: INgxMyDpOptions = {};
  name = 'Angular 6';
  htmlContent = '';

  // config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   defaultParagraphSeparator: 'p',
  //   defaultFontName: 'Arial',
  //   toolbarHiddenButtons: [
  //     ['bold']
  //     ],
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // };

  requestproductedit: RequestProductmgt;
  requestproductedit1: RequestAddBrand;
  role: any;
  userDetails: any;
  private headers: any = {};
  @ViewChild('inputFile') inputFile: any;
  idvalue: any;


  getValidityDate: any;
  ValidityDate: any;
  validity:any;
  isEdit:boolean= false;
  marked:boolean = false;
  editmarked:boolean = false;
  Disabledvalue:boolean=false;
  DisabledFace:boolean=false;
  brandData: any = [];
  brandData_name:any =[];


  //category

  categoryData: any = [];


  Edit: Boolean;
  Edit1:Boolean
  filevalue: any;


  userpassword: boolean = false;
  category:any;
  imagevalue:any;

product_name:any;
varient:any = '';
brandidvalue:any;
productidvalue:any;
isCheck :boolean = false;
iseditCheck : boolean=false;

previews: any;
pathurl: any;
preshow : boolean = false;
facehide:boolean = false;
faceshow:boolean = false;
userId :any;
b2cflag:string ='';
flagHide:boolean = false;
ischeckbox:boolean = false;
productDPDwon:boolean = false;
external_product_id:any;
productKeyword = "name";
  
  constructor(private sanitizer:DomSanitizer,private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    this.Edit1 = false;
    // this.categoryList = new Array<RequestBrandmgt>();
    this.requestproductedit = new RequestProductmgt();
    this.requestproductedit1 = new RequestAddBrand();


    let ValidityDate = new Date();
    this.getValidityDate = { date: { year: ValidityDate.getFullYear(), month: ValidityDate.getMonth() + 1, day: ValidityDate.getDate() } };
 

    this.ValidityDate = new Date();

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());  

    this.validityOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() },
    };

  

  }



  ngOnInit() {

    this.pathurl =  apiurl;

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
      this.userId = this.userDetails.UserID;
    }

    this.onChangebrand();
    this.onChangebrand_name();
    const { id } = history.state;
  
    if (id) {
      localStorage.setItem('editID', id);
      this.idvalue = id;
      this.Edit = true;
    } else {
      this.idvalue = localStorage.getItem('editID');   
    
    }

    if(this.Edit){
      this.getproducteditlist(this.idvalue);       
        }

        else{     

          let producteditlist: string | null = localStorage.getItem("producteditlist"); 

          // let producteditlist: string | null = localStorage.getItem("producteditlist"); 
          
          try{

            let editval = JSON.parse(producteditlist || '');  
            
            this.requestproductedit = editval;   
            this.Edit = true;            
                  
            this.requestproductedit.product_name = this.requestproductedit.name;
            this.requestproductedit.varient = this.requestproductedit.variant;
             this.requestproductedit.face_value = this.requestproductedit.face_value;
            this.requestproductedit.minface_value = this.requestproductedit.minface_value;
            this.requestproductedit.maxface_value = this.requestproductedit.maxface_value;
            this.requestproductedit.usage_mode = this.requestproductedit.usage_mode;
            this.requestproductedit.issuance_fee = this.requestproductedit.issuance_fee;
            this.requestproductedit.processing_fee = this.requestproductedit.processing_fee;
            this.requestproductedit.authentication_charge = this.requestproductedit.authendication_charges;
            this.requestproductedit.validity = this.requestproductedit.validity;
            this.requestproductedit.description = this.requestproductedit.description;
            this.requestproductedit.file = this.requestproductedit.image;
            this.requestproductedit.brand = this.requestproductedit.brand_id;
            this.brandidvalue = this.requestproductedit.brand_id;
            this.selectbrandEvent(this.requestproductedit.brand)
            this.imagevalue = this.requestproductedit.image;
            this.productidvalue = this.requestproductedit.id;
      
      
            // this.ValidityDate = this.requestproductedit.validity;    
            this.requestproductedit.validity = this.requestproductedit.validity; 
             //updateproduct
             if(this.requestproductedit.reward_points === null){
              this.requestproductedit.reward_points="";
            }
            if(this.requestproductedit.how_to_use === null){
              this.requestproductedit.how_to_use="";
            }
      
            if(this.requestproductedit.store_locator === null){
              this.requestproductedit.store_locator="";
            }
            if(this.requestproductedit.important_instruction === null){
              this.requestproductedit.important_instruction='';
            }
            if(this.requestproductedit.price === null){
              this.requestproductedit.price='';
            }
            this.requestproductedit.reward = this.requestproductedit.reward_points;   
            this.requestproductedit.howtouse = this.requestproductedit.how_to_use;  
            this.requestproductedit.storelocator = this.requestproductedit.store_locator;  
            this.requestproductedit.instrction = this.requestproductedit.important_instruction; 
            this.requestproductedit.price = this.requestproductedit.price;
            this.b2cflag = this.requestproductedit.b2cflag;
          if(this.b2cflag == "Y"){
           this.flagHide=true;
          }
          else{
           this.flagHide=false;
          }
  
            if(this.requestproductedit.variant === 'Digital'){
              this.facehide = false;
              this.faceshow = true;
            }
            else if(this.requestproductedit.variant === 'Product'){
            
              this.facehide = true;
              this.faceshow = false;
            }
            else if(this.requestproductedit.variant === 'Deal'){
            
              this.facehide = true;
              this.faceshow = false;
            }
            else{
              this.faceshow = false;
              this.facehide = false;
            }
          

          }
          catch(error){

            if (error instanceof SyntaxError) {
        
            } else {
          
            }
          }
      
         
    
        }

        this.pathurl =  apiurl;
        
  }



  getproducteditlist(id: any) {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };  

    this._http.getproducteditlist(this.idvalue, this.headers).subscribe((data: any) => {
 
      this.requestproductedit = data.data;
      this.requestproductedit1 = data.data;

    
   
      localStorage.setItem('producteditlist', JSON.stringify(this.requestproductedit));    

      this.requestproductedit.product_name = this.requestproductedit.name;
      this.requestproductedit.varient = this.requestproductedit.variant;
      this.requestproductedit.face_value = this.requestproductedit.face_value;
      this.requestproductedit.minface_value = this.requestproductedit.minface_value;
      this.requestproductedit.maxface_value = this.requestproductedit.maxface_value;
      this.requestproductedit.usage_mode = this.requestproductedit.usage_mode;
      this.requestproductedit.issuance_fee = this.requestproductedit.issuance_fee;
      this.requestproductedit.processing_fee = this.requestproductedit.processing_fee;
      this.requestproductedit.authentication_charge = this.requestproductedit.authendication_charges;
      this.requestproductedit.validity = this.requestproductedit.validity;
      this.requestproductedit.description = this.requestproductedit.description;
      this.requestproductedit.file = this.requestproductedit.image;
      this.requestproductedit.brand = this.requestproductedit.brand_id;
      this.brandidvalue = this.requestproductedit.brand_id;
      this.selectbrandEvent(this.requestproductedit.brand)
      this.imagevalue = this.requestproductedit.image;
      this.b2cflag = this.requestproductedit.b2cflag;
      if(this.b2cflag == "Y"){
        this.flagHide=true;
      }
      else{
        this.flagHide=false;
      }
      console.log("flag",this.b2cflag)
      this.productidvalue = this.requestproductedit.id;


      // this.ValidityDate = this.requestproductedit.validity; 
      this.requestproductedit.validity = this.requestproductedit.validity;   
       
      //updateproduct
      if(this.requestproductedit.reward_points === null){
        this.requestproductedit.reward_points="";
      }
      if(this.requestproductedit.how_to_use === null){
        this.requestproductedit.how_to_use="";
      }

      if(this.requestproductedit.store_locator === null){
        this.requestproductedit.store_locator=""
      }
      if(this.requestproductedit.important_instruction === null){
        this.requestproductedit.important_instruction=""
      }
      if(this.requestproductedit.price === null){
        this.requestproductedit.price=""
      }
      

      this.requestproductedit.reward = this.requestproductedit.reward_points;   
      this.requestproductedit.howtouse = this.requestproductedit.how_to_use;  
      this.requestproductedit.storelocator = this.requestproductedit.store_locator;  
      this.requestproductedit.instrction = this.requestproductedit.important_instruction;  
      this.requestproductedit.price = this.requestproductedit.price;

      if(this.requestproductedit.variant === 'Digital'){
        this.facehide = true;
        this.faceshow = true;
      }
      else if(this.requestproductedit.variant === 'Product'){
      
        this.facehide = true;
        this.faceshow = false;
      }
      else if(this.requestproductedit.variant === 'Deal'){
      
        this.facehide = true;
        this.faceshow = false;
      }
      else{
        this.faceshow = false;
        this.facehide = false;
      }
      if(this.requestproductedit.face_value!=0&&(this.requestproductedit.minface_value===0||this.requestproductedit.maxface_value===0)){
        this.Disabledvalue=true;
        this.DisabledFace=false;
      }else{
        this.Disabledvalue=false;
        this.DisabledFace=true;
      }
      // const blobUrl = URL.createObjectURL(this.filevalue);
      // const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);   
      // this.previews = safeblobUrl;
      // this.preshow = true;

    
      // const d = new Date(this.requestproductedit.validity)
      // this.getValidityDate = { date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() } };


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })

  }

  onCheckboxChange(e:any){
    this.marked= e.target.checked;
    if(this.marked){
      this.isCheck = true;
      this.iseditCheck =true;
      this.ischeckbox =true;
    }
    else{
      this.isCheck = false;
      this.iseditCheck =false;
    }
    this.isEdit = true;
    
    }
    
    restrictEnter(args: any) {
      if (args.key === "Enter" && args.keyCode == 13) args.preventDefault();
    }

  onChangebrand() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getbrandload(this.headers).subscribe((data: any) => {

      this.brandData = data.data;
      console.log(this.brandData)

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }
  onChangebrand_name() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getbrandName(this.headers).subscribe((data: any) => {

      this.brandData_name = data.data;
      console.log(this.brandData_name)

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }
  productClear(event:any){
    this.requestproductedit.product_name = "";
  }
  selectbrandEvent(id:any){  
    let data = this.brandData.find((val:any) => val.id === Number(id))
    let item = data.name;

    this.brandidvalue = data.id;
    if(item==="Airtel Recharge"||item==="Jio Recharge"||item==="Vi Recharge"||item==="BSNL Recharge" ||item==="MTNL Recharge" || item === "DTH Airtel" || item === "DTH Dish TV" || item === "DTH Sun" || item === "Direct DTH" || item === "Videocon DTH" || item === "Tata-Sky"){
      this.productDPDwon=true;
    }else{
      this.productDPDwon=false;
      this.requestproductedit.product_name = "";
    }
  }
  selectProduct_Name(item:any){  
      let value=this.brandData_name.find((val:any) => val.name === item.name)
      this.requestproductedit.product_name = value.name
      this.external_product_id = value.id
      console.log(value);
      
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = '0123456789.,'; // Define the allowed characters
    const inputChar = event.key;
  
    if (inputChar === ' ' || (inputChar !== '.' && allowedCharacters.indexOf(inputChar) === -1)) {
      
      
    
      event.preventDefault(); // Prevent the input
    }
  }


  // onValidityChanged(event: IMyDateModel): void {
    
  //   this.ValidityDate = new Date(event.jsdate.setDate(event.jsdate.getDate())).toISOString();
  //   let dp = new Date(this.ValidityDate);
  //   dp.setDate(dp.getDate());
  //   dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate());

  //   // this.validityOptions = {
  //   //   dateFormat: 'dd-mm-yyyy',
  //   //   alignSelectorRight: true,
  //   //   openSelectorTopOfInput: false,
  //   //   disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() -1 },
  //   // };   
  // }

  
 onValidityChanged(event: IMyDateModel): void {
  this.ValidityDate = new Date(event.jsdate.setDate(event.jsdate.getDate()+1)).toISOString();
 }
  

  selectVarientEvent(item:any){


if(item === 'Digital'){

  this.faceshow = true;
  this.facehide = true;
  this.requestproductedit.variant = 'Digital';
  this.requestproductedit.minface_value = '';
  this.requestproductedit.maxface_value = '';
  this.requestproductedit.face_value ='';
}
else if(item === 'Product'){

  this.facehide = true;
  this.faceshow = false;
  this.requestproductedit.variant = 'Product';
  this.requestproductedit.minface_value = 0;
  this.requestproductedit.maxface_value = 0;
  this.requestproductedit.face_value = '';
}
else if(item === 'Deal'){

  this.facehide = true;
  this.faceshow = false;
  this.requestproductedit.variant = 'Deal';
  this.requestproductedit.minface_value = 0;
  this.requestproductedit.maxface_value = 0;
  this.requestproductedit.face_value = '';
}
else{
  this.faceshow = false;
  this.facehide = false;
  this.requestproductedit.variant = '';
}
  }

  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
    else if(!this.requestproductedit.face_value&&!this.requestproductedit.minface_value&&!this.requestproductedit.maxface_value){
      this.Disabledvalue=false;
      this.DisabledFace=false;
    }else if((!this.requestproductedit.face_value)&&(this.requestproductedit.minface_value||this.requestproductedit.maxface_value)){
      this.Disabledvalue=false;
      this.DisabledFace=true;
    }else if((this.requestproductedit.face_value)&&(!this.requestproductedit.minface_value||!this.requestproductedit.maxface_value)){
      this.Disabledvalue=true;
      this.DisabledFace=false;
    }
  }

  letterOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  backclear() {

    this.router.navigate(['/internal-product'], { replaceUrl: false });
    localStorage.removeItem("producteditlist"); 
  }


  selectfile(event: any) {
 
    this.filevalue = event.target.files[0];   

    if(this.filevalue){

      this.pathurl =  apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue);
      const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);   
      this.previews = safeblobUrl;
      this.preshow = true;

      this.toastr.success("image upload successfully");
      return;
    }
    else{
      this.preshow = false;
    }
  }




  AddProduct(requestproductedit: RequestProductmgt) {      
  
    if((this.requestproductedit.variant === 'Digital')&&(!this.Disabledvalue )){

      if(!this.requestproductedit.minface_value || !this.requestproductedit.maxface_value ){
        this.toastr.error("Please fill all the mandatory fields1");
        return;
  }

      if (this.requestproductedit.minface_value === 0 || this.requestproductedit.minface_value === '0' || /^0*$/.test(this.requestproductedit.minface_value)) {
        this.toastr.error("Please enter min facevalue greater than 0");
        return;
      }
  
      if (this.requestproductedit.maxface_value === 0 || this.requestproductedit.maxface_value === '0' || /^0*$/.test(this.requestproductedit.maxface_value)) {
        this.toastr.error("Please enter max facevalue greater than 0");
        return;
      } 
   
      if (Number(this.requestproductedit.minface_value) >= Number(this.requestproductedit.maxface_value)) {
        this.toastr.error("Please enter max facevalue greater than min facevalue");
        return;
      }  

      if (!/^[0-9]+$/.test(this.requestproductedit.minface_value)) {
   
        this.toastr.error("Please enter min facevalue in numbers");
        return;
      }
  
      if (!/^[0-9]+$/.test(this.requestproductedit.maxface_value)) {
     
          this.toastr.error("Please enter max facevalue in numbers");
          return;
      }
    }

    if(this.requestproductedit.variant === 'Product' || this.requestproductedit.variant === 'Deal'){

      if(!this.requestproductedit.face_value){
        this.toastr.error("Please fill all the mandatory fields");
        return;
  }

  if (this.requestproductedit.face_value === 0 || this.requestproductedit.face_value === '0' || /^0*$/.test(this.requestproductedit.face_value)) {
    this.toastr.error("Please enter facevalue greater than 0");
    return;
  }

      if (!/^[0-9]+$/.test(this.requestproductedit.face_value)) {
     
        this.toastr.error("Please enter facevalue in numbers");
        return;
    }
    }
   

    // if (!/^[0-9]+$/.test(this.requestproductedit.discount)) {
   
    //   this.toastr.error("Please enter discount in numbers");
    //   return;
    // }

    if (!/^[0-9]+$/.test(this.requestproductedit.processing_fee)) {
   
        this.toastr.error("Please enter processing fee in numbers");
        return;
    }

    if (!/^[0-9]+$/.test(this.requestproductedit.issuance_fee)) {
   
      this.toastr.error("Please enter issuance fee in numbers");
      return;
    }

    if (!/^[0-9]+$/.test(this.requestproductedit.authentication_charge)) {
   
        this.toastr.error("Please enter authentication charge in numbers");
        return;
    }

    if (!/^[0-9]+$/.test(this.requestproductedit.validity)) {
   
      this.toastr.error("Please enter validity in numbers");
      return;
  }


    if (this.requestproductedit.processing_fee < 0 || this.requestproductedit.processing_fee < "0" && !this.Edit) {
      this.toastr.error("Please enter processing fee greater than 0");
      return;
    }

    if (this.requestproductedit.issuance_fee < 0 || this.requestproductedit.issuance_fee < "0" || !this.requestproductedit.issuance_fee && !this.Edit) {
      this.toastr.error("Please enter issuance fee greater than 0");
      return;
    }

    if (this.requestproductedit.authentication_charge < 0 || this.requestproductedit.processing_fee < "0" && !this.Edit) {
      this.toastr.error("Please enter authentication charge greater than 0");
      return;
    }

    if (this.requestproductedit.discount < 0 || this.requestproductedit.processing_fee < "0" && !this.Edit) {
      this.toastr.error("Please enter discount greater than 0");
      return;
    }


    if (this.requestproductedit.validity < 0 || this.requestproductedit.processing_fee < "0" && !this.Edit ) {
      this.toastr.error("Please enter validity greater than 0");
      return;
    }

    // if(this.requestproductedit.sku){

    //   var expressions = /^[A-Za-z0-9\-]+$/;
    //   var regex = new RegExp(expressions);
    //   const result = this.requestproductedit.sku.match(regex);
    //   if (result == null) {
    //     this.toastr.error("Please enter valid SKU");
    //     return;
    //   }
    // }

   
              
    // const isWhitespace = (this.requestproductedit.product_name || '').trim().length === 0;

    if(!this.requestproductedit.product_name){
  
      this.toastr.error("Please enter product name");
      return;
  
    }

    // const Whitespace = (this.requestproductedit.description || '').trim().length === 0;

    if(!this.requestproductedit.description){
  
      this.toastr.error("Please enter description");
      return;
  
    }

    // const whitespace = (this.requestproductedit.terms_condition || '').trim().length === 0;

    if(!this.requestproductedit.terms_condition){
  
      this.toastr.error("Please enter terms & conditions");
      return;
  
    }
  
    // const space = (this.requestproductedit.sku || '').trim().length === 0;

    if(!this.requestproductedit.sku){
  
      this.toastr.error("Please enter sku");
      return;
  
    }

    if (!this.filevalue && !this.Edit)   
    {
      this.toastr.error("Please upload image");
      return;
    }
    
    if (!this.requestproductedit.reward && this.isCheck && !this.Edit)   
    {
      this.toastr.error("Please enter reward points");
      return;
    }
    
    // if (!this.requestproductedit.howtouse && this.isCheck && !this.Edit )   
    // {
    //   this.toastr.error("Please enter how to use");
    //   return;
    // }
    if (!this.requestproductedit.storelocator && this.isCheck && !this.Edit )   
    {
      this.toastr.error("Please enter storelocator");
      return;
    }

    if (!this.requestproductedit.instrction && this.isCheck && !this.Edit)   
    {
      this.toastr.error("Please enter instruction");
      return;
    }
   
    if (!this.requestproductedit.price && this.isCheck && !this.Edit)   
    {
      this.toastr.error("Please enter price");
      return;
    }
    

   
    if(!this.Edit){
      if (!this.requestproductedit.varient || !this.requestproductedit.brand || !this.requestproductedit.product_name
        || !this.requestproductedit.discount || !this.requestproductedit.processing_fee || !this.requestproductedit.issuance_fee || !this.requestproductedit.authentication_charge || !this.requestproductedit.sku || !this.requestproductedit.description || !this.requestproductedit.terms_condition || !this.requestproductedit.validity) {
       this.toastr.error("Please fill all the mandatory fields");
       return;
     } 
    }
     
    if(this.marked){
      if (!this.requestproductedit.reward || !this.requestproductedit.storelocator || !this.requestproductedit.price
        || !this.requestproductedit.instrction ) {
       this.toastr.error("Please fill all the mandatory fields");
       return;
     } 
    }
     

    // if (

    //   this.isCheck && !this.requestproductedit.reward || !this.requestproductedit.howtouse || !this.requestproductedit.storelocator || !this.requestproductedit.instrction
    // ){
    //   this.toastr.error("Please fill all the mandatory fields");
    //   return;
    // }
        
   
    // let space = this.requestproductedit.product_name.value;
    // let RemoveEmptySpace = space.trim();
    // this.requestproductedit.product_name.setValue(RemoveEmptySpace);

    // public noWhitespaceValidator(control: FormControl) {     
  //     const isValid = !isWhitespace;
  //     return isValid ? null : { 'whitespace': true };
  // }


    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', "multipart/form-data");
    const formData: any = new FormData();

    if(this.Edit){    
      let productName = this.requestproductedit.product_name;
      if(typeof(productName) === "object"){
        productName = this.requestproductedit.product_name.name;
      }else{
        productName = this.requestproductedit.product_name;
      }
      // formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
      formData.append('name', productName);
      formData.append('description', this.requestproductedit.description);
      formData.append('variant', this.requestproductedit.varient);
      // formData.append('face_value', this.requestproductedit.face_value);
      // formData.append('minface_value', this.requestproductedit.minface_value);
      // formData.append('maxface_value', this.requestproductedit.maxface_value);
      // formData.append('face_value', this.requestproductedit.face_value);
      formData.append('usage_mode', this.requestproductedit.usage_mode);     
      formData.append('discount', this.requestproductedit.discount);
      formData.append('sku', this.requestproductedit.sku);
      formData.append('processing_fee', this.requestproductedit.processing_fee);
      formData.append('issuance_fee', this.requestproductedit.issuance_fee);
      formData.append('authendication_charges', this.requestproductedit.authentication_charge);
      formData.append('created_by', this.userDetails.UserID);
         
      formData.append('product_id', this.productidvalue);
      formData.append('brand_id', this.brandidvalue);    
      formData.append('terms_condition', this.requestproductedit.terms_condition);  
      formData.append('filepath', this.Edit ? this.requestproductedit.file : "");
      // formData.append('validity', this.ValidityDate); 
      formData.append('validity',this.requestproductedit.validity); 
      formData.append('modify_by',this.userId); 
      if(this.productDPDwon){
        formData.append('external_product_id', this.external_product_id);  
      }
      if(this.filevalue){
        formData.append('product', this.filevalue);
      }
      else{
        formData.append('product', '');
      }

      if(this.requestproductedit.varient === 'Digital'){

      formData.append('minface_value', this.requestproductedit.minface_value?this.requestproductedit.minface_value:'0');
      formData.append('maxface_value', this.requestproductedit.maxface_value?this.requestproductedit.maxface_value:'0');
      }
      else{
  
        formData.append('minface_value', '0');
        formData.append('maxface_value', '0');
      }
  
      if(this.requestproductedit.varient === 'Product' || this.requestproductedit.varient === 'Deal'){
  
        formData.append('face_value', this.requestproductedit.face_value);
       
      }
      else{
  
        formData.append('face_value', this.requestproductedit.face_value?this.requestproductedit.face_value:'0');
   
      }
      // if(this.marked){
        // formData.append('name', this.requestproductedit1.nameList);
        // formData.append('description', this.requestproductedit1.descriptionList);
        // formData.append('discount', this.requestproductedit1.discount);
        formData.append('store_locator', this.requestproductedit.storelocator ? this.requestproductedit.storelocator : null );     
        formData.append('how_to_use', this.requestproductedit.howtouse ? this.requestproductedit.howtouse : null);
        formData.append('reward_points', this.requestproductedit.reward ? this.requestproductedit.reward : null);
        formData.append('important_instruction', this.requestproductedit.instrction ? this.requestproductedit.instrction : null);
        formData.append('price', this.requestproductedit.price ? this.requestproductedit.price : null);
        // formData.append('terms_condition', this.requestproductedit1.terms_condition);
        // formData.append('price', this.requestproductedit1.price);
      // }
      if(this.iseditCheck || this.flagHide){
        formData.append('b2cflag',"Y")
            }
        else{
        formData.append('b2cflag',"N")}
     

    }
   else{

    formData.append('product', this.filevalue);
    // formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
    formData.append('name', this.requestproductedit.product_name.name);
    formData.append('description', this.requestproductedit.description);
    formData.append('variant', this.requestproductedit.varient);   
    // formData.append('face_value', this.requestproductedit.face_value);
    formData.append('usage_mode', this.requestproductedit.usage_mode); 
    formData.append('discount', this.requestproductedit.discount);
    formData.append('sku', this.requestproductedit.sku);
    formData.append('processing_fee', this.requestproductedit.processing_fee);
    formData.append('issuance_fee', this.requestproductedit.issuance_fee);
    formData.append('authendication_charges', this.requestproductedit.authentication_charge);
    formData.append('created_by', this.userDetails.UserID);
   
    formData.append('brand_id', this.brandidvalue);    
    formData.append('terms_condition', this.requestproductedit.terms_condition);  
    formData.append('validity', this.requestproductedit.validity);  
    // formData.append('validity', this.ValidityDate); 
    if(this.productDPDwon){
      formData.append('external_product_id', this.external_product_id);  
    }
    if(this.requestproductedit.varient === 'Digital'){

      formData.append('minface_value', this.requestproductedit.minface_value?this.requestproductedit.minface_value:'0');
      formData.append('maxface_value', this.requestproductedit.maxface_value?this.requestproductedit.maxface_value:'0');
    }
    else{

      formData.append('minface_value', '0');
      formData.append('maxface_value', '0');
    }

    if(this.requestproductedit.varient === 'Product' || this.requestproductedit.varient === 'Deal'){

      formData.append('face_value', this.requestproductedit.face_value);
     
    }
    else{

      formData.append('face_value', this.requestproductedit.face_value?this.requestproductedit.face_value:'0');
 
    }

    // if(this.isEdit){
      // formData.append('name', this.requestproductedit1.nameList);
      // formData.append('description', this.requestproductedit1.descriptionList);
      // formData.append('discount', this.requestproductedit1.discount);
      formData.append('store_locator', this.requestproductedit.storelocator ? this.requestproductedit.storelocator : null);     
      formData.append('how_to_use', this.requestproductedit.howtouse ? this.requestproductedit.howtouse : null);
      formData.append('reward_points', this.requestproductedit.reward ? this.requestproductedit.reward : null);
      formData.append('important_instruction', this.requestproductedit.instrction ? this.requestproductedit.instrction : null);
      formData.append('price', this.requestproductedit.price ? this.requestproductedit.price : null);

      // formData.append('terms_condition', this.requestproductedit1.terms_condition);
      // formData.append('price', this.requestproductedit1.price);
    // }
    if(this.isCheck || this.iseditCheck){
      formData.append('b2cflag','Y');      
    }else{
      formData.append('b2cflag','N');   
    }
    

   }  

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    if (this.Edit) {  

      this.loader.start();
      this._http.updateproductlist(formData, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();

          if (data.status == 1) {
            // this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("product updated successfully");
            this.Edit = false;
            this.router.navigate(['/internal-product'], { replaceUrl: false });
            // this.addForm.resetForm();
          }

          else if (data.status === 0) {
            this.toastr.error(data.msg);
            // this.closeCategoryBtn.nativeElement.click(); 
            this.Edit = false;
            this.router.navigate(['/internal-product'], { replaceUrl: false });
          }
        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          // this.closeCategoryBtn.nativeElement.click();
          this.Edit = false;
          this.router.navigate(['/internal-product'], { replaceUrl: false });
        }
      })

    }

    else {    

      this.loader.start();
      this._http.addNewproductlist(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          
          if (data.status == 1) {
            this.loader.stop();
            // this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("product added successfully");
            this.router.navigate(['/internal-product'], { replaceUrl: false });
            // this.addForm.resetForm();

          }

          else if (data.status === 0) {
            this.loader.stop();
            this.toastr.error(data.msg);
            // this.closeCategoryBtn.nativeElement.click();
            this.router.navigate(['/internal-product'], { replaceUrl: false });

          }

        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          // this.closeCategoryBtn.nativeElement.click();        
          this.router.navigate(['/internal-product'], { replaceUrl: false });
        }
      })
    }

  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold'],['toggleEditorMode']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

}
