export class RequestCategory {

    name:any;
    names:any;
    description:any;
    parent_category : any;
    created_by:any;
    created_date:any;
    modify_by : any;
    modify_date:any;
    parent_category_name:any; 
    category:any;
    id:any;
   file: any;
  product: any;
     
}