import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../restful-services/restful.service';
import * as XLSX from 'xlsx';
import { brandGeneration } from '../../models/BrandGeneration';
import { RequestBrand } from '../../models/RequestBrand';
import { Router ,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Supplier } from '../../models/supplierList';
import { Modal } from 'bootstrap';
import { Clipboard } from '@angular/cdk/clipboard';
import { apiurl,environment,environmentfiles } from "../../../environments/environment";
declare var bootstrap: any;


@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})



export class BKCreateOrderComponent {

  public showImageError: boolean = false;
  public showBatchData: boolean = false;
  public upldSucess: boolean = false;
  public batchData: any;
  @ViewChild('inputFile')
  myInputVariable!: ElementRef;
  brandGenerationList: brandGeneration[];
  requestCouponBrand: RequestBrand;
  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  voucherId: any;
  userDetails: any;
  lastvalue: number = 0;
  @ViewChild('inputUserFile') inputUserFile: any;

  private headers: any = {};

  public role: string = 'company';
  approvalVoucherList: any = [];
  approvalStatus: string = '';
  rejectedStatus: string = "";
  supplierType: any;
  productURL: string = '';
  urlError: boolean = false;
  supplierName: any;
  supplierList: any;
  newSupplier: boolean = false;
  supplierDetail: Supplier;
  supplierID: any;
  supplierKeyword = 'supplier_name';

  @ViewChild('closeApproveeBtn', { static: false }) closeApproveeBtn!: ElementRef;
  @ViewChild('closeBtn', { static: true }) closeBtn!: ElementRef;
  @ViewChild('supplierForm', { static: true }) supplierForm: any;


  voucherList: RequestBrand[];

  hide: boolean = false;
  approvestatus: boolean = false;
  rejectstatus: boolean = false;
  approvehide: boolean = true;
  rejecthide: boolean = true;
  pending: boolean = true;

  issuancedate: any;
  suppliershow: boolean = false;
  issuedate: any;
  issuehide: boolean = false;
  statushide: boolean = false;
  logvalue: any;
  tooltipList: any;

  voucherkartvalue:any;
  fileurls:any;
  settingvalue:any;



  // //QR Code

  // public myAngularxQrCode: any = null;
  pathurl:any;

  paramValue: any = '';

  constructor(private route: ActivatedRoute,private clipboard: Clipboard,private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.brandGenerationList = new Array<brandGeneration>();
    this.requestCouponBrand = new RequestBrand();
    this.supplierDetail = new Supplier();
    this.voucherList = new Array<RequestBrand>();
    this.role = localStorage.getItem('role') || '';

//  //QR Code

//     this.myAngularxQrCode = 'tutsmake.com';
  }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.paramValue = params['Voucher_request_id'];
    });
    console.log('Siva....',this.paramValue)
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }  

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    let idval = this.paramValue;
    this.loader.start();
    this._http.getviewlist(idval,this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.requestCouponBrand = data.data;      

        if(data.status === 1){
          this.requestCouponBrand = data.data;

          if (data) {              
                
              this.requestCouponBrand = data.data;
              this.voucherkartvalue = this.requestCouponBrand.source;          
            
              if (this.requestCouponBrand.status === 'A') {
      
                this.approvehide = false;
                this.rejecthide = false;
              }
              if (this.requestCouponBrand.status === 'R') {
      
                this.approvehide = true;
              }
      
              this.logvalue = this.requestCouponBrand.LOG;
      
              if (this.logvalue) {
                localStorage.setItem('logvalue', this.logvalue);
      
              }
              else {
                let logval = localStorage.getItem('logvalue');
                this.logvalue = logval;
              }
      
              let dates = new Date(this.requestCouponBrand.issuance_date);
              let date = new Date();
      
              if (date >= dates) {
      
                this.issuedate = 'soon';
                this.issuehide = true;
              }
              else {
                this.issuehide = false;
              }                 
               
          }
          else{            
    
            let voucherDetail = localStorage.getItem("voucherDetail");
            if (typeof voucherDetail === 'string') {
              const parse = JSON.parse(voucherDetail)
    
              this.requestCouponBrand = parse;
           
            }
    
          }
        }
        else if(data.status === 0){

          this.router.navigate(['/product-dashboard'], { replaceUrl: false });
          this.toastr.error("No records found");
          return;
        }


        // const { id } = history.state;
        const  id  = this.paramValue;       

        if (id !== null && id !== undefined) {

          this.voucherId = id;
          localStorage.setItem('companyid', id);
          localStorage.setItem('companyids', this.voucherId);
          this.getVoucherGenerationList(this.voucherId);   
                
        }
    
        else {
    
          let voucherDetail = localStorage.getItem("voucherDetail");
          if (typeof voucherDetail === 'string') {
            const parse = JSON.parse(voucherDetail)
    
            this.requestCouponBrand = parse;
            
    
            let dates = new Date(this.requestCouponBrand.issuance_date);
            let date = new Date();
    
            if (date >= dates) {
    
              this.issuedate = 'soon';
              this.issuehide = true;
            }
            else {
              this.issuehide = false;
            }
    
    
            this.voucherId = this.requestCouponBrand.voucher_request_id;
            localStorage.setItem('companyid', id);
            localStorage.setItem('companyids', this.voucherId);
    
            setTimeout(() => {
              this.getVoucherGenerationList(parse.voucher_request_id);
            }, 100);
    
    
          }
        }
    
    
        if (this.requestCouponBrand.source == 'QwikCilver' || this.requestCouponBrand.source == 'Amazon') {
    
          this.hide = false;
        }
    
        else {
          this.hide = true;
        }
    
        this.fileurls = environmentfiles.ENVIRONMENT.baseURL; 
    
        this.pathurl =  apiurl;

      }
    })   


    this._http.brandDetailItem.subscribe(data => {

      if (data) {

        let voucherDetail = localStorage.getItem("voucherDetail");
        if (typeof voucherDetail === 'string') {
          const parse = JSON.parse(voucherDetail)

          this.requestCouponBrand = parse;       

          // this.requestCouponBrand = data;    
        
          this.voucherkartvalue = this.requestCouponBrand.source; 
        
          if (this.requestCouponBrand.status === 'A') {
  
            this.approvehide = false;
            this.rejecthide = false;
          }
          if (this.requestCouponBrand.status === 'R') {
  
            this.approvehide = true;
          }
  
          this.logvalue = this.requestCouponBrand.log;
  
          if (this.logvalue) {
            localStorage.setItem('logvalue', this.logvalue);
  
          }
          else {
            let logval = localStorage.getItem('logvalue');
            this.logvalue = logval;
          }
  
          let dates = new Date(this.requestCouponBrand.issuance_date);
          let date = new Date();
  
          if (date >= dates) {
  
            this.issuedate = 'soon';
            this.issuehide = true;
          }
          else {
            this.issuehide = false;
          }
       
        }

       

      }
      else{            

        let voucherDetail = localStorage.getItem("voucherDetail");
        if (typeof voucherDetail === 'string') {
          const parse = JSON.parse(voucherDetail)

          this.requestCouponBrand = parse;
       
        }

      }
    })

    const { id } = history.state;

    if (id !== null && id !== undefined) {
      this.voucherId = id;
      localStorage.setItem('companyid', id);
      localStorage.setItem('companyids', this.voucherId);

      let voucherDetail = localStorage.getItem("voucherDetail");
      if (typeof voucherDetail === 'string') {
        const parse = JSON.parse(voucherDetail)

        this.requestCouponBrand = parse;
        this.voucherId = this.requestCouponBrand.voucher_request_id;
        this.getVoucherGenerationList(this.voucherId);
      }

     
    }

    else {

      let voucherDetail = localStorage.getItem("voucherDetail");
      if (typeof voucherDetail === 'string') {
        const parse = JSON.parse(voucherDetail)

        this.requestCouponBrand = parse;
        

        let dates = new Date(this.requestCouponBrand.issuance_date);
        let date = new Date();

        if (date >= dates) {

          this.issuedate = 'soon';
          this.issuehide = true;
        }
        else {
          this.issuehide = false;
        }


        this.voucherId = this.requestCouponBrand.voucher_request_id;
        localStorage.setItem('companyid', id);
        localStorage.setItem('companyids', this.voucherId);

        setTimeout(() => {
          this.getVoucherGenerationList(parse.voucher_request_id);
        }, 100);


      }
    }


    if (this.requestCouponBrand.source == 'QwikCilver' || this.requestCouponBrand.source == 'Amazon') {

      this.hide = false;
    }

    else {
      this.hide = true;
    }

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL; 

  }



  clear() {

    this.router.navigate(['/product-order-view'], { replaceUrl: false });

  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }



  getVoucherGenerationList(ids: number) {

    this._http.brandDetailItem.subscribe(data => {

      // this.requestCouponBrand = data;
    })

    this.issuancedate = this.requestCouponBrand.issuance_date;

    if (this.requestCouponBrand.source == 'QwikCilver') {

      let sourcevalue = this.requestCouponBrand;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'QC';

      this._http.getVoucherGenerationLists(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.brandGenerationList = data.data;
            this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.brandGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else if (this.requestCouponBrand.source == 'Ticket Express') {

      let sourcevalue = this.requestCouponBrand;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({

          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'TE';

      this._http.getVoucherGenerationListTE(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.brandGenerationList = data.data;
            this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.brandGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else if (this.requestCouponBrand.source == 'Amazon') {

      let sourcevalue = this.requestCouponBrand;


      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };


      this.loader.start();
      let action = 'AMAZON';

      this._http.getVoucherGenerationListAmazon(ids, this.limit, this.skip, this.headers, action).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data) {
            this.count = data.count;
            this.brandGenerationList = data.data;
            this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.brandGenerationList));
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })


    }

    else {

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this.loader.start();
      this._http.getVoucherGenerationList(ids, this.limit, this.skip, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data) {
            this.count = data.count;
            this.brandGenerationList = data.data;
            this.toolTipInit();

            localStorage.setItem('vouchergenerationlist', JSON.stringify(this.brandGenerationList));

            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        }, error: (_error) => {
          this.loader.stop();
        }
      })

    }
  }
 

  pageChanged(pageNo: number) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * this.limit;
    this.lastvalue = this.skip + 25;
    this.getVoucherGenerationList(this.voucherId);
    localStorage.removeItem('logvalue');
  }

  importUserBulkApproval(event: any) {

    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.csv')) {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   alert("Please select one supplier");
    //   return;
    // }
    // const element = document.getElementById('myModal') as HTMLElement;
    // const myModal = new Modal(element,
    //   {
    //     backdrop: 'static',
    //     keyboard: false
    //   });
    // myModal.hide();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData = new FormData();
      formData.append('filename', event.target.files[0]);
      formData.append('voucher_request_id', this.voucherId);
      formData.append('user_id', this.userDetails.UserID);
      formData.append('created_by_name', this.userDetails.UserName);
      // formData.append('supplier_type', this.supplierType);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e: any) => {
        let binaryDara = e.target.result;
        let workbook = XLSX.read(binaryDara, { type: 'binary' });
        // workbook.SheetNames.forEach(sheet=>{
        //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        //   console.log(this.batchData)
        // })
      }
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;
      // this.supplierType = "Internal";

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      this._http.importUserBulkApproval(formData, this.headers).subscribe((data: any) => {
        if (data) {
          if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getVoucherGenerationList(this.voucherId);
          }
          else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
          this.inputUserFile.nativeElement.value = '';
        }
      },
        error => {
          this.toastr.error(error.error.Message);
        }
      )
    }
  }


  back() {
    this.router.navigate(['/product-dashboard'], { replaceUrl: false });
    localStorage.removeItem('voucherDetail');
    localStorage.removeItem('logvalue');
  }




  approveBrand() {
  
    this.router.navigate(['/product-dashboard'], { replaceUrl: false });

    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;


    const params = {
      "voucherRequestIds": this.paramValue,
      "status": this.approvalStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "supplier_id": this.supplierName,
      "approved_by_name": this.userDetails.UserName
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe((data: any) => {
      this.loader.stop();

   
      this.closeBtn.nativeElement.click();
   
      if (data) {
        if (data.status == 1) {
          // this.selectAll = false;
          if (this.approvalStatus == 'A' || this.approvalStatus == 'Y') {
            this.toastr.success("Voucher approved successfully");
        
            this.approvestatus = true;
            this.approvehide = false;
            this.rejectstatus = false;
            this.rejecthide = false;
            this.pending = false;
            // this.supplierForm.resetForm();
       
          }
          else if (this.rejectedStatus == 'R') {
            this.toastr.error("Voucher rejected");
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            this.rejectstatus = true;
            this.pending = false;
            this.approvestatus = false;
            this.rejecthide = false;
         

          }
          else {
            this.toastr.success("Vouchers approved successfully");
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            this.approvestatus = true;
            this.approvehide = false;
            this.rejectstatus = false;
            this.rejecthide = false;
            this.pending = false;
            // this.supplierForm.resetForm();
            
          }
        }
        else {
          if (data.status == 2) {
            this.toastr.info("Voucher has been already approved or generated");
            // this.supplierForm.resetForm();
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
          }
        }
        this.closeBtn.nativeElement.click();
        this.approvalVoucherList = [];
        // this.getbrandRequestList();
      }
    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

  approveBrands(requestCouponBrand: RequestBrand) {

    this.router.navigate(['/product-dashboard'], { replaceUrl: false });

    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;

    const params = {
      "voucherRequestIds": this.paramValue,
      "status": this.approvalStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "supplier_id": this.supplierName,
      "approved_by_name": this.userDetails.UserName
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe((data: any) => {
      this.loader.stop();
      // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
      this.closeBtn.nativeElement.click();
 
      if (data) {
        if (data.status == 1) {
          // this.selectAll = false;
          if (this.approvalStatus == 'A' || this.approvalStatus == 'Y') {
            this.toastr.success("Voucher approved successfully");
         
            this.approvestatus = true;
            this.approvehide = false;
            this.rejectstatus = false;
            this.rejecthide = false;
            this.pending = false;
            // this.supplierForm.resetForm();
          
          }
          else if (this.rejectedStatus == 'R') {
            this.toastr.error("Voucher rejected");
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            this.rejectstatus = true;
            this.pending = false;
            this.approvestatus = false;
            this.rejecthide = false;
           
          }
          else {
            this.toastr.success("Vouchers approved successfully");
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            this.approvestatus = true;
            this.approvehide = false;
            this.rejectstatus = false;
            this.rejecthide = false;
            this.pending = false;
            // this.supplierForm.resetForm();
           
          }
        }
        else {
          if (data.status == 2) {
            this.toastr.info("Voucher has been already approved or generated");
            // this.supplierForm.resetForm();
            // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
          }
        }
        this.closeBtn.nativeElement.click();
        this.approvalVoucherList = [];
        // this.getbrandRequestList();
      }
    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

  Rejectbrand(requestCouponBrand: RequestBrand) {

    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;


    const params = {
      "voucherRequestIds": this.paramValue,
      "status": this.rejectedStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "approved_by_name": this.userDetails.UserName,
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == 'R') {
              this.toastr.success("Voucher rejected successfully");
              this.rejectstatus = true;
              // localStorage.setItem('rejectTrue', 'true');


              // const rejectstatus = { rejectTrue: 'true', pending: 'false', approvestatus: 'false' , rejecthide: 'false'};
              // localStorage.setItem('rejectstatus', JSON.stringify(rejectstatus));

              this.pending = false;
              this.approvestatus = false;
              this.rejecthide = false;
              this.router.navigate(['/product-dashboard'], { replaceUrl: false });
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.pending = false;
              this.router.navigate(['/product-dashboard'], { replaceUrl: false });
            }
          }
          else {
            if (data.statu == 2) {
              this.toastr.info("Voucher has been already approved or generated");
              this.router.navigate(['/product-dashboard'], { replaceUrl: false });

            }
          }
          this.approvalVoucherList = [];
          // this.getbrandRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      }
    })
  }

  showSupplierModal(approvalStatus: string) {

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

 
    this._http.getsettingslist(this.headers).subscribe({
      next: (res:any) => {       

        if (res) {

          let setvalue = res.data.order_limit_amount;             
         
          localStorage.setItem('settingslist', setvalue);
      
        }
      }})

    this.settingvalue = localStorage.getItem('settingslist');

    let qccount = 0;
    let othercount = 0;


    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // // console.log('ttt', JSON.parse(sourceval||''));
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.source;
    // let totalval = val.total;

    // let discountval = val.voucher_approve_admin;

  
    let listvalue = this.requestCouponBrand;
   
    let sourceval: string | null = localStorage.getItem("voucherDetail");
    // console.log('ttt', JSON.parse(sourceval||''));
    let val: any = {};
    if (sourceval) {
      val = JSON.parse(sourceval);
    }
    let sourcevalue = val.source || listvalue.source;
    let totalval = val.total || listvalue.total;

    let discountval = val.voucher_approve_admin || listvalue.voucher_approve_admin;
  
    let nullcount = 0;

    if (sourcevalue! == "" || sourcevalue! == undefined) {
      nullcount = nullcount + 1;

    }

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (sourcevalue == "QwikCilver" || sourcevalue == "Ticket Express" || sourcevalue == "Amazon") {

      qccount = qccount + 1;

    }
    else {
      othercount = othercount + 1;
    }

    if (qccount > 0 && othercount > 0) {

      this.toastr.error("please select unique source rows");
      return;

    }

    if(discountval == "YES" && this.userDetails.role === 'Admin')
{
  this.toastr.error("discount is more than 75% voucher approval by super admin");
  return;
}

else if(totalval >= this.settingvalue && this.userDetails.role === 'Admin'){

  this.toastr.error("voucher total amount is High. So, please get approval from super admin");
  return;
}


else{

  if (qccount > 0) {

    // this.approvalbrandList.push(brand.voucher_request_id);
    this.approvalStatus = approvalStatus;
    this.supplierType = 'external';
    const element = document.getElementById('approveModals') as HTMLElement;
    const approveModals = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    this.urlError = false;
    this.productURL = '';
    approveModals.show();
    // this.approveBrands(this.requestCouponBrand); 


  }
  else {

    // this.approvalbrandList.push(brand.voucher_request_id);
    this.approvalStatus = approvalStatus;
    this.supplierType = 'internal';
    const element = document.getElementById('approveModalss') as HTMLElement;
    const approveModalss = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    this.urlError = false;
    this.productURL = '';
    approveModalss.show();
  }
}

  

  }



  showModa(rejectedStatus: string) {

    this.rejectedStatus = rejectedStatus;
    this.supplierType = 'Internal';
    const element = document.getElementById('rejectModal') as HTMLElement;
    const rejectModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    rejectModal.show();
  }


  viewurl(url:any){

  this.clipboard.copy(url);

  if(url){

    this.toastr.success("Copied the text url");
    return;
  }
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void; }) => {
        tooltip.dispose();
      });
    } 
    }

}
