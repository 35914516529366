import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { apiurl } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";
import { RequestCategory } from '../models/RequestCategory';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { filesapiurl } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare var bootstrap: any;


@Component({
  selector: 'app-b2c-brand',
  templateUrl: './b2c-brand.component.html',
  styleUrls: ['./b2c-brand.component.scss']
})
export class B2cBrandComponent implements OnInit {

  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('closeCategoryBtn', { static: true }) closeCategoryBtn!: ElementRef;

  public external: string = "";
  private headers: any = {};
  skip: number = 0;
  limit: number = 10;
  role:any;
  getExternal: any;
  apiLoading = true;
  lastvalue: number = 0;
  count: number = 0;
  d: number = 1;
  pathurl: any;
  tooltipList: any;
  currentBody: any;
  emailInput: any;
  datas: any;
  idvalue: any;
  Edit: Boolean;
  userDetails: any;
  descriptionList: any;
  nameList: any;
  b2cname: any;
  updatedData: any;
  userName: boolean = false;
  filevalue:any;
  previews: any;
  preshow : boolean = false;
  imagevalue:any
  description:any;
  getExternalimage:any;
  categoryList: RequestCategory[];
  AddrequestCategory: RequestCategory;
  filevalue1: any;

  constructor(private service: AppService,private _http:AppService,private sanitizer:DomSanitizer, private router: Router, private loader: NgxUiLoaderService, private toastr: ToastrService,) {
    this.Edit = false;
    this.categoryList = new Array<RequestCategory>();
    this.AddrequestCategory = new RequestCategory();

  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.pathurl = apiurl;
    this.getDigitalBrandList();

  }

  filterList() {
    this.getbrandlistfilter();
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  statusChange(id : any, value : any){
console.log("ID",id)
console.log("ID",value)
let requestParams = {
  'id': id,
  'status': value
}  
let token = localStorage.getItem("token");
this.headers = {
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': 'Bearer ' + token
   })
 };

// this.loader.start();
this._http.B2CBrandUpdate(requestParams,this.headers).subscribe((data: any) => {
  //this.loader.stop();
  if (data.status == 1) {
    this.toastr.success("Status Updated Successfully");
    this.getDigitalBrandList();
  }
  else if (data.status === 0) {
    this.toastr.success("Something Went Wrong");
  }
  else {
    this.toastr.error(data.msg);
  }
})
  }

  clear() {

    this.external = '';
    // this.categoryList = [];
    this.getDigitalBrandList();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);
  }

  editCategory(list: any) {
    this.Edit = true;
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.idvalue = list.id;
    this.currentBody = this.getExternal.find((p: any) => {
      return p.id == list.id;

    });
    // console.log(this.currentBody.body);
    this.AddrequestCategory.description = this.currentBody.description;
    this.AddrequestCategory.name = this.currentBody.name;
    this.AddrequestCategory.file = this.currentBody.image;
    // this.selectfile1(this.currentBody.image)

    // console.log("image: ",this.AddrequestCategory.file);    
    // this.description = this.AddrequestCategory.description 
    // this.userName = true
    // console.log("thasa", this.nameList)
    // console.log("ddd", this.descriptionList)
    // this.currentId = this.currentBody.id;
    // this.getcategoryeditlist(this.idvalue);
    

  }


  editedDatas() {


    let postParams = {
      id: this.idvalue,
      name: this.b2cname,
      description: this.descriptionList,
      b2c_name: this.nameList

    }
    this.apiLoading = true;


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.service.getExternalBrandeditlist(postParams, this.headers).subscribe((data: any) => {
      this.updatedData = data;
      this.getDigitalBrandList();

      if (data.status == 1) {
        this.toastr.success(data.msg)
      } else if (data.status == 0) {
        this.toastr.error("Data updated failure")
      }

      // data = this.updatedDatas;
    },

      (error) => {
        this.toastr.error(error.error.Message)
        // console.log("error",error);

      }
    )
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  getbrandlistfilter() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();

    this.service.getB2cBrandlist(this.external, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;

        this.count = data.totalCount;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {

          this.count = data.totalCount;
          this.getExternal = data.list;
          this.getExternal=this.getExternal.map((x:any)=> {
            //console.log("eeee",x.image)
            this.getExternalimage = x.image;
            if(x.image&&(!x.image.includes('http') || !x.image.includes('https'))){
              
              x.image=filesapiurl+x.image;
              console.log("image cat", x.image)
            }
            return x
          })
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }

  selectfile(event: any) {
 
    this.filevalue = event.target.files[0];   

    if(this.filevalue){

      this.pathurl =  apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue);
      const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);   
      this.previews = safeblobUrl;
      this.preshow = true;

      this.toastr.success("brand image upload successfully");
      return;
    }
    else{
      this.preshow = false;
    }
  }

  selectfile1(event: any) {
 
    this.filevalue1 = event.target.files[0];   

    if(this.filevalue1){

      this.pathurl =  apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue1);
      const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);   
      this.previews = safeblobUrl;
      this.preshow = true;

      this.toastr.success("brand image upload successfully");
      return;
    }
    else{
      this.preshow = false;
    }
  }
  addNewBrand(){
    this.AddrequestCategory.name=''
    this.AddrequestCategory.file=''
    this.previews = '';
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
  }

  AddCategory(AddrequestCategory: RequestCategory) {

    if (

      !this.AddrequestCategory.name || !this.AddrequestCategory.description || !this.AddrequestCategory.file 

    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    const space = (this.AddrequestCategory.name || '').trim().length === 0;

    if (space) {

      this.toastr.error("Please enter name");
      return;

    }

    const spaceone = (this.AddrequestCategory.description || '').trim().length === 0;

    if (spaceone) {

      this.toastr.error("Please enter description");
      return;

    }
    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', "multipart/form-data");
    const formData: any = new FormData();


    // formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
    formData.append('name', this.AddrequestCategory.name);
    formData.append('description', this.AddrequestCategory.description);
    // formData.append('user_name', this.requestbrandedit.user_name);
    // formData.append('password', this.requestbrandedit.password);
    // formData.append('brand_image', this.filevalue);
    if(this.Edit){
      formData.append('filepath',this.AddrequestCategory.file);
      formData.append('brand_image', this.filevalue);
    }else{
      formData.append('brand_image', this.filevalue);
    }
    formData.append('created_by_role', this.role);
    // formData.append('company_website', this.requestbrandedit.url);
    // formData.append('support_email', this.requestbrandedit.support_email);
    formData.append('created_by', this.userDetails.UserID);
     formData.append('id',this.Edit ? this.idvalue : "");

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  

    if (this.Edit) {

      this.service.updateB2cBrandcategory(formData, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();

          if (data.status == 1) {
            this.loader.stop();
            this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("Brand updated successfully");
            this.addForm.resetForm();
            this.getDigitalBrandList();
            this.Edit = false;
          }

          else if (data.status === 0) {
            this.toastr.error(data.msg);
            this.closeCategoryBtn.nativeElement.click();
            this.Edit = false;

          }
        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeCategoryBtn.nativeElement.click();
          this.Edit = false;
        }
      })

    }

    else {

      this.service.addNewB2cbrand(formData, this.headers).subscribe({
        next: (data: any) => {

          if (data.status == 1) {
            this.loader.stop();
            this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("category added successfully");
            this.addForm.resetForm();
            this.getDigitalBrandList();
          }

          else if (data.status === 0) {
            this.loader.stop();
            this.toastr.error(data.msg);
            this.closeCategoryBtn.nativeElement.click();


          }

        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeCategoryBtn.nativeElement.click();

        }
      })
    }

  }

  backclear(){
    this.AddrequestCategory.name=''
    this.AddrequestCategory.file=''
    // this.getDigitalBrandList();
    // this.router.navigate(['/b2c-brand'], { replaceUrl: false });
    this.addForm.resetForm();
    this.Edit = false;

  }

  viewGenerationList(list:any){
    
    let productId = list.id
  this.router.navigate(['/b2c-product'], {
    queryParams :{id : productId},
    state: { productId },
    replaceUrl: true
  });
  this.service.clientDetailsproduct({
    clientName:list.name,
    
  })

  localStorage.removeItem('logvalue');
}


  getDigitalBrandList() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getB2cBrand(this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.getExternal = data.list;
        // this.getExternalimage = data.list.image;
        this.getExternal=this.getExternal.map((x:any)=> {
          // console.log("eeee",x.image)
          this.getExternalimage = x.image;
          if(x.image&&(!x.image.includes('http') || !x.image.includes('https'))){
            
            x.image=filesapiurl+x.image;
            console.log("image", x.image)
          }
          return x
        })
        // console.log("wwwww", this.getExternal)
        if (data) {
          this.apiLoading = false;
          this.count = data.totalCount;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }

        }
      },
      error: _error => {
        console.error(_error),
          this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * 10;
    this.lastvalue = this.skip + 10;
    localStorage.setItem('pageNo', pageNo);
    this.getDigitalBrandList();

  }


}

