<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Product Mapping</label>
                </div>

                <div class="col text-end d-flex justify-content-end">
                    <div class="form-floating me-3">
                        <input type="text" name="brand_name" (keypress)="filter();"
                            class="form-control border-0 bg-light" placeholder="Name" [(ngModel)]="productsku"
                            style="width:210px;padding-top: 13px;">
                            <label for="tnums">Search Product Name</label>
                        </div>
                    <button class="btn btn-success mr-5 mx-2" style="width: 100px;" (click)="filter()">Search</button>
                    <button class="btn btn-success mr-5" style="width: 124px;" (click)="newPopup()">Add Product</button>
                
                </div>
                
            </div>
            <div class="col-3">
                <label class="texts">Client Name : {{clientName}}</label>
            </div>
        </div>
    </div>
</div>

<div class="row mx-0">
    <div  class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
  <table class="table">
      <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
        <tr>
          <th scope="col" class="pt1">Product Name</th>
          <th scope="col" class="pt1">SKU</th>
          <th scope="col" class="pt1">Discount %</th>
          <th scope="col" class="pt1">Client Discount %</th>
          <th scope="col" class="pt1">Issuance Fee(₹)</th>
          <th scope="col" class="pt1">Status</th>
          <th scope="col" class="pt1">Action</th>
        </tr>
      </thead>
      <div style="width: 488%;" class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                    ng-reflect-ng-class="bg-light" *ngIf="productmapList && productmapList.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center col-12">
                            No Record Found.
                        </div>
                    </div>
                </div>
      <tbody>
        <tr *ngFor="let list of productmapList | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
          <th scope="row" class="text-start">{{list.name}}</th>
          <td class="text-center">{{list.SKU}}</td>
          <td class="text-center">{{(list.product_discount && list.product_discount !== '-') ? list.product_discount+'%':'-'}}</td>
          <td class="text-center" >{{(list.discount && list.discount !== '-') ? list.discount+'%':'-'}}</td>
          
          <!-- <td class="text-center" *ngIf="list.discount === 0">-</td> -->
          <td class="text-center">{{list.issuance_fee | currency : 'INR'}}</td>
          <td class="text-center">
            <a class="linkColor1 downloadIcon" *ngIf="list.status == 'Active'">
                <i class="fa fa-toggle-on cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active" (click)="statusChange(list.id,'InActive')"></i>
            </a>
            <a class="linkColor downloadIcon" *ngIf="list.status == 'InActive'">
                <i class="fa fa-toggle-off cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(list.id,'Active')"></i>
            </a>
          </td>
          <td class="text-center">
            <a class="linkColor downloadIcon" #Edit (click)="edit(list.id)">
                <i class="fa fa-edit mr-10 icolor " data-bs-toggle="modal" data-bs-placement="bottom" data-bs-target="#productmapModal" title="Edit"></i>
            </a>
          </td>
        </tr>

      </tbody>
    </table>
    <div>
        <div class="row" *ngIf="productmapList && productmapList.length > 0">
            <div class="col-6 pt-3">
                <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                    {{count}} entries</p>
            </div>
            <div class="col-6">
                <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    </div>
    <div class="col-12 text-end my-3 mr-3">
        <button class="btn backcolor margin" type="button" (click)="backStage()">Back</button>
    </div>
    </div>
    
    </div>















<div class="modal fade" id="productmapModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body row">                   
                 <div class="row mb-4">
                    <div class="col-3">
                        <p class="texthead">Add New Product :</p>
                    </div> 
                
                <div class="text-end col-9" *ngIf="this.Edit == false">                   

                    <a class="mr-10" style="color: green;" href="{{this.fileurls}}sample/voucherkart_sample_client_product.csv">Sample file</a>

                    
                    <label class="rounded px-3 py-2 bg-success text-center border-0 labelText  mr-10"
                        for="inputFile">
                        <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                            (change)="importProduct($event)">
                        <i class="fa fa-upload pr-1"></i> Import</label>        

                </div>
                 </div>

                <form #addForm="ngForm" name="form" (ngSubmit)="AddProductmap(Addrequestproductmap)" class="row
                ">   
                <div class="col-4 md-form form-sm" *ngIf="this.Edit == false">
                    <label class="mb-2">Source<span class="text-danger">*</span></label>

                    <select id="sourceList" class="form-select"  placeholder="Select Source"  [(ngModel)]="sourceList" name="category"
                        (change)="filterList($event)">

                        <option value="" disabled selected>Select Source Name</option>
                        <!-- <option value="All">All</option> -->
                        <option class="textarea" selected *ngFor="let option of sourceData" [value]="option.supplier_name">
                            {{ option.supplier_name }}
                        </option>

                    </select>
                </div>
                    <div class="col-4 md-form form-sm">                      
                            <label for="product" class="mb-2">Product Name<span class="text-danger">*</span></label>
                            <ng-autocomplete [data]="productData" [searchKeyword]="productKeyword" placeholder="Select Product Name" (inputCleared)='productsClear($event)'
                                [itemTemplate]="itemTemplateproduct" (keydown)="restrictEnter($event)"  (selected)='selectproductEvent($event)' (keypress)="onKeyPresscomma($event)" [disabled]="fieldDisabled && this.Edit == false"
                                [(ngModel)]="Addrequestproductmap.product_name" name="product_name" #product_name="ngModel" required 
                                mdbInput autocomplete="on"  (inputChanged)='onChangeProduct($event)' 
                                required [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplateproduct let-item>
                                <a [innerHTML]="item"></a>                           
                            </ng-template>
    
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <span class="text-danger" *ngIf="(product_name.touched || addForm.submitted) &&  product_name.errors?.['required']">
                                product name is required
                            </span>
                      
                    </div> 


                    <div class="col-4 md-form form-sm" *ngIf = "skuhide">
                        <div class="md-form form-sm">
                            <label  class="mb-2">Rewardkart SKU :
                                <span class="text-danger">*</span>
                            </label>                      
                            
                            <input class="form-control"                        
                                [(ngModel)]="Addrequestproductmap.rewardkart_sku" name="rewardkart_sku" #rewardkart_sku="ngModel" [disabled]="editDatas">                             
                         
                                <span class="text-danger" *ngIf="(rewardkart_sku.touched || addForm.submitted) &&  rewardkart_sku.errors?.['required']">
                                    rewardkart SKU is required
                                </span>
                        </div>
                    </div>


                    
                        <div class="col-4 md-form form-sm">
                            <label for="source" class="mb-2">Product Type 
                            </label>
                            <select id="source" class="form-select" placeholder="Select Source" [(ngModel)]="Addrequestproductmap.source"
                                name="source" #source="ngModel"  [disabled]="editDatas">
                                 
                                <option value="" selected hidden>Select Product type</option>
                                <option value="Internal" selected>Internal</option>                 
                                <option value="External">External</option>                           
                                                      
                            </select>
                        </div>                               


                        <div class="col-4 md-form form-sm">
                            <div class="md-form form-sm">
                                <label  class="mb-2">Price
                                </label>                      
                                
                                <input class="form-control" type="text"                         
                                    [(ngModel)]="Addrequestproductmap.price" name="price" #price="ngModel" [disabled]="editDatas"
                                    [value]="maxmin ? 'min ' + minvalue + ' - max ' + maxvalue : ''">
                            </div>
                        </div>

                        <div class="col-4 md-form form-sm">
                            <div class="md-form form-sm">
                                <label  class="mb-2">Discount %
                                </label>                      
                                
                                <input class="form-control"                        
                                    [(ngModel)]="Addrequestproductmap.discount" name="discount" #discount="ngModel" [disabled]="editDatas">                             
                             
                                    <!-- <span class="text-danger" *ngIf="(rewardkart_sku.touched || addForm.submitted) &&  rewardkart_sku.errors?.['required']">
                                        rewardkart SKU is required
                                    </span> -->
                            </div>
                        </div>

                        <div class="col-4 md-form form-sm">
                            <div class="md-form form-sm">
                                <label  class="mb-2">Issuance Fee
                                </label>                      
                                
                                <input class="form-control"                        
                                    [(ngModel)]="Addrequestproductmap.issuance_fee" name="issuance_fee" #issuance_fee="ngModel" (keypress)="onKeyPress($event)" >                             
                             
                                    <!-- <span class="text-danger" *ngIf="(rewardkart_sku.touched || addForm.submitted) &&  rewardkart_sku.errors?.['required']">
                                        rewardkart SKU is required
                                    </span> -->
                            </div>
                        </div>

                        <div class="col-4 md-form form-sm">
                            <div class="md-form form-sm">
                                <label  class="mb-2">Client Discount %
                                </label>                      
                                
                                <input class="form-control"                        
                                    [(ngModel)]="Addrequestproductmap.clientDiscount" name="clientDiscount" #clientDiscount="ngModel" (keypress)="onKeyPress($event)">                             
                             
                                    <!-- <span class="text-danger" *ngIf="(rewardkart_sku.touched || addForm.submitted) &&  rewardkart_sku.errors?.['required']">
                                        rewardkart SKU is required
                                    </span> -->
                            </div>
                        </div>
                 
                        <!-- <div class="col-4 md-form form-sm my-input-class">
                            <label for="product" class="mb-2">Product 
                                <span class="text-danger">*</span>
                            </label>
                            <ng-autocomplete  [data]="selectproductData" [searchKeyword]="selectproductKeyword" placeholder="Select Product"
                                [itemTemplate]="itemTemplatebrand" (keypress)="onKeyPresscomma($event)"
                                [(ngModel)]="Addrequestproductmap.product" name="product" #product="ngModel"  (selected)='selectproductselectEvent($event)' 
                                mdbInput autocomplete="on" (inputChanged)='onChangeProductselect($event)' (keydown)="restrictEnter($event)"
                              required [notFoundTemplate]="notFoundTemplate" required>
                            </ng-autocomplete>
                            <ng-template #itemTemplatebrand let-item>
                                <a [innerHTML]="item.BrandName"></a>
                            </ng-template>
    
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <span class="text-danger" *ngIf="
                                (product.touched || addForm.submitted) &&
                                product.errors?.['required']
                              ">
                              product is required
                            </span>
                        </div> -->
                  
                  
                 <div class="row mx-0">
                    <div class="col-md-12 text-end mt-3 btnalig">
                            <button class="btn btn-secondary mr-5 px-3 py-2" data-bs-dismiss="modal" #closeProductmapBtn
                                type="button">Cancel</button>
                            <button *ngIf="this.Edit == false" class="btn btn-success px-4 py-2" type="submit">Save</button>
                            <button *ngIf="this.Edit == true" class="btn btn-success px-4 py-2" type="button" (click)="updateClientProduct()" data-bs-dismiss="modal" >Update</button>                            
                    </div>
                 </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-ui-loader></ngx-ui-loader>