import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppService } from '../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit{
  userDetails: any;
  roletype: any;
  datas: any;
  headers: any;
  apiLoading: boolean = false;
  updatedData: any;
  postparams: any;
  values: any;
  limit: number = 25;
  skip: number = 0;
  constructor(private _http:AppService, private loader: NgxUiLoaderService,  private router: Router, private toastr: ToastrService ){}




  ngOnInit(){
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
      this.roletype = this.userDetails.UserID;
  }
  this.terms();
  }
  updatedTerms(){
    this.loader.start();
    this.apiLoading = true;
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

      let postparams = {
        voucher_terms_condition:this.datas,
      }
      
      this._http.updateTerms(postparams ,this.headers).subscribe((data:any) =>{
        this.loader.stop();
        this.updatedData = data;
        console.log(this.updatedData,"edited datas");
        
  
        if (data.status == 1){
          this.loader.stop();
          this.toastr.success(data.msg)
        }else if(data.status == 0){
          this.loader.stop();
          this.toastr.error("Data updated failure")
        }
        },
        
        (error) => {
          this.toastr.error(error.error.Message)        
        }
      )
  }

  terms(){
    this.loader.start();  
  
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  
    this.apiLoading = true;
  
    this._http.getTerms(this.limit, this.skip ,this.headers).subscribe((data:any) => {
      this.values = data.data;
      this.datas = this.values.voucher_terms_condition;
      
      this.loader.stop();
        
    },(error) => {
      this.toastr.error(error.error.Message);
      this.loader.stop();
  
    }
  )}

  

  

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
}
