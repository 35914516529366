import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMyDatePickerModule } from '@mksasi/ngx-mydatepicker';
import { ToastrModule } from 'ngx-toastr';
import { ProductmanagementComponent } from '../productmanagement/productmanagement.component';
import { ProductmgtEditComponent } from './productmgt-edit/productmgt-edit.component';
import { AngularEditorModule } from "@kolkov/angular-editor";




@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        FormsModule,
        NgxMyDatePickerModule.forRoot(),
        NgxPaginationModule,
        NgxUiLoaderModule,
        ReactiveFormsModule,
        AngularEditorModule,
        ToastrModule.forRoot()
    ],
    declarations: [
        ProductmanagementComponent,
        ProductmgtEditComponent
    ],
    exports: [],
    providers: []
})
export class ProductmgtModule {}