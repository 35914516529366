<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Voucher Redemption Report</label>
                </div>
                <div>
                    <div class="d-flex" style="padding-top: 22px;">
                        <div class="form-floating me-3">
                            <label for="branch" style="margin-left:-8px;">Brand name
                            </label>
                            <select class="form-select" placeholder="Brand Name" [(ngModel)]="brand"
                                (change)="selectbrand(brand);filterList()" style="margin-top: 47px;">
                                <option value="" selected disabled hidden>Brand Name</option>
                                <option *ngFor="let option of brandData" [value]="option.id">{{ option.name }}</option>
                            </select>
                        </div>
                        <div class="col-3" style="margin-left: 34px;">
                            <div class="md-form form-sm my-3 position-relative">
                                <label for="start_date" class="mb-2">Start Date
                                </label>
                                <input style="margin-top: 3px;" (click)="dp.openCalendar(); openCalendarAndDetectChanges(dp)" [(ngModel)]="getstartDate"
                                    name="start" placeholder="Select Start Date" class="form-control datepicker" (ngModelChange)="checkButtonStatus()"
                                    ngx-mydatepicker [options]="startOptions" readonly #dp="ngx-mydatepicker"
                                    type="text" #start="ngModel" (dateChanged)="onStartDateChanged($event)"
                                    (calendarViewChanged)="onCalendarViewChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 36px; right: 0">
                                    <a href="javascript:void(0)" (focus)="dp.openCalendar()"
                                        (click)="dp.openCalendar()"><img src="../../../assets/images/icons/calendar.svg"
                                            width="32"></a>
                                </span>
                                <span class="text-danger" *ngIf="(start.dirty || start.touched) && start.errors?.['required']">
                                    start date is required
                                </span>
                            </div>
                        </div>
                        <div class="col-3" style="margin-left: 45px;">

                            <div class="md-form form-sm my-3  leftAlign position-relative">
                                <label for="start_date" class="mb-2">End Date
                                </label>

                                <input style="margin-top: 3px;" (click)="edp.openCalendar(); openCalendarAndDetectChange(edp)" [(ngModel)]="getendDate" 
                                    name="end" placeholder="Select End Date" class="form-control datepicker"
                                    ngx-mydatepicker [options]="endOptions" readonly #edp="ngx-mydatepicker" type="text" (ngModelChange)="checkButtonStatus()"
                                    #end="ngModel" (dateChanged)="onEndDateChanged($event)"
                                    (keypress)="$event.preventDefault()" required>
                                <span class="position-absolute" style="top: 36px; right: 0">
                                    <a href="javascript:void(0)" (focus)="edp.openCalendar()"
                                        (click)="edp.openCalendar()"><img
                                            src="../../../assets/images/icons/calendar.svg" width="32"></a>
                                </span>
                                <span class="text-danger" *ngIf="end.touched && end.errors?.['required']">
                                    End date is required
                                </span>
                            </div>

                        </div>
                        <div class="col-3" style="padding-left: 68px; padding-top: 48px;">
                            <button type="button" [disabled]="isButtonDisabled" class="btn btn-success" (click)="filterData()">Search</button>
                        </div>

                    </div>



                </div>

            </div>
            <ngx-ui-loader></ngx-ui-loader>

            <table class="table ">
                <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
                    <tr>
                        <th scope="col" class="pt1">Brand Name</th>
                        <th scope="col" class="pt2">Denomation</th>
                        <th scope="col" class="pt2">Quantity</th>
                        <th scope="col" class="pt1">Issuance Date</th>
                        <th scope="col" class="pt2">Net worth</th>
                        <th scope="col" class="pt2">Redemption <br> Amount</th>
                        <th scope="col" class="pt2">Balance Amount</th>
                        <th scope="col" class="pt1">Action</th>



                    </tr>
                </thead>
                <div style="width: 637%;" class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                    ng-reflect-ng-class="bg-light" *ngIf="filteredData && filteredData.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center col-12">
                            No Record Found.
                        </div>
                    </div>
                </div>

                <tbody>
                    <tr
                        *ngFor="let list of filteredData | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                        <th scope="row">{{list.brand_name}}</th>
                        <td class="text-end">{{list.denomination}}</td>
                        <td class="text-end">{{list.quantity}}</td>
                        <td class="text-center">{{list.issuance_date | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-end">{{list.total | currency:"₹"}}</td>
                        <td class="text-end">{{list.redemption_amount !== null && list.redemption_amount !==
                            undefined ? (list.redemption_amount | currency:"₹") : 0 | currency:"₹" }}</td>
                        <td class="text-end">{{list.total - list.redemption_amount | currency:"₹" }}</td>
                        <td class="text-end"><button type="button" class="btn btn-success" (click)="viewReport(list)">View</button></td>

                    </tr>
                </tbody>
            </table>
            <div>
                <div class="row" *ngIf="filteredData && filteredData.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                            {{count}} entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>


        </div>

    </div>