import { Component } from '@angular/core';

@Component({
  selector: 'app-domesticvoucher',
  templateUrl: './domesticvoucher.component.html',
  styleUrls: ['./domesticvoucher.component.scss']
})
export class DomesticvoucherComponent {

}
