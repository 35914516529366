<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-4">
                    <label class="texts">Product Voucher Generation List</label>
                </div>
                <div class="text-end col-8"
                    *ngIf="brandGenerationList.length > 0 && requestCouponBrand.type == 'ID'">
                    <a href="{{this.fileurls}}/sample/generation_sample_user_import.csv"
                        class="add_bt btn btn-success mr-10" type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download pr-1"></i> Sample file
                    </a>
                    <label class="rounded px-4 btalig bg-success text-center border-0 labelText mr-10" for="inputFile">
                        <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputUserFile
                            (change)="importUserBulkApproval($event)">
                        <i class="fa fa-upload pr-1"></i> Import</label>
                </div>


                <div class="text-end col-8">

                    <button  (click)="showSupplierModal('A')" class="btn btn-success mr-2" type="button"
                    data-bs-toggle="tooltip" data-bs-placement="left" title="Accept Request List"
                    *ngIf="((role === 'admin' || role === 'SuperAdmin')) && approvehide && (requestCouponBrand.status == 'Y' || requestCouponBrand.status == 'R')"
                    >Approve</button>
                
    
                <button (click)="showModa('R')" class="btn btn-primary ms-2" type="button"
                    *ngIf="((role === 'admin' || role === 'SuperAdmin')) && rejecthide && requestCouponBrand.status == 'Y'" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" title="Reject Request List"
                  >Reject</button>
    
                </div>

            </div>



            <div class="col-12 card border-0 shadow-sm px-0">
                <div class="card-body bg-light pb-4">
                    <div class="row" style="line-height: 50px;">
                        <div class="col-md-3">
                            <label>Reference No: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role === 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" >                        
                          
                            {{requestCouponBrand.voucher_request_id}}
                            </span>
                        </div>
                        <div class="col-md-3 text-truncate">
                            <label>Client: </label>
                            <span class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.company_name}}">{{requestCouponBrand.company_name}}
                            </span>
                        </div>
                        <div class="col-md-3" *ngIf="role === 'admin' || role === 'SuperAdmin'">
                            <label>Source: </label>
                            <span class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.source}}
                            </span>
                        </div>

                               
                        <div class="col-md-3 text-truncate">
                            <label>Brand: </label>
                            <span class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.brand_name}}">{{requestCouponBrand.brand_name}}
                            </span>
                        </div>     
                   

                        <div class="col-md-3">
                            <label>Issuance Fee: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.issuance_fee | currency : 'INR' }}
                            </span>
                        </div>      
                        
                        
                        <div class="col-md-3">
                            <label>Denomination: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.denomination | currency : 'INR' }}
                            </span>
                        </div>                          
                                              
                       

                        <div class="col-md-3" *ngIf = "requestCouponBrand.kart_type === '0'">
                            <label>Discount: </label>                           
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.discount}} %                            
                            </span>
                        </div>    

                        <div class="col-md-3" *ngIf = "requestCouponBrand.kart_type === '1'">
                            <label>Discount: </label>                           
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.discount | currency : 'INR'}}                           
                            </span>
                        </div>    
                       
                        <div class="col-md-3">
                            <label>Total: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.total | currency : 'INR' }}
                            </span>
                        </div>    

                        <div class="col-md-3">
                            <label>Quantity: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.quantity}}
                            </span>
                        </div>

                        <div class="col-md-3">
                            <label>Type: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.category}}
                            </span>
                        </div>

                        <div class="col-md-3 text-truncate">
                            <label>Model: </label>
                            <span class="text-truncate mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" >{{requestCouponBrand.type == "ID" ?
                                "Issuance & Distribution"
                                : requestCouponBrand.type === "I" ? "Issuance" : ""}}
                            </span>
                        </div>                                                
                                           

                        <div class="col-md-3 text-truncate" *ngIf = "(requestCouponBrand.source !== 'QwikCilver' && requestCouponBrand.source !== 'Amazon' && requestCouponBrand.source !== 'Ticket Express')">
                            <label>Authentication Type: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.authentication_type}}">{{requestCouponBrand.authentication_type}}
                            </span>
                        </div>     
                        
                        <div class="col-md-3" *ngIf = "requestCouponBrand.usage_mode">
                            <label>Usage Mode: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.usage_mode}}
                            </span>
                        </div>
                        
                        
                        <div class="col-md-3">
                            <label>Payment: </label>
                            <span class="text-truncate mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.payment_status == "0" ?
                                "Advance"
                                : requestCouponBrand.payment_status == "1" ? "Credit" : ""}}
                            </span>
                        </div>

                        <div class="col-md-3 text-truncate">
                            <label>Issuance Date: </label>
                            <span class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.issuance_date | date : 'dd/MM/yyyy'}}">{{requestCouponBrand.issuance_date | date : 'dd/MM/yyyy'}}
                            </span>
                        </div>   


                        <div class="col-md-3 text-truncate">
                            <label>Start Date: </label>
                            <span class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.start_date | date : 'dd/MM/yyyy'}}">{{requestCouponBrand.start_date | date : 'dd/MM/yyyy'}}
                            </span>
                        </div>   

                        <!-- <span>{{this.voucherkartvalue}}</span> -->
                        <div class="col-md-3 text-truncate">
                            <label>Expiry: </label>
                            <span *ngIf = "(this.voucherkartvalue != 'voucherkart')" class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.validity}}">{{requestCouponBrand.validity}}
                            </span>
                            <span *ngIf = "(this.voucherkartvalue == 'voucherkart')" class="mr-5 text-truncate" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.validity}}">{{requestCouponBrand.validity | date : 'dd/MM/yyyy'}}
                            </span>
                        </div>  

                             
                        <div class="col-md-3" *ngIf = "requestCouponBrand.status === 'A' || requestCouponBrand.status === 'G'">
                            <label>Supplier Type: </label>
                            <span style="text-transform: capitalize;" class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.supplier_type}}
                            </span>
                        </div>             
                                    
                        <div
                        class="col-md-3 text-truncate"
                        *ngIf="requestCouponBrand.voucher_delivery_type">
                        <label>Delivery Type: </label>
                        <span
                          class="mr-5"
                          [ngClass]="
                            role == 'admin' || role === 'SuperAdmin'
                              ? 'advcolor'
                              : role == 'company'
                              ? 'sides'
                              : 'side'
                          "
                          title="{{ requestCouponBrand.voucher_delivery_type }}"
                          >{{ requestCouponBrand.voucher_delivery_type }}
                        </span>
                      </div>
                                           
                        <div class="col-md-3 text-truncate" *ngIf = "requestCouponBrand.product">
                            <label>Product: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'" title="{{requestCouponBrand.product}}">{{requestCouponBrand.product}}
                            </span>
                        </div>    
                        <div
                class="col-md-3 text-truncate"
                *ngIf="requestCouponBrand.voucher_delivery_type == 'Single'">
                <label>Recipient Email: </label>
                <span
                  class="mr-5"
                  [ngClass]="
                    role == 'admin' || role === 'SuperAdmin'
                      ? 'advcolor'
                      : role == 'company'
                      ? 'sides'
                      : 'side'
                  "
                  title="{{ requestCouponBrand.voucher_delivery_email }}"
                  >{{ requestCouponBrand.voucher_delivery_email }}
                </span>
              </div>
                        <div class="col-md-3" *ngIf = "requestCouponBrand.usagemode">
                            <label>Usage Mode: </label>
                            <span class="mr-5" [ngClass]="role == 'admin' || role == 'SuperAdmin' ? 'advcolor' :role == 'company' ? 'sides' :'side'">{{requestCouponBrand.usagemode}}
                            </span>
                        </div>                           
                                   
                     
                    </div>

                </div>
            </div>        


            <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
            *ngIf="brandGenerationList.length === 0">
            <div class="card-body row mx-0 ps-0">
                <div class="text-center" *ngIf="requestCouponBrand.status === 'Y' && pending">
                    Voucher is Pending for Approval.
                </div>


                <div class="text-center" *ngIf="(requestCouponBrand.status === 'A' || approvestatus) && !issuehide && !this.logvalue">
                    Voucher is Approved.   Voucher will be generated on {{issuancedate | date : 'dd/MM/yyyy'}}. 
                                                     
                </div>

                <div class="text-center" *ngIf="(requestCouponBrand.status === 'A' || approvestatus) && issuehide && !this.logvalue">
                    Voucher is Approved.   Voucher will be generated on {{issuedate}}. 
                                                     
                </div>


                <div class="text-center" *ngIf="this.logvalue">
                     {{this.logvalue}}. 
                                                     
                </div>



              
                <div class="text-center" *ngIf="requestCouponBrand.status === 'R' || rejectstatus">
                    Voucher is Rejected.
                </div>
               
                <div class="text-center" *ngIf="requestCouponBrand.status === 'G'">
                    Voucher is Generated.
                </div>
            </div>
        </div>



        <div class="mt-4 px-1" *ngIf="brandGenerationList.length > 0">
            <div class ="row mx-0 adcolor py-4 opacity-75">

                <div class="col-2 labelText text-start" *ngIf = "hide"> Voucher Code </div>   
                
                <div class="col-3 labelText text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> Voucher Pin </div>  

                <!-- <div class="col-2 labelText text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> QR Code </div>  -->
                
                <div class="col-2 labelText text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> Status </div>                   

                <div class="col-2 labelText text-center" *ngIf = "requestCouponBrand.source == 'Ticket Express'"> View Code</div>

                <div class="col-2 labelText text-center" *ngIf = "requestCouponBrand.source == 'Ticket Express'"> Activation URL</div>

                <div class="labelText" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' "> Card Number </div> 
                
                <div class="labelText text-center"  [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' || requestCouponBrand.source == 'Ticket Express' ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' || requestCouponBrand.source == 'Ticket Express' "> Card Pin </div> 

                <div class="labelText text-center"  [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'"> Activation URL </div>  

                <div class="labelText" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' "> Activation Code </div>                                          
                                                             
              
                <div class="labelText text-center"  [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col-3 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'">Validity</div>


                <div class="labelText text-center"  [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' ? 'ps-2 col-2':'col-3 px-0'" *ngIf="requestCouponBrand.type == 'ID'"> Allocated user email</div>
            </div>
            
            

            <div>
                <div 
                 *ngIf="brandGenerationList.length > 0">
                    <div class="" *ngFor="let list of brandGenerationList | paginate: {
                        itemsPerPage: limit,
                        currentPage: p,totalItems:count}">
                        <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 ps-0">


                                <div class="col-2 text-truncate text-start textsiz" *ngIf = "hide"> {{list.voucher_code}} </div>

                                <div class="textsiz" class="col-3 text-truncate text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> {{list.voucher_pin}} </div>

                                <!-- <div class="textsiz" class="col-2 text-truncate text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> <qrcode [qrdata]="'myAngularxQrCode'" [width]="50"></qrcode> </div>                                -->


                                <!-- <div class="col-2 text-truncate text-center"> <img class="imageval" [src]="this.pathurl+list.image">
                                </div>  -->


                              
                                <div class="textsiz" class="col-2 text-truncate text-center" *ngIf = "requestCouponBrand.source == 'voucherkart'"> {{list.status == 'G' ? "Generated" : ""}} </div>

                                
                                <div class="col-2 text-truncate text-center textsiz" *ngIf = "requestCouponBrand.source == 'Ticket Express'"> {{list.view_code}}
                                </div>

                                   
                                <!-- <div class="col-2 text-truncate text-start textsiz" *ngIf = "requestCouponBrand.source == 'Ticket Express'" title="{{list.edenred_url}}"> {{list.edenred_url}}
                                </div> -->


                                <div class="col-2 text-truncate text-center textsiz" *ngIf = "requestCouponBrand.source == 'Ticket Express'">

                                    <a *ngIf = "list.edenred_url" class="linkColor" (click)="viewurl(list.edenred_url)">
                                        <i class="fa fa-copy cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click here to Copy url"></i>
                                    </a>
                                </div>

                                <div class="textsiz" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'" title="{{list.card_number}}"> {{list.card_number}} </div>

                                <div class="textsiz text-center text-truncate" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' || requestCouponBrand.source == 'Ticket Express'   ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon' || requestCouponBrand.source == 'Ticket Express'" title="{{list.card_pin}}"> {{list.card_pin}} </div>
<!-- 
                                <div class="text-truncate" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-3':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'" title="{{list.activation_url}}"> {{list.activation_url}} </div> -->

                                  <!-- <div class="text-center" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-3':'col px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'">
                                        <button class="btn btn-success textsiz" (click)="viewurl(list.activation_url)">Click here to Copy</button>
                                    </div> -->


                                    <div class="text-center" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'">

                                        <a *ngIf = "list.activation_url" class="linkColor" (click)="viewurl(list.activation_url)" >
                                            <i class="fa fa-copy cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click here to Copy url"></i>
                                        </a>
                                    </div>
                                    <!-- <button [cdkCopyToClipboard]="value">Copy to clipboard</button> -->

                                <div class="textsiz" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'" title="{{list.activation_code}}"> {{list.activation_code}} </div>
                                                            
                         
                                <div class="text-center text-truncate textsiz" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col-2 px-0'" *ngIf = "requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'" title="{{list.validity}}"> {{list.validity}}
                                </div>



                                <div class="textsiz text-start text-truncate" [ngClass]="requestCouponBrand.source == 'QwikCilver' || requestCouponBrand.source == 'Amazon'  ? 'ps-2 col-2':'col-2 px-0'" *ngIf="requestCouponBrand.type == 'ID'" title="{{list.voucher_allocated_user_email}}">
                                    {{list.voucher_allocated_user_email}}
                                </div>
                               


                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="brandGenerationList.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of {{count}}
                            entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination adpage"
                            (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                </div>

            </div>


        </div>


      
        
        <div class="d-flex align-items-end justify-content-end mt-4">
            <button class="btn backcolor" (click)="back()">Back</button>
        </div>
    </div>
</div>      
             


<div class="modal fade" id="rejectModal" tabindex="-1" aria- labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to reject the voucher?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-6" data-bs-dismiss="modal"  (click)="clear()" #closeBtn>Cancel</button>
                    <button class="btn btn-success" data-bs-dismiss="modal" (click)="Rejectbrand(requestCouponBrand)">Confirm
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="approveModals" tabindex="-1" aria- labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-body text-center">
            <div>
                <label>Supplier Type : </label>
                <span class="tex text-primary">External
                </span>
            </div>
            <p class="mt-5">
                <button class="btn btn-secondary mr-6" data-bs-dismiss="modal"  (click)="clear()" #closeBtn>Cancel</button>
                <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveBrands(requestCouponBrand)">Okay
                </button>
            </p>
        </div>
    </div>
</div>
</div>


<div class="modal fade" id="approveModalss" tabindex="-1" aria- labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-body text-center">
            <div>
                <label>Supplier Type : </label>
                <span class="tex text-primary">Internal
                </span>
            </div>
            <p class="mt-5">
                <button class="btn btn-secondary mr-6" data-bs-dismiss="modal"  (click)="clear()" #closeBtn>Cancel</button>
                <button class="btn btn-success" data-bs-dismiss="modal" (click)="approveBrand()">Okay
                </button>
            </p>
        </div>
    </div>
</div>
</div>