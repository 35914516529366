import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from 'src/app/restful-services/restful.service';

@Component({
  selector: 'app-wallet-threshold',
  templateUrl: './wallet-threshold.component.html',
  styleUrls: ['./wallet-threshold.component.scss']
})
export class WalletThresholdComponent implements OnInit {

  wallet:any;
  private headers: any = {};
  role: any;
  userDetails: any;
  postParams:any;
  paramValue:any;
  userId:any;
  values: any = [];
  limit: number = 25;
  skip: number = 0;
  companylist : any;
  companyWallet : any;
  apiLoading: boolean = false;
  totalCount:number = 0;
  d: number = 1;
  lastvalue: number = 0;
  clientName :string = '';
  clientwallet:any;

  constructor(private route:ActivatedRoute, sanitizer:DomSanitizer,private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService)
   {
    this._http.getClientName().subscribe((data:any) =>{
     
      this.clientName = data.clientName;
    })

    // this._http.getClientwallet().subscribe((data:any) =>{
    //   this.clientwallet = data.availablewallet;
    // })
    
    
   }



ngOnInit(){

  this.route.queryParams.subscribe(params => {
    this.paramValue = params['id'];
  });
  // console.log('Siva id....',this.paramValue)
  this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
      
      this.userId = this.userDetails.UserID;
      // console.log(this.userId);
    }

    this.getWallet();
}

newPopup(){
  this.wallet = "";
  const element = document.getElementById('productmapModal') as HTMLElement;
  const addproductmapModal = new Modal(element,
    {
      backdrop: 'static',
      keyboard: false
    });
    addproductmapModal.show(); 
  }


  allowOnlyNumbers(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const key = event.key;

    if (!/^[0-9\s]+$/.test(key)) {
      event.preventDefault();
    }
  }


  addWallet(){
    if(!this.wallet){
      this.toastr.error("Please Enter the Wallet Amount");
      return;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

  
  this.postParams = {
    client_id:this.paramValue,
    amount:this.wallet,
    created_by:this.userId,
    created_by_role:this.role
}
this.loader.start();
  this._http.addWallet(this.postParams, this.headers).subscribe({
    next: (data: any) => {

      this.loader.stop();
      
      if (data.status == 1) {
        this.loader.stop();
        this.toastr.success("Wallet added successfully");
        this.getWallet();
       

      }

      else if (data.status === 0) {
        this.loader.stop();
        this.toastr.error(data.msg);

      }

    },
    error: error => {
      this.loader.stop();
      this.toastr.error(error.error.Message);
      
    }
  })
}

statusChange(id: any, status: any) {
  let requestParams = {
    'id': id,
    'status': status
  }

  let token = localStorage.getItem("token");
  this.headers = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
     })
   };

  this.apiLoading = true;
  this.loader.start();

  this._http.walletStatus(requestParams,this.headers).subscribe((data: any) => {
    this.apiLoading = false;
    this.loader.stop();
    if (data.status == 1) {
      this.toastr.success("Status Updated Successfully");
      this.getWallet();
    }
    else if (data.status ===0) {
      this.toastr.success("Something Went Wrong");
    }
    else {
      this.toastr.error(data.msg);
    }
  })
}

getWallet(){
  this.loader.start();  

  let token = localStorage.getItem("token");
  this.headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  };

  this.apiLoading = true;

  this._http.getWallet(this.paramValue, this.limit, this.skip, this.headers).subscribe((data:any) => {
    // console.log(data.list,data);
    
    this.values = data.list;
    this.companylist = data.company_info[0].CompanyName;
    this.companyWallet = data.company_info[0].wallet;
    this.loader.stop();
    this.totalCount = data.totalCount;
    if (this.skip == 0) {
      this.lastvalue = this.skip + 25;
    }
    

    
  },(error) => {
    this.toastr.error(error.error.Message);
    this.loader.stop();

  }
)}

pageChanged(pageNo: any) {
  this.d = pageNo;
  this.skip = (pageNo - 1) * 25;
  this.lastvalue = this.skip + 25;
  localStorage.setItem('pageNo', pageNo);
  this.getWallet();
}

backStage(){
  this.router.navigate(['/clients'],{replaceUrl:false});
}
}
