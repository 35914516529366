<div class="row mx-0">
    <div  class="col-12 card border-0 shadow-sm px-0">   
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Terms & Conditions</label>
                </div>
            </div>
            <angular-editor [(ngModel)]="this.datas" [config]="config"></angular-editor>
        </div>
    
        <div class="row mx-0">
            <div class="col-md-12 text-end">
                <button class="btn btn-success my-3 mx-3" (click)="updatedTerms()">Save</button>
            </div>

        </div>
       
    </div>
    
</div>
<ngx-ui-loader></ngx-ui-loader>