import { Component, ChangeDetectorRef, HostListener, OnInit, inject } from '@angular/core';
import { INgxMyDpOptions, IMyDateModel, IMyCalendarViewChanged } from '@mksasi/ngx-mydatepicker';
import { AppService } from '../restful-services/restful.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient, HttpHeaders, HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-digital-voucher-report',
  templateUrl: './digital-voucher-report.component.html',
  styleUrls: ['./digital-voucher-report.component.scss']
})
export class DigitalVoucherReportComponent implements OnInit {

  public startOptions: INgxMyDpOptions = {};
  public endOptions: INgxMyDpOptions = {};
  private headers: any = {};
  start_date: any;
  isDownload: boolean = true
  end_date: any;
  brand: any = '';
  public getstartDate: string = "";
  public getendDate: string = "";
  isButtonDisabled = true;
  skip: number = 0;
  limit: number = 10;
  d: number = 1;
  lastvalue: number = 0;
  count: number = 0;
  apiLoading = true;
  getDigital: any = [];
  dateRequire: boolean = false;
  role: any;
  columns:Array<string> = [
    "Source of Order",
    "Program - Sub ID",
    "Client Type",
    "Brand of EGV",
    "Deno",
    "Qty",
    "Total Value",
    "Our Best Source Discount",
    "Discount given to Client",
    "Gross Margin",
    "Earning",
    "Payment Through",
    "Payment Tagging"
  ];
  payment_type: string = "";
  paymentDatas: any = [
    {"value":"points","label":"Points Master Wallet"},
    {"value":"points_pay","label":"Points + Pay"},
    {"value":"payment","label":"Payment by BT"},
    {"value":"wallet","label":"API Wallet"}
  ]

  sourceName: string = "";
  sourceDatas: any = [
    {"value":"Rewardkart","label":"Rewardkart"},
    {"value":"SuperAdmin","label":"Bulk Buying"},
    {"value":"Company","label":"API Clients"}
  ]
  toastr = inject(ToastrService);
  primaryKeys: Array<string> = [
    "source_name",
    "client_name",
    "created_by_name",
    "source",
    "denomination",
    "quantity",
    "total",
    "Product_discount",
    "client_discount",
    "gross_marign",
    "net_profit",
    "payment_method",
    "payment_tagging"
  ];
  supplierName:string = "";
  constructor(private cdRef: ChangeDetectorRef, private service: AppService, private datepipe: DatePipe, private loader: NgxUiLoaderService) {
    let dp = new Date();
    dp.setDate(dp.getDate() + 1);
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay: true,
      disableSince: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },

    };
    let edp: any = new Date();
    edp.setDate(edp.getDate() + 1);
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay: true,
      disableSince: { year: edp.getFullYear(), month: edp.getMonth() + 1, day: edp.getDate() },
    };

  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.getDigitalVoucher();
    // if(this.role === "admin"){
    //   this.voucherClear();
    // }
  }



  onStartDateChanged(event: IMyDateModel): void {
    this.start_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged): void {
    // console.log(event);
    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
  }

  openCalendarAndDetectChanges(dp: any) {
    dp.openCalendar();
    this.cdRef.detectChanges();
  }

  onEndDateChanged(event: IMyDateModel): void {
    // console.log("end date " , new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 11).replace('T', ' '));
    this.end_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');


  }

  checkButtonStatus() {
    this.isButtonDisabled = !this.getstartDate || !this.getendDate;
  }

  openCalendarAndDetectChange(edp: any) {
    edp.openCalendar();
    this.cdRef.detectChanges();
  }

  filterData() {
    if(this.start_date && !this.end_date) {
      this.toastr.error("Please Select End Date");
      return;
    }
    if(!this.start_date && this.end_date) {
      this.toastr.error("Please Select Start Date");
      return;
    }
    this.getDigitalVoucher();
  }


  voucherDownload() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getVoucherDownload(this.limit, this.skip,true,this.role,this.start_date, this.end_date)
      
  }

  getDigitalVoucher() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getVoucherReport(this.limit, this.skip, this.headers, this.role, this.start_date, this.end_date,this.payment_type,this.sourceName,this.supplierName).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.getDigital = data.data;
        if (data) {
          this.apiLoading = false;
          this.count = data.count;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }

        }
      },
      error: _error => {
        console.error(_error),
          this.apiLoading = false;
        this.loader.stop();
      }
    })
  }


  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * 10;
    this.lastvalue = this.skip + 10;
    localStorage.setItem('pageNo', pageNo);
    if(!this.dateRequire)
      this.getDigitalVoucher();
    if(this.dateRequire)
      this.voucherClear();
    
  }

  voucherClear() {
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getVoucherClear(this.limit, this.skip, this.headers, this.role).subscribe({
      next: (data: any) => {
        this.loader.stop();
        this.getDigital = data.data;
        if (data) {
          this.apiLoading = false;
          this.count = data.count;
          // this.ProductmgtList = data.data;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }
        }
      }, error : (e:any) => {
        this.loader.stop();
      }
    })

  }

  clear() {
    if(this.sourceName || this.payment_type || this.getstartDate || this.getendDate || this.supplierName) {
      this.sourceName = "";
      this.payment_type = "";
      this.dateRequire = true;
      this.getstartDate = '';
      this.getendDate = '';
      this.supplierName = "";
      this.voucherClear();
    }
  }

  keys(params:object) {
    return Object.keys(params).filter(key => this.primaryKeys.includes(key));
  }

  checkDecimal(value:string | number,param:string) {
    let currency:boolean = false;
    let discount:boolean = false;
    if(param === "net_profit" || param === "total" || param === "denomination"){
      currency = true;
    }else if(param === "client_discount" || param === "Product_discount" || param === "gross_marign"){
      discount = true;
    }
    if(typeof(value) === "number"){
      let newVal = value.toString().split(".")[1];
      if(newVal && newVal.length > 2){
        return  currency ? `₹${value.toFixed(2)}` : discount ? `${value.toFixed(2)}%` : `${value.toFixed(2)}`;
      }
      else {
        return  currency ?  `₹${value}` : discount ? `${value}%` : `${value}`;
      }
    }else{
      return currency ? `₹${value}` : discount ? `${value}%` : `${value}`;
    }
  }

  paymentType(type:string) {
    this.payment_type = type;
  }

  sourceType(type:string) {
    this.sourceName = type;
  }


}
