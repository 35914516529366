import { Component, OnDestroy, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/restful-services/restful.service";
import { INgxMyDpOptions, IMyDateModel } from "@mksasi/ngx-mydatepicker";
import { DatePipe } from "@angular/common";
import { RequestVoucher } from "src/app/models/RequestVoucher";
import { HttpHeaders } from "@angular/common/http";
import * as XLSX from "xlsx";
import { Modal } from "bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AnimateTimings } from "@angular/animations";
import { last, min, takeUntil } from "rxjs";
import { Directive, ElementRef, HostListener } from "@angular/core";
import { environment, environmentfiles } from "src/environments/environment";
import { identifierName } from "@angular/compiler";
export const noFullStopRegex = /^[^.]*$/;
declare var bootstrap: any;

@Component({
  selector: 'app-internationalvoucher',
  templateUrl: './internationalvoucher.component.html',
  styleUrls: ['./internationalvoucher.component.scss']
})
export class InternationalvoucherComponent implements OnDestroy {

  public string: "[restrictChars]" | undefined;

  public startOptions: INgxMyDpOptions = {};
  public issuanceOptions: INgxMyDpOptions = {};
  public ExpiryOptions: INgxMyDpOptions = {};

  d: Date = new Date();
  optionsStatus = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  categoryOption = [
    { label: "Online", value: 0 },
    { label: "Offline", value: 1 },
  ];
  source: any = "";
  type: any;
  modal: any;
  brandName: any = "";
  category: any;
  denomination: any;
  payment_status: any = "";
  voucher_delivery_type: any = "";
  quantity: any;
  validity: any = "";
  vouchergeneratedStatus: any;
  company: any;
  requestCouponVoucher: RequestVoucher;
  isEdit: boolean = false;
  userDetails: any;
  voucherId: any;
  supplierType: any;
  companyKeyword = "CompanyName";
  brandKeyword = "BrandName";
  productKeyword = "ProductName";
  companyData: any = [];
  brandData: any = [];
  QCedit: any = [];
  Amazonedit: any = [];
  currencyData:any = [];
  sourceName: any = "";
  brandDisabled: boolean = false;
  noBrandFound: boolean = false;
  noCompanyFound: boolean = false;
  noSourceFound: boolean = false;
  endDate: any;
  issuanceDate: any;
  getstartDateOptions: any;
  public role: any;
  isValidUrl: boolean = true;

  getIssuanceDate: any;
  getExpiryDate: any;

  startDate: any;
  ExpiryDate: any;
  IssuanceDate: any;

  public getstartDate: any;
  public getendDate: any;
  public getissuanceDate: any;

  send_date = new Date();
  formattedDate: any;

  total: any;
  comname: any;
  discount: any;
  payment_received: any;
  issuance: any;
  start: any;
  kart_type: any = "";
  issuance_fee: any;
  voucher_delivery_email: any;

  private headers: any = {};

  comitem: any = {};
  minparam: any = {};

  authentication_type: any = "";
  voucher_redemption_type: any = "";
  otpshow: boolean = true;
  otpDisabled: boolean = false;

  uniquesource = [];
  uniquebrands = [];

  disvalue: any;
  distype: any;
  pay: any;
  issfee: any;
  fetchDisabled: boolean = false;

  minmaxshow: boolean = false;
  isDatepickerDisabled: boolean = false;
  expiryvalueint: any;
  expiryvalue: any;
  expiryvalues: any;
  maxprice: any;
  productid: any;
  minprice: any;
  NewArray: any;
  externalproductid: any;

  totalval: boolean = false;
  voucher_redemption_url: any;

  sourceData: any = [];
  sourcesName: any = "";
  SourceName: any = "";
  sourceKeyword = "SourceName";
  fetchdata: any = [];
  denomDisabled: boolean = false;
  hidebtns: boolean = true;
  hideauthfield: boolean = false;

  producthide: boolean = true;
  usagehide: boolean = false;
  productData: any = [];
  branid: any;
  brandedid: any;
  BrandName: any;

  brandidvalue: any;
  editvoucherId: any;

  totalshow: boolean = false;
  totalfacevalueshow: boolean = false;
  discountvalue: any;
  maxdiscountshow: boolean = false;
  discounttypehide: boolean = false;

  productshow: boolean = true;
  sourceid: any;

  editbrandId: any;
  brandlabelhide: boolean = false;
  brandlabelshow: boolean = true;

  clientchange: boolean = false;
  brandchange: boolean = false;
  productchange: boolean = false;

  editbrandidval: any;
  tooltipList: any;
  postParams: any;
  inputFile: any;
  fileurls: any;
  skuList: any;
  voucherDetails: any;
  currency: any;
  newCal: any;
  filteredCurrency: any;
  finalFilteredCurrency: any;
  currencyListdata: any = [];
  markUp: number = 1;
  markUpPercentage: number = 1;
  b2cPrice :any = [];
  yggHide: boolean = false;
  brandShow: boolean = false;
  newbrandData: Array<any> = [];
  newbrandKeyword: string = "BrandName";
  newBrandID!: string | number;

  constructor(
    private router: Router,
    private _http: AppService,
    public datePipe: DatePipe,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private el: ElementRef,
    private ngZone: NgZone
  ) {
    this.requestCouponVoucher = new RequestVoucher();

    this.companyData.forEach((CompanyName: any) => {
      if (!this.uniquesource.includes(CompanyName as never)) {
        this.uniquesource.push(CompanyName as never);
      }
    });

    this.brandData.forEach((BrandName: any) => {
      if (!this.uniquebrands.includes(BrandName as never)) {
        this.uniquebrands.push(BrandName as never);
      }
    });

    let tdate = new Date();
    this.getIssuanceDate = {
      date: {
        year: tdate.getFullYear(),
        month: tdate.getMonth() + 1,
        day: tdate.getDate(),
      },
    };

    let sdate = new Date();
    this.getstartDate = {
      date: {
        year: sdate.getFullYear(),
        month: sdate.getMonth() + 1,
        day: sdate.getDate(),
      },
    };

    this.startDate = new Date();
    this.IssuanceDate = new Date();

    let d = new Date();
    d.setDate(d.getDate() - 1);
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.startOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.issuanceOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.ExpiryOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };
  }

  ngOnInit(): void {
    this.onChangeSource();
    this.onChangeParcategory();

    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }

    this.role = localStorage.getItem("role");
    const { id } = history.state;
    const { validity } = history.state;

    if (validity) {
      this.voucherDetails = validity;
    }
    if (id) {
      this.isEdit = true;
      this.voucherId = id;
    } else {
      let data = localStorage.getItem("edit");
      this.isEdit = false;

      if (data) {
        this.requestCouponVoucher = JSON.parse(data);
        if(this.requestCouponVoucher.source === "Bamboo") {
          this.brandShow = true;
        }

        this.startDate = this.requestCouponVoucher.start_date;
        this.issuanceDate = this.requestCouponVoucher.issuance_date;

        const issuanceDate = new Date(this.requestCouponVoucher.issuance_date);
        this.getissuanceDate = {
          date: {
            year: issuanceDate.getFullYear(),
            month: issuanceDate.getMonth() + 1,
            day: issuanceDate.getDate(),
          },
        };

        const startDate = new Date(this.requestCouponVoucher.start_date);
        this.getstartDate = {
          date: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
          },
        };
      }
    }

    if (this.userDetails.CompanyName !== "") {
      this.brandDisabled = true;
    } else {
      this.brandDisabled = false;
    }

    if (this.role === "company") {
      this.requestCouponVoucher.type = "ID";
    }
    if (this.role === "brand") {
      this.requestCouponVoucher.type = "I";
    }

    if (this.role === "company") {
      if (this.userDetails.CompanyName) {
        this.requestCouponVoucher.company_name = this.userDetails.CompanyName;
      } else {
        this.requestCouponVoucher.company_name = this.userDetails.UserName;
      }
      this.brandDisabled = true;
      this.requestCouponVoucher.type = "ID";
    }

    if (this.role === "brand") {
      if (this.userDetails.CompanyName) {
        this.requestCouponVoucher.brand_name = this.userDetails.CompanyName;
      } else {
        this.requestCouponVoucher.brand_name = this.userDetails.UserName;
      }

      this.brandDisabled = true;
      this.requestCouponVoucher.type = "ID";
    }

    if (this.isEdit) {
      this.productshow = false;
      this.producthide = false;

      this._http.voucherDetailItem.subscribe({
        next: (data) => {
          if (data) {
            this.requestCouponVoucher = data;
            data.validity = this.voucherDetails;
            if(this.requestCouponVoucher.source === "YGG"){
              this.requestCouponVoucher.source = "YOU GOTA GIFT";
            }
            if(this.requestCouponVoucher.source === "DT"){
              this.requestCouponVoucher.source = "DT ONE";
            }
            if(this.requestCouponVoucher.source === "Bamboo") {
              this.requestCouponVoucher.brand_name = this.requestCouponVoucher.brand_name;
            }
            localStorage.setItem("edit", JSON.stringify(data));
            console.log(
              "this.requestCouponVoucher.usagemode :",
              this.requestCouponVoucher.usagemode
            );
            if (
              this.requestCouponVoucher.usage_mode &&
              this.requestCouponVoucher.source === "Ticket Express"
            ) {
              this.usagehide = true;
              this.requestCouponVoucher.usagemode =
                this.requestCouponVoucher.usage_mode;
            } else if (
              this.requestCouponVoucher.internal_usage_mode &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.usagehide = true;
              this.requestCouponVoucher.usagemode =
                this.requestCouponVoucher.internal_usage_mode;
            } else {
              this.denomDisabled = false;
              this.producthide = false;
              this.usagehide = false;
              this.requestCouponVoucher.usagemode = "";
            }
            if( this.requestCouponVoucher.source === "Bamboo") {
              this.brandShow = true;
            } else {
              this.brandShow = false;
            }
            if (this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT") {
              this.denomDisabled = true;
              this.producthide = true;
            } else {
              this.denomDisabled = false;
              this.producthide = false;
            }

            if (this.requestCouponVoucher.source === "voucherkart") {
              this.productshow = true;
              this.totalshow = true;
              this.totalfacevalueshow = true;
              this.calculate();
            } else {
              this.productshow = false;
              this.totalshow = false;
              this.totalfacevalueshow = false;
            }
            if (this.requestCouponVoucher.source === "MEA" || this.requestCouponVoucher.source === "YOU GOTA GIFT" ) {
              this.requestCouponVoucher.validity = this.voucherDetails;
            }
            if (
              (this.requestCouponVoucher.product_discount &&
                this.requestCouponVoucher.source === "Ticket Express") ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "EZPIN" ||
              this.requestCouponVoucher.source === "DT" ||
              this.requestCouponVoucher.source === "MEA" ||
              this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "Bamboo"
            ) {
              this.discountvalue = this.requestCouponVoucher.product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            } else if (
              this.requestCouponVoucher.internal_product_discount &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.discountvalue =
                this.requestCouponVoucher.internal_product_discount;
              this.maxdiscountshow = true;
              this.discounttypehide = false;
            } else {
              this.discountvalue = "";
              this.maxdiscountshow = false;
              this.discounttypehide = true;
            }

            if (
              (this.requestCouponVoucher.minPrice &&
                this.requestCouponVoucher.maxPrice &&
                this.requestCouponVoucher.source === "QwikCilver") ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "EZPIN" ||
              this.requestCouponVoucher.source === "DT" ||
              this.requestCouponVoucher.source === "MEA" ||
              this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "Bamboo"
            ) {
              this.minprice = this.requestCouponVoucher.minPrice;
              this.maxprice = this.requestCouponVoucher.maxPrice;
              this.minmaxshow = true;
            } else if (
              this.requestCouponVoucher.minface_value &&
              this.requestCouponVoucher.maxface_value &&
              this.requestCouponVoucher.source === "voucherkart"
            ) {
              this.minmaxshow = true;
              this.minprice = this.requestCouponVoucher.minface_value;
              this.maxprice = this.requestCouponVoucher.maxface_value;
            } else {
              this.minprice = "";
              this.maxprice = "";
              this.minmaxshow = false;
            }

            if (
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "EZPIN" ||
              this.requestCouponVoucher.source === "DT" ||
              this.requestCouponVoucher.source === "MEA" ||
              this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "Bamboo"

            ) {
              this.hideauthfield = false;
            } else {
              this.hideauthfield = true;
            }

            if (
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "EZPIN" ||
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "voucherkart" ||
              this.role === "brand" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "DT" ||
              this.requestCouponVoucher.source === "MEA" ||
              this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "Bamboo"
            ) {
              this.isDatepickerDisabled = true;
            } else {
              this.isDatepickerDisabled = false;
            }

            if (this.requestCouponVoucher.product && this.requestCouponVoucher.source !== "Bamboo") {
              this.producthide = true;
            } else {
              this.producthide = false;
            }

            if (
              this.requestCouponVoucher.source === "Ticket Express" ||
              this.requestCouponVoucher.source === "voucherkart" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "MEA"
            ) {
              this.requestCouponVoucher.kart_type = "0";
            } else {
              this.requestCouponVoucher.kart_type =
                this.requestCouponVoucher.kart_type;
            }

            if (
              this.requestCouponVoucher.source === "QwikCilver" ||
              this.requestCouponVoucher.source === "DT" ||
              this.requestCouponVoucher.source === "Amazon" ||
              this.requestCouponVoucher.source === "FlipKart" ||
              this.requestCouponVoucher.source === "EZPIN" ||
              this.requestCouponVoucher.source === "MEA" ||
              this.requestCouponVoucher.source === "YOU GOTA GIFT" || this.requestCouponVoucher.source === "DT ONE" || this.requestCouponVoucher.source === "Bamboo"
            ) {
              this.brandlabelhide = true;
              this.brandlabelshow = false;
            } else {
              this.brandlabelhide = false;
              this.brandlabelshow = true;
            }

            this.startDate = this.requestCouponVoucher.start_date;
            this.IssuanceDate = this.requestCouponVoucher.issuance_date;
            this.ExpiryDate = this.requestCouponVoucher.validity;
            this.requestCouponVoucher.currency = this.requestCouponVoucher.currency_code
            const i = new Date(this.requestCouponVoucher.issuance_date);
            this.getIssuanceDate = {
              date: {
                year: i.getFullYear(),
                month: i.getMonth() + 1,
                day: i.getDate(),
              },
            };

            const s = new Date(this.requestCouponVoucher.start_date);
            this.getstartDate = {
              date: {
                year: s.getFullYear(),
                month: s.getMonth() + 1,
                day: s.getDate(),
              },
            };

            const d = new Date(this.requestCouponVoucher.validity);
            this.getExpiryDate = {
              date: {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate(),
              },
            };

            //this.calculate();
            //  this.selectdistypeEvent();
            if (this.role === "admin" || this.role === "SuperAdmin") {
              this.brandidvalue = this.requestCouponVoucher.brandId;
            }

            // this.onChangeCompany(this.requestCouponVoucher.company_name);
            // this.onChangeBrand(this.requestCouponVoucher.brand_name);
            // this.onChangeProduct(this.requestCouponVoucher.product);
          } else {
          }
        },
      });
    } else {
      let sourceval: any = localStorage.getItem("edit");
      let val = JSON.parse(sourceval);

      if (val != null) {
        let sourcevalue = val.source;

        this.editvoucherId = val.voucher_request_id;
        let minvalue = val.minPrice;
        let maxvalue = val.maxPrice;
        let maxdiscount = val.product_discount;

        let internalminvalue = val.minface_value;
        let internalmaxvalue = val.maxface_value;
        let internalmaxdiscount = val.internal_product_discount;
        let kartval = val.kart_type;
        let typevalue = val.type;

        if (sourcevalue) {
          this.isEdit = true;
          this.requestCouponVoucher.voucher_request_id = val.voucher_request_id;
          this.requestCouponVoucher.currency = val.currency_code;
          this.requestCouponVoucher.validity = val.validity ? val.validity : this.voucherDetails;
          this.isDatepickerDisabled = true;
        } else {
          this.isEdit = false;
          this.requestCouponVoucher.voucher_request_id = val.voucher_request_id;
        }

        if (
          sourcevalue === "QwikCilver" ||
          sourcevalue === "Ticket Express" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "EZPIN" ||
          sourcevalue === "MEA"
        ) {
          this.hideauthfield = false;
        } else {
          this.hideauthfield = true;
        }

        if (sourcevalue === "Ticket Express") {
          this.producthide = true;
          this.usagehide = true;
          this.requestCouponVoucher.usagemode = val.usage_mode;
        } else if (sourcevalue === "voucherkart") {
          this.producthide = true;
          this.usagehide = true;
          this.requestCouponVoucher.usagemode = val.internal_usage_mode;
        } else {
          this.producthide = false;
          this.usagehide = false;
          this.requestCouponVoucher.usagemode = "";
        }

        if (sourcevalue === "voucherkart") {
          this.productshow = true;
          this.totalshow = true;
          this.totalfacevalueshow = true;
          this.calculate();
        } else {
          this.productshow = false;
          this.totalshow = false;
          this.totalfacevalueshow = false;
        }

        if (
          sourcevalue === "QwikCilver" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "EZPIN" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "Ticket Express" ||
          sourcevalue === "voucherkart" ||
          this.role === "brand" ||
          sourcevalue === "MEA" || sourcevalue === "YOU GOTA GIFT"
        ) {
          this.isDatepickerDisabled = true;
        } else {
          this.isDatepickerDisabled = false;
        }

        if (sourcevalue === "Ticket Express") {
          this.denomDisabled = true;
        } else {
          this.denomDisabled = false;
        }

        if (
          (minvalue && maxvalue && sourcevalue === "QwikCilver") ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "EZPIN" ||
          sourcevalue !== "voucherkart" ||
          sourcevalue === "DT" ||
          sourcevalue === "MEA" ||
          sourcevalue === "YGG" || sourcevalue === "DT ONE" || sourcevalue === "Bamboo"
        ) {
          this.minprice = val.minPrice;
          this.maxprice = val.maxPrice;
          this.minmaxshow = true;
        } else if (
          internalminvalue &&
          internalmaxvalue &&
          sourcevalue === "voucherkart"
        ) {
          this.minprice = val.minface_value;
          this.maxprice = val.maxface_value;
          this.minmaxshow = true;
        } else {
          this.minprice = "";
          this.maxprice = "";
          this.minmaxshow = false;
        }

        if (
          sourcevalue === "Ticket Express" ||
          sourcevalue === "voucherkart" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "QwikCilver" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "MEA"
        ) {
          this.requestCouponVoucher.kart_type = "0";
        } else {
          this.requestCouponVoucher.kart_type = val.kart_type;
        }

        if (
          sourcevalue === "QwikCilver" ||
          sourcevalue === "Amazon" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "EZPIN" ||
          sourcevalue === "DT" ||
          sourcevalue === "MEA" ||
          sourcevalue === "YOU GOTA GIFT" || sourcevalue === "DT ONE" || sourcevalue === "Bamboo"
        ) {
          this.brandlabelhide = true;
          this.brandlabelshow = false;
        } else {
          this.brandlabelhide = false;
          this.brandlabelshow = true;
        }

        if (
          (maxdiscount && sourcevalue === "Ticket Express") ||
          sourcevalue === "Amazon" ||
          sourcevalue === "QwikCilver" ||
          sourcevalue === "FlipKart" ||
          sourcevalue === "EZPIN" ||
          sourcevalue === "DT" ||
          sourcevalue === "MEA" ||
          sourcevalue === "YOU GOTA GIFT" || sourcevalue === "DT ONE" || sourcevalue === "Bamboo"
        ) {
          this.discountvalue = val.product_discount;
          this.maxdiscountshow = true;
          this.discounttypehide = false;
        } else if (internalmaxdiscount && sourcevalue === "voucherkart") {
          this.discountvalue = val.internal_product_discount;
          this.maxdiscountshow = true;
          this.discounttypehide = false;
        } else {
          this.discountvalue = "";
          this.maxdiscountshow = false;
          this.discounttypehide = true;
        }
      }
    }

    if (this.role === "brand") {
      this.productshow = true;
      this.producthide = true;
      // this.editbrandidval = this.userDetails.UserID;
    }

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;
  }

  cancelVoucherList() {
    this.router.navigate(["/international-dashboard"], { replaceUrl: false });
  }
  validateUrl() {
    console.log(this.requestCouponVoucher.voucher_redemption_url);
    const pattern =
      /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/g;
    this.isValidUrl = pattern.test(
      this.requestCouponVoucher.voucher_redemption_url
    );
    // this.totalval = !this.isValidUrl;
  }
  // const pattern = /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/g;
  // isValidUrls(voucher_redemption_url: any): boolean {
  //   // You can use a regular expression here to validate the URL
  //   const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  //   return urlPattern.test(voucher_redemption_url);
  // }

  saveWebshop() {}

  // @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  //   const keyCode = event.keyCode;
  //   if (keyCode === 190 || keyCode === 188) {
  //     event.preventDefault();
  //   }
  // }

  keyDownHandler(event: any) {
    if (event.code === "Space" && event.which === 32) {
      event.preventDefault();
    }
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = '0123456789.'; // Define the allowed characters
    const inputChar = event.key;
  
    if (inputChar === ' ' || (inputChar !== '.' && allowedCharacters.indexOf(inputChar) === -1)) {
      
      
    
      event.preventDefault(); // Prevent the input
    }
  }
  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes(".")) {
      event.target.value = inputValue.substring(0, inputValue.indexOf("."));
    }
  }

  setDateOptions() {
    let a = new Date(this.requestCouponVoucher.issuance_date);
    a.setDate(a.getDate() - 1);
    a = new Date(a.getFullYear(), a.getMonth(), a.getDate());
    this.startOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: a.getFullYear(),
        month: a.getMonth() + 1,
        day: a.getDate(),
      },
    };
    let b = new Date(this.requestCouponVoucher.start_date);
    b.setDate(b.getDate());
    b = new Date(b.getFullYear(), b.getMonth(), b.getDate());
    this.ExpiryOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: b.getFullYear(),
        month: b.getMonth() + 1,
        day: b.getDate(),
      },
    };
  }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1))
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
    this.ExpiryOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: dp.getFullYear(),
        month: dp.getMonth() + 1,
        day: dp.getDate(),
      },
    };
    this.ExpiryDate = "";
    this.getExpiryDate = "";
    console.log("start date: " + this.startDate);
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1))
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    this.ExpiryDate = this.endDate;
  }

  onIssuanceDateChanged(event: IMyDateModel): void {
    this.IssuanceDate = new Date(
      event.jsdate.setDate(event.jsdate.getDate() + 1)
    )
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    let dp = new Date(this.IssuanceDate);
    dp.setDate(dp.getDate() - 1);
    dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate());
    this.startOptions = {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      disableUntil: {
        year: dp.getFullYear(),
        month: dp.getMonth() + 1,
        day: dp.getDate(),
      },
    };
    this.startDate = "";
    this.getstartDate = "";
    console.log("issuance date: " + this.IssuanceDate);
  }

  selectModelEvent(value: any) {
    if (value === "I") {
      this.otpshow = false;
      this.otpDisabled = true;
      this.requestCouponVoucher.authentication_type = "PIN";
      this.hidebtns = false;
    } else {
      this.requestCouponVoucher.authentication_type = "";
      this.otpDisabled = false;
      this.otpshow = true;
      this.hidebtns = true;
    }
  }

  validityClear(event: any) {
    let source = this.requestCouponVoucher.source;
    // this.clientchange = false;

    if (
      (source != "voucherkart" && this.role === "admin") ||
      this.role === "SuperAdmin"
    ) {
      // this.requestCouponVoucher.kart_type = '';
      this.requestCouponVoucher.discount = "";
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.product_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.requestCouponVoucher.payment_status = "";
      this.requestCouponVoucher.voucher_delivery_type = "";
      // this.requestCouponVoucher.validity = '';
      this.requestCouponVoucher.total = "";
      this.brandchange = false;
    this.productData = [];
    this.requestCouponVoucher.product = "";
    // this.requestCouponVoucher.brand_name = "";
    this.branid = "";
    this.brandidvalue = "";
      this.fetchDisabled = false;
    }

    // if(this.requestCouponVoucher.source === 'QwikCilver'){

    // }

    // else{

    //   this.requestCouponVoucher.kart_type = this.distype;
    //   this.requestCouponVoucher.discount = this.disvalue;
    //   this.requestCouponVoucher.issuance_fee = this.issfee;
    //   this.requestCouponVoucher.payment_status = this.pay;
    //   this.fetchDisabled = false;

    // }


    // this.brandchange = false;
    // this.productData = [];
    // this.requestCouponVoucher.product = "";
    // this.requestCouponVoucher.brand_name = "";
    // this.branid = "";
    // this.brandidvalue = "";
    // this.requestCouponVoucher.usagemode = "";
    // this.requestCouponVoucher.validity = "";
    // this.brandName = "";
  }

  selectCurrency(item:any){
    console.log("ddd")
    this.brandData = [];
    // this.requestCouponVoucher.brand_name = "";
    this.requestCouponVoucher.denomination = "";
    this.requestCouponVoucher.validity = "";
  }

  productClear(event: any) {
    // this.requestCouponVoucher.brand_name = '';
    // this.brandData = [];
    // this.minmaxshow = false;
    // this.isDatepickerDisabled = false;

    let source = this.requestCouponVoucher.source;
    this.clientchange = false;

    if (
      (source != "voucherkart" && this.role === "admin") ||
      this.role === "SuperAdmin"
    ) {
      // this.requestCouponVoucher.kart_type = '';
      this.requestCouponVoucher.discount = "";
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.product_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.requestCouponVoucher.payment_status = "";
      this.requestCouponVoucher.voucher_delivery_type = "";
      // this.requestCouponVoucher.validity = '';
      this.requestCouponVoucher.total = "";
      this.fetchDisabled = false;
    }

    // if(this.requestCouponVoucher.source === 'QwikCilver'){

    // }

    // else{

    //   this.requestCouponVoucher.kart_type = this.distype;
    //   this.requestCouponVoucher.discount = this.disvalue;
    //   this.requestCouponVoucher.issuance_fee = this.issfee;
    //   this.requestCouponVoucher.payment_status = this.pay;
    //   this.fetchDisabled = false;

    // }
  }

  changeemail() {
    this.requestCouponVoucher.voucher_delivery_email = "";
  }

  onChangeCompany2(item: any) {
    // this.requestCouponVoucher.brand_name = '';
    // this.brandData = [];
    this.minmaxshow = false;
    // this.isDatepickerDisabled = true;

    // this.requestCouponVoucher.kart_type = '';
    this.requestCouponVoucher.discount = "";
    this.requestCouponVoucher.issuance_fee = "";
    this.requestCouponVoucher.voucher_delivery_email = "";
    this.requestCouponVoucher.payment_status = "";
    this.requestCouponVoucher.voucher_delivery_type = "";
    // this.requestCouponVoucher.validity = '';
    this.requestCouponVoucher.total = "";
    this.fetchDisabled = false;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getCompany(item, this.headers).subscribe(
      (data: any) => {
        this.companyData = data.data;
        if (this.companyData.length === 0) {
          this.noCompanyFound = true;
        } else {
          this.noCompanyFound = false;
        }
        if (this.companyData.length > 0) {
          for (var data of this.companyData) {
            if (data.CompanyName === item.CompanyName) {
              this.noCompanyFound = false;
              return;
            } else {
              this.noCompanyFound = true;
            }
          }
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  onChangeCompany(item: any) {
    // this.requestCouponVoucher.brand_name = '';
    // this.brandData = [];
    // this.minmaxshow = false;
    // this.isDatepickerDisabled = true;

    let source: any = this.requestCouponVoucher.source;

    if (this.isEdit) {
      this.clientchange = true;
    }

    if (
      ((source !==  "voucherkart" && this.role === "admin") || this.role === "SuperAdmin")) {
      // this.requestCouponVoucher.kart_type = '';
      this.requestCouponVoucher.discount = "";
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.requestCouponVoucher.payment_status = "";
      this.requestCouponVoucher.voucher_delivery_type = "";
      // this.requestCouponVoucher.validity = '';
      this.requestCouponVoucher.total = "";
      this.fetchDisabled = false;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getCompany(item, this.headers).subscribe(
      (data: any) => {
        this.companyData = data.data;
        if (this.companyData.length === 0) {
          this.noCompanyFound = true;
        } else {
          this.noCompanyFound = false;
        }
        if (this.companyData.length > 0) {
          for (var data of this.companyData) {
            if (data.CompanyName === item.CompanyName) {
              this.noCompanyFound = false;
              return;
            } else {
              this.noCompanyFound = true;
            }
          }
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  onChangeParcategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getCurrency(this.headers).subscribe((data: any) => {
      
      this.currencyData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }

  onChangeSource() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    let item = "";
    let type = "international"
    this._http.getInternationalSource(item,type, this.headers).subscribe(
      (data: any) => {
        this.sourceData = data.data;
        // console.log(this.sourceData);

        // this.requestCouponVoucher.source_id = item.supplier_id;

        // if (this.sourceData.length === 0) {
        //   this.noSourceFound = true;
        // }
        // else {
        //   this.noSourceFound = false;
        // }
        // if (this.sourceData.length > 0) {
        //   for (var data of this.sourceData) {

        //     if (data.supplier_name === item.supplier_name) {
        //       this.noCompanyFound = false;
        //       return;
        //     }
        //     else {
        //       this.noSourceFound = true;
        //     }
        //   }
        // }
      },
      (error) => {
        this.loader.stop();
        // this.toastr.error(error.error.Message);
      }
    );
  }

  //   onChangeBrandEdit(item: any,brandId : any ,sourced : any) {

  //     // let source: any = this.requestCouponVoucher.source;
  //     let source: any = sourced;

  //     if (source === 'QwikCilver') {

  //       let token = localStorage.getItem("token");
  //       this.headers = {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token
  //         })
  //       };

  //       const type = 'QC';
  //       this._http.getQCproducts(item, type, this.headers).subscribe((data: any) => {

  //         this.brandData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));

  //         this.productid = this.brandData[1];
  //         this.maxprice = this.brandData[2];
  //         this.minprice = this.brandData[3];
  //         this.expiryvalue = this.brandData[4];
  //         this.minmaxshow = true;

  //         if (this.brandData.length === 0) {
  //           this.noBrandFound = true;
  //         }
  //         else {
  //           this.noBrandFound = false;
  //         }

  //         if (this.brandData.length > 0) {

  //           for (let data of this.brandData) {

  //             if (data.BrandName === item.brandName) {
  //               this.noBrandFound = false;
  //               return;
  //             }
  //             else {
  //               this.noBrandFound = true;
  //             }
  //           }
  //         }

  //       },
  //         error => {
  //           this.loader.stop();
  //           this.toastr.error(error.error.Message);
  //         })

  //     }

  //     else if (source === 'Ticket Express') {

  //       let token = localStorage.getItem("token");
  //       this.headers = {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token
  //         })
  //       };

  //       let type = 'TE';

  //       this._http.getBrand(item, type, this.headers).subscribe((data: any) => {

  //         this.brandData = data.data;

  //         if (this.brandData.length === 0) {
  //           this.noBrandFound = true;
  //         }
  //         else {
  //           this.noBrandFound = false;
  //         }

  //         if (this.brandData.length > 0) {
  //           for (var data of this.brandData) {

  //             if (data.BrandName === item.brandName) {

  //               this.noBrandFound = false;
  //               return;
  //             }
  //             else {
  //               this.noBrandFound = true;
  //             }
  //           }
  //         }
  //       },
  //         error => {
  //           this.loader.stop();
  //           this.toastr.error(error.error.Message);
  //         })
  //     }

  //     else if (source === 'Amazon') {

  //       let token = localStorage.getItem("token");
  //       this.headers = {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token
  //         })
  //       };

  //       const type = 'AMAZON';
  //       this._http.getAmazonproducts(item, type, this.headers).subscribe((data: any) => {

  //         this.brandData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));

  //      let row = this.brandData.filter((x:any) => x.id === brandId);

  //       this.minprice = row.minPrice;
  //       this.maxprice = row.maxPrice;

  //         if (this.brandData.length === 0) {
  //           this.noBrandFound = true;
  //         }
  //         else {
  //           this.noBrandFound = false;
  //         }

  //         if (this.brandData.length > 0) {

  //           for (let data of this.brandData) {

  //             if (data.BrandName === item.brandName) {
  //               this.noBrandFound = false;
  //               return;
  //             }
  //             else {
  //               this.noBrandFound = true;
  //             }
  //           }
  //         }

  //       },
  //         error => {
  //           this.loader.stop();
  //           this.toastr.error(error.error.Message);
  //         })

  //     }

  //     else {

  //       let token = localStorage.getItem("token");
  //       this.headers = {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token
  //         })
  //       };

  //       let type = item.type;

  //       this._http.getBrand(item.search, type, this.headers).subscribe((data: any) => {

  //         this.brandData = data.data;

  //         if (this.brandData.length === 0) {
  //           this.noBrandFound = true;
  //         }
  //         else {
  //           this.noBrandFound = false;
  //         }

  //         if (this.brandData.length > 0) {
  //           for (var data of this.brandData) {

  //             if (data.BrandName === item.brandName) {

  //               this.noBrandFound = false;
  //               return;
  //             }
  //             else {
  //               this.noBrandFound = true;
  //             }
  //           }
  //         }
  //       },
  //         error => {
  //           this.loader.stop();
  //           this.toastr.error(error.error.Message);
  //         })
  //     }

  //   }

  onChangeBrand(item: any) {
    if (this.isEdit) {
      this.brandchange = true;
    }

    this.productData = [];
    // this.requestCouponVoucher.product = "";
    this.branid = "";
    this.brandidvalue = "";
    this.requestCouponVoucher.usagemode = "";
    this.requestCouponVoucher.validity = "";
    this.brandName = "";

    let source: any = this.requestCouponVoucher.source;

    if (source === "QwikCilver") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "QC";
      this._http.getQCproducts(item, type, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));

          if (this.brandData) {
            this.productid = this.brandData[1];
            // this.maxprice = this.brandData[2];
            // this.minprice = this.brandData[3];
            this.expiryvalue = this.brandData[4];
            // this.minmaxshow = true;
          } else {
            this.productid = "";
            // this.maxprice = '';
            // this.minprice = '';
            this.expiryvalue = "";
            // this.minmaxshow = false;
          }

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "Ticket Express") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let type = "TE";

      this._http.getBrand(item, type, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (var data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "Amazon") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "AMAZON";
      this._http.getAmazonproducts(item, type, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "EZPIN") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "EZPIN";
      this.currency = this.requestCouponVoucher.currency
      this._http.getEzpininternationalproducts(item, type,this.currency, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } 
    else if (source === "DT ONE") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "DT";
      this.currency = this.requestCouponVoucher.currency
      this._http.getDtoneinternationalproducts(item, type,this.currency, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          // console.log(this.brandData,"this.brandData");
          
          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
    else if (source === "YOU GOTA GIFT") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "YGG";
      this.currency = this.requestCouponVoucher.currency
      this._http.getDtoneinternationalproducts(item, type,this.currency, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          // console.log(this.brandData,"this.brandData");
          
          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
    else if (source === "FlipKart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "FlipKart";
      this._http.getAmazonproducts(item, type, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "voucherkart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      // let type = item.type;

      this._http.getbrandloads(item, this.headers).subscribe(
        (data: any) => {
          // this.brandData = data.data;

          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (var data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "MEA") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const type = "MEA";
      this.currency = this.requestCouponVoucher.currency
      this._http.getQCinternationalproducts(item, type,this.currency, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data.map((names: any) => ({
            ...names,
            BrandName: names.name,
          }));
          console.log(this.brandData,"sfddsf");
          

          if (this.brandData) {
            this.productid = this.brandData[1];
            // this.maxprice = this.brandData[2];
            // this.minprice = this.brandData[3];
            this.expiryvalue = this.brandData[4];
            // this.minmaxshow = true;
          } else {
            this.productid = "";
            // this.maxprice = '';
            // this.minprice = '';
            this.expiryvalue = "";
            // this.minmaxshow = false;
          }

          if (this.brandData.length === 0) {
            this.noBrandFound = true;
          } else {
            this.noBrandFound = false;
          }

          if (this.brandData.length > 0) {
            for (let data of this.brandData) {
              if (data.BrandName === item.brandName) {
                this.noBrandFound = false;
                return;
              } else {
                this.noBrandFound = true;
              }
            }
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
    if(source === "Bamboo") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      const type = "Bamboo";
      if(typeof (this.requestCouponVoucher.brand_name) === "object") {
        this.requestCouponVoucher.brand_name = this.requestCouponVoucher.brand_name.BrandName;
      }
      this._http.getQCinternationalproducts(item, type, this.requestCouponVoucher.currency, this.headers , this.requestCouponVoucher.brand_name).subscribe({
        next: (data:any) => {
          if(data && data.data.length > 0) {
            this.brandData = data.data.map((names: any) => ({
              ...names,
              BrandName: names.name,
            }));
          }
        }
      })
    }
  }

  restrictEnter(args: any) {
    if (args.key === "Enter" && args.keyCode == 13) args.preventDefault();
  }

  selectSourceEvent(item: any) {
    if(item && item === "YOU GOTA GIFT"){
      this.yggHide = true;
    }else{
      this.yggHide = false;
    }
    if(item === "Bamboo") {
      this.brandShow = true;
    } else {
      this.brandShow = false;
    }
    this.requestCouponVoucher.usagemode = "";
    this.maxdiscountshow = false;
    this.brandName = "";

    // this.requestCouponVoucher.kart_type = '';

    // this.requestCouponVoucher.currency = ""
    this.requestCouponVoucher.discount = "";
    this.requestCouponVoucher.issuance_fee = "";
    this.requestCouponVoucher.voucher_delivery_email = "";
    this.requestCouponVoucher.payment_status = "";
    this.requestCouponVoucher.voucher_delivery_type = "";
    this.requestCouponVoucher.total = "";
    this.requestCouponVoucher.totalfacevalue = "";
    this.requestCouponVoucher.brand_name = "";
    this.requestCouponVoucher.validity = "";
    this.requestCouponVoucher.quantity = "";
    this.requestCouponVoucher.denomination = "";
    this.denomDisabled = false;
    this.brandData = [];

    if (item != "voucherkart") {
      this.requestCouponVoucher.company_name = "";
    }
    if(item){
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      this.loader.start();
      this._http.getCurrencyList(this.headers).subscribe((data: any) => {
        // this.count = data.count;
        this.currencyListdata = data.data;
        this.loader.stop();
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
    if (item === "voucherkart") {
      this.productshow = true;
      this.totalshow = true;
      this.totalfacevalueshow = true;
      // this.requestCouponVoucher.kart_type = '0';
      // this.discounttypehide = false;
    } else {
      this.productshow = false;
      this.totalshow = false;
      this.totalfacevalueshow = false;
      // this.requestCouponVoucher.kart_type = '';
      this.discounttypehide = true;
    }

    if (this.role === "admin") {
      this.requestCouponVoucher.brand_name = "";
      this.brandData = [];

      this.requestCouponVoucher.product = "";
      this.productData = [];

      this.requestCouponVoucher.validity = "";
      this.isDatepickerDisabled = false;
      this.requestCouponVoucher.denomination = "";
      this.denomDisabled = false;
      this.minmaxshow = false;
    }

    this.requestCouponVoucher.kart_type = "0";

    if (item === "Ticket Express") {
      this.producthide = true;
      this.usagehide = true;
      this.discounttypehide = false;
      // this.requestCouponVoucher.kart_type = '0';
    } else if (item === "voucherkart") {
      this.discounttypehide = false;
      // this.requestCouponVoucher.kart_type = '0';
      this.usagehide = true;
    } else if (
      item === "Amazon" ||
      item === "FlipKart" ||
      item === "QwikCilver" ||
      item === "EZPIN" ||
      item === "DT ONE" ||
      item === "MEA" ||
      item === "YOU GOTA GIFT"
    ) {
      this.discounttypehide = false;
      this.producthide = false;
      this.usagehide = false;
      // this.requestCouponVoucher.kart_type = '0';
    } else {
      this.producthide = false;
      this.usagehide = false;
      this.discounttypehide = true;
      // this.requestCouponVoucher.kart_type = '';
      // localStorage.removeItem('pdthide');
    }

    if (
      item === "QwikCilver" ||
      item === "Ticket Express" ||
      item === "Amazon" ||
      item === "FlipKart" ||
      item === "EZPIN" ||
      item === "DT ONE" ||
      item === "MEA" ||
      item === "YOU GOTA GIFT"
    ) {
      // this.isDatepickerDisabled = true;
      this.expiryvalues = this.expiryvalue;
      this.hideauthfield = false;
    } else {
      // this.isDatepickerDisabled = false;
      this.hideauthfield = true;
    }

    if (item) {
      this.isDatepickerDisabled = true;
    } else {
      this.isDatepickerDisabled = false;
    }

    if (
      item === "DT ONE" ||
      item === "Amazon" ||
      item === "FlipKart" ||
      item === "EZPIN" ||
      item === "MEA" ||
      item === "YOU GOTA GIFT" || item === "Bamboo"
    ) {
      this.brandlabelhide = true;
      this.brandlabelshow = false;
      this.productchange = false;
    } else {
      this.brandlabelhide = false;
      this.brandlabelshow = true;
      this.productchange = true;
    }

    if (item) {
      this.calculate();
    }
    if(item === "Bamboo") {
      this.brandlabelhide = true;
    }

    //  this.getfetchlist(item);
    // this.calculate();
  }

  onChangeProduct(item: any) {
    if (this.role === "brand") {
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.minprice = "";
      this.maxprice = "";
      this.discountvalue = "";
      this.denomDisabled = false;
      this.maxdiscountshow = false;
      this.minmaxshow = false;
      this.usagehide = false;
    }

    if (this.isEdit) {
      this.productchange = true;
    }

    let source: any = this.requestCouponVoucher.source;

    if (this.role === "brand") {
      source = "voucherkart";
    }

    if (
      this.role === "admin" ||
      (this.role === "SuperAdmin" && source === "DT ONE")
    ) {
      this.requestCouponVoucher.validity = "";
      this.requestCouponVoucher.denomination = "";
      this.requestCouponVoucher.usagemode = "";
      this.denomDisabled = false;
    }

    if (source === "Ticket Express" && (this.branid || this.brandidvalue)) {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let brandid = this.branid || this.brandidvalue;

      const type = "TE";
      this._http.getTEproducts(item, type, brandid, this.headers).subscribe(
        (data: any) => {
          this.productData = data.data;

          if (data) {
            data.data.forEach((e: any) => {
              this.productData.push(e.name);
            });
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (source === "voucherkart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let brandid = this.branid || this.brandidvalue || this.userDetails.UserID;

      let variant = "Digital";

      this._http
        .getvoucherproducts(item, brandid, variant, this.headers)
        .subscribe(
          (data: any) => {
            this.productData = data.data;

            if (this.productData !== null && this.productData.length > 0) {
              this.requestCouponVoucher.issuance_fee =
                this.productData[0].issuance_fee;
              this.requestCouponVoucher.voucher_delivery_email =
                this.productData[0].voucher_delivery_email;
              this.requestCouponVoucher.usage_mode =
                this.productData[0].internal_usage_mode;
              this.requestCouponVoucher.validity = this.productData[0].validity;
              this.minprice = this.productData[0].minface_value;
              this.maxprice = this.productData[0].maxface_value;
              this.discountvalue =
                this.productData[0].internal_product_discount;
              // this.denomDisabled = true;
              this.maxdiscountshow = true;
              this.minmaxshow = true;
              this.usagehide = true;
              this.isDatepickerDisabled = true;
              this.calculate();
            } else {

              // if (this.role === 'brand') {
              //   this.requestCouponVoucher.kart_type = '0';
              // } else {
              //   this.requestCouponVoucher.kart_type = '';
              // }

              this.requestCouponVoucher.issuance_fee = "";
              this.requestCouponVoucher.voucher_delivery_email = "";
              // this.requestCouponVoucher.validity = '';
              this.minprice = "";
              this.maxprice = "";
              this.discountvalue = "";
              // this.denomDisabled = false;
              this.maxdiscountshow = false;
              this.minmaxshow = false;
              this.usagehide = false;
              this.isDatepickerDisabled = false;
            }

            if (data) {
              data.data.forEach((e: any) => {
                this.productData.push(e.name);
              });
            }
          },
          (error) => {
            this.loader.stop();
            this.toastr.error(error.error.Message);
          }
        );
    } 
    else if (source === "MEA") {
    }

    // else {

    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     })
    //   };

    //   let brandid = this.userDetails.UserID || this.editbrandId;
    //   let variant = 'Digital';
    //   this._http.getvoucherproducts(item, brandid, variant, this.headers).subscribe((data: any) => {

    //     this.productData = data.data;

    //     if (data) {
    //       data.data.forEach((e: any) => {

    //         this.productData.push(e.name);

    //       })
    //     }

    //   },
    //     error => {
    //       this.loader.stop();
    //       this.toastr.error(error.error.Message);
    //     })

    // }
  }

  selectCompanyEvent(item: any) {
    this.clientchange = true;
    if (item) {
      this.calculate();
    }

    this.requestCouponVoucher.kart_type = "0";
    this.requestCouponVoucher.companyId = item.id;
    this.sourceName = item.CompanyName;
    if (this.companyData.length > 0) {
      for (var data of this.companyData) {
        if (data.CompanyName === item.CompanyName) {
          this.noCompanyFound = false;
          if (this.role === "admin" || this.role === "SuperAdmin") {
            this.getfetchlist(item);
          }
          return;
        } else {
          this.noCompanyFound = true;
        }
      }
    }
  }

  getfetchlist(item: any) {
    let sourceval = this.requestCouponVoucher.source;
    if (item && sourceval != "voucherkart") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      // this.loader.start();

      this._http.getfetchlist(item, this.headers).subscribe(
        (data: any) => {
          // this.loader.stop();

          this.fetchdata = data.data;

          if (
            this.fetchdata !== null &&
            this.fetchdata.length > 0 &&
            this.requestCouponVoucher.company_name
          ) {
            this.requestCouponVoucher.discount =
              this.fetchdata[0].discount_value;
            this.requestCouponVoucher.issuance_fee =
              this.fetchdata[0].issuance_fee;
            this.requestCouponVoucher.voucher_delivery_email =
              this.fetchdata[0].voucher_delivery_email;
            this.requestCouponVoucher.payment_status =
              this.fetchdata[0].payment;
            this.requestCouponVoucher.voucher_delivery_type =
              this.fetchdata[0].voucher_delivery_type;
            // this.fetchDisabled = true;

            let sourceval = this.requestCouponVoucher.source;
            if (sourceval !== "Ticket Express") {
              this.requestCouponVoucher.kart_type =
                this.fetchdata[0].discount_type;
            }

            this.calculate();
            this.selectdistypeEvent(item, true);
          } else {
            // let sourceval = this.requestCouponVoucher.source;
            // if (sourceval === 'Ticket Express') {
            //   this.requestCouponVoucher.kart_type = '0';
            // } else {
            //   this.requestCouponVoucher.kart_type = '';
            // }
            this.requestCouponVoucher.kart_type = "0";
            this.requestCouponVoucher.discount = "";
            this.requestCouponVoucher.issuance_fee = "";
            this.requestCouponVoucher.voucher_delivery_email = "";
            this.requestCouponVoucher.payment_status = "";
            this.requestCouponVoucher.voucher_delivery_type = "";
            // this.fetchDisabled = false;
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
  }

  uploadEmailFile(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (getImg.toLowerCase() !== ".csv") {
      this.toastr.error("File not supported");
      return;
    } else {
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("deliveryfile", event.target.files[0]);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importbulkemail(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.requestCouponVoucher.voucher_delivery_email = data.data;
            this.toastr.success(data.msg);
          } else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
          // this.inputFile.nativeElement.value = '';
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }
  onChangeNewBrand (event:any) {
    if(event) {
      const type = "Bamboo";
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      this._http.getBrand(event,type,this.headers).subscribe({
        next: (data: any) => {
          if(data.data && data.data.length > 0) {
            this.newbrandData = data.data;
          }
          
        },error : e => {
          console.log(e);
        }
      })
    }
  }
  clearBrand() {
    this.requestCouponVoucher.brand_name = '';
    this.newBrandID = 0;
    if(this.requestCouponVoucher.product) {
      this.requestCouponVoucher.product = '';
    }
  }

  selectNewBrand(event:any) {
    if(event) {
      this.requestCouponVoucher.brand_name = event.BrandName;
      this.newBrandID = event.BrandId;
    }
  }

  selectBrandEvent(item: any) {

    this.brandchange = true;
    this.branid = item.BrandId;
    if (item) {
      this.calculate();
    }
    // this.requestCouponVoucher.brandId = item.BrandId;
    // this.onChangeProduct(item.BrandId);
    this.NewArray = this.requestCouponVoucher.source;
    if (
      this.NewArray === "QwikCilver" ||
      this.NewArray === "FlipKart" ||
      this.NewArray === "Amazon" ||
      this.NewArray === "EZPIN" ||
      this.NewArray === "MEA"
    ) {
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.expiryvalue = item.expiry;
      this.productid = item.id;
      this.externalproductid = item.id;
      this.minmaxshow = true;
      this.requestCouponVoucher.validity = this.expiryvalue
        ? this.expiryvalue
        : "Life Time Validity";
      this.isDatepickerDisabled = true;
      this.skuList = item.sku;
      this.requestCouponVoucher.kart_type = "0";
    } 
    else if(this.NewArray === "DT ONE") {
    
      this.newCal = JSON.parse(item.rates);
      console.log(this.newCal);
      
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.minmaxshow = true;
     
      this.expiryvalue = item.expiry;
      this.requestCouponVoucher.validity = this.expiryvalue
        ? this.expiryvalue
        : "Life Time Validity";
      this.isDatepickerDisabled = true;
      this.requestCouponVoucher.denomination = item.price;
      console.log(this.requestCouponVoucher.denomination,"before");
      // let roundedVal = Math.floor(Number(this.requestCouponVoucher.denomination * this.newCal.base))
      // this.requestCouponVoucher.denomination = roundedVal / this.newCal.wholesale;
      // console.log(this.requestCouponVoucher.denomination,"after");
      
      if(this.requestCouponVoucher.denomination){
        this.denomDisabled = true;
      }else{
        this.denomDisabled = false;
      }   
      this.productid = item.id;
      this.externalproductid = item.id;
      // this.brandedid = item.BrandId;
      // this.requestCouponVoucher.brandId = item.BrandId;
      this.requestCouponVoucher.kart_type = "0";
    } 
    else if(this.NewArray === "Bamboo") {
    
      this.newCal = JSON.parse(item.rates);
      console.log(this.newCal);
      
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.minmaxshow = true;
     
      this.expiryvalue = item.expiry;
      this.requestCouponVoucher.validity = this.expiryvalue
        ? this.expiryvalue
        : "Life Time Validity";
      this.isDatepickerDisabled = true;
      this.requestCouponVoucher.denomination = item.price;
      console.log(this.requestCouponVoucher.denomination,"before");
      // let roundedVal = Math.floor(Number(this.requestCouponVoucher.denomination * this.newCal.base))
      // this.requestCouponVoucher.denomination = roundedVal / this.newCal.wholesale;
      // console.log(this.requestCouponVoucher.denomination,"after");
      
      if(this.requestCouponVoucher.denomination){
        this.denomDisabled = true;
      }else{
        this.denomDisabled = false;
      }   
      this.productid = item.id;
      this.externalproductid = item.id;
      // this.brandedid = item.BrandId;
      // this.requestCouponVoucher.brandId = item.BrandId;
      this.requestCouponVoucher.kart_type = "0";
    } 
    else if(this.NewArray === "YOU GOTA GIFT" || this.NewArray === "YGG") {
    
      this.newCal = JSON.parse(item.rates);
      console.log(this.newCal);
      
      this.minprice = item.minPrice;
      this.maxprice = item.maxPrice;
      this.minmaxshow = true;
     
      this.expiryvalue = item.expiry;
      this.requestCouponVoucher.validity = this.expiryvalue
        ? this.expiryvalue
        : "Life Time Validity";
      this.isDatepickerDisabled = true;
      // this.requestCouponVoucher.denomination = item.price;
      this.b2cPrice = item.b2c_price.split(",").map(Number);
      if(Number.isNaN(this.b2cPrice[0])){
        this.yggHide = false;
      }else{
        this.yggHide = true;
      }
      // this.b2cPrice.push(b2cPrice);
      // console.log(b2cPrice);
      
      // console.log(this.b2cPrice,"arrauy of this.b2cPrice");
       
      console.log(this.requestCouponVoucher.denomination,"before");
      // let roundedVal = Math.floor(Number(this.requestCouponVoucher.denomination * this.newCal.base))
      // this.requestCouponVoucher.denomination = roundedVal / this.newCal.wholesale;
      // console.log(this.requestCouponVoucher.denomination,"after");
      
      if(this.requestCouponVoucher.denomination){
        this.denomDisabled = true;
      }else{
        this.denomDisabled = false;
      }   
      this.productid = item.id;
      this.externalproductid = item.id;
      // this.brandedid = item.BrandId;
      // this.requestCouponVoucher.brandId = item.BrandId;
      this.requestCouponVoucher.kart_type = "0";
    }
    else if (this.NewArray === "voucherkart") {
      this.requestCouponVoucher.kart_type = "0";
    } else {
      this.maxprice = "";
      this.minprice = "";
      this.expiryvalue = "";
      this.productid = item.id;
      this.minmaxshow = false;
      this.requestCouponVoucher.validity = "";
      this.isDatepickerDisabled = false;
      this.requestCouponVoucher.denomination = "";
      this.denomDisabled = false;
      this.requestCouponVoucher.brandId = item.BrandId;
      this.skuList = item.sku;
      // this.requestCouponVoucher.kart_type = '';
    }

    if (
      this.NewArray === "Amazon" ||
      this.NewArray === "QwikCilver" ||
      this.NewArray === "FlipKart" ||
      this.NewArray === "EZPIN" ||
      this.NewArray === "DT ONE" ||
      this.NewArray === "MEA" ||
      this.NewArray === "YOU GOTA GIFT" || this.NewArray === "Bamboo"
    ) {
      this.discountvalue = item.discount;
      this.maxdiscountshow = true;
    }

    this.calculate();
    this.brandName = item.BrandName;
    if (this.brandData.length > 0) {
      for (var data of this.brandData) {
        if (data.BrandName === item.BrandName) {
          this.noBrandFound = false;
          return;
        } else {
          this.noBrandFound = true;
        }
      }
    }
  }

  selectProductEvent(item: any) {
   

    this.productchange = true;
    this.requestCouponVoucher.kart_type = "0";
    if (
      this.role === "admin" ||
      this.role === "SuperAdmin" ||
      this.role === "brand"
    ) {
      let row = this.productData.filter((x: any) => x.name === item);
      console.log("Row value :", row[0].usage_mode);
      console.log("Row value condition :", row[0].usage_mode == "Offline");
      if (
        row[0].usage_mode == "Omni Channel" ||
        row[0].usage_mode == "Omnichannel"
      ) {
        this.requestCouponVoucher.usagemode = "Online + In-Store";
      } else if (row[0].usage_mode == "Offline") {
        this.requestCouponVoucher.usagemode = "In-Store";
      } else {
        this.requestCouponVoucher.usagemode = row[0].usage_mode;
      }
      this.requestCouponVoucher.issuance_fee = row[0].issuance_fee;
      this.requestCouponVoucher.voucher_delivery_email =
        row[0].voucher_delivery_email;
      this.requestCouponVoucher.usage_mode = row[0].internal_usage_mode;
      this.minprice = row[0].minface_value;
      this.maxprice = row[0].maxface_value;
      this.discountvalue = row[0].internal_product_discount;
      // this.denomDisabled = true;
      this.maxdiscountshow = true;
      this.minmaxshow = true;
      this.usagehide = true;
      let sourceval: any = this.requestCouponVoucher.source;
      if (
        this.role === "admin" ||
        this.role === "SuperAdmin" ||
        this.role === "brand"
      ) {
        if (sourceval == "voucherkart" || this.role === "brand") {
          this.requestCouponVoucher.validity = row[0].validity
            ? row[0].validity
            : "Life Time Validity";
        } else {
          this.requestCouponVoucher.validity = row[0].expiry;
        }
        this.requestCouponVoucher.denomination = row[0].price;
        if (this.requestCouponVoucher.denomination) {
          this.calculate();
        }
      }

      this.productid = row[0].id;
      this.externalproductid = item.id;
      this.discountvalue = row[0].discount;
    }

    if (this.discountvalue) {
      this.maxdiscountshow = true;
      this.discounttypehide = false;
    } else {
      this.maxdiscountshow = false;
      this.discounttypehide = true;
    }

    let sourceref: any = this.requestCouponVoucher.source;
    if (sourceref === "Ticket Express") {
      this.isDatepickerDisabled = true;
      this.denomDisabled = true;
    } else if (sourceref === "voucherkart" || this.role === "brand") {
      this.isDatepickerDisabled = true;
    } else {
      this.isDatepickerDisabled = false;
      this.denomDisabled = false;
    }

    if (this.role === "brand") {
      this.requestCouponVoucher.kart_type = "0";
    }
    // else{
    //   this.requestCouponVoucher.kart_type = '';
    // }

    if (this.role === "brand") {
      this.totalshow = true;
      this.totalfacevalueshow = true;
    } else if (sourceref === "voucherkart") {
      this.totalshow = true;
      this.totalfacevalueshow = true;
    } else {
      this.totalshow = false;
      this.totalfacevalueshow = false;
    }

    //   let sr = this.expiryvalue.split(' ');

    // // if (sr[1].toLowerCase() === 'year') {
    //   if (sr[1] === 'Months' || 'months') {

    //   this.expiryvalueint = Number(sr[0]) * 30;

    //   if (this.isEdit) {
    //     this.isDatepickerDisabled = true;
    //   }
    // }
    // else {
    //   this.expiryvalueint = sr[0];
    // }

    // let selectproduct = this.productData.filter((a:any)=>a.usage_mode === item);
    // console.log(selectproduct)
  }

  productsClear(event: any) {
    this.productchange = true;
    let sourceref: any = this.requestCouponVoucher.source;
    if (
      this.role === "admin" ||
      (this.role === "SuperAdmin" && sourceref === "Ticket Express")
    ) {
      this.requestCouponVoucher.validity = "";
      this.requestCouponVoucher.denomination = "";
      this.requestCouponVoucher.usagemode = "";
      this.denomDisabled = false;
    }

    if (this.role === "brand") {
      this.requestCouponVoucher.issuance_fee = "";
      this.requestCouponVoucher.voucher_delivery_email = "";
      this.minprice = "";
      this.maxprice = "";
      this.discountvalue = "";
      this.denomDisabled = false;
      this.maxdiscountshow = false;
      this.minmaxshow = false;
      this.usagehide = false;
    }
  }

  requestVoucher(requestCouponVoucher: RequestVoucher) {
    let source: any = this.requestCouponVoucher.source;
    //client manual input validate
    let comapanyval = this.companyData;
    if (this.clientchange) {
      let new_company = "";
      if (typeof this.requestCouponVoucher.company_name === "object") {
        const selectedCompany: any = this.requestCouponVoucher.company_name;
        new_company = selectedCompany.CompanyName;
      } else {
        new_company = this.requestCouponVoucher.company_name;
      }
      // console.log('this.requestCouponVoucher.company_name',this.requestCouponVoucher.company_name)
      const companyfilter = comapanyval.filter(
        (val: any) => val.CompanyName === new_company
      );
      // console.log('fdfdffarrray',companyfilter)
      if (companyfilter.length === 0) {
        this.toastr.error("Please enter valid client");
        return;
      }
    } else if (!this.isEdit && !this.clientchange) {
      this.toastr.error("Please enter valid client");
      return;
    }

    //brand manual input validate

    if (this.role === "admin" || this.role === "SuperAdmin") {
      let brandval = this.brandData;
      if (this.brandchange) {
        if (
          source !== "QwikCilver" &&
          source !== "Amazon" &&
          source !== "FlipKart" &&
          source !== "EZPIN" &&
          source !== "MEA" &&
          brandval.length === 0
        ) {
          this.toastr.error("Please enter valid brand");
          return;
        }
      } else if (!this.isEdit && !this.brandchange) {
        this.toastr.error(
          `Please enter valid ${
            source === "Amazon" ||
            source === "QwikCilver" ||
            source === "FlipKart" ||
            source === "EZPIN" ||
            source === "MEA"
              ? "Product"
              : "Brand"
          }`
        );
        return;
      }
    }

    // // product manual input validate

    // let productval = this.productData;

    // if (this.productchange) {

    //   if ((source !== 'voucherkart' && source !== 'Ticket Express') && productval.length === 0) {

    //     this.toastr.error("Please enter valid product");
    //     return;

    //   }
    // }

    // else if (!this.isEdit && !this.productchange) {

    //   if ((source !== 'QwikCilver' && source !== 'Amazon') && productval.length === 0) {

    //     this.toastr.error("Please enter valid product");
    //     return;

    //   }
    // }

    //product manual input validate

    let productval = this.productData;
    if (this.productchange) {
      let new_product = "";
      new_product = this.requestCouponVoucher.product;
      const productfilter = productval.filter(
        (val: any) => val.name === new_product
      );
      if (
        source !== "voucherkart" &&
        source !== "Ticket Express" &&
        productfilter.length === 0
      ) {
        this.toastr.error("Please enter valid product");
        return;
      }
    } else if (!this.isEdit && !this.productchange) {
      if (
        source !== "DT ONE"&&
        source !== "YOU GOTA GIFT" &&
        source !== "EZPIN" &&
        source !== "MEA" && source !== "Bamboo" &&
        productval.length === 0
      ) {
        this.toastr.error("Please enter valid product");
        return;
      }
    }

    this.requestCouponVoucher.start_date = this.startDate;
    this.requestCouponVoucher.issuance_date = this.IssuanceDate;
    this.requestCouponVoucher.user_name = this.userDetails.Name;
    this.requestCouponVoucher.denomination =
      this.requestCouponVoucher.denomination;
    this.requestCouponVoucher.payment_status =
      this.requestCouponVoucher.payment_status;
    this.requestCouponVoucher.voucher_delivery_type =
      this.requestCouponVoucher.voucher_delivery_type;

    this.requestCouponVoucher.total = this.requestCouponVoucher.total;
    this.requestCouponVoucher.discount = this.requestCouponVoucher.discount;
    this.requestCouponVoucher.payment_received =
      this.requestCouponVoucher.payment_received;
    this.requestCouponVoucher.category = this.requestCouponVoucher.category;
    if (source === "EZPIN") {
      this.requestCouponVoucher.product_id = this.productid;
    } else {
      this.requestCouponVoucher.product_id =
        this.externalproductid || this.productid;
    }
    this.requestCouponVoucher.brandId =
      this.branid || this.externalproductid || this.productid;
    
    this.requestCouponVoucher.source = this.requestCouponVoucher.source;

    // if (this.brandName !== null && this.brandName !== "") {
    //   this.requestCouponVoucher.brand_name = this.brandName;
    // }

    if (this.sourceName !== null && this.sourceName !== "") {
      this.requestCouponVoucher.company_name = this.sourceName;
    }

    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "EZPIN" &&
      source !== "DT ONE" &&
      source !== "MEA" &&
      source !== "YOU GOTA GIFT" && source !== "Bamboo"
    ) {
      if (
        this.requestCouponVoucher.authentication_type === "" ||
        this.requestCouponVoucher.authentication_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "EZPIN" &&
      source !== "DT ONE" &&
      source !== "MEA" &&
      source !== "YOU GOTA GIFT" && source !== "Bamboo"

    ) {
      if (
        this.requestCouponVoucher.voucher_redemption_type === "" ||
        this.requestCouponVoucher.voucher_redemption_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "DT ONE" &&
      source !== "MEA" &&
      source !== "YOU GOTA GIFT" && source !== "Bamboo"

    ) {
      if (
        this.requestCouponVoucher.voucher_delivery_type === "" ||
        this.requestCouponVoucher.voucher_delivery_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "DT ONE" &&
      source !== "MEA" &&
      source !== "YOU GOTA GIFT" && source !== "Bamboo"

    ) {
      if (
        this.requestCouponVoucher.voucher_delivery_email === "" ||
        this.requestCouponVoucher.voucher_delivery_email === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }
    if (
      source !== "QwikCilver" &&
      source !== "Ticket Express" &&
      source !== "Amazon" &&
      source !== "FlipKart" &&
      source !== "EZPIN" &&
      source !== "DT ONE" &&
      source !== "MEA" && 
      source !== "YOU GOTA GIFT" && source !== "Bamboo"

    ) {
      if (
        this.requestCouponVoucher.voucher_redemption_url === "" ||
        this.requestCouponVoucher.voucher_redemption_url === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
      if (
        !this.requestCouponVoucher.voucher_redemption_url == this.isValidUrl
      ) {
        this.toastr.error("Please enter the valid URL");
        return;
      }
    }

    // let sourceval: any = this.requestCouponVoucher.source;
    else if (source !== "Ticket Express") {
      if (
        this.requestCouponVoucher.kart_type === "" ||
        this.requestCouponVoucher.kart_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    } else if (source !== "voucherkart") {
      if (
        this.requestCouponVoucher.kart_type === "" ||
        this.requestCouponVoucher.kart_type === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    if (this.role === "admin" || this.role === "SuperAdmin") {
      if (
        this.requestCouponVoucher.type === "" ||
        this.requestCouponVoucher.type === null ||
        this.requestCouponVoucher.payment_status === "" ||
        this.requestCouponVoucher.voucher_delivery_type === "" ||
        this.requestCouponVoucher.voucher_delivery_type === null ||
        this.requestCouponVoucher.payment_status === null ||
        this.requestCouponVoucher.discount === "" ||
        this.requestCouponVoucher.discount === null ||
        this.requestCouponVoucher.issuance_fee === "" ||
        this.requestCouponVoucher.voucher_delivery_email === "" ||
        this.requestCouponVoucher.voucher_delivery_email === null ||
        this.requestCouponVoucher.issuance_fee === null ||
        this.requestCouponVoucher.source === "" ||
        this.requestCouponVoucher.source === null ||
        this.requestCouponVoucher.brand_name === "" ||
        this.requestCouponVoucher.brand_name === null ||
        this.requestCouponVoucher.quantity === "" ||
        !this.requestCouponVoucher.quantity === null ||
        this.requestCouponVoucher.validity === "" ||
        this.requestCouponVoucher.validity === null ||
        this.requestCouponVoucher.issuance_date === "" ||
        this.requestCouponVoucher.issuance_date === null ||
        this.requestCouponVoucher.start_date === "" ||
        this.requestCouponVoucher.start_date === null ||
        this.requestCouponVoucher.denomination === "" ||
        this.requestCouponVoucher.denomination === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    } else if (this.role === "company") {
      if (
        !this.requestCouponVoucher.payment_status ||
        !this.requestCouponVoucher.voucher_delivery_type ||
        !this.requestCouponVoucher.discount ||
        !this.requestCouponVoucher.issuance_fee ||
        this.requestCouponVoucher.voucher_delivery_email ||
        !this.requestCouponVoucher.brand_name ||
        !this.requestCouponVoucher.quantity ||
        !this.requestCouponVoucher.validity ||
        !this.requestCouponVoucher.issuance_date ||
        !this.requestCouponVoucher.start_date ||
        !this.requestCouponVoucher.denomination ||
        /^ *$/.test(this.requestCouponVoucher.brand_name)
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    // else if (this.role === 'brand') {

    //   if (!this.requestCouponVoucher.product || !this.requestCouponVoucher.type || !this.requestCouponVoucher.payment_status || !this.requestCouponVoucher.company_name ||
    //     !this.requestCouponVoucher.discount || !this.requestCouponVoucher.issuance_fee || !this.requestCouponVoucher.quantity || !this.requestCouponVoucher.validity
    //     || !this.requestCouponVoucher.issuance_date || !this.requestCouponVoucher.start_date || !this.requestCouponVoucher.denomination) {
    //     this.toastr.error("Please fill all the mandatory fields");
    //     return;
    //   }
    // }
    else if (this.role === "brand") {
      if (
        this.requestCouponVoucher.type === "" ||
        this.requestCouponVoucher.type === null ||
        this.requestCouponVoucher.payment_status === "" ||
        this.requestCouponVoucher.voucher_delivery_type === "" ||
        this.requestCouponVoucher.voucher_delivery_type === null ||
        this.requestCouponVoucher.payment_status === null ||
        this.requestCouponVoucher.discount === "" ||
        this.requestCouponVoucher.discount === null ||
        this.requestCouponVoucher.issuance_fee === "" ||
        this.requestCouponVoucher.voucher_delivery_email === "" ||
        this.requestCouponVoucher.voucher_delivery_email === null ||
        this.requestCouponVoucher.issuance_fee === null ||
        this.requestCouponVoucher.quantity === "" ||
        !this.requestCouponVoucher.quantity === null ||
        this.requestCouponVoucher.validity === "" ||
        this.requestCouponVoucher.validity === null ||
        this.requestCouponVoucher.issuance_date === "" ||
        this.requestCouponVoucher.issuance_date === null ||
        this.requestCouponVoucher.start_date === "" ||
        this.requestCouponVoucher.start_date === null ||
        this.requestCouponVoucher.denomination === "" ||
        this.requestCouponVoucher.denomination === null
      ) {
        this.toastr.error("Please fill all the mandatory fields");
        return;
      }
    }

    // if (!this.discountvalue) {

    //   if (!this.requestCouponVoucher.kart_type) {

    //     this.toastr.error("Please fill all the mandatory fields");
    //     return;
    //   }

    // }

    let sourceref: any = this.requestCouponVoucher.source;

    if (
      sourceref === "QwikCilver" ||
      sourceref === "Ticket Express" ||
      sourceref === "Amazon" ||
      sourceref === "FlipKart" ||
      sourceref === "DT ONE" ||
      source === "MEA" ||
      sourceref === "YOU GOTA GIFT" || source === "Bamboo"
    ) {
      this.requestCouponVoucher.authentication_type = null;

      this.requestCouponVoucher.voucher_redemption_type = null;
      this.requestCouponVoucher.voucher_redemption_url = null;
    } else if (sourceref === "EZPIN") {
      this.requestCouponVoucher.authentication_type = "PIN";
    } else {
      this.requestCouponVoucher.authentication_type =
        this.requestCouponVoucher.authentication_type;
      this.requestCouponVoucher.voucher_redemption_type =
        this.requestCouponVoucher.voucher_redemption_type;
      this.requestCouponVoucher.voucher_redemption_url =
        this.requestCouponVoucher.voucher_redemption_url;
    }

    if (
      (this.discount && this.discount.includes(",")) ||
      (this.discount && this.discount.includes(".")) ||
      (this.quantity && this.quantity.includes(",")) ||
      (this.quantity && this.quantity.includes(".")) ||
      (this.denomination && this.denomination.includes(",")) ||
      (this.denomination && this.denomination.includes(".")) ||
      (this.issuance_fee && this.issuance_fee.includes(",")) ||
      (this.issuance_fee && this.issuance_fee.includes("."))
    ) {
      this.toastr.error("Input contains invalid characters");
      return;
    }

    if (this.role === "admin" || this.role === "SuperAdmin") {
      let selectsource = this.sourceData.filter(
        (e: any) => e.supplier_name === this.requestCouponVoucher.source
      );

      if (selectsource.length === 0) {
        this.toastr.error("Please select source");
        return;
      }

      let sourceid = selectsource[0].supplier_id;
      // this.sourceid = sourceid;

      this.requestCouponVoucher.source_id =
        this.role === "admin" || this.role === "SuperAdmin" ? sourceid : null;
    } else {
      this.requestCouponVoucher.source_id = null;
    }

    // if(this.role === 'brand'){

    //   let selectsource = this.sourceData.filter((e: any) => e.supplier_name === this.requestCouponVoucher.source);

    //   let sourceid = selectsource[0].supplier_id;
    //   this.requestCouponVoucher.source_id = sourceid;

    // }

    if (
      this.requestCouponVoucher.validity === "0" ||
      this.requestCouponVoucher.validity === 0 ||
      /^0*$/.test(this.requestCouponVoucher.validity)
    ) {
      this.toastr.error("Please enter validity greater than 0");
      return;
    }

    // if (this.requestCouponVoucher.discount === '0' || this.requestCouponVoucher.discount === 0 || /^0*$/.test(this.requestCouponVoucher.discount)) {
    //   this.toastr.error("Please enter discount value greater than 0");
    //   return;
    // }

    if (
      this.requestCouponVoucher.quantity === 0 ||
      this.requestCouponVoucher.quantity === "0" ||
      /^0*$/.test(this.requestCouponVoucher.quantity)
    ) {
      this.toastr.error("Please enter quantity value greater than 0");
      return;
    }

    if (
      this.requestCouponVoucher.denomination === "0" ||
      this.requestCouponVoucher.denomination === 0 ||
      /^0*$/.test(this.requestCouponVoucher.denomination)
    ) {
      this.toastr.error("Please enter denomination value greater than 0");
      return;
    }

    // if (this.requestCouponVoucher.issuance_fee === 0 || this.requestCouponVoucher.issuance_fee === '0' || /^0*$/.test(this.requestCouponVoucher.issuance_fee)) {
    //   this.toastr.error("Please enter issuance fee value greater than 0");
    //   return;
    // }

    // if (this.noCompanyFound === true) {
    //   this.toastr.error("Company name is not matched with selectbox value. Please select other company name");
    //   return;
    // }

    // if (this.noBrandFound === true) {
    //   this.toastr.error("Brand name is not matched with selectbox value. Please select other brand name");
    //   return;
    // }
    if (this.requestCouponVoucher.source != "Ticket Express") {
      if (
        this.minprice &&
        this.minprice > this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination min values");
        return;
      }

      if (
        this.maxprice &&
        this.maxprice < this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination max values");
        return;
      }
    }

    if (this.role === "brand") {
      if (
        this.minprice &&
        this.minprice > this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination min values");
        return;
      }

      if (
        this.maxprice &&
        this.maxprice < this.requestCouponVoucher.denomination
      ) {
        this.toastr.error("Please enter correct denomination max values");
        return;
      }
    }

    if (this.requestCouponVoucher.source === "voucherkart") {
      if (!/^[0-9]+$/.test(this.requestCouponVoucher.validity)) {
        this.toastr.error("Please enter validity in numbers");
        return;
      }
    }

    if (!/^[0-9]+$/.test(this.requestCouponVoucher.quantity)) {
      this.toastr.error("Please enter quantity in numbers");
      return;
    }

    // if (!/^[0-9]+$/.test(this.requestCouponVoucher.denomination)) {
    //   this.toastr.error("Please enter denomination in numbers");
    //   return;
    // }

    // if (!/^[0-9]+$/.test(this.requestCouponVoucher.discount)) {
    //   this.toastr.error("Please enter discount in numbers");
    //   return;
    // }

    // if (!/^[0-9]+$/.test(this.requestCouponVoucher.issuance_fee)) {
    //   this.toastr.error("Please enter issuance fee in numbers");
    //   return;
    // }

    if (this.requestCouponVoucher.voucher_delivery_type === "Single") {
      var expressions = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
      var regex = new RegExp(expressions);
      const result =
        this.requestCouponVoucher.voucher_delivery_email.match(regex);
      if (result == null) {
        this.toastr.error("Please enter valid Email");
        return;
      }
    }

    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "QwikCilver"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }

    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "FlipKart"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }

    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "Ticket Express"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }

    if (
      this.expiryvalueint &&
      this.expiryvalueint < this.requestCouponVoucher.validity &&
      requestCouponVoucher.source !== "Amazon"
    ) {
      this.toastr.error("Please enter correct validity value");
      return;
    }

    if (this.discountvalue) {
      if (this.discountvalue < this.requestCouponVoucher.discount) {
        this.toastr.error("Please enter valid discount");
        return;
      }
    }

    if (this.role === "brand" && this.discountvalue) {
      if (this.discountvalue < this.requestCouponVoucher.discount) {
        this.toastr.error("Please enter valid discount");
        return;
      }
    }

    if (this.discountvalue) {
      this.requestCouponVoucher.kart_type = "0";
      this.requestCouponVoucher.brand_total =
        this.requestCouponVoucher.totalfacevalue;

      let discountpercent = Number(this.discountvalue) * (75 / 100);

      if (this.requestCouponVoucher.discount >= discountpercent) {
        this.requestCouponVoucher.voucher_approve_admin = "YES";
      } else {
        this.requestCouponVoucher.voucher_approve_admin = "NO";
      }
    } else {
      this.requestCouponVoucher.kart_type = this.requestCouponVoucher.kart_type;
    }

    // this.send_date = new Date(this.startDate);

    // this.send_date.setDate(this.send_date.getDate() + (Number(this.requestCouponVoucher.validity)));
    // this.formattedDate = this.send_date.toISOString().slice(0, -1);

    // if (this.requestCouponVoucher.source === 'QwikCilver' || this.requestCouponVoucher.source === 'Ticket Express' || this.requestCouponVoucher.source === 'Amazon') {

    //   this.send_date.setDate(this.send_date.getDate() + (Number('365')));
    //   this.formattedDate = this.send_date.toISOString().slice(0, -1);
    // }

    // else {

    //   if (this.requestCouponVoucher && this.requestCouponVoucher.validity == "1 Year from the Date of Expiry") {
    //     this.send_date.setDate(this.send_date.getDate() + (Number('365')));
    //     this.formattedDate = this.send_date.toISOString().slice(0, -1);

    //   }

    //   else {
    //     this.send_date.setDate(this.send_date.getDate() + (Number(this.requestCouponVoucher.validity)));
    //     this.formattedDate = this.send_date.toISOString().slice(0, -1);
    //   }

    // }

    let dat: any = this.requestCouponVoucher.source;

    if (
      dat !== "QwikCilver" &&
      dat !== "Ticket Express" &&
      dat !== "Amazon" &&
      dat !== "FlipKart" &&
      dat !== "EZPIN" &&
      dat !== "DT ONE" &&
      dat !== "MEA" &&
      dat !== "YOU GOTA GIFT" && dat !== "Bamboo"
    ) {
      this.send_date = new Date(this.startDate);

      this.send_date.setDate(
        this.send_date.getDate() + Number(this.requestCouponVoucher.validity)
      );
      this.formattedDate = this.send_date.toISOString().slice(0, -1);

      this.requestCouponVoucher.validity = this.formattedDate;
    }

    this.requestCouponVoucher.created_by_role = this.userDetails.role;

    if (this.isEdit) {
      this.requestCouponVoucher.action = "UPDATE";
      this.requestCouponVoucher.voucher_request_id =
        this.voucherId || this.editvoucherId;
      this.requestCouponVoucher.modified_by = this.userDetails.UserID;
      this.requestCouponVoucher.modiedfy_by_name = this.userDetails.UserName;
      this.requestCouponVoucher.modifieddate = new Date();
    } else {
      this.requestCouponVoucher.action = "CREATE";
      this.requestCouponVoucher.created_by = this.userDetails.UserID;
      this.requestCouponVoucher.status = "Y";
      this.requestCouponVoucher.created_by_name = this.userDetails.UserName;
      delete this.requestCouponVoucher.modified_by;
    }
    
    this.requestCouponVoucher.voucher_type = "voucher";
    this.requestCouponVoucher.source === "DT ONE" ? this.requestCouponVoucher.source = "DT" : "";
    this.requestCouponVoucher.source === "YOU GOTA GIFT" ? this.requestCouponVoucher.source = "YGG" : "";
    if(this.requestCouponVoucher.source === "Bamboo" && typeof(this.requestCouponVoucher.brand_name) === "object") {
          this.requestCouponVoucher.brand_name = this.requestCouponVoucher.brand_name.BrandName;
    }
    if(typeof(this.requestCouponVoucher.product) === "object") {
      this.requestCouponVoucher.product = this.requestCouponVoucher.product.BrandName;
    }
    this.requestCouponVoucher.brandId = this.newBrandID;
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http
      .voucherRequest(this.requestCouponVoucher, this.headers)
      .subscribe(
        (data: any) => {
          this.loader.stop();

          if (data.msg == "Success") {
            this.toastr.success("Voucher request send successfully");
            this.cancelVoucherList();
            this.router.navigate(["/international-dashboard"], { replaceUrl: false });
          }
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
  }

  showSupplierModal() {
    const element = document.getElementById("myModal") as HTMLElement;
    const myModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    myModal.show();
  }

  onSelectFile(event: any) {}

  selectBrand() {
    /*if (this.role === 'brand') {
      this.requestCouponVoucher.brandName = this.requestCouponVoucher.source;
    }*/
  }

  importUserData(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (
      getImg.toLowerCase() !== ".xlsx" &&
      getImg.toLowerCase() !== ".xls" &&
      getImg.toLowerCase() !== ".csv"
    ) {
      // this.showImageError = true;
      return;
    } else {
      // this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e: any) => {
        let binaryDara = e.target.result;
        let workbook = XLSX.read(binaryDara, { type: "binary" });
        // workbook.SheetNames.forEach(sheet=>{
        //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        //   console.log(this.batchData)
        // })
      };
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      // this.loader.start();
      this._http.importUserDataVoucher(formData, this.headers).subscribe(
        (data: any) => {
          this.loader.stop();
          if (data) {
            this.toastr.success("Your file uploaded successfully");
            // this.upldSucess = true;
            // const element = document.getElementById('myModal') as HTMLElement;
            // const myModal = new Modal(element,
            //   {backdrop: 'static',
            //   keyboard: false});
            // myModal.show();
          }
        },
        (_error) => {
          this.loader.stop();
        }
      );
    }
  }

  restrictspecialchars(event: KeyboardEvent): boolean {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error("Number only accepted here");
      return false;
    } else {
      return true;
    }
  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  // ngOnDestroy() {
  //   localStorage.removeItem('edit');

  // }

  selectdistypeEvent(item: any, type: boolean ,source?:string) {
    if(source){
      this.requestCouponVoucher.denomination = item;
      this.calculate();
    }
    if (!type) this.requestCouponVoucher.discount = "";

    let self = this;
    setTimeout(() => {
      let {
        discount = 0,
        quantity = 0,
        denomination = 0,
        issuance_fee = 0,
        kart_type,
      } = self.requestCouponVoucher;

      //  console.log('discount', discount, 'quantity', quantity, 'denomination', denomination, 'issuance_fee', issuance_fee, 'kart_type', kart_type);

      if (!issuance_fee) {
        issuance_fee = "0";
      }

      if (kart_type == "0") {
        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (
          finalval <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }

        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalval;
        } else {
          this.requestCouponVoucher.total = "";
        }
      } else if (kart_type == "1") {
        let qdvalue = parseInt(quantity || 0) * parseInt(denomination || 0);
        let discountvalue = qdvalue - parseInt(discount || 0);
        let finalvalue = discountvalue + parseInt(issuance_fee);
        let finalval = Math.round(finalvalue);

        if (
          finalval <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }

        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalval;
        } else {
          this.requestCouponVoucher.total = "";
        }
      }
    }, 200);
  }
  calculate() {
    // if(this.requestCouponVoucher.source === "YOU GOTA GIFT"){
    //   let token = localStorage.getItem("token");
    //   this.headers = {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     }),
    //   };
    //   this._http.currencyConversion(this.headers).subscribe((data:any) => {
    //     this.filteredCurrency = data.data.find((item:any) => {
    //       return item.conversion_currency === this.requestCouponVoucher.currency;
    //   });
      
    //   this.finalFilteredCurrency = this.filteredCurrency ? this.filteredCurrency.currency_rate : "";
       
    //   })
    // }
    if(this.requestCouponVoucher.source){
      if(this.currencyListdata){
        let curr = this.requestCouponVoucher.source === "YOU GOTA GIFT" ? "SAR" : "AED";
        let newArr = this.currencyListdata.find((data:any) => {
          return data.from_currency === curr && data.to_currency === this.requestCouponVoucher.currency
        })
        // setTimeout(() => {
          if(newArr && newArr.markup){
            this.markUp = newArr.markup ? newArr.markup : 1;
          }
          if(newArr && newArr.percentage){
            this.markUpPercentage = newArr.percentage ? newArr.percentage : 1;
          }
        // }, 0);
        
      }
    }
    let self = this;
    setTimeout(() => {
      let {
        discount = 0,
        quantity = 0,
        denomination = 0,
        issuance_fee = 0,
        kart_type,
      } = self.requestCouponVoucher;
      // if(this.requestCouponVoucher.source === "YOU GOTA GIFT"){
      //   console.log("before",denomination);
      //   denomination = this.finalFilteredCurrency ? this.finalFilteredCurrency * denomination : denomination;
      //   console.log("after",denomination);
      // }
      if(this.requestCouponVoucher.source === "DT ONE"){
        // if(this.requestCouponVoucher.source && this.newCal){
        //   console.log("before",denomination);
        //   let roundedVal = Number(denomination * this.newCal.base);
        //   let val$ =  roundedVal / this.newCal.wholesale
        //   denomination = val$.toFixed(2);
        //   console.log("after",denomination);
        // }
      }else{
        if(this.requestCouponVoucher.source && this.newCal){
          console.log("before",denomination);
          let roundedVal = Math.floor(Number(denomination * this.newCal.base));
          denomination = roundedVal / this.newCal.wholesale;
          console.log("after",denomination);
        }
      }
      
      //  console.log('discount', discount, 'quantity', quantity, 'denomination', denomination, 'issuance_fee', issuance_fee, 'kart_type', kart_type);

      if (!issuance_fee) {
        issuance_fee = "0";
      }

      if (kart_type == "0") {
        let qdvalue = parseInt(quantity || 0) * parseFloat(denomination || 0);
        let discountpercent = qdvalue * ((discount || 0) / 100);
        let sum = qdvalue - discountpercent;
        let finalvalue = sum + parseFloat(issuance_fee);
        // let finalval = Math.round(finalvalue);

        if (
          finalvalue <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }

        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalvalue;
          this.requestCouponVoucher.total = this.requestCouponVoucher.total ? this.requestCouponVoucher.total.toFixed(4) : 0;
          if(this.requestCouponVoucher.source){
            
            this.requestCouponVoucher.total = this.requestCouponVoucher.total * this.markUp * this.markUpPercentage;
          }
        } else {
          this.requestCouponVoucher.total = "";
        }
      } else if (kart_type == "1") {
        let qdvalue = parseInt(quantity || 0) * parseFloat(denomination || 0);
        let discountvalue = qdvalue - parseInt(discount || 0);
        let finalvalue = discountvalue + parseInt(issuance_fee);
        // let finalval = Math.round(finalvalue);

        if (
          finalvalue <= 0 &&
          kart_type &&
          discount &&
          quantity &&
          denomination &&
          issuance_fee
        ) {
          this.toastr.error("Voucher cannot be negative");
          this.totalval = true;
        } else {
          this.totalval = false;
        }

        if (denomination && quantity) {
          this.requestCouponVoucher.totalfacevalue = qdvalue;
        } else {
          this.requestCouponVoucher.totalfacevalue = "";
        }

        if (
          !isNaN(denomination) &&
          !isNaN(quantity) &&
          !isNaN(issuance_fee) &&
          !isNaN(discount)
        ) {
          this.requestCouponVoucher.total = finalvalue;
          this.requestCouponVoucher.total = this.requestCouponVoucher.total ? this.requestCouponVoucher.total.toFixed(4) : 0;
          if(this.requestCouponVoucher.source){

            this.requestCouponVoucher.total = this.requestCouponVoucher.total * this.markUp * this.markUpPercentage;
          }
        } else {
          this.requestCouponVoucher.total = "";
        }
      }
    }, 200);
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
    localStorage.removeItem("edit");
  }

}
