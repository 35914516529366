import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { INgxMyDpOptions, IMyDateModel, IMyCalendarViewChanged } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { HttpClient,HttpHeaders ,HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";
import { AppService } from '../restful-services/restful.service';
import { RequestProductmgt } from '../models/Requestproductmgt';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { VoucherRedemptionHistory } from '../models/VoucherRedemptionHistory';
import { Router } from '@angular/router';
import { WalletHistory } from '../models/WalletHistory';


@Component({
  selector: 'app-voucher-report',
  templateUrl: './voucher-report.component.html',
  styleUrls: ['./voucher-report.component.scss']
})
export class VoucherReportComponent implements OnInit {

  public startOptions: INgxMyDpOptions = {};
  public endOptions: INgxMyDpOptions = {};

  public ExpiryOptions: INgxMyDpOptions = {};

  public getstartDate: string = "";
  public getendDate: string = "";
  brandidvalue : any;
  brand:any = '';
  limit: number = 10; 
  skip: number = 0;
  d: number = 1;
  product:any;
  
  start_date: any;
  end_date:any;
  brandDisabled1: boolean = false;
  private headers:any={}; 
  apiLoading: boolean = true;
  ProductmgtList: VoucherRedemptionHistory[];
  count: number = 0;
  lastvalue: number = 0;
  brandData: any = [];
  filteredData: any;
  isButtonDisabled = true;




  constructor(private datepipe:DatePipe,private _http: AppService, private loader: NgxUiLoaderService,
    private toastr: ToastrService, private router:Router,private cdRef: ChangeDetectorRef){

    this.ProductmgtList = new Array<VoucherRedemptionHistory>();


    let dp = new Date();
    dp.setDate(dp.getDate() + 1);
    this.startOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay:true,
      disableSince: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate()},
    
    };
    let edp: any = new Date();
    edp.setDate(edp.getDate() + 1);
    this.endOptions = {
      dateFormat: 'dd-mm-yyyy',
      alignSelectorRight: true,
      openSelectorTopOfInput: false,
      markCurrentDay:true,
      disableSince: { year: edp.getFullYear(), month: edp.getMonth() + 1, day: edp.getDate() },
    }; 
    
  }
  ngOnInit(): void {
    this.onChangebrand();
    this.getproductmgtlistfilter();  
    // this.checkDate();
  }

  checkButtonStatus() {
    this.isButtonDisabled = !this.getstartDate || !this.getendDate;
  }
  openCalendarAndDetectChanges(dp: any) {
    dp.openCalendar();
    this.cdRef.detectChanges();
  }

  openCalendarAndDetectChange(edp: any) {
    edp.openCalendar();
    this.cdRef.detectChanges();
  }
 



  // onStartDateChanged(event: IMyDateModel): void {
  //   this.start_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString;
  //   let dp: any = new Date(event.jsdate.setDate(event.jsdate.getDate() - 1));
  //   this.ExpiryOptions = {
  //     dateFormat: 'dd-mm-yyyy',
  //     alignSelectorRight: true,
  //     openSelectorTopOfInput: false,
  //     disableUntil: { year: dp.getFullYear(), month: dp.getMonth() + 1, day: dp.getDate() },
  //   };
  //   this.getstartDate = '';
  //   console.log(this.start_date)
  // }
  // today= new Date();
  onStartDateChanged(event: IMyDateModel): void {
    this.start_date = new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 10).replace('T', ' ');
    console.log("start date " + this.start_date)
  }
      // dp = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate());

  
  
  onCalendarViewChanged(event: IMyCalendarViewChanged): void {
    // console.log(event);
    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
  }

  onEndDateChanged(event: IMyDateModel): void {
    // console.log("end date " , new Date(event.jsdate.setDate(event.jsdate.getDate() + 1)).toISOString().slice(0, 11).replace('T', ' '));
    this.end_date = new Date(event.jsdate.setDate(event.jsdate.getDate()+ 1)).toISOString().slice(0, 10).replace('T', ' ');
    console.log("end date " +this.end_date);
    
   

  }

  
  
  
  getproductmgtlistfilter() { 
    this.loader.start();  
    
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    
    this.apiLoading = true;
    this._http.getVoucherRedemptionHistory(this.product, this.brandidvalue ,this.limit, this.skip, this.headers, this.start_date,this.end_date).subscribe({
      next: (data: any) => {    

        this.loader.stop();
                        
        this.ProductmgtList = data.data;   
        this.filteredData = this.ProductmgtList;

        // console.log(this.ProductmgtList);
        
        if (data) {
          this.apiLoading = false;   
          this.count = data.count;
          this.ProductmgtList = data.data;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }
         
        }
        
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }
  onChangebrand() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };


    this._http.getbrandload(this.headers).subscribe((data: any) => {
      this.brandData = data.data;
    },
      error => {
        // this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }
selectbrand(item:any){
this.brandidvalue = item;
}


filterData(){
  this.getproductmgtlistfilter();
}


viewReport(walletList:WalletHistory){
    this._http.voucherWalletDetail(walletList);
    const id = walletList.voucher_request_id;
    localStorage.setItem('voucher_request_id', JSON.stringify(walletList));
    this.router.navigate(['/digital-order-view'], {
    queryParams :{Voucher_request_id : id},
    state: { id },
    replaceUrl: true
  });
  localStorage.removeItem('logvalue');
}


filterList() {  
  if ( this.limit || this.skip || this.product || this.brand || this.start_date || this.end_date ) {
          this.skip = 0;
          this.d = 1;
        }
        // this.getproductmgtlistfilter();
      }
pageChanged(pageNo: any) {
  this.d = pageNo;
  this.skip = (pageNo - 1) * 10;
  this.lastvalue = this.skip + 10;
  localStorage.setItem('pageNo', pageNo);
  this.getproductmgtlistfilter();
}
@HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  const keyCode = event.keyCode;
  if (keyCode === 190 || keyCode === 188) {
    event.preventDefault();
  }
}
keyDownHandler(event: any) {

  if (event.code === 'Space' && event.which === 32) {
    event.preventDefault();
  }

}
restrictEnter(args: any) {
  if (args.key === "Enter" && args.keyCode == 13)
    args.preventDefault();
}
restrictspecialchars(event: KeyboardEvent): boolean {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[a-zA-Z0-9]*$/;
  if (!pattern.test(inputChar)) {
    event.preventDefault();
    return false;
  }
  return true;
}

onKeyPresscomma(event: KeyboardEvent) {
  if (event.keyCode === 44) {
    event.preventDefault();
  }
}
restrictAlphabets(evt: any) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  if (key.length == 0) return;
  var regex = /^[0-9.,\b]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}
 

}


