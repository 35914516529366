
<div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true"
    style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 text-center justify-content-center">
                <div class="row mx-0">
                    <div class="col-md-12 texts text-center">
                        Add Product 
                    </div>
                </div>
            </div>
            <div class="modal-body text-center">



                <!-- <form #addForm="ngForm" name="form"  class="mt-4">    -->



                <div class="md-form form-sm my-3  leftAlign">
                    <!-- <label class="mb-2">Product Type <span class="text-danger">*</span>
                    </label>
                   
                        <select id="type" class="form-select " placeholder="Select Product" (click)="addProduct()"  [(ngModel)]="productList" name="productList"
                            >
                            <option value="" disabled selected>Select Product</option>
                            <option value="INT">Internal</option>
                            <option value="EXT">External</option>
                        </select> -->
                    
                    <label class="mb-2">Name <span class="text-danger">*</span>
                    </label>
                     <select id="sourceList" class="form-select"  placeholder="Select Source"  [(ngModel)]="sourceList" name="category"
                        >

                         <option value="" disabled selected>Select Source Name</option>
                        <!-- <option value="All">All</option> -->
                        <option class="textarea" selected *ngFor="let option of sourceDatas" [value]="option.name">
                            {{ option.name }}
                        </option>

                    </select>

                </div>
                <div class="md-form form-sm my-3  leftAlign">                    

                     <a class="mr-10" style="color: green;" href="{{this.fileurls}}sample/sample_b2c_products.csv">Sample file</a>   

                </div> 
                <div class="md-form form-sm my-3  leftAlign"> 
                <label class="rounded px-3 py-2 bg-success text-end border-0 labelText  mr-10"
                for="inputFile">
                <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                    (change)="importProduct($event)">
                <i class="fa fa-upload pr-1"></i> Import</label>   
                </div>  
                


                <div class="mt-3 text-end">
                    <button class="btn btn-secondary px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                        type="button">Close</button>
                    <!-- <button class="btn btn-success  px-4 py-2" data-bs-dismiss="modal"
                        (click)="editedDatas()">Add</button> -->
                </div>


                <!-- </form> -->
            </div>
        </div>
    </div>
</div>

<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-6">
                    <label class="texts"> {{clientName}} >> Product list </label>
                </div>
                <div class="text-end col-6">
                    <div class="dropdown">
                        <button class="btn btn-success mr-5 float-right" (click)="addNewBrand()" >Add New Product</button>
                    </div>
                </div>

                <div class="d-flex">


                    <div class="form-floating me-5">
                        <input type="text" [(ngModel)]="external" name="external" class="form-control border-0 bg-light"
                            placeholder="Product" style="width:210px;padding-top: 13px;" (keypress)="filterList()">
                        <label for="tnums">Product Name</label>
                    </div>
                    <button class="btn btn-success mr-5 " (click)="filterList()" style="width: 100px;">Search</button>
                    <button class="btn btn-secondary mr-5 " style="width: 90px;" (click)="clear()">Clear</button>
                    
                </div>
                        
                    
            </div>
            <div class="row mx-0">
                <div class="col-12 card border-0 shadow-sm px-0">
                    <div class="card-body pb-4">
                        <table class="table">
                            <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
                                <tr>
                                    <th scope="col" class="pt1">SI.No</th>
                                    <th scope="col" class="pt1">Name</th>
                                    
                                    <th scope="col" class="pt1 text-center">Description</th>
                                    <th scope="col" class="pt1 text-center">Product Image</th>
                                    <th scope="col" class="pt1">Action</th>
                                </tr>
                            </thead>
                            <div style="width: 353%;" class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                             *ngIf="getExternal && getExternal.length === 0 && !apiLoading">
                            <div class="card-body row mx-0 ps-0">
                                <div class="text-center col-12">
                                    No Record Found.
                                </div>
                            </div>
                        </div>

                            <tbody>
                                <tr
                                    *ngFor="let value of getExternal | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                                    <th scope="row">{{i + 1+skip}}</th>
                                    <td>{{value.name}}</td>                                    
                                    <td class=" leftAlign">{{value.description || '-'}}</td>
                                    <td class="text-center"><img class="imageval" [src]="value.thumbnail"></td>

                                    <td class="text-center"><a class="linkColor" (click)="deleteModal(value.id,value.product_mapping_id)">
                                        <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                                    </a></td>
                                </tr>
                            </tbody>

                        </table>
                        <div>
                            <div class="row" *ngIf="getExternal && getExternal.length > 0">
                                <div class="col-6 pt-3">
                                    <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                                        {{count}} entries</p>
                                </div>
                                <div class="col-6">
                                    <pagination-controls class="list-pagination adpage"
                                        (pageChange)="pageChanged($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-md-12 text-end">
                        <button type="button" class="btn back-btn mb-2 mt-3" (click)="back()">Back</button>
                    </div>
                </div>
            </div>
            <ngx-ui-loader></ngx-ui-loader>
        </div>
    </div>

    <div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <p>Are you sure to delete the product?</p>
                    <p class="mt-5">
                        <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closeproductBtn>Cancel</button>
                        <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteproduct()">Confirm</button>
                    </p>
                </div>
            </div>
        </div>
    </div>