export class RequestSetting {
  order_amount: any;
  order_limit_amount: any;
}

export class DeliveryEmail {
  success_email_list: any;
  failure_email_list: any;
  success_email_list_cc: any;
  failure_email_list_cc: any;
}
