<loyalty-app-login *ngIf="!login" (loggedStatus)="session($event)"></loyalty-app-login>

<div class="d-flex flex-row min-vh-100" *ngIf="login">
    <loyalty-app-left-sidebar></loyalty-app-left-sidebar>
    <div class="d-flex flex-column w-100 right-side-content bg-light">
        <loyalty-app-main (loggedStatus)="session($event)"></loyalty-app-main>
        <div class="p-3">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>