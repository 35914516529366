
<!-- <div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true"
    style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">

                <p class="texts">Update Brand</p>


                



                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">Name <span class="text-danger">*</span>
                    </label>
                     <input type="text" [(ngModel)]="nameList" class="form-control" placeholder="Enter Name" mdbInput
                        autocomplete="off" name="name" #nameval="ngModel" (keypress)="onKeyPresscomma($event)"
                        [disabled]="userName" (input)="restrictDecimals($event)" required>

                    <span class="text-danger" *ngIf="nameval.touched  && nameval.errors?.['required']">
                        name is required
                    </span>


                </div>

                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">Description
                        <span class="text-danger">*</span>
                    </label>
                     <textarea type="text" [(ngModel)]="descriptionList" class="form-control"
                        placeholder="Enter Description" mdbInput autocomplete="off" name="description"
                        #description="ngModel" (keypress)="onKeyPresscomma($event)" [disabled]="userName"
                        (input)="restrictDecimals($event)" required>
                            <span class="text-danger" 
                            *ngIf="description.touched && description.errors?.['required']">
                            description is required
                        </span> </textarea>

                </div>
                <div class="md-form form-sm my-3  leftAlign">
                    <label class="mb-2">B2C Name

                    </label>
                     <textarea type="text" [(ngModel)]="b2cname" class="form-control" placeholder="Enter Description"
                        mdbInput autocomplete="off" name="description" #description="ngModel"
                        (keypress)="onKeyPresscomma($event)" (input)="restrictDecimals($event)" required> </textarea>

                </div>


                <p class="mt-3">
                    <button class="btn btn-secondary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                        type="button">Back</button>
                    <button class="btn btn-success px-4 py-2" data-bs-dismiss="modal"
                        (click)="editedDatas()">Update</button>
                </p>


               
            </div>
        </div>
    </div>
</div> -->

<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">B2C Brand </label>
                </div>
                <div class="trees col-9">
                    <div class="dropdown">
                        <button class="btn btn-success mr-10 float-right" (click)="addNewBrand()">Add New Brand</button>
                    </div>
                    </div>

                <div class="d-flex">


                    <div class="form-floating me-5">
                        <input type="text" [(ngModel)]="external" name="external" class="form-control border-0 bg-light"
                            placeholder="Brand Name" style="width:210px;padding-top: 13px;" (keypress)="filterList()">
                        <label for="tnums">Brand Name</label>
                    </div>
                    <button class="btn btn-success mr-5 " (click)="filterList()" style="width: 100px;">Search</button>
                    <button class="btn btn-secondary mr-5 " style="width: 90px;" (click)="clear()">Clear</button>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 card border-0 shadow-sm px-0">
                    <div class="card-body pb-4">
                        <table class="table mb-0">
                            <thead class=" adcolor py-4 px-3 mx-0 opacity-75">
                                <tr>
                                    <th scope="col" class="pt1">SI.No</th>
                                    <th scope="col" class="pt1">Name</th>                                    
                                    <th scope="col" class="pt1 text-center">Description</th>
                                    <th scope="col" class="pt1 text-center">Brand Image</th>
                                    <th scope="col" class="pt1">Products</th>
                                    <th scope="col" class="pt1">Action</th>
                                </tr>
                            </thead>
                            <!-- <div style="width: 488%;" class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light" *ngIf="getExternal && getExternal.length === 0 && !apiLoading">
                            <div class="card-body row mx-0 ps-0">
                                <div class="text-center col-12">
                                    No Record Found.
                                </div>
                            </div>
                        </div> -->

                            <tbody>
                                <tr
                                    *ngFor="let value of getExternal | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                                    <th scope="row">{{i + 1+skip}}</th>
                                    <td>{{value.name}}</td>
                                    
                                    <td class="text-center">{{value.description || '-'}}</td>
                                    <td class="text-center"><img class="imageval" [src]="value.image"></td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-outline-warning position-relative" (click)="viewGenerationList(value)">
                                            View
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {{value.product_count}}
                                            </span>
                                          </button>
                                        <!-- <a class="linkColor" (click)="viewGenerationList(value)">
                                        <i class="fa fa-eye cursor col" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="View">
                                            <span class="badge bg-secondary">{{value.product_count}}</span></i>
                                    </a> -->
                                </td>

                                    <td class="text-center"><a class="linkColor downloadIcon" #Edit
                                            (click)="editCategory(value)">
                                            <i class="fa fa-edit mr-10" data-bs-toggle="modal"
                                                data-bs-placement="bottom" data-bs-target="#exampleModal"
                                                title="Edit"></i>
                                        </a>
                                        <a class="linkColor1 downloadIcon" *ngIf="value.status == '1'">
                                            <i class="fa fa-toggle-on cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active" (click)="statusChange(value.id,'0')"></i>
                                           </a>
                                           <a class="linkColor downloadIcon" *ngIf="value.status == '0'">
                                            <i class="fa fa-toggle-off cursor mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(value.id,'1')"></i>
                                           </a>
                                        </td>
                                </tr>
                            </tbody>

                        </table>
                        <table class="w-100" *ngIf="getExternal && getExternal.length === 0 ">
                            <tr class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light" >
                                <td class="card-body text-center m-1" >
                                    No Record Found.
                                </td>
                            </tr>
                        </table>
                        <div>
                            <div class="row"  *ngIf="getExternal && getExternal.length > 0">
                                <div class="col-6 pt-3">
                                    <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                                        {{count}} entries</p>
                                </div>
                                <div class="col-6">
                                    <pagination-controls class="list-pagination adpage"
                                        (pageChange)="pageChanged($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngx-ui-loader></ngx-ui-loader>
        </div>
    </div>
    <div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center">    
                    
                    
                    <p class="texts">{{Edit ? 'Update':'Add'}} Brand</p>
    
    
                    <form #addForm="ngForm" name="form" (ngSubmit)="AddCategory(AddrequestCategory)" class="mt-4">   
                        
    
                        <div class="md-form form-sm my-3  leftAlign">
                            <label class="mb-2">Brand Name
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="AddrequestCategory.name" 
                                class="form-control" placeholder="Enter Brand Name" mdbInput
                                autocomplete="off" name="name"
                                #nameval="ngModel"
                                required >
                            <span class="text-danger" 
                                *ngIf="(nameval.touched || addForm.submitted) && nameval.errors?.['required']">
                                Brand Name is required
                            </span>
                        </div>         
                        
                       
                        <div class="md-form form-sm my-3  leftAlign">
                            <label class="mb-2">Description
                                <span class="text-danger">*</span>
                            </label>
                             <textarea type="text" [(ngModel)]="AddrequestCategory.description" 
                                class="form-control" placeholder="Enter Description" mdbInput
                                autocomplete="off" name="description"
                                #description="ngModel"
                               
                                required > </textarea>
                                
                              
    
                            <span class="text-danger" 
                                *ngIf="(description.touched || addForm.submitted) && description.errors?.['required']">
                                Brand Description is required
                            </span>
                        </div>         
                        
    
    
                        <!-- <div class="md-form form-sm my-3 leftAlign">
    
                            <label for="category" class="mb-2">Parent Category  -->
                                <!-- <span class="text-danger">*</span> -->
                            <!-- </label> -->
                            <!-- <select id="category" class="form-select" [(ngModel)]="AddrequestCategory.category"  (change)='selectCategoryEvent(AddrequestCategory.category)'
                            name="category" #category="ngModel" required> -->
                             
                            <!-- <option value="" selected disabled hidden>Select Parent Category</option> -->
                            <!-- <option [value]="option.id">{{ option.name }}</option>                        -->
                                                  
                       
    
                            <!-- <span class="text-danger"
                                *ngIf="(category.touched || addForm.submitted) && category.errors?.['required']">
                                Parent category is required
                            </span> -->
                        <!-- </div> -->

                        <!-- <div class="md-form form-sm my-3  leftAlign" *ngIf="!Edit">
                            <label class="mb-2">Product
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="AddrequestCategory.product" 
                                class="form-control" placeholder="Enter Product" mdbInput
                                autocomplete="off" name="name"
                                #nameval="ngModel"                                                   
                                (keypress)="onKeyPresscomma($event)"
                                (input)="restrictDecimals($event)"                           
                                
                                required >            
                                                            
                               
                            <span class="text-danger" 
                                *ngIf="(nameval.touched || addForm.submitted) && nameval.errors?.['required']">
                                name is required
                            </span>
                        </div>          -->

                        <div class="col-6">
                            <div class="md-form form-sm my-3 css">
                              <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile">                  
                                <input id="inputFile" type="file" accept="image/*" class="form-control d-none" id="inputFile" #inputFile
                                       (change)="selectfile($event)" [(ngModel)]="AddrequestCategory.file"
                                       [ngModelOptions]="{ standalone: true }">
                                <i class="fa fa-upload pr-1"></i> Upload Brand Image
                              </label>
                                                
                              <!-- <span class="text-danger" *ngIf="(file.touched || brandEditForm.submitted) && file.errors?.['required']">
                                file Upload is required
                              </span> -->
                            </div>
                          </div>
                          
                          <div class="col-2 prealig">
                            <label *ngIf="preshow">Preview Image :</label>
                            <br>
                            <img [src]="this.previews" class="preview" />
                          
                            <img *ngIf="Edit && !this.previews" class="preview" [src]=" AddrequestCategory.file" />
                          </div>
                        <!-- </div> -->
                
                                                                      
                      
                        
                    
    
                       
    
                     
                        <div class="mt-3 row mx-0">
                            <div class="col-md-12 text-end">
                                <button class="btn btn-secondary mx-3 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                                type="button"  (click)="backclear()">Back</button>
                                <button class="btn btn-success px-4 py-2" type="submit"> {{Edit ? 'Update':'Add'}}</button> 
                            </div>
                        </div>
    
    
                    </form>
                </div>
            </div>
        </div>
    </div>