import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { AppService } from './../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RequestBrandmgt } from '../models/Requestbrandmgt';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
declare var bootstrap: any;

import { apiurl } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";



@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.scss']
})






export class BrandManagementComponent implements OnInit {

  @ViewChild('closebrandBtn', { static: true }) closebrandBtn!: ElementRef;

  limit: number = 25;
  supplierType: any;
  skip: number = 0;
  b: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  pathurl: any;
  role: any;
  userDetails: any;
  private headers: any = {};
  apiLoading: boolean = false;

  public name: string = "";

  // categoryList: RequestBrandmgt[];
  AddrequestCategory: RequestBrandmgt;


  brandmgtList: any = [];
  brand_name: any;
  tooltipList: any;
  brandid: any;
  category: any = '';
  categoryData: any = [];
  categoryidvalue: any;


  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.brandmgtList = new Array<RequestBrandmgt>();
    this.AddrequestCategory = new RequestBrandmgt();



  }



  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.getbrandlist();
    this.onChangeParcategory();

    this.pathurl = apiurl;
    // let headerId: any = document.getElementById('logoutDrop')
    // console.log(headerId)
    // if (headerId) {
    //   headerId.className = 'px-4 bd-highlight text-end border-end my-4'
    // }

  }


  onChangeParcategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getParcategory(this.headers).subscribe((data: any) => {

      this.categoryData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }


  selectcategory(item: any) {

    this.categoryidvalue = item;
  }


  getbrandlist() {


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();

    this._http.getbrandmgtlist(this.brand_name, this.categoryidvalue, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();

        // this.brandmgtList = data.data;

        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {
          this.apiLoading = false;
          this.count = data.count;
          this.brandmgtList = data.data;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  getbrandlistfilter() {


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;

    this._http.getbrandmgtlist(this.brand_name, this.categoryidvalue, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.brandmgtList = data.data;

        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {
          this.apiLoading = false;
          this.count = data.count;
          this.brandmgtList = data.data;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }



  filterList() {

    if (this.limit || this.skip || this.brand_name || this.category) {
      this.skip = 0;
      this.b = 1;
    }
    this.getbrandlistfilter();
  }


  clear() {

    this.categoryidvalue = '';
    this.brand_name = '';
    this.category = '';
    this.brandmgtList = [];
    this.getbrandlist();

    this.b = 1;
    let page = this.b;
    this.pageChanged(page);

  }


  addNewcategory() {

    this.router.navigate(['/brandmgt-add'], { replaceUrl: false });
    this.brandmgtList = [];
    localStorage.removeItem('editID');
    localStorage.removeItem("editlist");
  }


  addNewCategory() {

    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();

  }

  pageChanged(pageNo: any) {

    this.b = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    // this.getproductlist();
  }


  deleteModal(brandid: any) {

    this.brandid = brandid;
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }

  deletebrand() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.deletebrandList(this.brandid, this.headers).subscribe(
      (data: any) => {

        this.loader.stop();
        this.closebrandBtn.nativeElement.click();

        if (data.status == 1) {
          this.toastr.success("brand deleted successfully");
          this.getbrandlist();

        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);

        }
      })
  }


  editbrand(brandmgtList: RequestBrandmgt) {

    this._http.brandmgtDetails(brandmgtList);
    const id = brandmgtList.id;
    this.router.navigate(['/brandmgt-add'], {
      state: { id },
      replaceUrl: true
    });
  }




  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void; }) => {
        tooltip.dispose();
      });
    }
    let headerId: any = document.getElementById('logoutDrop')
    if (headerId) {
      headerId.className = 'px-4 flex-fill bd-highlight text-end border-end my-4'
    }
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

}
