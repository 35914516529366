import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { AppService } from './../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestCategory } from '../models/RequestCategory';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
declare var bootstrap: any;




@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})




export class CategoryComponent implements OnInit {

  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('closeCategoryBtn', { static: true }) closeCategoryBtn!: ElementRef;

  limit: number = 25;
  skip: number = 0;
  d: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  tooltipList: any;


  role: any;
  userDetails: any;

  public name: string = "";
  public names: string = "";
  private headers: any = {};
  apiLoading: boolean = false;

  categoryList: RequestCategory[];
  AddrequestCategory: RequestCategory;
  categoryData: any = [];
  Edit: Boolean;
  parcatid: any;
  description: any;
  idvalue: any;
  catid: any;


  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    this.categoryList = new Array<RequestCategory>();
    this.AddrequestCategory = new RequestCategory();

  }



  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.getcategorylist();
    this.onChangeParcategory();

  }




  getcategorylist() {


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();
    this._http.getcategorylist(this.name, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {  

        this.loader.stop();
        this.apiLoading = false;

        this.count = data.count;

        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {

          this.count = data.count;
          this.categoryList = data.data;   
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  getcategorylistfilter() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();

    this._http.getcategorylist(this.name, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;

        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {

          this.count = data.count;
          this.categoryList = data.data;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  filterList() {

    if (this.limit || this.skip || this.name) {
      this.skip = 0;
      this.d = 1;
    }
    this.getcategorylistfilter();
  }


  clear() {

    this.name = '';
    this.categoryList = [];
    this.getcategorylist();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);

  }


  addNewCategory() {

    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();

  }


  editCategory(list: RequestCategory) {

    this.Edit = true;
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.idvalue = list.id;
    this.getcategoryeditlist(this.idvalue);

  }


  getcategoryeditlist(id: any) {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    let idval = id;

    this._http.getcategoryeditlist(idval, this.headers).subscribe((data: any) => {

      this.AddrequestCategory = data.data;
      this.AddrequestCategory.category = this.AddrequestCategory.parent_category;

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })

  }


  deleteModal(categoryid: any) {

    this.catid = categoryid;
    const element = document.getElementById('deleteModal') as HTMLElement;
    const deleteModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    deleteModal.show();
  }

  deletecategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.deletecategoryList(this.catid, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        this.closeCategoryBtn.nativeElement.click();


        if (data.status == 1) {
          this.toastr.success("category deleted successfully");
          this.getcategorylist();  
          this.parcatid = null;
          this.catid = null;
     
        }
        else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      })
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }


  AddCategory(AddrequestCategory: RequestCategory) {

    if (

      !this.AddrequestCategory.name || !this.AddrequestCategory.description

    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    const space = (this.AddrequestCategory.name || '').trim().length === 0;

    if (space) {

      this.toastr.error("Please enter name");
      return;

    }

    const spaceone = (this.AddrequestCategory.description || '').trim().length === 0;

    if (spaceone) {

      this.toastr.error("Please enter description");
      return;

    }


    let postparams = {

      name: this.AddrequestCategory.name,
      description: this.AddrequestCategory.description,
      created_by_id: this.userDetails.UserID,
      action: this.Edit ? 'UPDATE' : "CREATE",
      parent_category_id: this.parcatid,
      id: this.Edit ? this.idvalue : null

    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  

    if (this.Edit) {

      this._http.updatecategory(postparams, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();

          if (data.status == 1) {
            this.loader.stop();
            this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("category updated successfully");
            this.addForm.resetForm();
            this.Edit = false;
          }

          else if (data.status === 0) {
            this.toastr.error(data.msg);
            this.closeCategoryBtn.nativeElement.click();
            this.Edit = false;

          }
        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeCategoryBtn.nativeElement.click();
          this.Edit = false;
        }
      })

    }

    else {

      this._http.addNewcategory(postparams, this.headers).subscribe({
        next: (data: any) => {

          if (data.status == 1) {
            this.loader.stop();
            this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("category added successfully");
            this.addForm.resetForm();

          }

          else if (data.status === 0) {
            this.loader.stop();
            this.toastr.error(data.msg);
            this.closeCategoryBtn.nativeElement.click();


          }

        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeCategoryBtn.nativeElement.click();

        }
      })
    }

  }



  onChangeParcategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getParcategory(this.headers).subscribe((data: any) => {

      this.categoryData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }


  selectCategoryEvent(item: any) {

    if(item){
      this.parcatid = item;
    }
    else{
      this.parcatid = null;
    }

  }


  pageChanged(pageNo: any) {

    this.d = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getcategorylist();
  }


  backclear() {

    this.getcategorylist();
    this.router.navigate(['/category'], { replaceUrl: false });
    this.addForm.resetForm();
    this.Edit = false;

  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }


  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void; }) => {
        tooltip.dispose();
      });
    }
  }



  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  letterOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }
}
