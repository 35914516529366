<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Source Management List</label>
                </div>
                <div class="text-end col-9">
                    <!-- <button class="btn btn-primary mr-10" (click)="addNewSupplier('create')"><i
                            class="fa fa-plus mr-10"></i>Add New</button> -->

                    <!-- <a href="{{this.fileurls}}//sample/sample_supplier_request.csv"
                        class="add_bt btn btn-success mr-10" type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download mr-10"></i> Sample file
                    </a>
                    <label class="rounded px-3 py-2 bg-success text-center border-0 labelText  mr-10" for="inputFile">
                        <input type="file" accept=".csv" class="form-control d-none" id="inputFile" #inputFile
                            (change)="importSupplier($event)">
                        <i class="fa fa-upload mr-10"></i> Import</label> -->
                </div>
            </div>

            <div class="d-flex">

                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="supplierName" name="supplierName"
                        class="form-control border-0 bg-light" placeholder="Source Name"
                        style="width:210px;padding-top: 13px;" (keypress)="onKeyPresscomma($event)" (change)="filterList()">
                        <label for="tnums">Source Name</label>
                </div>

                 <!-- <div class="form-floating me-3">
                    <input   id="tnum"  type="text" class="form-control border-0 bg-light" [(ngModel)]="supplierName" name="brand"
                        placeholder="Brand" (change)="filterList()"  style="width:210px;padding-top: 13px;"> 
                    <label class="zip" style="color: black;" for="tnum">Brand</label>
                </div>                  -->
                
                
                <div class="form-floating me-3">
                    <select id="type" class="form-select border-0 bg-light" [(ngModel)]="statuss" name="statuss"
                        style="width:210px;padding-top: 13px;" (change)="filterList()">
                        <option value="" selected disabled hidden class="lts">Status</option>
                        <option value="0">Active</option>
                        <option value="1">InActive</option>
                    </select>
                </div>
                <button class="btn btn-success mr-5" (click)="getSupplierList()" style="width: 100px;">Search</button>
                <button class="btn btn-secondary mr-5" (click)="clear()" style="width: 90px;">Clear</button>
            </div>

            <div class="mt-4 batch-data px-1">
                <div class="row mx-0 adcolor py-4 opacity-75">
                    <div class="col-4 labelText ps-5 text-start"> Source Name </div>
                    <!-- <div class="col px-0"> API URL </div>
                    <div class="col px-0"> API Key </div>
                    <div class="col px-2"> API Authentication Code</div> -->
                    <div class="col-4 labelText text-center"> Status </div>
                    <div class="col-4 labelText text-center"> Action </div>
                </div>
                <div>
                    <div class="noItems" *ngIf="supplierList.length === 0 && !apiLoading">
                        <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 ps-0">
                                <p>No source found</p>
                            </div>
                        </div>
                    </div>
                    <div class=""
                        *ngFor="let list of supplierList | paginate: {itemsPerPage: limit, currentPage: p,totalItems:count};let i = index">
                        <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                            ng-reflect-ng-class="bg-light">
                            <div class="card-body row mx-0 ps-0">
                            
                                <div class="col-4 text-truncate text-start ps-5"> {{list.supplier_name}} </div>
                                <!-- <div class="col text-truncate pe-0 leftAlign"> {{list.api_url}} </div>
                                <div class="col text-truncate pe-0 leftAlign"> {{list.api_key}} </div>
                                <div class="col text-truncate pe-0 leftAlign"> {{list.api_authentication_code}}
                                </div> -->
                                <div class="col-4 text-center"> {{list.status == 0 ? 'Active' :
                                    'InActive'}} </div>

                                <div class="col-4 text-center">

                                    <!-- <a class="linkColor downloadIcon">
                                        <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"(click)="editSupplier(list,'edit')"></i>
                                    </a> -->                                  


                                    <a class="linkColor1 downloadIcon" *ngIf="list.status == 0">
                                        <i class="fa fa-toggle-on cursor mr-11" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active"(click)="statusChange(list.supplier_id,1)"></i>
                                    </a>


                                    <a class="linkColor downloadIcon" *ngIf="list.status == 1">
                                        <i class="fa fa-toggle-off cursor mr-11" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Inactive" (click)="statusChange(list.supplier_id,0)"></i>
                                    </a>

                                    <!-- <a class="linkColor" (click)="deleteModal(list.supplier_id)">
                                        <i class="fa fa-trash mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                                    </a> -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="supplierList && supplierList.length > 0">
                        <div class="col-6 pt-3">
                            <p>Showing {{p > 1 ? skip + 1 :p}} to {{lastvalue > count ? count : lastvalue}} of
                                {{count}} entries</p>
                        </div>
                        <div class="col-6">
                            <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>


<div class="modal fade" id="addModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">

                <p class="texts"> {{UPDATE ? 'Update':'Add'}} Source</p>


                <form #supplierForm="ngForm" name="form" (ngSubmit)="addSupplier()" class="mt-4">
                  
                  
                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">Source Name
                            <span class="text-danger">*</span>
                        </label>
                         <input type="text" [(ngModel)]="supplierDetail.supplier_name" name="supplier_name"
                            #supplier_name="ngModel" class="form-control" placeholder="Source Name" mdbInput
                            autocomplete="off" (keyup)="onChangeSupplier($event)" required>
                        <span class="text-danger" 
                            *ngIf="(supplier_name.touched || supplierForm.submitted) && supplier_name.errors?.['required']">
                            source Name is required
                        </span>
                    </div>


                    <div class="md-form form-sm my-3 leftAlign">
                        <label for="statuss" class="mb-2">Status
                            <span class="text-danger">*</span>
                        </label>
                        <select id="status" class="form-select" [(ngModel)]="supplierDetail.status" name="statuss" 
                            #status="ngModel"
                             required>
                            <option value="2" selected disabled hidden>Select Status</option>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                        </select>
                        <span class="text-danger"
                            *ngIf="(status.touched || supplierForm.submitted) && status.errors?.['required']">
                            status is required
                        </span>
                    </div>


<!--                
                        <div class="md-form form-sm my-3 leftAlign">
                            <label for="status" class="mb-2">Status
                                <span class="text-danger">*</span>
                            </label>
                            <select id="status" class="form-select" [(ngModel)]="supplierDetail.status"
                                name="status"  #status="ngModel" required>
                                                     
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>                          
                                                      
                            </select>
                            <span class="text-danger"
                            *ngIf="(status.touched || supplierForm.submitted) && status.errors?.['required']">
                            Source Name is required
                        </span>
                        </div> -->
              


                    <!-- <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">API URL</label>
                         <input type="url" [(ngModel)]="supplierDetail.api_url" name="api_url" class="form-control"
                            placeholder="API URL *" mdbInput autocomplete="off" required #api_url="ngModel">
                        <span class="text-danger"
                            *ngIf="(api_url.touched || supplierForm.submitted) && api_url.errors?.['required']">
                            API URL is required
                        </span>
                     
                    </div>


                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">API Key</label>
                         <input type="text" [(ngModel)]="supplierDetail.api_key" name="api_key" class="form-control"
                            placeholder="API Key *" mdbInput autocomplete="off" required #api_key="ngModel">
                        <span class="text-danger"
                            *ngIf="(api_key.touched || supplierForm.submitted) && api_key.errors?.['required']">
                            API Key is required
                        </span>
                    </div>


                    <div class="md-form form-sm my-3  leftAlign">
                        <label class="mb-2">API authentication code</label>
                         <input type="text" [(ngModel)]="supplierDetail.api_authentication_code"
                            name="api_authentication_code" class="form-control" placeholder="API authentication code *"
                            mdbInput autocomplete="off" required #api_authentication_code="ngModel">
                        <span class="text-danger"
                            *ngIf="(api_authentication_code.touched || supplierForm.submitted) && api_authentication_code.errors?.['required']">
                            API authentication code is required
                        </span>
                    </div> -->


                    <p class="mt-3">
                        <button class="btn btn-primary mr-8 px-4 py-2" data-bs-dismiss="modal" #closeSupplierBtn
                            type="button"  (click)="backclear()">Back</button>
                        <button class="btn btn-success px-4 py-2" type="submit">Add</button>
                    </p>


                </form>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="deleteModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Are you sure to delete the source?</p>
                <p class="mt-5">
                    <button class="btn btn-secondary mr-5" data-bs-dismiss="modal" #closeBtn>Cancel</button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteSupplier()">Confirm</button>
                </p>
            </div>
        </div>
    </div>
</div> -->