import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { apiurl } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";
import { RequestCategory } from '../models/RequestCategory';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
declare var bootstrap: any;


@Component({
  selector: 'app-external-category',
  templateUrl: './external-category.component.html',
  styleUrls: ['./external-category.component.scss']
})
export class ExternalCategoryComponent implements OnInit {

  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('closeCategoryBtn', { static: true }) closeCategoryBtn!: ElementRef;

  public external: string = "";
  private headers: any = {};
  skip: number = 0;
  limit: number = 10;
  getExternal: any;
  apiLoading = true;
  lastvalue: number = 0;
  count: number = 0;
  d: number = 1;
  pathurl: any;
  tooltipList: any;
  currentBody: any;
  emailInput: any;
  datas: any;
  idvalue: any;
  Edit: Boolean;
  userDetails: any;
  descriptionList: any;
  nameList: any;
  b2cname: any;
  updatedData: any;
  userName: boolean = false;
  categoryList: RequestCategory[];
  AddrequestCategory: RequestCategory;

  constructor(private service: AppService, private loader: NgxUiLoaderService, private toastr: ToastrService,) {
    this.Edit = false;
    this.categoryList = new Array<RequestCategory>();
    this.AddrequestCategory = new RequestCategory();

  }

  ngOnInit(): void {
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.pathurl = apiurl;
    this.getDigitalList();

  }

  filterList() {
    this.getcategorylistfilter();
  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  clear() {

    this.external = '';
    // this.categoryList = [];
    this.getDigitalList();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);
  }

  editCategory(list: any) {

    this.Edit = true;
    const element = document.getElementById('categoryModal') as HTMLElement;
    const addcategoryModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    addcategoryModal.show();
    this.idvalue = list.id;
    this.currentBody = this.getExternal.find((p: any) => {
      return p.id == list.id;

    });
    // console.log(this.currentBody.body);
    this.descriptionList = this.currentBody.description;
    this.nameList = this.currentBody.name;
    this.b2cname = this.currentBody.b2c_name;
    this.userName = true
    console.log("thasa", this.nameList)
    console.log("ddd", this.descriptionList)
    // this.currentId = this.currentBody.id;
    // this.getcategoryeditlist(this.idvalue);

  }


  editedDatas() {


    let postParams = {
      id: this.idvalue,
      name: this.b2cname,
      description: this.descriptionList,
      b2c_name: this.nameList

    }
    this.apiLoading = true;


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.service.getExternaleditlist(postParams, this.headers).subscribe((data: any) => {
      this.updatedData = data;
      this.getDigitalList();

      if (data.status == 1) {
        this.toastr.success(data.msg)
      } else if (data.status == 0) {
        this.toastr.error("Data updated failure")
      }

      // data = this.updatedDatas;
    },

      (error) => {
        this.toastr.error(error.error.Message)
        // console.log("error",error);

      }
    )
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  getcategorylistfilter(val?:any) {
    if(val){
      this.skip = 0;
    }
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();

    this.service.getNamelist(this.external, this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {

        this.loader.stop();
        this.apiLoading = false;

        this.count = data.totalCount;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }


        if (data) {

          this.count = data.totalCount;
          this.getExternal = data.list;
          // console.log(this.getExternal,"this.getExternal");
          
          this.toolTipInit();
          this.d = 1
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }


  getDigitalList() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.service.getExternalReport(this.limit, this.skip, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.getExternal = data.list;
        console.log("wwwww", this.getExternal)
        if (data) {
          this.apiLoading = false;
          this.count = data.totalCount;

          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }

        }
      },
      error: _error => {
        console.error(_error),
          this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * 10;
    this.lastvalue = this.skip + 10;
    localStorage.setItem('pageNo', pageNo);
    this.getDigitalList();
    // if(!this.dateRequire)
    //   this.getDigitalVoucher();
    // if(this.dateRequire)
    //   this.voucherClear();

  }


}
