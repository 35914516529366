import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AppService } from "src/app/restful-services/app-services/app.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { DeliveryEmail, RequestSetting } from "src/app/models/settingmenu";
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-settingsmenu",
  templateUrl: "./settingsmenu.component.html",
  styleUrls: ["./settingsmenu.component.scss"],
})
export class SettingsmenuComponent {
  RequestSetting: RequestSetting;
  DeliveryEmail: DeliveryEmail;
  role: any;
  userDetails: any;
  private headers: any = {};
  @ViewChild("inputFile") inputFile: any;
  idvalue: any;

  limit: number = 25;
  skip: number = 0;
  b: number = 1;
  count: number = 0;
  lastvalue: number = 0;
  apiLoading: boolean = false;
  settingList: any = [];
  amountval: any;
  flipKartBalance:number = 0;
  ezpinBalance:boolean = false;
  balanceDatas: any = [];
  amazonDatas:any = [];
  dtBal: number = 0;
  dtBalanceDatas: any = [];
  yggBal: number = 0;
  YggBalDatas: any = [];
  amazon:boolean = false;
  dtUnit: string = "";
  yggCurrency: string = "";
  josBalance:number = 0;
  source:string = "";
  bambooBalance: Array<any> = [];

  constructor(
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.RequestSetting = new RequestSetting();
    this.DeliveryEmail = new DeliveryEmail();
  }

  ngOnInit() {
    this.role = localStorage.getItem("role");
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.onChangesettinglist();
    this.getFlipkartBalance();
    this.getEzPinBal();
    this.getdtBal();
    this.yggBalance();
    this.josBalannce();
    this.getBambooBalance();
    // this.amazonBalance();
  }

  getFlipkartBalance() {
    this.loader.start();
    this._http.getFlkrtBalance(this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.balance) {
          this.flipKartBalance = data.balance;
        }else{
          this.flipKartBalance = 0;
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  getEzPinBal() {
    this.loader.start();
    this._http.getEzPin(this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data && (data.status === 0 || data.status === "0")) {
          this.balanceDatas = data.data;
          this.ezpinBalance = true;
        }else{
          this.toastr.error(data.msg);
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  getdtBal() {
    this.loader.start();
    this._http.getDtPin(this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data && (data.status === 0 || data.status === "0")) {
          this.dtBalanceDatas = data.data;
          this.dtBal = this.dtBalanceDatas[0].available;
          this.dtUnit = this.dtBalanceDatas[0].unit;
        }else{
          this.toastr.error(data.msg);
          this.dtBal = 0;
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }
  yggBalance() {
    this.loader.start();
    this._http.getyggBal(this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data && (data.status === 0 || data.status === "0")) {
          this.YggBalDatas = data.data;
          this.yggBal = this.YggBalDatas.balance_amount['amount'];
          this.yggCurrency = this.YggBalDatas.balance_amount['currency'];
        }else{
          this.toastr.error(data.msg);
          this.yggBal = 0;
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  amazonBalance(){
    this.loader.start();
    this._http.getamazonBal(this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        if(data && (data.status === 1 || data.status === "1")){
          this.amazon = true;
          this.amazonDatas = data.data;
        }else{
          this.toastr.error(data.msg);
        }
      },error: (e:any) => {
        this.loader.stop();
        this.toastr.error(e.error.Message);
      }
    })
  }

  josBalannce(){
    this.loader.start();
    this._http.getJoyalukkasBal(this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        if(data && (data.status === 1 || data.status === "1")){
          this.josBalance = data.data[0]['balance'];
          this.source = data.data[0]['source'];
        }else{
          this.toastr.error(data.msg);
        }
      },error: (e:any) => {
        this.loader.stop();
        this.toastr.error(e.error.Message);
      }
    })
  }

  getBambooBalance() {
    this.loader.start();
    const URL = "bamboo/balance"
    this._http.httpGet(URL,this.headers).subscribe({
      next: (data:any) => {
        this.loader.stop();
        if(data.status === 1) {
          this.bambooBalance = data.data;
        }
      }, error : e => {
        this.loader.stop();
      }
    })
  }


  onChangesettinglist() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();

    this._http.getsettingslist(this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.RequestSetting.order_amount = data.data.order_limit_amount;

        this.amountval = this.RequestSetting.order_amount;

        this.DeliveryEmail.success_email_list = data.data.success_email_list;
        this.DeliveryEmail.success_email_list_cc =
          data.data.success_email_list_cc;
        this.DeliveryEmail.failure_email_list = data.data.failure_email_list;
        this.DeliveryEmail.failure_email_list_cc =
          data.data.failure_email_list_cc;
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  Updateorderamount(RequestSetting: RequestSetting) {
    if (!this.RequestSetting.order_amount) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    if (!/^[0-9]+$/.test(this.RequestSetting.order_amount)) {
      this.toastr.error("Please enter approval limit in numbers");
      return;
    }

    if (
      this.RequestSetting.order_amount === "0" ||
      this.RequestSetting.order_amount === 0 ||
      /^0*$/.test(this.RequestSetting.order_amount)
    ) {
      this.toastr.error("Please enter approval limit greater than 0");
      return;
    }

    let params = {
      order_limit_amount: this.RequestSetting.order_amount,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.updateorderamount(params, this.headers).subscribe({
      next: (data: any) => {
        if (data.status == 1) {
          this.loader.stop();
          this.toastr.success("order amount limit update successfully");
        } else if (data.status === 0) {
          this.loader.stop();
          this.toastr.error(data.msg);
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes(".")) {
      event.target.value = inputValue.substring(0, inputValue.indexOf("."));
    }
  }

  letterOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  keyDownHandler(event: any) {
    if (event.code === "Space" && event.which === 32) {
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error("Number only accepted here");
      return false;
    } else {
      return true;
    }
  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      key
    );
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }

  UpdateEmailSettings(emailIDs: any) {
    console.log("email IDs: ", this.DeliveryEmail);

    let params = {
      success_email_list: this.DeliveryEmail.success_email_list,
      failure_email_list: this.DeliveryEmail.failure_email_list,
      success_email_list_cc: this.DeliveryEmail.success_email_list_cc,
      failure_email_list_cc: this.DeliveryEmail.failure_email_list_cc,
    };
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.saveEmails(params, this.headers).subscribe({
      next: (data: any) => {
        if (data.status == 1) {
          this.loader.stop();
          this.toastr.success(data.msg);
        } else if (data.status === 0) {
          this.loader.stop();
          this.toastr.error(data.msg);
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  // static commaSeparatedEmailsValidator(): ValidatorFn {
  //   console.log("in function Validate");
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     if (!control.value) {
  //       // If the control is empty, consider it valid
  //       return null;
  //     }

  //     const emails = control.value
  //       .split(",")
  //       .map((email: string) => email.trim());

  //     const isInvalid = emails.some(
  //       (email: any) => !Validators.email(new FormControl(email))
  //     );

  //     return isInvalid ? { invalidEmails: { value: control.value } } : null;
  //   };
  // }
}
