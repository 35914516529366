import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { AppService } from '../../restful-services/restful.service';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { RequestBrand } from '../../models/RequestBrand';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { browserRefresh } from '../../app.component';
import { Supplier } from '../../models/supplierList';
import { environment,environmentfiles } from "../../../environments/environment";
declare var bootstrap: any;


@Component({
  selector: 'brand-voucher-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class BKDashboardComponent implements OnInit {

  @ViewChild('pdflist', { static: true }) pdflist!: ElementRef;
  @ViewChild('closeBtn', { static: true }) closeBtn!: ElementRef;
  @ViewChild('closeApproveeBtn', { static: false }) closeApproveeBtn!: ElementRef;
  @ViewChild('closeImportBtn', { static: true }) closeImportBtn!: ElementRef;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: ChartData<'doughnut'> = {
    datasets: []
  };
  public doughnutChartType: ChartType = 'doughnut';

  public startOptions: INgxMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  public endOptions: INgxMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  public trackData: any;
  public trackCount: any;
  public trackDatas: any = [];
  public getstartDate: any;
  public getendDate: any;
  public startDate: any;
  public endDate: any;
  public showChart: boolean = false;
  public role: any = 'company';
  brandList: RequestBrand[];
  public company: string = "";
  public brand: string = "";
  public approved: string = "";
  public type: string = "";
  public modal: string = "";

  public category: string = "";
  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  user_type: any;
  userDetails: any;
  supplierType: any;
  approvalbrandList: any = [];
  voucherStatus: string = "";
  selectAll: boolean = false;
  showApproveReject: boolean = false;
  approvalStatus: string = '';
  supplierImportType: string = '';
  selectAllChecked: boolean = false;
  lastvalue: number = 0;
  @ViewChild('inputFile') inputFile: any;
  browserRefresh: boolean = false;
  showRemoveImport: boolean = true;
  rejectedStatus: string = "";
  supplierList: any;
  supplierName: any;
  supplierKeyword = 'supplier_name';
  supplierID: any;
  supplierDetail: Supplier;
  newSupplier: boolean = false;
  tooltipList: any;
  apiLoading: boolean = false;
  private headers: any = {};
  requestCouponBrand: RequestBrand;
  urlError: boolean = false;
  productURL: string = '';

  fileurls:any;

  @ViewChild('supplierForm', { static: true }) supplierForm: any;

  settingvalue:any;

  constructor(public datePipe: DatePipe, private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.brandList = new Array<RequestBrand>();
    this.supplierDetail = new Supplier();
    this.requestCouponBrand = new RequestBrand();
  }



  ngOnInit(): void {

    const d: Date = new Date();
    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    this.startOptions = {
      dateFormat: 'dd/mm/yyyy',
      disableSince: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() + 1 }
    }
    this.getstartDate = { date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() } };

    this.endOptions = {
      dateFormat: 'dd/mm/yyyy',
      disableUntil: { year: this.getstartDate.date.year, month: this.getstartDate.date.month, day: this.getstartDate.date.day }
    }
    this.getendDate = { date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() } };

    this.startDate = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    this.endDate = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

    this.category = "Digital";

    let page: any = localStorage.getItem('pagesNo');

    if (page !== null) {
      this.pageChanged(page);
    }
    else {
      this.getbrandRequestList();
    }

    this.browserRefresh = browserRefresh;
    if (this.browserRefresh === true) {
      this.p = 1;
      this.pageChanged(this.p);
    }

    if (this.role === 'company') {
      this.type = "ID";
    }
    if (this.role === 'brand') {
      this.type = "I";
    }

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL; 
  }

  addNewCoupon() {   

    this.brandList = [];
    localStorage.removeItem('voucherDetail');
    localStorage.removeItem('edit');
    localStorage.removeItem('authboolean');
    localStorage.removeItem('pdthide');
  
    this.router.navigate(['/add-product'], { replaceUrl: false });

  }

  filterList() {

    if (this.approved || this.type || this.category || this.brand || this.company) {
      this.skip = 0;
      this.p = 1;
    }
    this.getVoucherRequestListfilter();
  }

  getVoucherRequestListfilter() {   

    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    }
    else if (this.role === "company" || this.role === "brand") {
      this.user_type = 'Restricted';
    }
    if (this.role === "company") {
      this.type = 'ID';
      this.company = this.userDetails.UserName;
    }
    else if (this.role === "brand") {
      this.type = '';
      this.brand = this.userDetails.UserName;
    }

    this.showRemoveImport = true;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this._http.getVoucherList(this.limit, this.skip, this.company, this.brand, this.approved, this.type, this.category, this.user_type, this.userDetails.UserID, 'brand', this.headers).subscribe({
      next: (data: any) => {

        if (data) {
          this.count = data.count;
          this.brandList = data.data;
          this.apiLoading = false;
          this.toolTipInit();
          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  getbrandRequestList() {   

    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    }
    else if (this.role === "company" || this.role === "brand") {
      this.user_type = 'Restricted';
    }
    if (this.role === "company") {
      this.type = 'ID';
      this.company = this.userDetails.UserName;
    }
    else if (this.role === "brand") {
      this.type = '';
      this.brand = this.userDetails.UserName;
    }

    this.showRemoveImport = true;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this.apiLoading = true;


    // this._http.getVoucherList(this.limit, this.skip, this.company, this.brand, this.approved, this.type, this.category, this.user_type, this.userDetails.UserID, 'voucher', this.headers).subscribe({
    //   next: (data: any) => {

  this._http.getVoucherList(this.limit, this.skip, this.company, this.brand, this.approved, this.type,this.category, this.user_type, this.userDetails.UserID, 'brand',this.headers).subscribe({
      next: (data: any) => {


        this.loader.stop();
        if (data) {
          this.count = data.count;
          this.brandList = data.data;
          this.apiLoading = false;
          this.toolTipInit();
          if (this.skip == 0) {
            this.lastvalue = this.skip + 25;
          }
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })
  }

  editVoucher(brandList: RequestBrand) {  

    let dat: any = brandList.source;

    if (dat !== 'QwikCilver' && dat !== 'Ticket Express' && dat !== 'Amazon') {

      const start = new Date(brandList.start_date);
      const end = new Date(brandList.validity);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      let difference = Math.ceil(timeDiff / (1000 * 3600 * 24));
      brandList.validity = difference;

    }   

        this._http.brandDetails(brandList);
        const id = brandList.voucher_request_id;
        this.router.navigate(['/add-product'], {
        state: { id },
        replaceUrl: true
         });
  }

  pageChanged(pagesNo: any) {

    this.p = pagesNo;
    this.skip = (pagesNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pagesNo', pagesNo);
    this.getbrandRequestList();

    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.showRemoveImport = true;
    this.approvalbrandList = [];


  }




  clear() {

    this.company = '';
    if (this.role === 'company') {
      this.type = "ID";
    }
    else if (this.role === 'brand') {
      this.type = "I";
    }
    else {
      this.type = '';
    }
    this.brand = '';
    this.category = "Digital";
    this.approved = '';
    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.brandList = [];
    this.approvalbrandList = [];
    this.getbrandRequestList();

    this.p = 1;
    let page = this.p;
    this.pageChanged(page);  

  }

  viewGenerationList(brandList: RequestBrand) {

    this._http.voucherDetails(brandList);
    const id = brandList.voucher_request_id;
    localStorage.setItem('voucherDetail', JSON.stringify(brandList));
    this.router.navigate(['/product-order-view'], {
      queryParams :{Voucher_request_id : id},
      state: { id },
      replaceUrl: true
    });

    localStorage.removeItem('logvalue');
  }

  exportVoucher(voucherRequestId: number) {

    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    }
    else if (this.role === "company" || this.role === "brand") {
      this.user_type = "Restricted";
    }

    this._http.exportVoucherssDatas(voucherRequestId, this.user_type, this.userDetails.UserID, 'voucher',)
  }


  exportAllVoucher() {

    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    }
    else if (this.role === "company" || this.role === "brand") {
      this.user_type = "Restricted";
    }
    this._http.exportAllVouchers(this.user_type, this.userDetails.UserID, 'brand')
  }



  showSupplierImportModal() {
    this.supplierImportType = 'Internal';
    const element = document.getElementById('importModal') as HTMLElement;
    const importModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    importModal.show();
  }


  importVoucher(event: any) {

    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.csv')) {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierImportType == "" || this.supplierImportType == undefined || this.supplierImportType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    // this.closeImportBtn.nativeElement.click();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData: any = new FormData();
      formData.append('filename', event.target.files[0]);
      // formData.append('supplier_type', this.supplierImportType);
      formData.append('created_by', this.userDetails.UserID);
      formData.append('created_by_name', this.userDetails.UserName);
      // const selectedFile = event.target.files[0];
      // const fileReader = new FileReader();
      // fileReader.readAsBinaryString(selectedFile);
      // fileReader.onload = (e: any) => {
      //   let binaryDara = e.target.result;
      //   let workbook = XLSX.read(binaryDara, { type: 'binary' });
      //   // workbook.SheetNames.forEach(sheet=>{
      //   //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      //   //   console.log(this.batchData)
      //   // })
      // }
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };

      // this.loader.start();  

      this._http.importUserDataVoucher(formData, this.headers).subscribe({
        // this._http.importUserBulkApproval(formData,this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getbrandRequestList();
          }
          else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
          this.inputFile.nativeElement.value = '';
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        }
      })
    }
  }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = event.formatted;
    this.endOptions = {
      dateFormat: 'dd/mm/yyyy',
      disableUntil: { year: event.date.year, month: event.date.month, day: event.date.day },
      enableDates: [{ year: event.date.year, month: event.date.month, day: event.date.day }]
    }
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = event.formatted;
  }


  assignVoucher() {

    if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null ||
      this.supplierName == "" || this.supplierName == undefined || this.supplierName == null

    ) {
      this.toastr.error("Please select one aggregator");
      return;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    const params = {
      "voucherRequestIds": this.approvalbrandList,
      "status": this.approvalStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "supplier_id": this.supplierName,
      "approved_by_name": this.userDetails.UserName

    }

    this.loader.start();

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == 'A' || this.approvalStatus == 'Y') {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = '';
              this.supplierList = '';
              this.supplierForm.resetForm();
            }
            else if (this.rejectedStatus == 'R') {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = '';
              this.supplierList = '';
              this.supplierForm.resetForm();
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = '';
              this.supplierList = '';
            }
          }
          else {
            if (data.status == 2) {
              this.toastr.info("Voucher has been already approved or generated");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = '';
              this.supplierList = '';
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalbrandList = [];
          this.getbrandRequestList();
          this.supplierName = '';
          this.supplierList = '';
        }
      },
      error: error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    })
  }


  approveBrand() {


    const params = {
      "voucherRequestIds": this.approvalbrandList,
      "status": this.approvalStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "supplier_id": this.supplierName,
      "approved_by_name": this.userDetails.UserName
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };   

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == 'A' || this.approvalStatus == 'Y') {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
            else if (this.rejectedStatus == 'R') {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          }
          else {
            if (data.status == 2) {
              this.toastr.info("Voucher has been already approved or generated");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalbrandList = [];
          this.getbrandRequestList();
        }
      },
      error: error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    })
  }

  approveBrands(requestCouponBrand: RequestBrand) {

  
    const params = {
      "voucherRequestIds": this.approvalbrandList,
      "status": this.approvalStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "supplier_id": this.supplierName,
      "approved_by_name": this.userDetails.UserName
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {

            if (this.approvalStatus == 'A' || this.approvalStatus == 'Y') {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
            else if (this.rejectedStatus == 'R') {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          }
          else {
            if (data.status == 2) {
              this.toastr.info("Voucher has been already approved or generated");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalbrandList = [];
          this.getbrandRequestList();
        }
      },
      error: error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    })
  }

  Rejectbrand(requestCouponBrand: RequestBrand) {

    const params = {
      "voucherRequestIds": this.approvalbrandList,
      "status": this.rejectedStatus,
      "userId": this.userDetails.UserID,
      "user_name": this.userDetails.Name,
      "supplier_type": this.supplierType,
      "approved_by_name": this.userDetails.UserName,
    }


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == 'R') {
              this.toastr.success("Voucher rejected successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            }
          }
          else {
            if (data.statu == 2) {
              this.toastr.info("Voucher has been already approved or generated");
              this.selectAll = false;
              this.showApproveReject = false;
            }
          }
          this.approvalbrandList = [];
          this.getbrandRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      }
    })
  }

  approvalVoucherId(event: any, voucher: RequestBrand) {

    this.toolTipInit();
    this.selectAll = false;
    this.voucherStatus = voucher.status;
    if (event.target.checked == true) {
      this.approvalbrandList.push(voucher.voucher_request_id);
    }
    else {
      if (this.approvalbrandList.length > 0) {
        const index = this.approvalbrandList.findIndex((ch: any) => ch === voucher.voucher_request_id);
        this.approvalbrandList.splice(index, 1);
        const listIndex = this.brandList.findIndex((ch: any) => ch.voucher_request_id === voucher.voucher_request_id);
        this.brandList[listIndex].isSelected = false;
      }
    }
    if (this.approvalbrandList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false

    } else {
      this.showRemoveImport = true;
      this.showApproveReject = false;
    }
  }

  selectAllFn() {

    this.toolTipInit();
    this.selectAllChecked = true;
    let voucher: any = this.brandList;
    if (this.selectAll) {
      this.brandList.forEach((c: any) => c.isSelected = true);
      // console.log("test5",this.brandList.filter((v: any) =>v.status == 'R' || v.status == 'Y'));
      this.brandList.filter((v: any) => v.status == 'R' || v.status == 'Y').forEach((c: any) => this.approvalbrandList.push(c.voucher_request_id));
      this.selectAllChecked = true;
    }
    else {
      this.approvalbrandList = [];
      this.brandList.forEach((c: any) => c.isSelected = false);
      // this.selectAll = false;
      // this.selectAllChecked = false;
    }
    if (this.approvalbrandList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false;
      this.toolTipInit();
    } else {
      this.showApproveReject = false;
      this.showRemoveImport = true;
    }
  }


  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }

  

  blockVoucher(voucher: RequestBrand, blockStatus: any) {
  
    let requestParams = {
      "voucher_request_id": voucher.voucher_request_id,
      "status": blockStatus
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.loader.start();
    this._http.blockVoucher(requestParams, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.status == 1 && blockStatus == 1) {
          this.toastr.success("Voucher blocked successfully");
          this.getbrandRequestList();
        }
        else if (data.status == 1 && blockStatus == 0) {
          this.toastr.success("Voucher unblocked successfully");
          this.getbrandRequestList();
        }
        else {
          this.toastr.error(data.msg);
        }
      },
      error: _error => {
        this.loader.stop();
      }
    })
  }






  showModa(rejectedStatus: string) {

    this.rejectedStatus = rejectedStatus;
    this.supplierType = 'Internal';
    const element = document.getElementById('rejectModal') as HTMLElement;
    const rejectModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    rejectModal.show();
  }


  rejectshowModal(rejectedStatus: string, voucher: RequestBrand) {

    this.approvalbrandList.push(voucher.voucher_request_id);
    this.rejectedStatus = rejectedStatus;
    this.supplierType = 'Internal';
    const element = document.getElementById('rejectModal') as HTMLElement;
    const rejectModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    rejectModal.show();
  }



  approveshowModal(approvalStatus: string, brand: RequestBrand) {

    let token = localStorage.getItem("token");
    this.headers = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       })
     };

 
    this._http.getsettingslist(this.headers).subscribe({
      next: (res:any) => {

        if (res) {

          let setvalue = res.data.order_limit_amount;             
         
          localStorage.setItem('settingslist', setvalue);
      
        }
      }})

    this.settingvalue = localStorage.getItem('settingslist');

    this.requestCouponBrand = brand;
    let sourcevalue = brand;

    let totalval = sourcevalue.total;
    let discountval = sourcevalue.voucher_approve_admin;  

    let nullcount = 0;

    if (sourcevalue!.source == "" || sourcevalue!.source == undefined) {
      nullcount = nullcount + 1;

    }

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (discountval == "YES" && this.userDetails.role === 'Admin') {
      this.toastr.error("discount is more than 75% voucher approval by super admin");
      return;
    }

    else if(totalval >= this.settingvalue && this.userDetails.role === 'Admin'){

      this.toastr.error("voucher total amount is High. So, please get approval from super admin");
      return;
    }


    else {

      if (sourcevalue.source === 'QwikCilver' || sourcevalue.source === 'Ticket Express' || sourcevalue.source === 'Amazon') {

        this.approvalbrandList = [brand.voucher_request_id];
        this.approvalStatus = approvalStatus;
        this.supplierType = 'external';
        // this.approveBrands(this.requestCouponBrand);
        this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;

        const element = document.getElementById('approveModals') as HTMLElement;
        const approveModals = new Modal(element,
          {
            backdrop: 'static',
            keyboard: false
          });
        this.urlError = false;
        this.productURL = '';
        approveModals.show();
      }
      else {

        this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = 'internal';
        const element = document.getElementById('approveModalss') as HTMLElement;
        const approveModalss = new Modal(element,
          {
            backdrop: 'static',
            keyboard: false
          });
        this.urlError = false;
        this.productURL = '';
        approveModalss.show();

      }

    }
  }


  showSupplierModal(approvalStatus: string) {

    let qccount = 0;
    let othercount = 0;
    let nullcount = 0;

    let disArray: any = [];

    this.approvalbrandList.forEach((e: any) => {

      let selectrow = this.brandList.find((a: any) => a.voucher_request_id == e);

      let discountval: any = this.brandList.find((a: any) => {
        // console.log(a, a.voucher_approve_admin, a.voucher_request_id)
        return a.voucher_approve_admin == 'YES' && a.voucher_request_id == e
      });

      if (discountval) {

        disArray.push(discountval);
      }


      if (selectrow!.source == "" || selectrow!.source == undefined) {
        nullcount = nullcount + 1;
      }

      if (selectrow!.source == "QwikCilver" || selectrow!.source == "Ticket Express" || selectrow!.source == "Amazon") {

        qccount = qccount + 1;

      }
      else {
        othercount = othercount + 1;
      }

    });


    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }


    if (qccount > 0 && othercount > 0) {

      this.toastr.error("please select unique source rows");
      return;

    }
 

    let discountvalue: any = disArray.find((a: any) => {
      return a.voucher_approve_admin;
    });

    let disvalue: string = '';
    if (discountvalue) {

      disvalue = discountvalue.voucher_approve_admin;
    }  

    if (disvalue === 'YES' && this.userDetails.role === 'Admin') {

      // this.showApproveReject = false;
      // this.showRemoveImport = true;
      // this.clear();      
      this.toastr.error("discount is more than 75% voucher approval by super admin");
      return;

    }
    else {

      if (qccount > 0) {

        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = 'external';
        const element = document.getElementById('approveModals') as HTMLElement;
        const approveModals = new Modal(element,
          {
            backdrop: 'static',
            keyboard: false
          });
        this.urlError = false;
        this.productURL = '';
        approveModals.show();
        // this.approveBrands(this.requestCouponBrand);



      }
      else {

        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = 'internal';
        const element = document.getElementById('approveModalss') as HTMLElement;
        const approveModalss = new Modal(element,
          {
            backdrop: 'static',
            keyboard: false
          });
        this.urlError = false;
        this.productURL = '';
        approveModalss.show();
      }
    }
  }



  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void; }) => {
        tooltip.dispose();
      });
    }
    localStorage.removeItem('pagesNo');
  
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

}
