<h6 class="font-18 font-weight-400 mt-4 mb-3 text-left product-heading title">
    Brand {{Edit ? 'Update' : 'Creation'}}
  </h6>
  
  <div class="card">
    <div class="card-body">
      <form #brandEditForm="ngForm" name="form" autocomplete="off" (ngSubmit)="AddBrand(requestbrandedit)">
        <div class="row flex-wrap">
  
          <h4 class="adcol text-left">
            Brand Details :
          </h4>
  
          <div class="col-4">
            <div class="md-form form-sm my-3">
              <label for="category" class="mb-2">Category
                <span class="text-danger">*</span>
              </label>
  
              <select id="category" class="form-select" placeholder="Enter Category" [(ngModel)]="requestbrandedit.category" [ngModelOptions]="{ standalone: true }" required>
                <option value="" selected disabled hidden>Select Parent Category</option>
                <option *ngFor="let option of categoryData" [value]="option.id">{{ option.name }}</option>
              </select>
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['category']?.touched || brandEditForm.submitted) && brandEditForm.controls['category']?.errors?.['required']">
                Category is required
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="entity_name" class="mb-2">
                Entity Name <span class="text-danger">*</span>
              </label>
              <input id="entity_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.entity_name" placeholder="Enter Entity Name" (keypress)="onKeyPresscomma($event)" mdbInput autocomplete="off" name="entity_name" required (input)="restrictDecimals($event)" />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['entity_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['entity_name']?.errors?.['required']">
                Entity name is required
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="brand_name" class="mb-2">
                Brand name <span class="text-danger">*</span>
              </label>
              <input id="brand_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.brand_name" placeholder="Enter Brand Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="brand_name" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['brand_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['brand_name']?.errors?.['required']">
                Brand name is required
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="user_name" class="mb-2">
                User Name <span class="text-danger">*</span>
              </label>
              <input id="user_name" type="text" class="form-control" [(ngModel)]="requestbrandedit.user_name" maxlength="20" placeholder="Enter User Name" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="user_name" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['user_name']?.touched || brandEditForm.submitted) && brandEditForm.controls['user_name']?.errors?.['required']">
                User name is required
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="password" class="mb-2">
                Password <span class="text-danger">*</span>
              </label>
              <input id="password" type="password" class="form-control" [(ngModel)]="requestbrandedit.password" maxlength="50" placeholder="Enter Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="password" required />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['password']?.touched || brandEditForm.submitted) && brandEditForm.controls['password']?.errors?.['required']">
                Password is required
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="confirmpassword" class="mb-2">
                Confirm Password <span class="text-danger">*</span>
              </label>
              <input id="confirmpassword" type="password" class="form-control" [(ngModel)]="requestbrandedit.confirmpassword" maxlength="50" placeholder="Confirm Password" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" [disabled]="userpassword" name="confirmpassword" required compare="password" />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['required']">
                Confirm password is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['confirmpassword']?.touched || brandEditForm.submitted) && brandEditForm.controls['confirmpassword']?.errors?.['compare']">
                Passwords must match
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="email" class="mb-2">
                Email <span class="text-danger">*</span>
              </label>
              <input id="email" type="email" class="form-control" [(ngModel)]="requestbrandedit.email" maxlength="100" placeholder="Enter Email" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="email" required email />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['email']?.touched || brandEditForm.submitted) && brandEditForm.controls['email']?.errors?.['required']">
                Email is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['email']?.touched || brandEditForm.submitted) && brandEditForm.controls['email']?.errors?.['email']">
                Invalid email format
              </span>
            </div>
          </div>
  
          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="contact" class="mb-2">
                Contact <span class="text-danger">*</span>
              </label>
              <input id="contact" type="text" class="form-control" [(ngModel)]="requestbrandedit.contact" maxlength="15" placeholder="Enter Contact" mdbInput autocomplete="off" (keypress)="onKeyPresscomma($event)" name="contact" required number />
  
              <span class="text-danger" *ngIf="(brandEditForm.controls['contact']?.touched || brandEditForm.submitted) && brandEditForm.controls['contact']?.errors?.['required']">
                Contact is required
              </span>
              <span class="text-danger" *ngIf="(brandEditForm.controls['contact']?.touched || brandEditForm.submitted) && brandEditForm.controls['contact']?.errors?.['number']">
                Invalid contact number
              </span>
            </div>
          </div>

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="wallet" class="mb-2">Wallet</label>
              <input id="wallet" type="text" class="form-control"
                [(ngModel)]="requestbrandedit.wallet" maxlength="10" placeholder="Enter Wallet" 
                #wallet="ngModel" mdbInput autocomplete="off" (input)="restrictDecimals($event)" 
                (keypress)="onKeyPresscomma($event);numberOnly($event);restrictAlphabets($event)"
                [ngModelOptions]="{ standalone: true }">
            </div>
          </div>
          <!-- <span class="text-danger" *ngIf="
                (wallet.touched || brandEditForm.submitted) &&
                wallet.errors?.['required']">
                Wallet is required
              </span> -->
                    

          <div class="col-4 no-icon">
            <div class="md-form form-sm my-3">
              <label for="description" class="mb-2">
                Description: <span class="text-danger">*</span>  
              </label>
              <textarea id="description" [(ngModel)]="requestbrandedit.description"
                        class="form-control"
                        placeholder="Enter Description"
                        mdbInput
                        autocomplete="off"
                        (keypress)="onKeyPresscomma($event)"
                        [ngModelOptions]="{ standalone: true }"
                        required
                        name="description"></textarea>
                                
              <span class="text-danger" *ngIf="
                (brandEditForm.controls['description']?.touched || brandEditForm.submitted) &&
                brandEditForm.controls['description']?.errors?.['required']">
                Description is required
              </span>
            </div>
          </div>
          

          <div class="col-3">
            <div class="md-form form-sm my-3 css">
              <label class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10" for="inputFile">                  
                <input id="inputFile" type="file" accept="image/*" class="form-control d-none" id="inputFile" #inputFile
                       (change)="selectfile($event)" [(ngModel)]="requestbrandedit.file"
                       [ngModelOptions]="{ standalone: true }">
                <i class="fa fa-upload pr-1"></i> Upload Brand Image
              </label>
                                
              <!-- <span class="text-danger" *ngIf="(file.touched || brandEditForm.submitted) && file.errors?.['required']">
                file Upload is required
              </span> -->
            </div>
          </div>
          
          <div class="col-2 prealig">
            <label *ngIf="preshow">Preview Image :</label>
            <br>
            <img [src]="this.previews" class="preview" />
          
            <img *ngIf="Edit && !this.previews" class="preview" [src]="this.pathurl + this.imagevalue" />
          </div>
          


          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <hr>
          <br>


          <h4 class="adcol text-left">
              Bank Details :</h4>


              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="actnumber" class="mb-2">Account Number</label>
                  <input id="actnumber" type="text" class="form-control" [(ngModel)]="requestbrandedit.actnumber" maxlength="18" placeholder="Enter Account Number" (keypress)="onKeyPresscomma($event);onKeyPressspecialcharacters($event);numberOnly($event);restrictAlphabets($event)"
                    #actnumber="ngModel" mdbInput autocomplete="off" required [ngModelOptions]="{ standalone: true }">
                  <!-- <span class="text-danger" *ngIf="(actnumber.touched || brandEditForm.submitted) && actnumber.errors?.['required']">
                    account number is required
                  </span> -->
                </div>
              </div>
              
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="actholdername" class="mb-2">Account Holder Name</label>
                  <input id="actholdername" type="text" class="form-control" [(ngModel)]="requestbrandedit.actholdername" maxlength="60" placeholder="Enter Account Holder Name"
                    #actholdername="ngModel" mdbInput autocomplete="off" (input)="restrictDecimals($event)" (keypress)="alphabetsonly($event);onKeyPresscomma($event)"
                    required [ngModelOptions]="{ standalone: true }">
                  <!-- <span class="text-danger" *ngIf="(actholdername.touched || brandEditForm.submitted) && actholdername.errors?.['required']">
                    accountholder name is required
                  </span> -->
                </div>
              </div>
              
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="bankname" class="mb-2">Bank Name</label>
                  <input id="bankname" type="text" class="form-control" [(ngModel)]="requestbrandedit.bankname" maxlength="60" placeholder="Enter Bank Name"
                    #bankname="ngModel" mdbInput autocomplete="off" (keypress)="alphabetsonly($event);onKeyPresscomma($event)"
                    (input)="restrictDecimals($event)" required [ngModelOptions]="{ standalone: true }">
                  <!-- <span class="text-danger" *ngIf="(bankname.touched || brandEditForm.submitted) && bankname.errors?.['required']">
                    bank name is required
                  </span> -->
                </div>
              </div>
              
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="ifsc" class="mb-2">IFSC Code</label>
                  <input id="ifsc" type="text" class="form-control" [(ngModel)]="requestbrandedit.ifsc" maxlength="11" placeholder="Enter IFSC Code" (keypress)="onKeyPressspecialcharacters($event);onKeyPresscomma($event)"
                    #ifsc="ngModel" mdbInput autocomplete="off" required [ngModelOptions]="{ standalone: true }">
                  <!-- <span class="text-danger" *ngIf="(ifsc.touched || brandEditForm.submitted) && ifsc.errors?.['required']">
                    IFSC code is required
                  </span> -->
                </div>
              </div>
              
              <div class="col-4 no-icon">
                <div class="md-form form-sm my-3">
                  <label for="branch" class="mb-2">Bank Branch</label>
                  <input id="branch" type="text" class="form-control" [(ngModel)]="requestbrandedit.branch" maxlength="60" placeholder="Enter Branch"
                    #branch="ngModel" mdbInput autocomplete="off" (keypress)="alphabetsonly($event);onKeyPresscomma($event)"
                    (input)="restrictDecimals($event)" required [ngModelOptions]="{ standalone: true }">
                  <!-- <span class="text-danger" *ngIf="(branch.touched || brandEditForm.submitted) && branch.errors?.['required']">
                    branch is required
                  </span> -->
                </div>
              </div>
              
  
        </div>


            
          
      
          
  
        <!-- <div class="row flex-wrap">
          <div class="col-12 text-right">
            <button class="btn btn-primary btn-rounded waves-effect waves-light btn-md" type="submit" [disabled]="brandEditForm.invalid || loading">
              {{ Edit ? 'Update' : 'Create' }}
            </button>
          </div>
        </div> -->

          
        <div class="d-flex align-items-end justify-content-end">
            <button  class="btn btn-secondary mr-2" type="button" (click)="backclear()" >Cancel</button>
            <!-- <button  class="btn btn-success mr-2" type="button">Save</button> -->
            <button class="btn btn-success mr-2" type="submit" >{{Edit ? 'Update':'Save'}}</button>
       
        </div>
      </form>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>