<div class="adcolor side-nav" *ngIf="role === 'admin' && roletype === 'Admin'">
    <ul>
        <li>
            <div class="my-4 text-center">
                <img src="../../assets/images/vok logo inverse.png" height="40" />
            </div>
        </li>
    </ul>
    <ul class="menu-items" *ngFor="let item of items; let i = index">

        <li class="main-menus" *ngIf="item.children" [ngClass]="{'active': selectedItem === i}" [value]="item.value"
            (click)="toggleSubMenu(item, i,true)">

            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}

            <ul class="sub-menu-items1 pe-0" *ngIf="item.children && item.subMenuVisible">
                <li class="sublist ps-3 pb-3" *ngFor="let item2 of item.children;let x = index"
                    [ngClass]="{'active': selectedItem === item2.value?true:path===item2.activeurl?true:false}"
                    [value]="item2.value"
                    (click)='item2.children&&item2.children.length>0?itemSelect23(item2.value, item2.url,item2,i,x):itemSelect22(item2.value, item2.url,item2,i)'>
                    <img src="../../assets/images/icons/{{item2.icon}}"> {{item2.name}}
                    <ul class="sub-menu-items pe-0"  *ngIf="item2.children && item2.subMenuVisible">
                        <li class="sublist ps-3 pb-3" *ngFor="let item3 of item2.children;let indexs=index"
                        [ngClass]="{'active': selectedItem1 === item3.value?true:path===item3.activeurl?true:false}"
                            [value]="item3.value" (click)='itemSelect22(item3.value, item3.url,item,i,x)'>
                            <img src="../../assets/images/icons/{{item3.icon}}"> {{item3.name}}
                        </li>
                    </ul>
                </li>
            </ul>

        </li>
        <li class="main-menus1" *ngIf="!item.children" [ngClass]="{'active': selectedItem === i}" [value]="item.value"
            (click)='itemSelect(i, item.url)'>
            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}
        </li>
    </ul>
</div>



<div class="adcolor side-nav" *ngIf="roletype === 'SuperAdmin' && role === 'SuperAdmin'">
    <ul>
        <li>
            <div class="my-4 text-center">
                <img src="../../assets/images/vok logo inverse.png" height="40" />
            </div>
        </li>
    </ul>

    <ul class="menu-items" *ngFor="let item of items; let i = index">

        <li class="main-menus" *ngIf="item.children" [ngClass]="{'active': selectedItem === i}" [value]="item.value"
            (click)="toggleSubMenu(item, i)">

            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}
            <ul class="sub-menu-items1 pe-0" *ngIf="item.children && item.subMenuVisible">
                <li class="sublist ps-3 pb-3" *ngFor="let item2 of item.children;let x = index"
                    [ngClass]="{'active': selectedItem === item2.value?true:path===item2.activeurl?true:false}"
                    [value]="item2.value" (click)='item2.children&&item2.children.length>0?itemSelect23(item2.value, item2.url,item2,i,x):itemSelect22(item2.value, item2.url,item2,i)'>
                    <img src="../../assets/images/icons/{{item2.icon}}"> {{item2.name}}

                    <ul class="sub-menu-items pe-0"  *ngIf="item2.children && item2.subMenuVisible">
                        <li class="sublist ps-3 pb-3" *ngFor="let item3 of item2.children;let indexs=index"
                        [ngClass]="{'active': selectedItem1 === item3.value?true:path===item3.activeurl?true:false}"
                            [value]="item3.value" (click)='itemSelect22(item3.value, item3.url,item,i,x)'>
                            <img src="../../assets/images/icons/{{item3.icon}}"> {{item3.name}}
                        </li>
                    </ul>
                </li>
            </ul>

        </li>

        <li style="font-size: 13px;" class="main-menus1" *ngIf="!item.children"
            [ngClass]="{'active': selectedItem === i}" [value]="item.value" (click)='itemSelect(i, item.url)'>

            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}

        </li>
    </ul>



</div>


<div class="sides side-nav" *ngIf="role === 'company' || role === 'Company'">
    <ul>
        <li>
            <div class="my-4 text-center">
                <img src="../../assets/images/vok logo inverse.png" height="40" />
            </div>
        </li>
    </ul>
    <ul class="menu-item">
        <li *ngFor="let item of items; let i = index" [ngClass]="{'active': selectedItem === i}" [value]="item.value"
            (click)='itemSelect(i, item.url)'>
            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}
        </li>
    </ul>

</div>


<div class="side side-nav" *ngIf="role === 'brand'">
    <ul>
        <li>
            <div class="my-4 text-center">
                <img src="../../assets/images/vok logo inverse.png" height="40" />
            </div>
        </li>
    </ul>
    <ul class="menu-item">
        <li *ngFor="let item of items; let i = index" [ngClass]="{'active': selectedItem === i}" [value]="item.value"
            (click)='itemSelect(i, item.url)'>
            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}
        </li>
    </ul>
    <!-- <ul class="menu-item" >
        <li *ngFor="let item of items; let i = index" [ngClass]="{'active': selectedItem === i}"  [value]="item.value"  
         (click)='itemSelect(i, item.url)'>
            <img src="../../assets/images/icons/{{item.icon}}"> {{item.name}}
        </li>
    </ul> -->

</div>