import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../restful-services/restful.service';
import { apiurl } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse, JsonpInterceptor } from "@angular/common/http";

@Component({
  selector: 'loyalty-app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  @Output() loggedStatus = new EventEmitter<boolean>();
  private headers: any = {};
  userName: any;
  getWalletData:any;
  userDetails: any;
  role:any="Company"
  rolename:any;
  imageURL: any = "";
  companyName: any = "";
  apiLoading: boolean = true;
  localvalues:any;
  pathurl:any;
  wallet: any;
  userId:any;
  walletrole: any;
  // class:string;
  
  constructor(private router: Router,private service:AppService) { 
    // console.log(window.location.pathname) 
   //this.class=window.location.pathname==='/brandmgt'?'px-4 testHead bd-highlight text-end border-end my-4':'px-4 flex-fill bd-highlight text-end border-end my-4'
    setInterval(() => {
      let logged = localStorage.getItem('logged');
    if(logged && logged === 'in'){
      this.service.checkCookieExpiration();
    }
    }, 15000);       
  }

  
  
  ngOnInit() {

    let user = localStorage.getItem("userDetails"); 
    
       
    if (typeof user === 'string') {
      const parse = JSON.parse(user);  

      this.userName = parse.data.UserName;
      this.companyName = parse.data.CompanyName;
      this.rolename = parse.data.role; 
      this.userId = parse.data.UserID;     
  
      if(parse.data.ImageURL){

        this.pathurl =  apiurl;
        this.imageURL = parse.data.ImageURL !== "" ? parse.data.ImageURL : '<i class="fa-fa-user"></i>'
      }
     
    }
    let role = localStorage.getItem("role");
    this.walletrole = role ; 
    if(role === "Company"){
      this.getWallet();
    }
    
  }

  loggedIn() {
    return this.service.getToken() !== '';
  }
  isLoggedIn() {
    return this.service.isLoggedIn();
  }

  logout() {

    this.service.logout();
    localStorage.clear();
    this.loggedStatus.emit(false);
   this.router.navigate(['/login']);

  }

  getWallet(){

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.service.getWalletAmount(this.userId,this.headers).subscribe({
      next: (data: any) => {                       
        this.getWalletData = data;   
        this.wallet = this.getWalletData.wallet;
        console.log("rrrrr",this.getWalletData)
      },
      error: _error => {
        this.apiLoading = false;
        // this.loader.stop();
      }
    })
    // this.service.clientDetailswallet({
    //   clientwallet: this.getWalletData.wallet,
    // })
  }
}

