import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { AppService } from 'src/app/restful-services/app-services/app.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestBrandmgt } from 'src/app/models/Requestbrandmgt';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
import { apiurl } from "src/environments/environment";
import { DomSanitizer} from '@angular/platform-browser';
declare var bootstrap: any;



@Component({
  selector: 'app-brand-mgt-edit',
  templateUrl: './brand-mgt-edit.component.html',
  styleUrls: ['./brand-mgt-edit.component.scss']
})


export class BrandMgtEditComponent {

  requestbrandedit: RequestBrandmgt;
  role: any;
  userDetails: any;
  private headers: any = {};
  @ViewChild('inputFile') inputFile: any;
  idvalue: any;

  file:any = '';
  //category

  categoryData: any = [];


  Edit: Boolean;
  filevalue: any;


  userpassword: boolean = false;
  category:any = '';
  brand_name:any;
  imagevalue:any;
  imagevalues:any;
  pathurl:any;

  previews: any;
  preshow : boolean = false;


  entity_name:any;
  user_name:any;
  password:any;
  confirmpassword:any;
  email:any;
  contact:any;
  description:any;
  loading: boolean = false;



  constructor(private sanitizer:DomSanitizer,private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    // this.categoryList = new Array<RequestBrandmgt>();
    this.requestbrandedit = new RequestBrandmgt();

  }



  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

    this.onChangeParcategory();   

      const { id } = history.state;
  
      if (id) {
        localStorage.setItem('editID', id);
        this.idvalue = id;
        this.Edit = true;
      } else {
        this.idvalue = localStorage.getItem('editID');  
      
      }

      if(this.Edit){
        this.getbrandeditlist(this.idvalue);
      }
      
      else{     
    
        let editlist: string | null = localStorage.getItem("editlist");        
       
        try {
          let editval = JSON.parse(editlist || ''); 
          this.requestbrandedit = editval; 
          this.Edit = true;          
       
          this.requestbrandedit.brand_name = this.requestbrandedit.name;
          this.requestbrandedit.contact = this.requestbrandedit.contact_number;
          this.requestbrandedit.actnumber = this.requestbrandedit.account_number;
          this.requestbrandedit.actholdername = this.requestbrandedit.account_holder_name;
          this.requestbrandedit.bankname = this.requestbrandedit.bank_name;
          this.requestbrandedit.ifsc = this.requestbrandedit.bank_ifsc_code;
          this.requestbrandedit.branch = this.requestbrandedit.bank_branch;
          this.requestbrandedit.file = this.requestbrandedit.image;
          this.requestbrandedit.confirmpassword = this.requestbrandedit.password;
    
          this.imagevalues = this.requestbrandedit.image;   
          this.imagevalue = this.requestbrandedit.image;  
          this.userpassword = true;
          // Use the parsed data
        } 
        catch (error) {
          if (error instanceof SyntaxError) {
            // console.error('Invalid JSON string');
          } else {
            // console.error('Error parsing JSON string', error);
          }
        }
    
      
      }
      this.pathurl =  apiurl;
  }


  getbrandeditlist(id: any) {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };  

    this._http.getbrandeditlist(this.idvalue, this.headers).subscribe((data: any) => {

      this.requestbrandedit = data.data;

      localStorage.setItem('editlist', JSON.stringify(this.requestbrandedit));

      this.requestbrandedit.brand_name = this.requestbrandedit.name;
      this.requestbrandedit.contact = this.requestbrandedit.contact_number;
      this.requestbrandedit.actnumber = this.requestbrandedit.account_number;
      this.requestbrandedit.actholdername = this.requestbrandedit.account_holder_name;
      this.requestbrandedit.bankname = this.requestbrandedit.bank_name;
      this.requestbrandedit.ifsc = this.requestbrandedit.bank_ifsc_code;
      this.requestbrandedit.branch = this.requestbrandedit.bank_branch;
      this.requestbrandedit.file = this.requestbrandedit.image;
      this.requestbrandedit.confirmpassword = this.requestbrandedit.password;

      this.imagevalue = this.requestbrandedit.image;    
      this.userpassword = true;

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })

  }

  onChangeParcategory() {

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.getParcategory(this.headers).subscribe((data: any) => {

      this.categoryData = data.data;


    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }


  AddBrand(requestbrandedit: RequestBrandmgt) {    

    if (

      !this.requestbrandedit.category || !this.requestbrandedit.entity_name || !this.requestbrandedit.brand_name
     || !this.requestbrandedit.email  || !this.requestbrandedit.user_name  || !this.requestbrandedit.password
      || !this.requestbrandedit.contact 
      || !this.requestbrandedit.description || !this.requestbrandedit.confirmpassword

    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }

    
    const space = (this.requestbrandedit.entity_name || '').trim().length === 0;

    if(space){
  
      this.toastr.error("Please enter entity name");
      return;
  
    }

    const spaceone = (this.requestbrandedit.brand_name || '').trim().length === 0;

    if(spaceone){
  
      this.toastr.error("Please enter brand name");
      return;
  
    }

    const spacetwo = (this.requestbrandedit.description || '').trim().length === 0;

    if(spacetwo){
  
      this.toastr.error("Please enter description");
      return;
  
    }  

    if (!this.filevalue && !this.Edit)   
    {
      this.toastr.error("Please upload brand image");
      return;
    }

    if(this.requestbrandedit.password !== this.requestbrandedit.confirmpassword){

      this.toastr.error("Passwords do not match");
      return;
    } 
   


    var expressions = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    var regex = new RegExp(expressions);
    const result = this.requestbrandedit.email.match(regex);
    if (result == null) {
      this.toastr.error("Please enter valid Email");
      return;
    }

    if(this.requestbrandedit.actnumber){

      var expression = /^[0-9]{9,18}$/;
      var regex = new RegExp(expression);
      const resul = this.requestbrandedit.actnumber.match(regex);
      if (resul == null) {
        this.toastr.error("Please enter valid Account Number");
        return;
      }  
    }

   
if(this.requestbrandedit.ifsc){

  // var expression = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  var expression = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    var regex = new RegExp(expression);
    const results = this.requestbrandedit.ifsc.match(regex);
    if (results == null) {
      this.toastr.error("Please enter valid IFSC code");
      return;
    }
}


if (!/^[0-9]+$/.test(this.requestbrandedit.contact)) {
   
  this.toastr.error("Please enter contact number in numbers");
  return;
}


// if (!/^$|^[0-9]+$/.test(this.requestbrandedit.wallet)) {
//   this.toastr.error("Please enter wallet in numbers");
//   return;
// }  


    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', "multipart/form-data");
    const formData: any = new FormData();

    if(this.Edit){
     
      formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
      formData.append('name', this.requestbrandedit.brand_name);
      formData.append('entity_name', this.requestbrandedit.entity_name);
      formData.append('category', this.requestbrandedit.category);
      formData.append('user_name', this.requestbrandedit.user_name);
      formData.append('password', this.requestbrandedit.password);
      formData.append('email', this.requestbrandedit.email);
      formData.append('contact_number', this.requestbrandedit.contact);   
      if(this.requestbrandedit.wallet){
        formData.append('wallet', this.requestbrandedit.wallet);
      }
      formData.append('description', this.requestbrandedit.description);
      formData.append('created_by_id', this.userDetails.UserID);   

      formData.append('filepath', this.Edit ? this.imagevalue : null);
      formData.append('modify_by_id', this.Edit ? this.userDetails.UserID : "");
      formData.append('id', this.Edit ? this.idvalue : "");


      if(this.filevalue){
        formData.append('brand', this.filevalue);
      }
      else{
        formData.append('brand', '');
      }

      if(this.requestbrandedit.actnumber){
        formData.append('account_number', this.requestbrandedit.actnumber);
      }
      else{
        formData.append('account_number', '');
      }

      if(this.requestbrandedit.actholdername){
        formData.append('account_holder_name', this.requestbrandedit.actholdername);
      }
      else{
        formData.append('account_holder_name', '');
      }

      if(this.requestbrandedit.bankname){
        formData.append('bank_name', this.requestbrandedit.bankname);
      }
      else{
        formData.append('bank_name', '');
      }

      if(this.requestbrandedit.ifsc){
        formData.append('band_ifsc_code', this.requestbrandedit.ifsc);
      }
      else{
        formData.append('band_ifsc_code', '');
      }

      if(this.requestbrandedit.branch){
        formData.append('brand_branch', this.requestbrandedit.branch);
      }
      else{
        formData.append('brand_branch', '');
      }


    }


   else{
   
    formData.append('action', this.Edit ? 'UPDATE' : "CREATE");
    formData.append('name', this.requestbrandedit.brand_name);
    formData.append('entity_name', this.requestbrandedit.entity_name);
    formData.append('category', this.requestbrandedit.category);
    formData.append('user_name', this.requestbrandedit.user_name);
    formData.append('password', this.requestbrandedit.password);
    formData.append('email', this.requestbrandedit.email);
    formData.append('contact_number', this.requestbrandedit.contact);   
    if(this.requestbrandedit.wallet){
      formData.append('wallet', this.requestbrandedit.wallet);
    }
    formData.append('description', this.requestbrandedit.description);
    formData.append('created_by_id', this.userDetails.UserID);   


    if(this.filevalue){
      formData.append('brand', this.filevalue);
    }
    else{
      formData.append('brand', '');
    }

    if(this.requestbrandedit.actnumber){
      formData.append('account_number', this.requestbrandedit.actnumber);
    }
    else{
      formData.append('account_number', '');
    }

    if(this.requestbrandedit.actholdername){
      formData.append('account_holder_name', this.requestbrandedit.actholdername);
    }
    else{
      formData.append('account_holder_name', '');
    }

    if(this.requestbrandedit.bankname){
      formData.append('bank_name', this.requestbrandedit.bankname);
    }
    else{
      formData.append('bank_name', '');
    }

    if(this.requestbrandedit.ifsc){
      formData.append('band_ifsc_code', this.requestbrandedit.ifsc);
    }
    else{
      formData.append('band_ifsc_code', '');
    }

    if(this.requestbrandedit.branch){
      formData.append('brand_branch', this.requestbrandedit.branch);
    }
    else{
      formData.append('brand_branch', '');
    }

   }   

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    
    if (this.Edit) {

      this.loader.start();
      this._http.updatebrand(formData, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();

          if (data.status == 1) {
            // this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("brand updated successfully");
            this.Edit = false;
            this.router.navigate(['/brandmgt'], { replaceUrl: false });
            // this.addForm.resetForm();
          }

          else if (data.status === 0) {
            this.toastr.error(data.msg);
            // this.closeCategoryBtn.nativeElement.click(); 
            this.Edit = false;
            this.router.navigate(['/brandmgt'], { replaceUrl: false });
          }
        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          // this.closeCategoryBtn.nativeElement.click();
          this.Edit = false;
          this.router.navigate(['/brandmgt'], { replaceUrl: false });
        }
      })

    }

    else {
   
      this.loader.start();
      this._http.addNewbrand(formData, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();
          if (data.status == 1) {
            this.loader.stop();
            // this.closeCategoryBtn.nativeElement.click();
            this.toastr.success("brand added successfully");
            this.router.navigate(['/brandmgt'], { replaceUrl: false });
            // this.addForm.resetForm();

          }

          else if (data.status === 0) {
            this.loader.stop();
            this.toastr.error("Something is Wrong");
            // this.closeCategoryBtn.nativeElement.click();
            this.router.navigate(['/brandmgt'], { replaceUrl: false });

          }

        },
        error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          // this.closeCategoryBtn.nativeElement.click();        
          this.router.navigate(['/brandmgt'], { replaceUrl: false });
        }
      })
    }

  }


  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }


  backclear() {

    this.router.navigate(['/brandmgt'], { replaceUrl: false });
    localStorage.removeItem("editlist"); 
  }

  selectfile(event: any) {
 
    this.filevalue = event.target.files[0];   

    if(this.filevalue){

      this.pathurl =  apiurl;
      const blobUrl = URL.createObjectURL(this.filevalue);
      const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);   
      this.previews = safeblobUrl;
      this.preshow = true;

      this.toastr.success("brand image upload successfully");
      return;
    }
    else{
      this.preshow = false;
    }
  }


  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  letterOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  numberOnly(event: any) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toastr.error('Number only accepted here')
      return false
    }
    else {
      return true
    }

  }

  restrictAlphabets(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }


  alphabetsonly(event: KeyboardEvent) {
    // Get the key that was pressed
    const key = event.key;
  
    // Allow backspace, delete, arrow keys, and spaces
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === ' ') {
      return;
    }
  
    // Allow alphabets only
    if (!/[a-zA-Z]/.test(key)) {
      event.preventDefault();
    }
  }


}
